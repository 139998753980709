import { Chart } from "react-google-charts";
import { useRef } from "react";

const getTicksArrayForTrendingChart = (minValue, maxValue) => {
  // Figure out the largest number (positive or negative)
  var biggestNumber = Math.max(Math.abs(maxValue), Math.abs(minValue));

  // Round to an exponent of 10 appropriate for the biggest number
  var roundingExp = Math.floor(Math.log(biggestNumber) / Math.LN10);
  var roundingDec = Math.pow(10, roundingExp);

  // Round your max and min to the nearest exponent of 10
  var newMax = Math.ceil(maxValue / roundingDec) * roundingDec;
  var newMin = Math.floor(minValue / roundingDec) * roundingDec;

  // Determine the range of your values
  var range = Math.max(Math.abs(newMax), Math.abs(newMin));
  //(newMin<0)?newMax - newMin : newMax;
  var gridlines = 2;

  // Calculate the best factor for number of gridlines (2-5 gridlines)
  // If the range of numbers divided by 2 or 5 is a whole number, use it
  for (var i = 2; i <= 4; ++i) {
    if (Math.round(range / i) === range / i) {
      gridlines = i;
    }
  }
  var step = Math.round(range / gridlines);
  var ticksArr = [];
  if (newMin < 0) {
    for (i = 0; i >= newMin - step; i -= step) {
      ticksArr.push({ v: i, f: String(Math.abs(i)) });
      if (minValue >= i) {
        break;
      }
    }
    ticksArr.reverse();
  }
  if (newMax > 0) {
    for (i = 0; i <= newMax + step; i += step) {
      ticksArr.push(i);
      if (i > maxValue) {
        break;
      }
    }
  }
  return ticksArr;
};

const getTooltip = function (statuses, date, name, values) {
  var tooltip =
    '<div align="left" style="width: auto; float: left; table-layout: auto">' +
    '<table class="table table-hover table-condensed table-sm" >' +
    "<thead><tr><th>" +
    date +
    "</th></tr></thead>" +
    "<tbody>";

  let index = 0;
  for (let statValue of statuses) {
    if (statValue === name) {
      tooltip =
        tooltip +
        '<tr class="table-danger" key={' +
        index +
        "}><td><b>" +
        name.charAt(0).toUpperCase() +
        name.slice(1) +
        "</b>";
    } else {
      tooltip =
        tooltip +
        "<tr  key={" +
        index +
        "}><td>" +
        statValue.charAt(0).toUpperCase() +
        statValue.slice(1);
    }
    tooltip =
      tooltip +
      ": <b>" +
      Math.abs(getData(values[statValue]) ? values[statValue] : 0) +
      "</b></td></tr>";
    index++;
  }
  tooltip = tooltip + "</tbody></table></div>";

  return tooltip;
};

const getData = (data) => {
  let counter = 0;

  if (Array.isArray(data)) {
    if (!data.length) {
      return counter;
    } else {
      return data.length;
    }
  } else {
    return data;
  }
};

export default function QADefectsFoundCurrentRelease(props) {
  const currentIterationInfoRef = useRef();
  const currentDataTableRef = useRef();

  let pre_statuses = Object.entries(props?.jiraChartsInfo?.qaDefectsFound);

  let pre_statuses_data_treatment =
    pre_statuses.length > 0 ? pre_statuses[0][1].trendingQAStatMap : [];

  let obj_values = Object.values(pre_statuses_data_treatment);
  let index = 0;
  let count = 0;

  obj_values.forEach((item) => {
    if (Object.entries(item).length > count) {
      index = obj_values.indexOf(item);
      count = Object.entries(item).length;
    }
  });

  let bigger_obj = obj_values[index];
  let statuses = Object.keys(bigger_obj);

  var defectTitles = [
    "Issue ID",
    "Issue Summary",
    "Severity",
    "Assigne",
    "Reporter",
  ];

  const notSortedInfo =
    props.currentIteration === "Overall"
      ? props.jiraChartsInfo.qaDefectsFound[0]
      : props.jiraChartsInfo.qaDefectsFound[props.currentIteration];

  if (!notSortedInfo) {
    return (
      <div className="form-outline mt-4" id="error-message">
        <div className="alert alert-danger" role="alert">
          Data is empty!
        </div>
      </div>
    );
  }

  var maxValue = 0;
  var minValue = 0;

  let columnArray = ["Dates"];
  for (let status of statuses) {
    columnArray.push(status);
  }

  let valEmptyArray = [
    "Dates",
    0,
    "",
    0,
    "",
    0,
    "",
    0,
    "",
    0,
    "",
    0,
    "",
    0,
    "",
    0,
    "",
    0,
    "",
    0,
    "",
  ];

  let currentIterationInfo = {};

  if (notSortedInfo) {
    Object.keys(notSortedInfo)
      .sort(function (a, b) {
        return new Date(a).getTime() - new Date(b).getTime();
      })
      .forEach(function (key) {
        currentIterationInfo[key] = notSortedInfo[key];
      });
  }

  const currentIterationInfoCopy = JSON.parse(
    JSON.stringify(currentIterationInfo)
  );

  Object.values(currentIterationInfo.trendingQAStatMap).forEach((date) => {
    for (const key in date) {
      if (Array.isArray(date[key])) {
        date[key] = date[key].length;
      }
    }
  });

  currentIterationInfoRef.current = currentIterationInfo;

  var dataTable = [];
  var header = ["Dates"];
  for (var i = 1; i < columnArray.length; i++) {
    header.push(columnArray[i]);
    header.push({ type: "string", role: "tooltip", p: { html: true } });
  }
  dataTable.push(header);

  const datesArr = Object.keys(currentIterationInfo.trendingQAStatMap);
  var isStatEmpty = true;
  for (let date of datesArr) {
    let tempMaxValue = 0;
    let tempMinValue = 0;

    isStatEmpty = false;
    var tempStatObj = currentIterationInfo.trendingQAStatMap[date];

    let currentDateObjArray = [];
    currentDateObjArray.push(date);

    for (let status of statuses) {
      if (!tempStatObj[status]) {
        tempStatObj[status] = 0;
      }

      if (
        status === "Closed" ||
        status === "Resolved" ||
        status === "closed" ||
        status === "resolved"
      ) {
        tempMaxValue += tempStatObj[status];
      } else {
        if (tempStatObj[status] - tempStatObj[status] == 0) {
          tempStatObj[status] = -1 * Math.abs(tempStatObj[status]);
        }
        tempMinValue += tempStatObj[status];
      }
      currentDateObjArray.push(getData(tempStatObj[status]));
      currentDateObjArray.push(getTooltip(statuses, date, status, tempStatObj));
    }

    if (maxValue < tempMaxValue) {
      maxValue = tempMaxValue;
    }

    if (minValue > tempMinValue) {
      minValue = tempMinValue;
    }

    dataTable.push(currentDateObjArray);
  }

  if (isStatEmpty) {
    dataTable.push(valEmptyArray);
  }

  dataTable[0].forEach((header, index) => {
    if (typeof header === "string" && header !== "Dates") {
      return (dataTable[0][index] =
        header.charAt(0).toUpperCase() + header.slice(1));
    }
  });

  var data = dataTable;

  currentDataTableRef.current = dataTable;
  var ticksArr = getTicksArrayForTrendingChart(minValue, maxValue);

  var options = {
    title: "QE Defects found in current release",
    titleTextStyle: { fontSize: "16" },
    bars: "vertical",
    isStacked: true,
    hAxis: {
      slantedText: true,
      slantedTextAngle: 45,
      textStyle: { fontSize: 11 },
    },
    chartArea: { left: 70, top: 50, width: "65%", height: "65%" },
    tooltip: { isHtml: true },
    vAxis: {
      ticks: ticksArr,
      format: "#",
      minValue: 10,
    },
  };

  var chartEvents = [
    {
      eventName: "ready",
      callback: ({ chartWrapper, google }) => {
        var handler = async function () {
          const chart = chartWrapper.getChart();
          var selection = chart.getSelection();
          if (!selection || selection.length < 1) {
            return;
          }
          var row = selection[0].row;
          var col = selection[0].column;
          const issueMap = props.jiraChartsInfo?.issueMap
            ? props.jiraChartsInfo.issueMap
            : {};
          const mapStatuses = {
            1: "analyze",
            3: "closed",
            5: "code complete",
            7: "groomed",
            9: "grooming",
            11: "in progress",
            13: "new",
            15: "pre-grooming",
            17: "re-finement",
            19: "resolved",
            21: "rig review",
            23: "to verify",
          };

          let selectedDateData = currentIterationInfoCopy.trendingQAStatMap;
          let selectedDate = dataTable[row + 1][0];
          let selectedIssues = selectedDateData[selectedDate][mapStatuses[col]];

          var defectsInfoArray = [];
          Object.keys(issueMap).forEach((issueId) => {
            if (Array.isArray(selectedIssues)) {
              if (selectedIssues?.indexOf(issueId) >= 0) {
                defectsInfoArray.push(issueMap[issueId]);
              }
            }
          });

          let formattedTitle =
            mapStatuses[col].charAt(0).toUpperCase() +
            mapStatuses[col].slice(1);

          props.setModalData({
            title: formattedTitle + ": " + selectedDate,
            projectId: props.currentProjectId,
            createdDt: null,
            tableTitles: defectTitles,
            defects: defectsInfoArray,
          });
          if (defectsInfoArray.length < 1) {
            props.setShowModal(false);
          } else {
            props.setShowModal(true);
          }
        };

        google.visualization.events.addListener(
          chartWrapper.getChart(),
          "select",
          () => handler()
        );
      },
    },
  ];

  if (data[0] && data[0].length === 1) {
    options.vAxis.ticks = [
      { v: -10, f: "10" },
      { v: 0, f: "0" },
      0,
      10,
      20,
      30,
    ];
    data.forEach((item) => {
      if (data.indexOf(item) === 0) {
        item.push("Closed");
        item.push("Resolved");
      } else {
        item.push(0);
        item.push(0);
      }
    });
  }
  return (
    <Chart
      chartType="ColumnChart"
      width="100%"
      height="400px"
      data={data}
      options={options}
      chartEvents={chartEvents}
    />
  );
}
