import FetchUtils from "./FetchUtils";

class MSVersion {
  static async getMicroServicesVersions(token, credentials) {
    const headers = {
      authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    try {
      const response = await FetchUtils.fetch(
        process.env.REACT_APP_ADMIN_SERVICE_URL + "/users/microservice/info",
        "GET",
        headers
      );
      if (!response || response.status !== 200) {
        let errorMsg =
          "Microservices versions info cannot be taken from the server: " +
          (response?.message
            ? response.message
            : response.error
            ? response.error
            : "Unexpected server error [" + response.status + "]");
        return { message: errorMsg };
      }

      return response;
    } catch (err) {
      return {
        message:
          "Error getting Microservices versions info (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getLPReleaseVersion(token, credentials) {
    const headers = {
      authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    try {
      const response = await FetchUtils.fetch(
        process.env.REACT_APP_JIRA_SERVICE_URL +
          "/jira/latest-released-version",
        "GET",
        headers
      );

      return response;
    } catch (err) {
      return {
        message:
          "Error getting LaunchPad release version info (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }
}

export default MSVersion;
