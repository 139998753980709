import React, { useState, useEffect } from "react";
import Jira from "../../services/Jira";
import Projects from "../../services/Projects";
import ProjectTypes from "../../components/projects/ProjectTypes";
import ReactTooltip from "react-tooltip";
import ErrorMessage from "../../components/common/ErrorMessage";
import FaultContainmentChart from "../../components/charts/defectAnalyticsCharts/FaultContainmentChart";
import ComponentsChart from "../../components/charts/defectAnalyticsCharts/ComponentsChart";
import EscapedDefectsChart from "../../components/charts/defectAnalyticsCharts/EscapedDefectsChart";
import DiscoveredByChart from "../../components/charts/defectAnalyticsCharts/DiscoveredByChart";
import EnvironmentsChart from "../../components/charts/defectAnalyticsCharts/EnvironmentsChart";
import ResolutionChart from "../../components/charts/defectAnalyticsCharts/ResolutionChart";
import PhaseDetectedChart from "../../components/charts/defectAnalyticsCharts/PhaseDetectedChart";
import useProjects from "../../components/hooks/useProjects";
import { MultiSelect } from "react-multi-select-component";
import "../../components/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";

async function getAllProjects(token) {
  try {
    return await Projects.getAllProjects(token);
  } catch (err) {
    return {
      message: "Projects cannot be retrieved! (" + JSON.stringify(err) + ")",
    };
  }
}

async function getDefectAnaliticsChartsInfo(token, projectIds) {
  try {
    const resp = await Jira.getDefectAnaliticsChartsInfo(token, projectIds);
    return resp;
  } catch (err) {
    return {
      message: err.message ? err.message : JSON.stringify(err),
    };
  }
}

export default function DefectAnalyticsPage(props) {
  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);
  const [chartsInfo, setChartsInfo] = useState({});
  const [selectedProjectType, setSelectedProjectType] = useState([
    { projectTypeId: 0, projectTypeName: "All" },
  ]);
  const { projects, setProjects } = useProjects();
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [tooltipMessage, setTooltipMessage] = useState("");

  let localProps = {};
  Object.assign(localProps, props);
  localProps.style = "multy";
  localProps.styleValue = { height: "auto" };

  //get projects if the list is empty
  useEffect(() => {
    const handleProjectsInfo = async (e) => {
      if (!projects || Object.entries(projects).length === 0) {
        setLoading(true);
        setErrorMessage("");
        const response = await getAllProjects(props.token);
        setLoading(false);
        if (response.errMsg) {
          setErrorMessage(response.errMsg);
          setProjects([]);
        } else {
          if (!(response?.length > 0)) {
            setErrorMessage("Projects list is empty!");
            setProjects([]);
          } else {
            setErrorMessage(null);
            setProjects(response);
            let tmpPrjs = response.map((item) => {
              return { label: item.projectName, value: item.projectId };
            });
            setFilteredProjects(tmpPrjs);
          }
        }
      } else {
        let tmpPrjs = projects.map((item) => {
          return { label: item.projectName, value: item.projectId };
        });
        setFilteredProjects(tmpPrjs);
      }
    };
    props.changeStyle("defectAnalytics");
    handleProjectsInfo();
  }, [projects]);

  //disable button if more then 25 projects selected
  useEffect(() => {
    if (
      selectedProjects?.length >=
      process.env.REACT_APP_DEFECT_ANALITYCS_PROJECTS_COUNT
    ) {
      setIsButtonDisabled(true);
      setTooltipMessage(
        "You can select up to " +
          process.env.REACT_APP_DEFECT_ANALITYCS_PROJECTS_COUNT +
          " projects. Please remove extra selection."
      );
    } else {
      setIsButtonDisabled(false);
      setTooltipMessage("");
    }
  }, [selectedProjects]);

  useEffect(() => {
    setErrorMessage(null);
    if (!projects) {
      return;
    }
    let filteredByPrjTypeProjects = projects;

    if (
      selectedProjectType?.[0] &&
      selectedProjectType[0].projectTypeId !== 0
    ) {
      filteredByPrjTypeProjects = projects.filter((item) => {
        return item.projectTypeId === selectedProjectType[0].projectTypeId;
      });
    }
    let tmpPrjs = filteredByPrjTypeProjects.map((item) => {
      return { label: item.projectName, value: item.projectId };
    });

    setSelectedProjects([]);
    setFilteredProjects(tmpPrjs);
  }, [selectedProjectType]);

  const handleClick = () => {
    const handleChartsInfo = async (e) => {
      setChartsInfo({});

      if (selectedProjects?.length > 0) {
        setLoading(true);
        let selectedProjectsIds = selectedProjects.map((item) => {
          return item.value;
        });
        const response = await getDefectAnaliticsChartsInfo(
          props.token,
          selectedProjectsIds
        );
        if (response.errMsg) {
          setErrorMessage(response.errMsg);
          setChartsInfo({});
        } else {
          if (!response?.data || Object.keys(response.data).length <= 0) {
            setErrorMessage("Response does not contain any information!");
            setChartsInfo({});
          } else {
            setErrorMessage(null);
            setChartsInfo(response.data);
          }
        }
        setLoading(false);
      } else {
        setChartsInfo({});
      }
    };

    handleChartsInfo();
  };

  return (
    <div id="defect_analytics_main_container">
      {loading && (
        <div className="d-flex justify-content-center bg-transparent align-items-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      <div
        id="defect_analytics_main_container_1"
        className="bg-light container-fluid pt-3 pb-3"
        style={
          loading
            ? {
                pointerEvents: "none",
                opacity: "0.4",
              }
            : {}
        }
      >
        <h4 className="ms-4">Defect Analytics</h4>
        <ErrorMessage errorMessage={errorMessage} />
        <div id="" className="container-fluid pb-3 border">
          <div className="row mb-3 ms-3 me-3 mt-3">
            <div className="col">
              <label htmlFor="projectTypes" className="form-label fs-7 ">
                Program
              </label>
              <div id="new" style={{ height: "40px" }}>
                <ProjectTypes
                  id="projectTypes"
                  props={localProps}
                  handleChange={setSelectedProjectType}
                />
              </div>
            </div>
            <div className="col">
              <div
                data-tip={
                  "You can select up to  " +
                  process.env.REACT_APP_DEFECT_ANALITYCS_PROJECTS_COUNT +
                  " projects at the same time."
                }
              >
                <label htmlFor="projects" className="form-label fs-7 ">
                  Projects
                  <i> (not more than 25 projects are allowed for selection)</i>
                </label>
                <ReactTooltip type="info" />
              </div>
              <MultiSelect
                id="projects"
                options={filteredProjects}
                placeholder="Select Project(s)"
                onChange={setSelectedProjects}
                value={selectedProjects}
              />
            </div>
          </div>
          <div className="row mb-3 ms-3 me-3 mt-3">
            <div className="col-sm-3" data-tip={tooltipMessage}>
              <button
                className="btn  btn-primary  btn-block "
                disabled={isButtonDisabled ? true : false}
                onClick={() => handleClick()}
              >
                Request Info
              </button>
              <ReactTooltip type="info" multiline={true} />
            </div>
          </div>
        </div>
        <div className="row ms-3 me-3 mb-4 mt-3 justify-content-around">
          <div id="fault-containment-chart" className="col col-6">
            <div id="fault-containment-chart-root">
              <FaultContainmentChart
                chartsInfo={chartsInfo}
                projects={projects}
              />
            </div>
          </div>
          <div id="component-chart" className="col col-6">
            <div id="component-chart-root">
              <ComponentsChart chartsInfo={chartsInfo} projects={projects} />
            </div>
          </div>
        </div>
        <div className="row ms-3 me-3 mb-4 mt-3 justify-content-around">
          <div id="component-chart" className="col col-6">
            <div id="component-chart-root">
              <EscapedDefectsChart
                chartsInfo={chartsInfo}
                projects={projects}
              />
            </div>
          </div>
          <div id="discoveredBy-chart" className="col col-6">
            <div id="discoveredBy-chart-root">
              <DiscoveredByChart chartsInfo={chartsInfo} projects={projects} />
            </div>
          </div>
        </div>
        <div className="row ms-3 me-3 mb-4 mt-3 justify-content-around">
          <div id="environments-chart" className="col col-6">
            <div id="environments-chart-root">
              <EnvironmentsChart chartsInfo={chartsInfo} projects={projects} />
            </div>
          </div>
          <div id="resolution-chart" className="col col-6">
            <div id="resolution-chart-root">
              <ResolutionChart chartsInfo={chartsInfo} projects={projects} />
            </div>
          </div>
        </div>
        <div className="row ms-3 me-3 mb-4 mt-3 justify-content-around">
          <div id="phaseDetected-chart" className="col col-6">
            <div id="phaseDetected-chart-root">
              <PhaseDetectedChart chartsInfo={chartsInfo} projects={projects} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
