import React, { useState, useEffect, useRef } from "react";
import Qtest from "../../services/Qtest";
import Loading from "../../components/common/Loading";
import ErrorMessage from "../../components/common/ErrorMessage";
import TestCasesDevelopmentModal from "../../components/common/modals/TestCasesDevelopmentModal";
import useQtestInfo from "../../components/hooks/useQtestInfo";
import useProjectDetails from "../../components/hooks/useProjectDetails";
import useErrorMessage from "../../components/hooks/useErrorMessage";
import { Chart } from "react-google-charts";
import "../../components/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";

async function getTestDevelopmentInfoData(token, projectId) {
  try {
    const resp = await Qtest.getTestDevelopmentInfo(token, projectId);
    return resp;
  } catch (err) {
    return {
      message:
        "Error getting Test Development info (" + err.message
          ? err.message
          : JSON.stringify(err) + ")",
    };
  }
}

export default function TestDevelopmentPage(props) {
  const { testDevelopmentInfo, setTestDevelopmentInfo } = useQtestInfo();
  const { projectDetails } = useProjectDetails();
  const { setErrorMessage, errorMessage } = useErrorMessage();
  const [loading, setLoading] = useState(true);
  const [iterations, setIterations] = useState([]);
  const [currentIteration, setCurrentIteration] = useState("All");
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState();
  const currentIterationInfoTCDevRef = useRef();
  const currentIterationInfoAutoDevRef = useRef();

  useEffect(() => {
    const handleQtestDetails = async (e) => {
      var currentProjectId = props.currentProjectId;
      var url = window.location.href;
      let res = /project\/(\d+)/g.exec(url);

      if (!currentProjectId) {
        if (res?.length > 1) {
          currentProjectId = res[1];
          props.setCurrentProjectId(currentProjectId);
        } else {
          setErrorMessage("Project is not selected!");
          setLoading(false);
          return;
        }
      }

      if (
        !testDevelopmentInfo?.testDevelopmentMap ||
        testDevelopmentInfo.projectId !== currentProjectId
      ) {
        const response = await getTestDevelopmentInfoData(
          props.token,
          currentProjectId
        );

        if (response.statusCode !== 200) {
          setErrorMessage(response.message);
          setTestDevelopmentInfo(null);
        } else {
          response.projectTypeId += "";
          if (response.qtestTestCaseDevTrendingDto) {
            response.qtestTestCaseDevTrendingDto.projectId = response.projectId;
          }
          setTestDevelopmentInfo(response.qtestTestCaseDevTrendingDto);
          setIterations(
            sortIterationsByStartDate(
              Object.keys(
                response.qtestTestCaseDevTrendingDto?.testDevelopmentMap
              )
            )
          );
        }
      } else {
        if (testDevelopmentInfo?.testDevelopmentMap) {
          setTestDevelopmentInfo(testDevelopmentInfo);
          saveIterations(Object.keys(testDevelopmentInfo?.testDevelopmentMap));
        }
      }
      setLoading(false);
    };

    props.changeStyle("testdevelopment");
    handleQtestDetails();
  }, [props.currentProjectId, testDevelopmentInfo?.testDevelopmentMap]);

  const sortIterationsByStartDate = (iterationsList) => {
    let sorted = ["All"];
    projectDetails?.lpIterations.sort((a, b) =>
      a.startDate > b.startDate ? 1 : b.startDate > a.startDate ? -1 : 0
    );
    projectDetails?.lpIterations.forEach((item) => {
      if (iterationsList.indexOf(item.iterationName) >= 0) {
        sorted.push(item.iterationName);
      }
    });
    return sorted;
  };

  const saveIterations = (iterationsList) => {
    let list = ["All"];
    if (iterationsList) {
      list = list.concat(iterationsList.filter((item) => item !== "All"));
    }
    list = sortIterationsByStartDate(list);
    return setIterations(list);
  };

  const handleIterationClick = (event) => {
    setCurrentIteration(event.target.id);
  };

  const getTooltip = function (date, name, values, tableName) {
    let statuses =
      tableName === "automationTrending"
        ? {
            Automated: "Automated",
            AutomationCandidate: "Automation Candidate",
            ManualOnly: "Manual Only",
            NotEvaluated: "Not Evaluated",
          }
        : {
            InProgress: "In Progress",
            Baselined: "Baselined",
            ReadyForBaseline: "Ready For Baseline",
            New: "New",
          };

    var tooltip =
      '<div align="left" style="width: auto; float: left; table-layout: auto">' +
      '<table class="table table-hover table-condensed table-sm" >' +
      "<thead><tr><th>" +
      date +
      "</th></tr></thead>" +
      "<tbody>";

    for (let statValue in statuses) {
      if (statuses[statValue] === name) {
        tooltip = tooltip + '<tr class="table-danger"><td><b>' + name + "</b>";
      } else {
        tooltip = tooltip + "<tr><td>" + statuses[statValue];
      }
      tooltip =
        tooltip +
        ": <b>" +
        Math.abs(values[statValue] ? values[statValue] : 0) +
        "</b></td></tr>";
    }
    tooltip = tooltip + "</tbody></table></div>";

    return tooltip;
  };

  const getTicksArrayForTrendingChart = (minValue, maxValue) => {
    // Figure out the largest number (positive or negative)
    var biggestNumber = Math.max(Math.abs(maxValue), Math.abs(minValue));

    // Round to an exponent of 10 appropriate for the biggest number
    var roundingExp = Math.floor(Math.log(biggestNumber) / Math.LN10);
    var roundingDec = Math.pow(10, roundingExp);

    // Round your max and min to the nearest exponent of 10
    var newMax = Math.ceil(maxValue / roundingDec) * roundingDec;
    var newMin = Math.floor(minValue / roundingDec) * roundingDec;

    // Determine the range of your values
    var range = Math.max(Math.abs(newMax), Math.abs(newMin));
    var gridlines = 2;

    // Calculate the best factor for number of gridlines (2-5 gridlines)
    // If the range of numbers divided by 2 or 5 is a whole number, use it
    for (var i = 2; i <= 4; ++i) {
      if (Math.round(range / i) === range / i) {
        gridlines = i;
      }
    }
    var step = Math.round(range / gridlines);
    var ticksArr = [];
    if (newMin < 0) {
      for (i = 0; i >= newMin - step; i -= step) {
        ticksArr.push({ v: i, f: String(Math.abs(i)) });
        if (minValue >= i) {
          break;
        }
      }
      ticksArr.reverse();
    }
    if (newMax > 0) {
      for (i = 0; i <= newMax + step; i += step) {
        ticksArr.push(i);
        if (i > maxValue) {
          break;
        }
      }
    }

    return ticksArr;
  };

  const findMaxValue = (obj) => {
    let maxVal = -Infinity;

    for (let prop in obj) {
      if (typeof obj[prop] === "number" && obj[prop] > maxVal) {
        maxVal = obj[prop];
      }
    }

    return maxVal;
  };

  const findMinValue = (obj) => {
    let minVal = Infinity;

    for (let prop in obj) {
      if (typeof obj[prop] === "number" && obj[prop] < minVal) {
        minVal = obj[prop];
      }
    }

    return minVal;
  };

  const TestDevelopmentTrendingChart = () => {
    const getValue = (value) => {
      return value ? value.length : 0;
    };
    let notSortedInfo =
      testDevelopmentInfo.testDevelopmentMap[currentIteration];

    if (!notSortedInfo) {
      return (
        <div className="form-outline mt-4" id="error-message">
          <div className="alert alert-danger" role="alert">
            Data is empty!
          </div>
        </div>
      );
    }

    let columnArray = [
      "Dates",
      "In Progress",
      "Baselined",
      "Ready For Baseline",
      "New",
    ];
    let valEmptyArray = ["Dates", 0, "", 0, "", 0, "", 0, ""];
    var currentIterationTrendingInfo = {};

    if (notSortedInfo) {
      Object.keys(notSortedInfo)
        .sort(function (a, b) {
          return new Date(a).getTime() - new Date(b).getTime();
        })
        .forEach(function (key) {
          currentIterationTrendingInfo[key] = notSortedInfo[key];
        });
    }

    currentIterationInfoTCDevRef.current = currentIterationTrendingInfo;

    var dataTable = [];
    var header = ["Dates"];

    for (var i = 1; i < columnArray.length; i++) {
      header.push(columnArray[i]);
      header.push({ type: "string", role: "tooltip", p: { html: true } });
    }
    dataTable.push(header);

    const datesArr = Object.keys(currentIterationTrendingInfo);
    var isStatEmpty = true;

    for (let date of datesArr) {
      isStatEmpty = false;
      var tempStatObj = {};
      tempStatObj.InProgress = getValue(
        currentIterationTrendingInfo[date].InProgress
      );
      tempStatObj.Baselined = getValue(
        currentIterationTrendingInfo[date].Baselined
      );
      tempStatObj.ReadyForBaseline = getValue(
        currentIterationTrendingInfo[date]["Ready For Baseline"]
      );
      tempStatObj.New = getValue(currentIterationTrendingInfo[date].New);

      var dataArray = [
        date,
        tempStatObj.InProgress,
        getTooltip(date, "InProgress", tempStatObj),
        tempStatObj.Baselined,
        getTooltip(date, "Baselined", tempStatObj),
        tempStatObj.ReadyForBaseline,
        getTooltip(date, "Ready For Baseline", tempStatObj),
        tempStatObj.New,
        getTooltip(date, "New", tempStatObj),
      ];

      dataTable.push(dataArray);
    }

    let maxTickValue = findMaxValue(tempStatObj);
    let minTickValue = findMinValue(tempStatObj);
    var ticksArr = getTicksArrayForTrendingChart(minTickValue, maxTickValue);

    if (isStatEmpty) {
      dataTable.push(valEmptyArray);
    }

    var data = dataTable;
    var options = {
      title: "Test Case Development Trending",
      titleTextStyle: { fontSize: "16" },
      bars: "vertical",
      isStacked: true,
      hAxis: {
        slantedText: true,
        slantedTextAngle: 45,
        textStyle: { fontSize: 11 },
      },
      vAxis: { ticks: ticksArr },
      chartArea: { left: 70, top: 50, width: "65%", height: "65%" },
      tooltip: { isHtml: true },
    };
    var chartEvents = [
      {
        eventName: "ready",
        callback: ({ chartWrapper, google }) => {
          var handler = async function (e) {
            const chart = chartWrapper.getChart();
            var selection = chart.getSelection();
            if (!selection || selection.length < 1) {
              return;
            }
            var row = selection[0].row;
            var col = selection[0].column;
            var selectedStatus = dataTable[0][col];
            var selectedDate = dataTable[row + 1][0];
            let testCasesArray = [];
            let testCaseInfoData = testDevelopmentInfo.testcases;
            let currentTestCases =
              currentIterationInfoTCDevRef.current[selectedDate][
                selectedStatus
              ];

            currentTestCases?.forEach((item) => {
              testCasesArray.push({
                testCaseId: testCaseInfoData[item].testCaseId,
                testCaseDescription: testCaseInfoData[item].testCaseName,
                link: testCaseInfoData[item].webUrl,
              });
            });

            setModalData({
              title: `${selectedDate} : ${selectedStatus}`,
              testCasesData: testCasesArray,
            });
            setShowModal(true);
          };

          google.visualization.events.addListener(
            chartWrapper.getChart(),
            "select",
            handler
          );
        },
      },
    ];

    return (
      <Chart
        chartType="ColumnChart"
        width="100%"
        height="400px"
        data={data}
        options={options}
        chartEvents={chartEvents}
      />
    );
  };

  const TestAutomationTrendingChart = () => {
    const getValue = (value) => {
      return value ? value.length : 0;
    };
    let notSortedInfo =
      testDevelopmentInfo.automationTrendingMap[currentIteration];

    if (!notSortedInfo) {
      return (
        <div className="form-outline mt-4" id="error-message">
          <div className="alert alert-danger" role="alert">
            Data is empty!
          </div>
        </div>
      );
    }

    let columnArray = [
      "Dates",
      "Automated",
      "Automation Candidate",
      "Manual Only",
      "Not Evaluated",
    ];
    let valEmptyArray = ["Dates", 0, "", 0, "", 0, "", 0, ""];
    var currentIterationAutomationTrendingInfo = {};

    if (notSortedInfo) {
      Object.keys(notSortedInfo)
        .sort(function (a, b) {
          return new Date(a).getTime() - new Date(b).getTime();
        })
        .forEach(function (key) {
          currentIterationAutomationTrendingInfo[key] = notSortedInfo[key];
        });
    }

    currentIterationInfoAutoDevRef.current =
      currentIterationAutomationTrendingInfo;

    var dataTable = [];
    var header = ["Dates"];

    for (var i = 1; i < columnArray.length; i++) {
      header.push(columnArray[i]);
      header.push({ type: "string", role: "tooltip", p: { html: true } });
    }
    dataTable.push(header);

    const datesArr = Object.keys(currentIterationAutomationTrendingInfo);
    let isStatEmpty = true;

    for (let date of datesArr) {
      isStatEmpty = false;
      var tempStatObj = {};
      tempStatObj.Automated = getValue(
        currentIterationAutomationTrendingInfo[date].Automated
      );
      tempStatObj.AutomationCandidate = getValue(
        currentIterationAutomationTrendingInfo[date]["Automation Candidate"]
      );
      tempStatObj.ManualOnly = getValue(
        currentIterationAutomationTrendingInfo[date]["Manual Only"]
      );
      tempStatObj.NotEvaluated = getValue(
        currentIterationAutomationTrendingInfo[date]["Not Evaluated"]
      );

      var dataArray = [
        date,
        tempStatObj.Automated,
        getTooltip(date, "Automated", tempStatObj, "automationTrending"),
        tempStatObj.AutomationCandidate,
        getTooltip(
          date,
          "Automation Candidate",
          tempStatObj,
          "automationTrending"
        ),
        tempStatObj.ManualOnly,
        getTooltip(date, "Manual Only", tempStatObj, "automationTrending"),
        tempStatObj.NotEvaluated,
        getTooltip(date, "Not Evaluated", tempStatObj, "automationTrending"),
      ];

      dataTable.push(dataArray);
    }

    let maxTickValue = findMaxValue(tempStatObj);
    let minTickValue = findMinValue(tempStatObj);
    var ticksArr = getTicksArrayForTrendingChart(minTickValue, maxTickValue);

    if (isStatEmpty) {
      dataTable.push(valEmptyArray);
    }

    var data = dataTable;
    var options = {
      title: "Test Automation Trending",
      titleTextStyle: { fontSize: "16" },
      bars: "vertical",
      isStacked: true,
      hAxis: {
        slantedText: true,
        slantedTextAngle: 45,
        textStyle: { fontSize: 11 },
      },
      vAxis: { ticks: ticksArr },
      chartArea: { left: 70, top: 50, width: "65%", height: "65%" },
      tooltip: { isHtml: true },
    };

    var chartEvents = [
      {
        eventName: "ready",
        callback: ({ chartWrapper, google }) => {
          var handler = async function (e) {
            const chart = chartWrapper.getChart();
            var selection = chart.getSelection();
            if (!selection || selection.length < 1) {
              return;
            }
            var row = selection[0].row;
            var col = selection[0].column;
            var selectedStatus = dataTable[0][col];
            var selectedDate = dataTable[row + 1][0];
            let testCasesArray = [];
            let testCaseInfoData = testDevelopmentInfo.testcases;
            let currentTestCases =
              currentIterationInfoAutoDevRef.current[selectedDate][
                selectedStatus
              ];

            currentTestCases?.forEach((item) => {
              testCasesArray.push({
                testCaseId: testCaseInfoData[item].testCaseId,
                testCaseDescription: testCaseInfoData[item].testCaseName,
                link: testCaseInfoData[item].webUrl,
              });
            });

            setModalData({
              title: `${selectedDate} : ${selectedStatus}`,
              testCasesData: testCasesArray,
            });
            setShowModal(true);
          };

          google.visualization.events.addListener(
            chartWrapper.getChart(),
            "select",
            handler
          );
        },
      },
    ];

    return (
      <Chart
        chartType="ColumnChart"
        width="100%"
        height="400px"
        data={data}
        options={options}
        chartEvents={chartEvents}
      />
    );
  };

  return (
    <div id="main_container">
      <TestCasesDevelopmentModal
        isOpen={showModal}
        setShowModal={setShowModal}
        setModalData={setModalData}
        data={modalData}
        token={props.token}
      />
      {loading ? (
        <Loading />
      ) : (
        <div
          id="main_container_1"
          className="bg-light  container-fluid pt-3 pb-3"
        >
          <h4 className="ms-4">Test Case Development</h4>
          <ErrorMessage errorMessage={errorMessage} />
          <div id="sprints-container" className="container-fluid pt-3 pb-3">
            <div
              className="btn-group ms-4"
              role="group"
              aria-label="Iterations"
            >
              {iterations &&
                iterations.map((iteration, index) => {
                  return (
                    <div className={"ms-1"} key={index}>
                      <button
                        type="button"
                        key={index}
                        id={iteration}
                        className={
                          "btn btn-outline-primary " +
                          (iteration === currentIteration ? "active" : "")
                        }
                        onClick={handleIterationClick}
                      >
                        {iteration === "All" ? "Overall" : iteration}
                      </button>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="row ms-3 row-cols-auto justify-content-around">
            <div className="col col-5 mb-4">
              <div id="test-execution-summary-root">
                {testDevelopmentInfo?.testDevelopmentMap && (
                  <TestDevelopmentTrendingChart />
                )}
              </div>
            </div>
            <div className="col col-5 mb-4">
              <div id="test-execution-summary-root">
                {testDevelopmentInfo?.automationTrendingMap && (
                  <TestAutomationTrendingChart />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
