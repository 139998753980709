import React, { useEffect, useState } from "react";
import PlusButton from "./PlusButton";
import useProjectDetails from "../hooks/useProjectDetails";
import TrashButton from "./TrashButton";
import DatePicker from "react-datepicker";
import ADO from "../../services/ADO";
import {
  MenuItem,
  Autocomplete,
  TextField,
  Tooltip,
  Chip,
} from "@mui/material";

const getTestSuitesRequest = async (token, program, project, plandIds) => {
  try {
    const response = await ADO.getADOTestSuites(
      token,
      program,
      project,
      plandIds
    );
    return response;
  } catch (error) {
    return null;
  }
};

const getIterationsRequest = async (token, program) => {
  try {
    const response = await ADO.getADOIterations(token, program);
    return response;
  } catch (error) {
    return null;
  }
};

const getADODates = async (token, projectName, path) => {
  try {
    const response = await ADO.getADOIterationDates(token, projectName, path);
    return response;
  } catch (err) {
    return {
      message:
        "Error getting ado iteration dates. (" + err.message
          ? err.message
          : JSON.stringify(err) + ")",
    };
  }
};

const ADOSprintsManagementTool = ({
  props,
  editMode,
  testPlansReleases,
  ADORequestData,
  handleADOSprintsRequestData,
}) => {
  const { projectDetails } = useProjectDetails();
  const [isIterationsPopulated, setIsIterationsPopulated] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [iterations, setIterations] = useState([]);
  const [selectedTestCyclesSuites, setSelectedTestCyclesSuites] = useState([]);
  const [testCyclesSuitesOptions, setTestCyclesSuitesOptions] = useState([]);
  const [selectedIterationPath, setSelectedIterationPath] = useState([]);
  const [iterationPathOptions, setIterationPathOptions] = useState([]);
  const [firstCallMade, setFirstCallMade] = useState(false);
  const [manualDate, setManualDate] = useState(false);

  const tooltipStyles = {
    tooltip: {
      sx: {
        backgroundColor: "#66b9ff",
        color: "#ffffff",
        fontSize: "12px",
      },
    },
    arrow: {
      sx: {
        color: "#66b9ff",
      },
    },
  };

  useEffect(() => {
    if ((projectDetails && !editMode) || !testPlansReleases.length) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  });

  useEffect(() => {
    if (projectDetails) {
      handlePopulateFields();
    }
  }, [projectDetails]);

  useEffect(() => {
    const handleTestSuites = async () => {
      // Delete this line 81 when backend is deployed [QE-2982]
      let program = ADORequestData[0]?.adoOrgName;
      let project = testPlansReleases[0]?.program;
      let planIds = testPlansReleases
        .map((testPlan) => {
          let string = testPlan.id;
          let pos = string.split(":-:");
          // Replace this below line when backend is deployed with
          // pos[1] + "-" + pos[2] [QE-2982]
          let finalString = pos[2];

          return finalString;
        })
        .join(",");

      if (planIds) {
        let testSuites = await getTestSuitesRequest(
          props.token,
          program,
          project,
          planIds
        );

        if (testSuites?.statusCode === "ERROR") {
          return;
        } else {
          let testSuitesOptions = testSuites.map((suite) => {
            let string = suite.id;
            let pos = string.split(":-:");
            let finalString = pos[1];

            return { id: suite.id, name: suite.name, program: finalString };
          });
          let testSuitesOptionsNoDuplicates = [...new Set(testSuitesOptions)];

          let selectedTestPlansReleases = testPlansReleases.map(
            (plan) => plan.name
          );
          filterIterations(iterations, selectedTestPlansReleases);

          setTestCyclesSuitesOptions(testSuitesOptionsNoDuplicates);
          setLoading(false);
        }
      }
    };

    if (!projectDetails) {
      if (testPlansReleases.length) {
        setLoading(true);
        handleTestSuites();
      } else {
        setIterations([]);
      }
    } else {
      if (testPlansReleases.length) {
        setLoading(true);
        handleTestSuites();
      } else if (editMode) {
        setIterations([]);
      }
    }
  }, [testPlansReleases]);

  useEffect(() => {
    const handleIterationsPathRequest = async () => {
      let projectsLength = ADORequestData.length;

      if (projectsLength > 0) {
        let iterations = await getIterationsRequest(
          props.token,
          ADORequestData.map((project) => project.adoProjName)
        );

        if (iterations) {
          const finalStrings = getCombinationsDataValues(
            iterations?.adoIterationData
          ).sort();

          setIterationPathOptions(finalStrings);
        }
      }
    };

    if (!projectDetails) {
      setSelectedTestCyclesSuites([]);
      setTestCyclesSuitesOptions([]);
      setSelectedIterationPath([]);
      setIterationPathOptions([]);
    }

    if (!firstCallMade && ADORequestData?.length >= 1) {
      handleIterationsPathRequest();
      setFirstCallMade(true);
    }
  }, [ADORequestData.length]);

  useEffect(() => {
    setFirstCallMade(false);
  }, [iterationPathOptions]);

  useEffect(() => {
    handleADOSprintsRequestData(iterations);
  }, [iterations]);

  const filterIterations = (iterations, values) => {
    return iterations.map((iteration) => {
      iteration.iterationCycles = iteration.iterationCycles.filter((cycle) => {
        return values.some((value) => cycle.name.includes(value));
      });
      return iteration;
    });
  };

  const handlePopulateFields = () => {
    if (!projectDetails) return;

    let newIterations = structuredClone(projectDetails.lpIterations);
    let testCycles = [];

    projectDetails.lpIterations.forEach((iteration, iterationIndex) => {
      let iterationCyclesObject = iteration.iterationCycles?.map(
        (testCycle) => {
          let pos = testCycle.length
            ? testCycle?.split(":-:")
            : testCycle?.id.split(":-:");
          let testCycleName = pos[4];
          let testCycleProgram = pos[1];

          return {
            id: testCycle,
            name: testCycleName,
            program: testCycleProgram,
          };
        }
      );

      testCycles.push({
        id: iterationIndex,
        value: iterationCyclesObject,
      });
    });

    testCycles.forEach((cycle, index) => {
      newIterations[index].iterationCycles = cycle.value;
    });

    setIterations(newIterations);
  };

  const handleAddIterationClick = () => {
    let newIterations = [...iterations];

    if (!newIterations.length) {
      newIterations.push({
        id: 1,
        iterationName: "",
        iterationCycles: [],
        iterationPath: "",
        projectId: "",
        startDate: "",
        endDate: "",
      });
    } else {
      let ids = newIterations.map((item) => {
        return item.id;
      });
      ids.sort(function (a, b) {
        return a - b;
      });
      let id = ids.length > 0 ? ids[ids.length - 1] + 1 : 1;

      newIterations.push({
        id,
        iterationName: "",
        iterationCycles: [],
        iterationPath: "",
        projectId: "",
        startDate: "",
        endDate: "",
      });
    }

    setIterations(newIterations);
  };

  const handleDeleteIterationClick = (iterationId, index) => {
    let findIterationTestCycle = selectedTestCyclesSuites[index];
    let findIterationPath = selectedIterationPath[index];

    setSelectedTestCyclesSuites((prevValues) => {
      let newIterations = [...prevValues];
      let filteredIterations = newIterations.filter(
        (iterationObject) => iterationObject.id !== findIterationTestCycle?.id
      );

      return filteredIterations;
    });
    setSelectedIterationPath((prevValues) => {
      let newIterations = [...prevValues];
      let filteredIterations = newIterations.filter(
        (iterationObject) => iterationObject.id !== findIterationPath?.id
      );

      return filteredIterations;
    });
    setIterations((prevValues) => {
      let newIterations = [...prevValues];
      let filteredIterations = newIterations.filter(
        (iterationObject) => iterationObject.id !== iterationId
      );

      return filteredIterations;
    });
  };

  const handleTestCyclesSuitesChange = (value, index) => {
    setIterations((prevValues) => {
      const newIterations = [...prevValues];
      const iteration = newIterations[index];

      if (iteration) {
        if (value.length > 0) {
          iteration.iterationCycles = [];
          value.forEach((selectedValue) => {
            iteration.iterationCycles.push(selectedValue);
          });
        } else {
          iteration.iterationCycles = [];
        }
      }

      return newIterations;
    });
  };

  const handleIterationPathChange = (value, index) => {
    setIterations((prevValues) => {
      const newIterations = [...prevValues];
      const iteration = newIterations[index];
      if (iteration) {
        if (value.length > 0) {
          iteration.iterationPath = value[0];
          handleDatesRequest(value, index);
        } else {
          iteration.iterationPath = "";
          iteration.startDate = "";
          iteration.endDate = "";
        }
      }
      return newIterations;
    });
  };

  const handleSprintNameChange = (event, iterationId) => {
    setIterations((prevValues) => {
      let newIterations = [...prevValues];
      let findIteration = newIterations.find(
        (iterationObject) => iterationObject.id === iterationId
      );

      findIteration.iterationName = event.target.value;

      return newIterations;
    });
  };

  const handleDatesRequest = async (values, iterationId) => {
    if (values.length) {
      let projectsLength = ADORequestData.length;
      let pathToFind = values[0];
      let encodedPath = pathToFind.replace(/(\\{1,2})/g, "%5C");

      let datesRequest = await getADODates(
        props.token,
        ADORequestData[projectsLength - 1]?.adoProjName,
        encodedPath
      );

      if (datesRequest) {
        if (!datesRequest.startDate || !datesRequest.endDate) {
          return setManualDate(true);
        }
        setIterations((prevValues) => {
          let newIterations = [...prevValues];
          newIterations[iterationId].startDate = new Date(
            datesRequest.startDate
          );
          newIterations[iterationId].endDate = new Date(datesRequest.endDate);
          newIterations[iterationId].iterationPath = values[0];

          return newIterations;
        });
        setManualDate(false);
      }
    } else {
      setIterationPathOptions([]);
      setIterations([]);
    }
  };

  const handleStartDateChange = (date, iterationId) => {
    setIterations((prevValues) => {
      let newIterations = [...prevValues];
      let findIteration = newIterations.find(
        (iterationObject) => iterationObject.id === iterationId
      );

      findIteration.startDate = date;

      return newIterations;
    });
  };

  const handleEndDateChange = (date, iterationId) => {
    setIterations((prevValues) => {
      let newIterations = [...prevValues];
      let findIteration = newIterations.find(
        (iterationObject) => iterationObject.id === iterationId
      );

      findIteration.endDate = date;

      return newIterations;
    });
  };

  const getDate = (date) => {
    if (!date) return;

    const initialDate = new Date(date);
    initialDate.setDate(initialDate.getDate() + 1);

    return initialDate;
  };

  const getCombinationsDataValues = (object, prefix = "") => {
    let finalData = [];

    for (let key in object) {
      if (
        typeof object[key] === "object" &&
        Object.keys(object[key]).length > 0
      ) {
        finalData.push(
          ...getCombinationsDataValues(object[key], prefix + key + "\\")
        );
      } else {
        finalData.push(prefix + key);
      }
    }

    return finalData;
  };

  return (
    <div className="ado-sprints-tool-container">
      <div className="row mb-4">
        <div className="col md-6">
          <label
            htmlFor="ado-sprints-label"
            className="form-label fs-7 fw-bold"
            style={{ marginBottom: "16px" }}
          >
            <span style={iterations.length ? {} : { color: "red" }}>* </span>
            ADO Sprints:
          </label>
          <table className="table table-bordered table-sm ">
            <thead>
              <tr
                style={{ backgroundColor: "#e9ecef", color: "#212529" }}
                className="border-bottom-0"
              >
                <th scope="col" className="fw-normal " style={{ width: "10%" }}>
                  Name
                </th>
                <th scope="col" className="fw-normal" style={{ width: "35%" }}>
                  Test Cycles/Suites
                </th>
                <th scope="col" className="fw-normal" style={{ width: "25%" }}>
                  Iterations Path
                </th>
                <th scope="col" className="fw-normal" style={{ width: "10%" }}>
                  Start Date
                </th>
                <th scope="col" className="fw-normal" style={{ width: "10%" }}>
                  End Date
                </th>
                <th scope="col" className="fw-normal" style={{ width: "5%" }}>
                  <PlusButton
                    disabled={!!props.disabled}
                    onClick={handleAddIterationClick}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {iterations &&
                iterations.map((iteration, index) => {
                  return (
                    <tr className="bg-body" key={index}>
                      <td>
                        <input
                          id={"sprint-name-" + index}
                          className="form-control"
                          type="text"
                          value={iteration.iterationName}
                          disabled={!!props.disabled}
                          onChange={(e) => {
                            handleSprintNameChange(e, iteration.id);
                          }}
                          autoComplete="off"
                        />
                      </td>
                      <td style={{ maxWidth: "480px" }}>
                        <div>
                          <Autocomplete
                            multiple
                            disableCloseOnSelect
                            disabled={disabled || loading}
                            limitTags={2}
                            loading={true}
                            value={iteration.iterationCycles || []}
                            options={testCyclesSuitesOptions || []}
                            groupBy={(option) => option.program}
                            getOptionLabel={(option) => option.name}
                            onChange={(_, value) => {
                              handleTestCyclesSuitesChange(value, index);
                            }}
                            sx={{
                              "& .MuiInputBase-root .MuiAutocomplete-tag": {
                                background: "#0096fb",
                                color: "#ffffff",
                              },
                              "& .MuiSvgIcon-root": {
                                color: "#ffffff",
                                fontSize: "16px",
                              },
                              "& .MuiFormLabel-root": {
                                fontSize: "14px",
                              },
                              "& .MuiChip-deleteIcon": {
                                color: "#ffffff!important",
                              },
                            }}
                            renderInput={(params) => {
                              return (
                                <TextField
                                  {...params}
                                  label="Select Test Cycles/Suites"
                                  size="small"
                                  sx={{ width: "100%" }}
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: loading ? (
                                      <div
                                        className="spinner-border text-secondary spinner-border-sm"
                                        role="status"
                                      >
                                        <span className="visually-hidden">
                                          Loading...
                                        </span>
                                      </div>
                                    ) : null,
                                  }}
                                />
                              );
                            }}
                            renderOption={(props, option, index) => {
                              return (
                                <Tooltip title={option.name} arrow>
                                  <MenuItem
                                    dense
                                    sx={{ fontSize: "14px" }}
                                    key={`${option.id - option.name}`}
                                    {...props}
                                  >
                                    {option.name}
                                  </MenuItem>
                                </Tooltip>
                              );
                            }}
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Tooltip
                                  key={option.id}
                                  title={option.name}
                                  placement="right"
                                  arrow
                                  disableFocusListener
                                  disableTouchListener
                                  componentsProps={tooltipStyles}
                                >
                                  <Chip
                                    label={option.name}
                                    {...getTagProps({ index })}
                                    sx={{
                                      background: "#0096fb",
                                      color: "#ffffff",
                                      ".MuiChip-deleteIcon": {
                                        color: "#ffffff!important",
                                      },
                                    }}
                                  />
                                </Tooltip>
                              ))
                            }
                          />
                        </div>
                      </td>
                      <td style={{ maxWidth: "250px" }}>
                        <div>
                          <Autocomplete
                            multiple
                            disableCloseOnSelect
                            disabled={disabled}
                            value={
                              iterations[index]?.iterationPath
                                ? [iterations[index].iterationPath]
                                : []
                            }
                            options={iterationPathOptions || []}
                            onChange={(_, value) => {
                              handleIterationPathChange(value, index);
                            }}
                            getOptionDisabled={(option) =>
                              iterations[index]?.iterationPath ? true : false
                            }
                            sx={{
                              "& .MuiInputBase-root .MuiAutocomplete-tag": {
                                background: "#0096fb",
                                color: "#ffffff",
                              },
                              "& .MuiSvgIcon-root": {
                                color: "#ffffff",
                                fontSize: "16px",
                              },
                              "& .MuiFormLabel-root": {
                                fontSize: "14px",
                              },
                              "& .MuiChip-deleteIcon": {
                                color: "#ffffff!important",
                              },
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select Project Iteration Path"
                                size="small"
                                sx={{ width: "100%" }}
                              />
                            )}
                            renderOption={(props, option) => (
                              <Tooltip title={option} arrow>
                                <MenuItem
                                  dense
                                  sx={{ fontSize: "14px" }}
                                  {...props}
                                >
                                  {option}
                                </MenuItem>
                              </Tooltip>
                            )}
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Tooltip
                                  key={option.id}
                                  title={option}
                                  placement="right"
                                  arrow
                                  disableFocusListener
                                  disableTouchListener
                                  componentsProps={tooltipStyles}
                                >
                                  <Chip
                                    label={option}
                                    {...getTagProps({ index })}
                                    sx={{
                                      background: "#0096fb",
                                      color: "#ffffff",
                                      ".MuiChip-deleteIcon": {
                                        color: "#ffffff!important",
                                      },
                                    }}
                                  />
                                </Tooltip>
                              ))
                            }
                          />
                        </div>
                      </td>
                      <td>
                        <DatePicker
                          id={"sprint-start-date-" + index}
                          className="form-control "
                          dateFormat={"yyyy-MM-dd"}
                          selected={
                            iteration?.startDate !== ""
                              ? getDate(iteration.startDate)
                              : null
                          }
                          disabled={!manualDate}
                          onChange={(date) => {
                            handleStartDateChange(date, iteration.id);
                          }}
                        />
                      </td>
                      <td>
                        <DatePicker
                          id={"sprint-end-date-" + index}
                          className="form-control "
                          dateFormat={"yyyy-MM-dd"}
                          selected={
                            iteration?.endDate !== ""
                              ? getDate(iteration.endDate)
                              : null
                          }
                          disabled={!manualDate}
                          onChange={(date) =>
                            handleEndDateChange(date, iteration.id)
                          }
                        />
                      </td>
                      <td>
                        <TrashButton
                          disabled={!!props.disabled}
                          id={"trash-button-" + index}
                          onClick={() =>
                            handleDeleteIterationClick(iteration.id, index)
                          }
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ADOSprintsManagementTool;
