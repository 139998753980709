import { Chart } from "react-google-charts";
import { useRef } from "react";

const getTooltip = function (statuses, date, name, values, isADO) {
  var tooltip =
    '<div align="left" style="width: auto; float: left; table-layout: auto">' +
    '<table class="table table-hover table-condensed table-sm" >' +
    "<thead><tr><th>" +
    date +
    "</th></tr></thead>" +
    "<tbody>";
  let index = 0;

  for (let statValue of statuses) {
    if (statValue === name) {
      if (isADO && name == "Done") {
        name = "Closed/Done";
      }
      tooltip =
        tooltip +
        '<tr class="table-danger" key={' +
        index +
        "}><td><b>" +
        name +
        "</b>";
    } else {
      if (isADO && statValue == "Done") {
        tooltip = tooltip + "<tr  key={" + index + "}><td>" + "Closed/Done";
      } else {
        tooltip = tooltip + "<tr  key={" + index + "}><td>" + statValue;
      }
    }
    tooltip =
      tooltip +
      ": <b>" +
      Math.abs(values[statValue].length ? values[statValue].length : 0) +
      "</b></td></tr>";
    index++;
  }
  tooltip = tooltip + "</tbody></table></div>";

  return tooltip;
};

export default function OpenClosedDefectsChart(props) {
  const currentIterationInfoRef = useRef();
  const currentDataTableRef = useRef();
  let issueMap = {};
  issueMap =
    props?.isADO == "ADO"
      ? props?.jiraChartsInfo?.defectAgeChartMap?.data?.issueMap
      : props?.jiraChartsInfo?.issueMap;
  var statuses =
    props.isADO === "ADO"
      ? ["Open", "Done", "Created"]
      : ["Created", "Closed", "Open"];

  var defectTitles = [
    "Issue ID",
    "Issue Summary",
    "Status",
    "Resolution",
    "Severity",
    "Assigne",
  ];

  const getValue = (value) => {
    return value ? value : 0;
  };

  let notSortedOpenClosedDefects = null;

  if (props.isADO === "ADO") {
    if (props?.jiraChartsInfo?.defectAgeChartMap?.data?.openClosedDefects) {
      notSortedOpenClosedDefects =
        props?.jiraChartsInfo?.defectAgeChartMap?.data?.openClosedDefects[
          props.currentIteration
        ] || null;
    } else {
      notSortedOpenClosedDefects = null;
    }
  } else {
    if (props?.jiraChartsInfo?.openClosedDefects) {
      notSortedOpenClosedDefects =
        props?.jiraChartsInfo?.openClosedDefects[props.currentIteration];
    } else {
      notSortedOpenClosedDefects = null;
    }
  }

  let col_max = 0;

  let columnArray =
    props?.isADO == "ADO"
      ? ["Open", "Done", "Created"]
      : ["Created", "Closed", "Open"];
  let valEmptyArray = ["Dates", 0, "", 0, "", 0, ""];

  var dataTable = [];
  var header = ["Dates"];

  for (var i = 0; i < columnArray.length; i++) {
    header.push(columnArray[i]);
    header.push({ type: "string", role: "tooltip", p: { html: true } });
  }
  dataTable.push(header);

  let currentIterationForOpendClosedDefects = {};

  if (notSortedOpenClosedDefects) {
    let sorted = Object.keys(notSortedOpenClosedDefects).sort(function (a, b) {
      return new Date(a).getTime() - new Date(b).getTime();
    });
    sorted.forEach(function (key) {
      currentIterationForOpendClosedDefects[key] =
        notSortedOpenClosedDefects[key];
    });
  }

  currentIterationInfoRef.current = currentIterationForOpendClosedDefects;

  const datesArr = Object.keys(currentIterationForOpendClosedDefects);
  var isStatEmpty = true;

  for (let date of datesArr) {
    isStatEmpty = false;
    var tempStatObj = currentIterationForOpendClosedDefects[date];
    tempStatObj.Created = tempStatObj.Created ? tempStatObj.Created : [];
    if (props?.isADO !== "ADO") {
      tempStatObj.Closed = tempStatObj.Closed ? tempStatObj.Closed : [];
    }

    tempStatObj.Open = tempStatObj.Open ? tempStatObj.Open : [];
    if (props?.isADO === "ADO") {
      tempStatObj.Done = tempStatObj.Done ? tempStatObj.Done : [];
    }
    let currentSelectedProjectTypeId;

    if (localStorage.getItem("currentSelectedProjectTypeId") !== null) {
      currentSelectedProjectTypeId = localStorage.getItem(
        "currentSelectedProjectTypeId"
      );
    }

    if (props?.isADO == "ADO") {
      tempStatObj.Done = tempStatObj.Closed.concat(tempStatObj.Done);
      tempStatObj.Closed = [];
    }

    if (col_max < tempStatObj.Created.length) {
      col_max = tempStatObj.Created.length;
    }
    if (col_max < tempStatObj.Closed.length && props?.isADO !== "ADO") {
      col_max = tempStatObj.Closed.length;
    }
    if (col_max < tempStatObj.Open.length) {
      col_max = tempStatObj.Open.length;
    }
    if (props?.isADO == "ADO" && col_max < tempStatObj?.Done?.length) {
      col_max = tempStatObj.Done.length;
    }
    let dataArray = [];
    if (props?.isADO == "ADO") {
      dataArray = [
        date,
        getValue(tempStatObj.Open.length),
        getTooltip(statuses, date, "Open", tempStatObj, props?.isADO == "ADO"),
        getValue(tempStatObj.Done.length),
        getTooltip(statuses, date, "Done", tempStatObj, props?.isADO == "ADO"),
        getValue(tempStatObj.Created.length),
        getTooltip(
          statuses,
          date,
          "Created",
          tempStatObj,
          props?.isADO == "ADO"
        ),
      ];
    } else {
      dataArray = [
        date,
        getValue(tempStatObj.Created.length),
        getTooltip(
          statuses,
          date,
          "Created",
          tempStatObj,
          props?.isADO == "ADO"
        ),
        getValue(tempStatObj.Closed.length),
        getTooltip(
          statuses,
          date,
          "Closed",
          tempStatObj,
          props?.isADO == "ADO"
        ),
        getValue(tempStatObj.Open.length),
        getTooltip(statuses, date, "Open", tempStatObj, props?.isADO == "ADO"),
      ];
    }
    dataTable.push(dataArray);
  }

  // set default value if no results received from server
  if (isStatEmpty) {
    dataTable.push(valEmptyArray);
  }

  var data = dataTable;
  currentDataTableRef.current = dataTable;

  var options = {
    title: "Open & Closed Defects",
    titleTextStyle: { fontSize: "16" },
    hAxis: {
      slantedText: true,
      slantedTextAngle: 45,
      textStyle: { fontSize: 11 },
    },
    vAxis: { format: "#" },
    gridlines: { count: -1 },
    chartArea: { left: 70, top: 50, width: "65%", height: "65%" },
    tooltip: { isHtml: true },
  };

  if (col_max < 5) {
    options.vAxis = { format: "#", viewWindow: { max: 4 } };
  } else {
    options.vAxis = { format: "#", viewWindow: { max: col_max + 1 } };
  }

  var chartEvents = [
    {
      eventName: "ready",
      callback: ({ chartWrapper, google }) => {
        var handler = async function (e) {
          const chart = chartWrapper.getChart();
          var selection = chart.getSelection();
          if (!selection || selection.length < 1) {
            return;
          }
          var row = selection[0].row;
          var col = selection[0].column;

          let selectedStatus =
            currentDataTableRef.current[0][col] === "Closed/Done"
              ? real_status
              : currentDataTableRef.current[0][col];
          var selectedDate = currentDataTableRef.current[row + 1][0];
          var defectsIdsArray =
            currentIterationInfoRef.current?.[selectedDate]?.[selectedStatus];
          defectsIdsArray = defectsIdsArray ? defectsIdsArray : [];
          var defectsInfoArray = [];
          console.log(currentDataTableRef.current[0]);
          Object.keys(issueMap).forEach((issueId) => {
            let value = props?.isADO == "ADO" ? parseInt(issueId) : issueId;

            if (defectsIdsArray?.indexOf(value) >= 0) {
              defectsInfoArray.push(issueMap[issueId]);
            }
          });

          props.setModalData({
            title: selectedDate + ": " + selectedStatus,
            projectId: props.currentProjectId,
            createdDt: selectedDate,
            releaseName: null,
            status: selectedStatus,
            severity: null,
            tableTitles: defectTitles,
            defects: defectsInfoArray,
          });

          if (defectsInfoArray.length < 1) {
            props.setShowModal(false);
          } else {
            props.setShowModal(true);
          }
        };

        google.visualization.events.addListener(
          chartWrapper.getChart(),
          "select",
          handler
        );
      },
    },
  ];

  //set image URI property if needed - used in Report page
  if (props.event) {
    chartEvents.push({
      eventName: "ready",
      callback: ({ chartWrapper, google }) => {
        let url = chartWrapper.getChart().getImageURI();
        props.setOpenClosedDefectsChartUri(url);
        props.setOpenClosedDefectsChartDisplay(false);
      },
    });
  }
  const real_status = data[0][3];
  if (props.isADO == "ADO") {
    data[0][3] = "Closed/Done";
  }
  return (
    <Chart
      chartType="LineChart"
      width="100%"
      height="400px"
      data={data}
      options={options}
      chartEvents={chartEvents}
    />
  );
}
