import React from "react";
import "../css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";

function Loading() {
  return (
    <div className="container py-5 h-100 bg-light">
      <div className="row d-flex justify-content-center align-items-center h-100">
        <div className="col-12 col-md-8 col-lg-6 col-xl-5">
          <div className="d-flex justify-content-center">
            <p className="form-outline mb-4">Please wait...</p>
          </div>
          <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Loading;
