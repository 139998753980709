import ProjectsList from "../components/projects/ProjectsList";
import NavigationTab from "../components/common/NavigationTab";
import useToken from "../components/hooks/useToken";

const Dashboard = (props) => {
  const { token, setToken } = useToken();

  return (
    <div style={{ minHeight: "calc(100vh - 60px)" }}>
      <NavigationTab
        changeStyle={props.navTabChangeStyle}
        currentProjectId={props.currentProjectId}
      />
      <ProjectsList
        token={token}
        navTabChangeStyle={props.navTabChangeStyle}
        setCurrentProjectId={props.setCurrentProjectId}
        selectedProjectType={props.selectedProjectType}
        setSelectedProjectType={props.setSelectedProjectType}
        loggedUser={props.loggedUser}
        setLoggedUser={props.setLoggedUser}
      />
    </div>
  );
};

export default Dashboard;
