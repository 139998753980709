import React, { useState, useEffect } from "react";
import Qtest from "../../../services/Qtest";
import ErrorMessage from "../../common/ErrorMessage";
import useJiraInfo from "../../hooks/useJiraInfo";
import useErrorMessage from "../../hooks/useErrorMessage";
import { Chart } from "react-google-charts";
import "../../css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";

async function getRequirementsChartsInfo(token, projectId) {
  try {
    const resp = await Qtest.getRequirementChartsInfo(token, projectId);
    return resp;
  } catch (err) {
    return {
      message:
        "Error getting requirements info (" + err.message
          ? err.message
          : JSON.stringify(err) + ")",
    };
  }
}

export default function RequirementsCoverageCharts(props) {
  const { requirementChartsInfo, setRequirementChartsInfo } = useJiraInfo();

  const { setErrorMessage, errorMessage } = useErrorMessage();
  const [loading, setLoading] = useState(true);

  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({
    title: "",
    projectId: null,
    createdDt: null,
    releaseName: null,
    status: null,
    severity: null,
  });

  useEffect(() => {
    const handleRequirementsDetails = async (e) => {
      var currentProjectId = props.currentProjectId;
      var url = window.location.href;
      let res = /project\/(\d+)/g.exec(url);

      if (!currentProjectId) {
        if (res?.length > 1) {
          currentProjectId = res[1];
          props.setCurrentProjectId(currentProjectId);
        } else {
          setErrorMessage("Project is not selected!");
          setRequirementChartsInfo(null);
          setLoading(false);
          return;
        }
      }

      if (
        !requirementChartsInfo ||
        requirementChartsInfo.projectId !== currentProjectId
      ) {
        const response = await getRequirementsChartsInfo(
          props.token,
          currentProjectId
        );
        if (response.errMsg) {
          setErrorMessage(response.errMsg);
          setRequirementChartsInfo(null);
        } else {
          if (!response?.Coverage) {
            setErrorMessage("Response does not contain any information!");
            setRequirementChartsInfo(null);
          } else {
            setErrorMessage("");
            setRequirementChartsInfo(response);
          }
        }
      } else {
        if (requirementChartsInfo) {
          if (!requirementChartsInfo?.Coverage) {
            setErrorMessage("Response does not contain any information!");
            setRequirementChartsInfo(null);
          }
        }
      }
      setLoading(false);
    };
    handleRequirementsDetails();
  }, [props.currentProjectId]);

  const getTooltip = function (statuses, date, name, values) {
    var tooltip =
      '<div align="left" style="width: auto; float: left; table-layout: auto">' +
      '<table class="table table-hover table-condensed table-sm" >' +
      "<thead><tr><th>" +
      date +
      "</th></tr></thead>" +
      "<tbody>";

    let index = 0;
    for (let statValue of statuses) {
      if (statValue === name) {
        tooltip =
          tooltip +
          '<tr class="table-danger" key={' +
          index +
          "}><td><b>" +
          name +
          "</b>";
      } else {
        tooltip = tooltip + "<tr  key={" + index + "}><td>" + statValue;
      }
      tooltip =
        tooltip +
        ": <b>" +
        Math.abs(values[statValue] ? values[statValue] : 0) +
        "</b></td></tr>";
      index++;
    }
    tooltip = tooltip + "</tbody></table></div>";

    return tooltip;
  };

  //===============================================
  //----------Create ticks array for trending charts
  //===============================================

  const getTicksArrayForTrendingChart = (minValue, maxValue) => {
    // Figure out the largest number (positive or negative)
    var biggestNumber = Math.max(Math.abs(maxValue), Math.abs(minValue));

    // Round to an exponent of 10 appropriate for the biggest number
    var roundingExp = Math.floor(Math.log(biggestNumber) / Math.LN10);
    var roundingDec = Math.pow(10, roundingExp);

    // Round your max and min to the nearest exponent of 10
    var newMax = Math.ceil(maxValue / roundingDec) * roundingDec;
    var newMin = Math.floor(minValue / roundingDec) * roundingDec;

    // Determine the range of your values
    var range = Math.max(Math.abs(newMax), Math.abs(newMin));
    var gridlines = 2;

    // Calculate the best factor for number of gridlines (2-5 gridlines)
    // If the range of numbers divided by 2 or 5 is a whole number, use it
    for (var i = 2; i <= 4; ++i) {
      if (Math.round(range / i) === range / i) {
        gridlines = i;
      }
    }
    var step = Math.round(range / gridlines);
    var ticksArr = [];
    if (newMin < 0) {
      for (i = 0; i >= newMin - step; i -= step) {
        ticksArr.push({ v: i, f: String(Math.abs(i)) });
        if (minValue >= i) {
          break;
        }
      }
      ticksArr.reverse();
    }
    if (newMax > 0) {
      for (i = 0; i <= newMax + step; i += step) {
        ticksArr.push(i);
        if (i > maxValue) {
          break;
        }
      }
    }
    return ticksArr;
  };

  //======================================================================
  // 'Requirements Coverage by Test Cases'
  //======================================================================

  const RequirementsCoverageByTestCasesChart = () => {
    const coverageByTestCasesInfo = requirementChartsInfo.Coverage;

    if (
      !coverageByTestCasesInfo ||
      Object.keys(coverageByTestCasesInfo).length < 1
    ) {
      return (
        <div className="form-outline mt-4" id="error-message">
          <div className="alert alert-danger" role="alert">
            Data is empty!
          </div>
        </div>
      );
    }

    const labels = Object.keys(coverageByTestCasesInfo);

    var columns = [
      "Coverage",
      "Covered",
      { type: "string", role: "tooltip" },
      "Not Covered",
      { type: "string", role: "tooltip" },
    ];

    var data = [];
    data.push(columns);

    labels.forEach(function (date) {
      let tempStatObj = coverageByTestCasesInfo[date];
      tempStatObj = tempStatObj ? tempStatObj : {};
      tempStatObj["Covered"] = tempStatObj["Covered"]
        ? tempStatObj["Covered"]
        : 0;
      tempStatObj["Not Covered"] = tempStatObj["Not Covered"]
        ? tempStatObj["Not Covered"]
        : 0;

      let overall = tempStatObj["Covered"] + tempStatObj["Not Covered"];
      let dataArray = [
        date,
        tempStatObj["Covered"],
        date +
          "\nCovered: " +
          Math.round((tempStatObj["Covered"] / overall) * 100) +
          "%\nRequirements: " +
          tempStatObj["Covered"],
        tempStatObj["Not Covered"],
        date +
          "\nNot Covered: " +
          Math.round((tempStatObj["Not Covered"] / overall) * 100) +
          "%\nRequirements: " +
          tempStatObj["Not Covered"],
      ];
      data.push(dataArray);
    });

    data.sort((a, b) => {
      return new Date(a[0]) - new Date(b[0]);
    });

    if (data.length < 2) {
      data.push(["Dates", 0, "", 0, ""]);
    }

    var options = {
      title: "Requirements Coverage by Test Cases",
      titleTextStyle: {
        fontSize: "16",
      },
      bars: "vertical",
      hAxis: { slantedText: true, textStyle: { fontSize: 11 } },
      vAxis: { format: "0" },
    };

    return (
      <Chart
        chartType="ColumnChart"
        width="100%"
        height="400px"
        data={data}
        options={options}
        legendToggle
      />
    );
  };

  ///==========================================================================
  // 'Requirements Status'
  ///==========================================================================

  const RequirementsStatusChart = () => {
    var statuses = {
      Passed: "Passed",
      Failed: "Failed",
      Blocked: "Blocked",
      Incomplete: "In Complete",
      Unexecuted: "Unexecuted",
    };

    var columnArray = [
      "Dates",
      "Passed",
      "Failed",
      "Blocked",
      "In Complete",
      "Unexecuted",
    ];

    const notSortedInfo = requirementChartsInfo.Status;

    if (!notSortedInfo) {
      return (
        <div className="form-outline mt-4" id="error-message">
          <div className="alert alert-danger" role="alert">
            Data is empty!
          </div>
        </div>
      );
    }

    var maxValue = 0;
    var minValue = 0;

    let valEmptyArray = ["Dates", 0, "", 0, "", 0, "", 0, "", 0, ""];
    var sortedInfo = {};
    if (notSortedInfo) {
      Object.keys(notSortedInfo)
        .sort(function (a, b) {
          return new Date(a).getTime() - new Date(b).getTime();
        })
        .forEach(function (key) {
          sortedInfo[key] = notSortedInfo[key];
        });
    }

    var dataTable = [];
    var header = ["Dates"];
    for (var i = 1; i < columnArray.length; i++) {
      header.push(columnArray[i]);
      header.push({ type: "string", role: "tooltip", p: { html: true } });
    }
    dataTable.push(header);

    const datesArr = Object.keys(sortedInfo);
    var isStatEmpty = true;
    var statusLabels = Object.keys(statuses).map((key) => statuses[key]);
    for (let date of datesArr) {
      let tempMaxValue = 0;
      let tempMinValue = 0;

      isStatEmpty = false;
      var tempStatObj = sortedInfo[date];
      var currResultStatObj = {};

      let currentDateObjArray = [];
      currentDateObjArray.push(date);

      for (let status in statuses) {
        if (!tempStatObj[status]) {
          tempStatObj[status] = 0;
        }
        currResultStatObj[statuses[status]] = tempStatObj[status];

        if (status === "Unexecuted") {
          tempMinValue = tempMinValue - tempStatObj[status];
          currResultStatObj[statuses[status]] = tempStatObj[status] * -1;
        } else {
          tempMaxValue += tempStatObj[status];
        }
        currentDateObjArray.push(currResultStatObj[statuses[status]]);
        currentDateObjArray.push(
          getTooltip(statusLabels, date, statuses[status], currResultStatObj)
        );
      }

      if (maxValue < tempMaxValue) {
        maxValue = tempMaxValue;
      }
      if (minValue > tempMinValue) {
        minValue = tempMinValue;
      }

      dataTable.push(currentDateObjArray);
    }

    // set default value if no results received from server
    if (isStatEmpty) {
      dataTable.push(valEmptyArray);
    }

    var data = dataTable;
    // set up ticks for vertical axis
    var ticksArr = getTicksArrayForTrendingChart(minValue, maxValue);

    var options = {
      title: "Requirements Status",
      titleTextStyle: { fontSize: "16" },
      bars: "vertical",
      isStacked: true,
      tooltip: { isHtml: true },
      hAxis: { slantedText: true, textStyle: { fontSize: 11 } },
      vAxis: {
        ticks: ticksArr,
        format: "0",
      },
    };

    return (
      <Chart
        chartType="ColumnChart"
        width="100%"
        height="400px"
        data={data}
        options={options}
      />
    );
  };

  return (
    <div
      id="charts_main_container_1"
      className="bg-light  container-fluid pt-3 pb-3"
      style={
        loading
          ? {
              pointerEvents: "none",
              opacity: "0.4",
            }
          : {}
      }
    >
      {loading && (
        <div className="d-flex justify-content-center bg-transparent align-items-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      <h4 className="ms-4">Requirements</h4>
      <div className="ms-4 mt-4">
        <ErrorMessage errorMessage={errorMessage} />
        <div className="row ms-3 me-3 ">
          <div id="requirementsCoverageByTestCasesChart" className="col-6">
            <div id="req-coverage-tc-root">
              {requirementChartsInfo && (
                <RequirementsCoverageByTestCasesChart />
              )}
            </div>
          </div>
          <div id="requirementsStatusChart" className="col-6">
            <div id="req-status-root">
              {requirementChartsInfo && <RequirementsStatusChart />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
