import React, { useState, useEffect, useRef } from "react";
import Jira from "../../../services/Jira";
import Qtest from "../../../services/Qtest";
import ErrorMessage from "../../common/ErrorMessage";
import useJiraInfo from "../../hooks/useJiraInfo";
import useQtestInfo from "../../hooks/useQtestInfo";
import useProjects from "../../hooks/useProjects";
import { DownloadTableExcel } from "react-export-table-to-excel";
import "../../css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";

async function getRequirementsCoverageJiraInfo(token, projectId) {
  try {
    const resp = await Jira.getRequirementCoverageJiraInfo(token, projectId);
    return resp;
  } catch (err) {
    return {
      message:
        "Error getting Requirements Coverage Jira info (" + err.message
          ? err.message
          : JSON.stringify(err) + ")",
    };
  }
}

async function getRequirementsCoverageQtestInfo(token, projectId) {
  try {
    const resp = await Qtest.getRequirementCoverageQtestInfo(token, projectId);
    return resp;
  } catch (err) {
    return {
      message:
        "Error getting Requirements Coverage qTest info (" + err.message
          ? err.message
          : JSON.stringify(err) + ")",
    };
  }
}

export default function RequirementsCoverageTestCasesMapping(props) {
  const { requirementCoverageJiraInfo, setRequirementCoverageJiraInfo } =
    useJiraInfo();
  const { requirementCoverageQtestInfo, setRequirementCoverageQtestInfo } =
    useQtestInfo();

  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);

  const [jiraStatusFilter, setJiraStatusFilter] = useState(["All"]);
  const [sprintFilter, setSprintFilter] = useState(["All"]);
  const [defectStatusFilter, setDefectStatusFilter] = useState(["All"]);
  const [tcIdFilter, setTcIdFilter] = useState(["All"]);
  const [tcStatusFilter, setTcStatusFilter] = useState(["All"]);

  const [selectedJiraStatusFilter, setSelectedJiraStatusFilter] =
    useState("All");
  const [selectedSprintFilter, setSelectedSprintFilter] = useState("All");
  const [selectedDefectStatusFilter, setSelectedDefectStatusFilter] =
    useState("All");
  const [selectedTcIdFilter, setSelectedTcIdFilter] = useState("All");
  const [selectedTcStatusFilter, setSelectedTcStatusFilter] = useState("All");

  const [
    filteredRequirementCoverageJiraInfo,
    setFilteredRequirementCoverageJiraInfo,
  ] = useState(requirementCoverageJiraInfo);
  const [
    filteredRequirementCoverageQtestInfo,
    setFilteredRequirementCoverageQtestInfo,
  ] = useState(requirementCoverageQtestInfo);

  const emptyValue = "_EMPTY";

  const tableRef = useRef(null);
  const { projects } = useProjects();

  useEffect(() => {
    const handleRequirementsDetails = async (e) => {
      setErrorMessage();
      var currentProjectId = props.currentProjectId;
      var url = window.location.href;
      let res = /project\/(\d+)/g.exec(url);

      if (!currentProjectId) {
        if (res?.length > 1) {
          currentProjectId = res[1];
          props.setCurrentProjectId(currentProjectId);
        } else {
          setErrorMessage("Project is not selected!");
          return;
        }
      }

      if (
        !requirementCoverageQtestInfo ||
        requirementCoverageQtestInfo.projectId !== currentProjectId
      ) {
        setLoading(true);
        setRequirementCoverageJiraInfo(null);
        setRequirementCoverageQtestInfo(null);
        setQTestFilters();
        setJiraFilters();

        const response = await getRequirementsCoverageQtestInfo(
          props.token,
          currentProjectId
        );

        if (response.errMsg) {
          setErrorMessage(response.errMsg);
          setRequirementCoverageQtestInfo(null);
        } else {
          if (!response || response?.data?.length < 1) {
            //setErrorMessage('Qtest response does not contain any information!');
            setRequirementCoverageQtestInfo(null);
            setFilteredRequirementCoverageQtestInfo(null);
            setQTestFilters(null);
            setLoading(false);
          } else {
            setErrorMessage("");
            response.data.sort((a, b) =>
              a.requirementId > b.requirementId
                ? 1
                : b.requirementId > a.requirementId
                ? -1
                : 0
            );
            setRequirementCoverageQtestInfo(response);
            setFilteredRequirementCoverageQtestInfo(response);
            setQTestFilters(response.data);

            //get according Jira info
            let qTestIds = response.data.map((item) => {
              return item.jiraId;
            });

            setLoading(true);
            const jiraResponse = await getRequirementsCoverageJiraInfo(
              props.token,
              qTestIds
            );
            setLoading(false);
            if (jiraResponse.errMsg) {
              setErrorMessage(jiraResponse.errMsg);
              setRequirementCoverageJiraInfo(null);
            } else {
              if (!jiraResponse || jiraResponse.length < 1) {
                setErrorMessage(
                  "Jira response does not contain any information!"
                );
                setRequirementCoverageJiraInfo(null);
              } else {
                setErrorMessage("");
                setRequirementCoverageJiraInfo(jiraResponse);
                setFilteredRequirementCoverageJiraInfo(jiraResponse);
                setJiraFilters(jiraResponse);
              }
            }
          }
        }
      } else {
        if (
          !requirementCoverageQtestInfo ||
          requirementCoverageQtestInfo?.data?.length < 1
        ) {
          setErrorMessage("Qtest response does not contain any information!");
          setRequirementCoverageQtestInfo(null);
          setQTestFilters();
          setJiraFilters();
        } else if (!requirementCoverageJiraInfo) {
          setJiraFilters();
          setQTestFilters(requirementCoverageQtestInfo.data);

          //get according Jira info
          let qTestIds = requirementCoverageQtestInfo.data.map((item) => {
            return item.jiraId;
          });
          const jiraResponse = await getRequirementsCoverageJiraInfo(
            props.token,
            qTestIds
          );
          setLoading(false);
          if (jiraResponse.errMsg) {
            setErrorMessage(jiraResponse.errMsg);
            setRequirementCoverageJiraInfo(null);
          } else {
            if (!jiraResponse || jiraResponse.length < 1) {
              setErrorMessage(
                "Jira response does not contain any information!"
              );
              setRequirementCoverageJiraInfo(null);
            } else {
              setErrorMessage("");
              setRequirementCoverageJiraInfo(jiraResponse);
              setFilteredRequirementCoverageJiraInfo(jiraResponse);
              setJiraFilters(jiraResponse);
            }
          }
        } else {
          setJiraFilters(requirementCoverageJiraInfo);
          setQTestFilters(requirementCoverageQtestInfo.data);
        }
      }

      //sessionStorage.setItem("scrollY", '');
    };
    setFilteredRequirementCoverageJiraInfo();
    setFilteredRequirementCoverageQtestInfo();
    handleRequirementsDetails();
  }, [props.currentProjectId]);

  useEffect(() => {
    let allValue = "All";

    if (requirementCoverageQtestInfo?.data?.length < 1) {
      setFilteredRequirementCoverageQtestInfo();
      setFilteredRequirementCoverageJiraInfo();
      return;
    }
    if (requirementCoverageJiraInfo?.length < 1) {
      setFilteredRequirementCoverageJiraInfo();
    }

    let _qtestInfo = { data: [] };
    //iterate all qTest data to find only items matched filters values
    if (
      selectedTcIdFilter === allValue &&
      selectedTcStatusFilter === allValue
    ) {
      setFilteredRequirementCoverageQtestInfo(requirementCoverageQtestInfo);
    } else {
      _qtestInfo.data = requirementCoverageQtestInfo.data.filter(
        (qTestReq, index) => {
          if (
            qTestReq.testCaseDtls.length > 0 &&
            selectedTcIdFilter !== emptyValue
          ) {
            let filteredTCs = qTestReq.testCaseDtls.filter((tcItem) => {
              let status = tcItem.status ? tcItem.status : emptyValue;
              if (
                (selectedTcIdFilter === allValue ||
                  selectedTcIdFilter === tcItem.testCaseId) &&
                (selectedTcStatusFilter === allValue ||
                  selectedTcStatusFilter === status)
              ) {
                return true;
              }
              return false;
            });
            if (filteredTCs.length > 0) {
              return true;
            } else {
              return false;
            }
          }
          if (
            qTestReq.testCaseDtls.length < 1 &&
            selectedTcIdFilter === emptyValue
          ) {
            return true;
          }
          return false;
        }
      );
      //sort qTest values
      _qtestInfo.data.sort((a, b) =>
        a.requirementId > b.requirementId
          ? 1
          : b.requirementId > a.requirementId
          ? -1
          : 0
      );
      setFilteredRequirementCoverageQtestInfo(_qtestInfo);
    }

    //iterate Jira data to match filters
    let _jiraInfo = [];
    if (
      selectedJiraStatusFilter === allValue &&
      selectedSprintFilter === allValue &&
      selectedDefectStatusFilter === allValue
    ) {
      setFilteredRequirementCoverageJiraInfo(requirementCoverageJiraInfo);
    } else {
      _jiraInfo = requirementCoverageJiraInfo.filter((jiraItem) => {
        if (
          selectedJiraStatusFilter === allValue ||
          jiraItem.status === selectedJiraStatusFilter
        ) {
          if (
            selectedSprintFilter === allValue ||
            jiraItem.sprints.indexOf(selectedSprintFilter) >= 0 ||
            (jiraItem.sprints.length < 1 && selectedSprintFilter === emptyValue)
          ) {
            //if first 2 filters match then check the filter for defects
            if (
              selectedDefectStatusFilter === allValue ||
              (jiraItem.defects.length < 1 &&
                selectedDefectStatusFilter === emptyValue)
            ) {
              return true;
            }
            let filteredDefects = jiraItem.defects.filter((defectItem) => {
              return (
                defectItem.defectStatus === selectedDefectStatusFilter ||
                (!defectItem.defectStatus &&
                  selectedDefectStatusFilter === emptyValue)
              );
            });
            if (filteredDefects.length > 0) {
              return true;
            }
          }
        }
        return false;
      });

      setFilteredRequirementCoverageJiraInfo(_jiraInfo);
    }
  }, [
    selectedJiraStatusFilter,
    selectedSprintFilter,
    selectedDefectStatusFilter,
    selectedTcIdFilter,
    selectedTcStatusFilter,
  ]);

  function setJiraFilters(jiraInfo) {
    if (!jiraInfo) {
      setJiraStatusFilter(["All"]);
      setSprintFilter(["All"]);
      setDefectStatusFilter(["All"]);
      setSelectedJiraStatusFilter("All");
      setSelectedSprintFilter("All");
      setSelectedDefectStatusFilter("All");
      return;
    }
    let _jiraStatusFilter = [];
    let _sprintFilter = [];
    let _defectStatusFilter = [];

    jiraInfo.forEach((item) => {
      if (_jiraStatusFilter.indexOf(item.status) < 0) {
        _jiraStatusFilter.push(item.status);
      }
      if (item.sprints.length < 1 && _sprintFilter.indexOf(emptyValue) < 0) {
        _sprintFilter.push(emptyValue);
      }
      item.sprints.forEach((sprint) => {
        if (_sprintFilter.indexOf(sprint) < 0) {
          _sprintFilter.push(sprint);
        }
      });
      if (
        item.defects.length < 1 &&
        _defectStatusFilter.indexOf(emptyValue) < 0
      ) {
        _defectStatusFilter.push(emptyValue);
      }
      item.defects.forEach((defect) => {
        if (_defectStatusFilter.indexOf(defect.defectStatus) < 0) {
          _defectStatusFilter.push(defect.defectStatus);
        }
      });
    });

    setJiraStatusFilter(["All"].concat(_jiraStatusFilter.sort(sortFunction)));
    setSprintFilter(["All"].concat(_sprintFilter.sort(sortFunction)));
    setDefectStatusFilter(
      ["All"].concat(_defectStatusFilter.sort(sortFunction))
    );

    setSelectedJiraStatusFilter("All");
    setSelectedSprintFilter("All");
    setSelectedDefectStatusFilter("All");
  }

  const sortFunction = (a, b) => {
    if (a.startsWith("_")) {
      return -1;
    }
    if (b.startsWith("_")) {
      return 1;
    }
    return a.localeCompare(b);
  };

  function setQTestFilters(qTestInfo) {
    if (!qTestInfo) {
      setTcIdFilter(["All"]);
      setTcStatusFilter(["All"]);
      setSelectedTcIdFilter("All");
      setSelectedTcStatusFilter("All");
      return;
    }

    let _tcIdFilter = [];
    let _tcStatusFilter = [];
    qTestInfo.forEach((item) => {
      //set values for filters
      if (item.testCaseDtls?.length > 0) {
        item.testCaseDtls.forEach((element) => {
          if (_tcIdFilter.indexOf(element.testCaseId) < 0) {
            _tcIdFilter.push(element.testCaseId);
          }
          let status = element.status ? element.status : emptyValue;
          if (_tcStatusFilter.indexOf(status) < 0) {
            _tcStatusFilter.push(status);
          }
        });
      } else {
        if (_tcIdFilter.indexOf(emptyValue) < 0) {
          _tcIdFilter.push(emptyValue);
        }
      }
    });

    setTcIdFilter([...["All"], ..._tcIdFilter.sort(sortFunction)]);
    setTcStatusFilter([...["All"], ..._tcStatusFilter.sort(sortFunction)]);
    setSelectedTcIdFilter("All");
    setSelectedTcStatusFilter("All");
  }

  function Rows() {
    function Bugs(jiraItem) {
      try {
        jiraItem = jiraItem.jiraItem;
        return jiraItem.defects.map((bugItem, bugIndex) => {
          return (
            <tr className="p-0" key={bugIndex}>
              <td
                className={
                  bugItem.defectStatus === "Closed"
                    ? "table-success p-0 table-bordered"
                    : "p-0 table-bordered"
                }
                width="20%"
              >
                <div className="small">
                  <a
                    href={
                      "https://jira.wolterskluwer.io/jira/browse/" +
                      bugItem.defectKey
                    }
                    className="text-decoration-none"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {bugItem.defectKey + "(" + bugItem.defectStatus + ")"}
                  </a>
                </div>
              </td>
            </tr>
          );
        });
      } catch (err) {
        return null;
      }
    }

    function TestCases(props) {
      try {
        if (!props.testCasesArr || props.testCasesArr.length < 1) {
          return null;
        }

        return props.testCasesArr.map((tcItem, tcIndex) => {
          const status = tcItem.status ? tcItem.status : "";
          return (
            <tr className="p-0 small" key={tcIndex}>
              <td className="col-sm-1 ">{tcItem.testCaseId}</td>
              <td className="col-sm-8 ">{tcItem.testCaseName}</td>
              <td
                className={
                  (status.toUpperCase() === "PASSED"
                    ? "table-success p-0"
                    : status.toUpperCase() === "FAILED"
                    ? "bg-danger p-0"
                    : "p-0") + " col-sm-2"
                }
                width="20%"
              >
                {status}
              </td>
            </tr>
          );
        });
      } catch (err) {
        return null;
      }
    }

    if (!filteredRequirementCoverageQtestInfo) {
      return null;
    }

    return filteredRequirementCoverageQtestInfo.data.map((qTestReq, index) => {
      let jiraId = qTestReq.jiraId;
      if (!filteredRequirementCoverageJiraInfo) {
        return null;
      }
      let jiraItem = filteredRequirementCoverageJiraInfo.filter(
        (item) => item.issueKey === jiraId
      );
      try {
        jiraItem = jiraItem[0];
      } catch (err) {
        return null;
      }

      if (jiraItem) {
        return (
          <tr className="bg-body" key={index}>
            <td
              className={
                jiraItem.status.toUpperCase() === "CLOSED"
                  ? "table-success"
                  : ""
              }
            >
              <div className="small">
                <a
                  href={
                    "https://jira.wolterskluwer.io/jira/browse/" +
                    jiraItem.issueKey
                  }
                  className="link-primary text-decoration-none"
                  fontSize="12px"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {jiraItem.issueKey}
                </a>
              </div>
            </td>
            <td
              className={
                jiraItem.status.toUpperCase() === "CLOSED"
                  ? "table-success"
                  : ""
              }
            >
              <div className="small">{jiraItem.status}</div>
            </td>
            <td>
              {jiraItem.sprints.map((sprint, sprintIndex) => {
                return (
                  <div className="small" key={sprintIndex}>
                    {sprint}
                  </div>
                );
              })}
            </td>
            <td>
              <table className="table table-bordered table-condensed inherit-style mb-0 table-sm">
                <tbody>
                  <Bugs jiraItem={jiraItem} />
                </tbody>
              </table>
            </td>
            <td>
              <div className="small">{jiraItem.storyPoints}</div>
            </td>
            <td>
              <div className="small">{qTestReq.requirementId}</div>
            </td>
            <td>
              <div className="small">{qTestReq.requirementName}</div>
            </td>
            <td colSpan="3" className=" m-0 p-0 ">
              <table className="table table-bordered table-condensed m-0 p-0 table-sm">
                <tbody>
                  <TestCases testCasesArr={qTestReq.testCaseDtls} />
                </tbody>
              </table>
            </td>
          </tr>
        );
      } else return null;
    });
  }

  return (
    <div
      id="main_container_1"
      className="bg-light  container-fluid pt-3 pb-3"
      style={
        loading
          ? {
              pointerEvents: "none",
              opacity: "0.4",
            }
          : {}
      }
    >
      {loading && (
        <div className="d-flex justify-content-center bg-transparent align-items-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      <div className="ms-4 mt-4">
        <h4>Requirement to Test Cases Mapping</h4>
        <ErrorMessage errorMessage={errorMessage} />
        {/* <button type="button" className="btn btn-link"> Export to Excel</button> */}
        <DownloadTableExcel
          filename="LP_Requirements_export"
          sheet={
            projects?.length > 0
              ? projects.filter(
                  (item) => item.projectId === props.currentProjectId
                )[0]?.projectName
              : ""
          }
          currentTableRef={tableRef.current}
        >
          <button type="button" className="btn btn-link">
            {" "}
            Export to Excel{" "}
          </button>
        </DownloadTableExcel>
        <div className="row ms-1 mt-2 me-3">
          <table
            id="requirementsMappingTable"
            ref={tableRef}
            className="table table-bordered table-condensed table-striped table-responsive table-hover table-sm"
          >
            <thead className="table-primary small">
              <tr
                style={{
                  backgroundColor: "rgb(233, 236, 239)",
                  color: "rgb(33, 37, 41)",
                }}
              >
                <th colSpan="5">JIRA</th>
                <th colSpan="2">qTest REQ</th>
                <th colSpan="3">Test Cases</th>
              </tr>
              <tr
                style={{
                  backgroundColor: "rgb(233, 236, 239)",
                  color: "rgb(33, 37, 41)",
                }}
              >
                <th className="col-sm-1">CR ID</th>
                <th className="col-sm-1">
                  <div>Status</div>
                  <div style={{ display: "inline-block", maxWidth: "90%" }}>
                    <select
                      style={{ color: "#000000", width: "100%" }}
                      defaultValue="All"
                      onChange={(event) =>
                        setSelectedJiraStatusFilter(event.target.value)
                      }
                    >
                      {jiraStatusFilter.map((jirastatus, index) => {
                        return (
                          <option value={jirastatus} key={index}>
                            {jirastatus}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </th>
                <th className="col-sm-1">
                  <div>Sprint</div>
                  <div style={{ display: "inline-block", maxWidth: "90%" }}>
                    <select
                      style={{ color: "#000000", width: "100%" }}
                      defaultValue="All"
                      onChange={(event) =>
                        setSelectedSprintFilter(event.target.value)
                      }
                    >
                      {sprintFilter.map((sprint, index) => {
                        return (
                          <option value={sprint} key={index}>
                            {sprint}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </th>
                <th className="col-sm-1">
                  <div>Defect ID</div>
                  <div style={{ display: "inline-block", maxWidth: "90%" }}>
                    <select
                      style={{ color: "#000000", width: "100%" }}
                      defaultValue="All"
                      onChange={(event) =>
                        setSelectedDefectStatusFilter(event.target.value)
                      }
                    >
                      {defectStatusFilter.map((status, index) => {
                        return (
                          <option value={status} key={index}>
                            {status}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </th>
                <th className="col-sm-1">Story Points</th>
                <th className="col-sm-1">ID</th>
                <th className="col-sm-2">Name</th>

                <td className="col-sm-5 m-0 p-0">
                  <table className="table table-bordered table-condensed m-0 p-0 table-sm">
                    <tbody>
                      <tr className="table-primary">
                        <th className="col-sm-1 table-bordered">
                          <div>ID</div>
                          <div
                            style={{ display: "inline-block", maxWidth: "90%" }}
                          >
                            <select
                              style={{ color: "#000000", width: "100%" }}
                              defaultValue="All"
                              onChange={(event) =>
                                setSelectedTcIdFilter(event.target.value)
                              }
                            >
                              {tcIdFilter.map((id, index) => {
                                return (
                                  <option value={id} key={index}>
                                    {id}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </th>
                        <th className="col-sm-8 table-bordered">
                          <div>Name</div>
                        </th>
                        <th className="col-sm-2 table-bordered">
                          <div>Status</div>
                          <div
                            style={{ display: "inline-block", maxWidth: "90%" }}
                          >
                            <select
                              style={{ color: "#000000", width: "100%" }}
                              defaultValue="All"
                              onChange={(event) =>
                                setSelectedTcStatusFilter(event.target.value)
                              }
                            >
                              {tcStatusFilter.map((tcStatus, index) => {
                                return (
                                  <option value={tcStatus} key={index}>
                                    {tcStatus}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </thead>
            <tbody>
              <Rows />
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
