import React, { useEffect, useState } from "react";
import useNavTabStyles from "../hooks/useNavTabStyles";
import useProjects from "../hooks/useProjects";
import { useHistory, useLocation } from "react-router-dom";
import "../css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "../css/custom.min.css";

function NavigationTab(props) {
  const history = useHistory();
  const location = useLocation();
  const { projects } = useProjects();
  const { navTabStyles, setNavTabStyles } = useNavTabStyles({
    dashboard: "active",
    crossProject: "",
    details: "",
    serviceInsights: "",
    serviceInsightsDetails: "",
  });
  const [projectName, setProjectName] = useState("");

  let hasInsightsDataStored = localStorage.getItem("serviceInsightsDetails");

  const handleClick = (tabId) => {
    switch (tabId) {
      case "dashboard": {
        props.changeStyle("dashboard");
        history.replace(location.pathname, "");
        history.push("/dashboard");
        break;
      }
      case "crossProject": {
        props.changeStyle("crossProject");
        history.push("/crossProject");
        break;
      }
      case "details": {
        props.changeStyle("details");
        history.replace(location.pathname, "");
        history.push(`/project/${props.currentProjectId}`);

        break;
      }
      case "serviceInsights": {
        props.changeStyle("serviceInsights");
        history.replace(location.pathname, "");
        history.push(`/service-insights`);
        break;
      }
      case "serviceInsightsDetails": {
        props.changeStyle("serviceInsightsDetails");
        history.replace(location.pathname, "");
        history.push(`/service-insights-details`);
        break;
      }
      default: {
        props.changeStyle("dashboard");
        history.push("/dashboard");
        break;
      }
    }
  };

  useEffect(() => {
    var url = window.location.href;
    if (props.currentProjectId && projects?.lengh > 0) {
      setProjectName(
        projects.filter((item) => item.projectId === props.currentProjectId)[0]
          ?.projectName
      );
    } else {
      setProjectName();
    }

    if (url.indexOf("dashboard") > 0) {
      setNavTabStyles({
        dashboard: "active",
        crossProject: "",
        details: "",
        serviceInsights: "",
      });
      props.changeStyle("dashboard");
    }
    if (url.indexOf("details") > 0 || url.indexOf("/project/") > 0) {
      setNavTabStyles({
        dashboard: "",
        crossProject: "",
        details: "active",
        serviceInsights: "",
      });
      props.changeStyle("details");
    }
    if (url.indexOf("crossProject") > 0) {
      setNavTabStyles({
        dashboard: "",
        crossProject: "active",
        details: "",
        serviceInsights: "",
      });
      props.changeStyle("crossProject");
    }
    if (url.indexOf("service-insights") > 0) {
      setNavTabStyles({
        dashboard: "",
        crossProject: "",
        details: "",
        serviceInsights: "active",
      });
      props.changeStyle("serviceInsights");
    }
    if (url.indexOf("service-insights-details") > 0) {
      setNavTabStyles({
        dashboard: "",
        crossProject: "",
        details: "",
        serviceInsights: "",
        serviceInsightsDetails: "active",
      });
      props.changeStyle("serviceInsightsDetails");
    }
  }, [window.location.href]);

  return (
    <ul className="nav nav-tabs" id="navTab" role="tablist">
      <li className="nav-item" role="presentation">
        <button
          className={`nav-link ${navTabStyles["dashboard"]}`}
          aria-current="page"
          onClick={() => handleClick("dashboard")}
        >
          Projects
        </button>
      </li>
      {props.currentProjectId && (
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link ${navTabStyles["details"]}`}
            onClick={() => handleClick("details")}
          >
            {"Project Details " + (projectName ? ": " + projectName : "")}
          </button>
        </li>
      )}
      <li className="nav-item" role="presentation">
        <button
          className={`nav-link ${navTabStyles["crossProject"]}`}
          onClick={() => handleClick("crossProject")}
        >
          Cross-Project
        </button>
      </li>
      <li className="nav-item" role="presentation">
        <button
          className={`nav-link ${navTabStyles["serviceInsights"]}`}
          onClick={() => handleClick("serviceInsights")}
        >
          Service Insights
        </button>
      </li>
      {(props.currentInsightsDetails || hasInsightsDataStored) && (
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link ${navTabStyles["serviceInsightsDetails"]}`}
            onClick={() => handleClick("serviceInsightsDetails")}
          >
            Service Insights Details
          </button>
        </li>
      )}
    </ul>
  );
}

export default NavigationTab;
