import React, { useEffect, useState } from "react";
import DeleteModal from "../components/common/modals/DeleteModal";
import EditModal from "../components/common/modals/EditModal";
import Projects from "../services/Projects";
import AdminService from "../services/Admin";
import useProjects from "../components/hooks/useProjects";
import useUserInfo from "../components/hooks/useUserInfo";
import "../components/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";

async function getUsers(credentials) {
  try {
    return await AdminService.getUsers(credentials);
  } catch (err) {
    return { message: "Get users error returns (" + err.message + ")" };
  }
}

async function getProjectTypes(token) {
  try {
    return await Projects.getProjectTypes(token);
  } catch (err) {
    return {
      message: "Project Types list was not received! (" + err.message + ")",
    };
  }
}

const Admin = ({ token }) => {
  const { loggedUser } = useUserInfo({});
  const { projectTypes, setProjectTypes } = useProjects();
  const [toUpdateUsers, setToUpdateUsers] = useState(true);
  const [users, setUsers] = useState();
  const [usersFiltered, setUsersFiltered] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);
  const [sortById, setSortById] = useState({ up: false, down: false });
  const [sortByAdmin, setSortByAdmin] = useState({ up: false, down: false });
  const [sortByUserName, setSortByUserName] = useState({
    up: false,
    down: false,
  });
  const [searchValue, setSearchValue] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const props = { token: token, singleSelect: false, style: "multy" };

  useEffect(() => {
    const handleUsers = async (e) => {
      if (!users || toUpdateUsers) {
        setLoading(true);
        const response = await getUsers(token);
        if (response.message) {
          setErrorMessage(response.message);
          setUsers([]);
          setUsersFiltered([]);
        } else {
          setUsers(response);
        }
        setToUpdateUsers(false);
        setLoading(false);
      }
    };
    const handleProjectTypes = async (e) => {
      if (!projectTypes || Object.entries(projectTypes).length === 0) {
        const response = await getProjectTypes(props.token);
        if (response.message) {
          setErrorMessage(response.message);
          setProjectTypes([]);
        } else {
          if (!response || response.length < 1) {
            setErrorMessage("Projects types list is empty!");
          }
          setProjectTypes(response);
        }
      }
    };

    if (loggedUser?.admin) {
      handleProjectTypes();
      handleUsers();

      let usersTmp = users ? users : [];

      usersTmp.filter((user) => {
        user.prjTypesNames = [];
        user.prjTypesNamesList = "";
        if (!user.projectTypes || users.projectTypes?.length < 1) {
          return user;
        }
        if (projectTypes) {
          projectTypes.map((prjType) => {
            if (user.projectTypes.indexOf(prjType.projectTypeId) >= 0) {
              user.prjTypesNames.push(prjType.projectTypeName);
            }
            return prjType;
          });
        }
        user.prjTypesNamesList = user.prjTypesNames.join(", ");
        return user;
      });

      setUsers(usersTmp);
    } else {
      setErrorMessage("You do not have permissions to view the page.");
    }
  }, [loggedUser?.admin, toUpdateUsers]);

  useEffect(() => {
    const doFilter = () => {
      let tmpUsers = [];
      if (!users) {
        setUsersFiltered(tmpUsers);
        return;
      }

      if (!searchValue) {
        setUsersFiltered(users);
      } else {
        tmpUsers = users.filter((user) => {
          return user.username
            .toLowerCase()
            .includes(searchValue.toLowerCase());
        });
        setUsersFiltered(tmpUsers);
      }
    };

    doFilter();
  }, [searchValue, users]);

  const handleEditClick = (userId) => {
    let userObj = users.filter((user) => user.id === userId);
    if (userObj.length > 0) {
      setSelectedUser(userObj[0]);
    } else {
      setSelectedUser({});
    }
    setShowEditModal(true);
  };

  const handleDeleteClick = (userId) => {
    let userObj = users.filter((user) => user.id === userId);
    if (userObj.length > 0) {
      setSelectedUser(userObj[0]);
    } else {
      setSelectedUser({});
    }
    setShowDeleteModal(true);
  };
  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };
  const handleAddUserClick = () => {
    setSelectedUser({});
    setShowEditModal(true);
  };

  const handleUserNameSort = (direction) => {
    switch (direction) {
      case "up": {
        setSortByUserName({ up: true, down: false });
        setSortByAdmin({ up: false, down: false });
        setSortById({ up: false, down: false });
        usersFiltered.sort((a, b) => {
          let firstValue = a.username.toLowerCase();
          let secondValue = b.username.toLowerCase();
          return firstValue > secondValue
            ? 1
            : secondValue > firstValue
            ? -1
            : 0;
        });
        break;
      }
      case "down":
        setSortByUserName({ up: false, down: true });
        setSortByAdmin({ up: false, down: false });
        setSortById({ up: false, down: false });
        usersFiltered.sort((a, b) => {
          let firstValue = a.username.toLowerCase();
          let secondValue = b.username.toLowerCase();
          return firstValue < secondValue
            ? 1
            : secondValue < firstValue
            ? -1
            : 0;
        });
        break;
      default:
        break;
    }
  };

  const handleSortById = (direction) => {
    switch (direction) {
      case "up": {
        setSortById({ up: true, down: false });
        setSortByAdmin({ up: false, down: false });
        setSortByUserName({ up: false, down: false });
        //users sort by id
        usersFiltered.sort((a, b) => {
          return a.id - b.id;
        });
        break;
      }
      case "down":
        setSortById({ up: false, down: true });
        setSortByAdmin({ up: false, down: false });
        setSortByUserName({ up: false, down: false });
        //users sort by id
        usersFiltered.sort((a, b) => {
          return b.id - a.id;
        });
        break;
      default:
        break;
    }
  };

  const handleSortByAdmin = (direction) => {
    switch (direction) {
      case "up": {
        setSortByAdmin({ up: true, down: false });
        setSortById({ up: false, down: false });
        setSortByUserName({ up: false, down: false });
        usersFiltered.sort((a, b) => {
          return a.admin - b.admin;
        });
        break;
      }
      case "down":
        setSortByAdmin({ up: false, down: true });
        setSortById({ up: false, down: false });
        setSortByUserName({ up: false, down: false });
        usersFiltered.sort((a, b) => {
          return b.admin - a.admin;
        });
        break;
      default:
        break;
    }
  };

  return (
    <div id="admin_main_container">
      {loading && (
        <div className="d-flex justify-content-center bg-transparent align-items-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      <div
        id="admin_main_container_1"
        className="bg-light  container-fluid pt-3 pb-3"
        style={
          loading
            ? {
                pointerEvents: "none",
                opacity: "0.4",
              }
            : {}
        }
      >
        <div id="admin_header_container" className="container-fluid pt-3 pb-3 ">
          <h4 className="ms-4">User Settings</h4>
          {errorMessage && (
            <div className="form-outline mt-4">
              <div className="alert alert-danger" role="alert">
                {errorMessage}
              </div>
            </div>
          )}
        </div>
        {loggedUser?.admin && (
          <div id="admin_body_container">
            <div
              id="app-project-status-select border-0"
              className="container-fluid pb-3 border"
            >
              <div className="pt-3 ms-4">
                <h6 id="02"></h6>
              </div>
              <div
                className="btn-toolbar mb-3 "
                role="toolbar"
                aria-label="Toolbar with button groups"
              >
                <div className="input-group ms-4 border">
                  <input
                    className="form-control border-0"
                    type="search"
                    placeholder="Search by name"
                    id="admin-search-input"
                    onChange={handleSearchChange}
                  />
                  <span className="input-group-append">
                    <button
                      className="btn btn-outline-secondary bg-white border-0 ms-n5"
                      type="button"
                    >
                      <i className="fa fa-search"></i>
                    </button>
                  </span>
                </div>
                <div>
                  <button
                    className="btn text-light bg-primary border-1 ms-4"
                    type="button"
                    onClick={handleAddUserClick}
                    disabled={loggedUser?.admin ? false : true}
                  >
                    Add User
                  </button>
                </div>
              </div>
            </div>
            <div
              id="admin_main_container"
              className="bg-light container-fluid pt-3 pb-3"
            >
              <div className="row ms-3 row-cols-auto">
                <table className="table table-striped table-hover ">
                  <thead>
                    <tr>
                      <th scope="col" className="col-sm-1">
                        #
                        {sortById.up ? (
                          <i
                            className="fa fa-solid fa-sort-up ms-1"
                            onClick={() => handleSortById("down")}
                          />
                        ) : sortById.down ? (
                          <i
                            className="fa fa-solid fa-sort-down ms-1"
                            onClick={() => handleSortById("up")}
                          />
                        ) : (
                          <i
                            className="fa fa-sort ms-1"
                            onClick={() => handleSortById("up")}
                          ></i>
                        )}
                      </th>
                      <th scope="col" className="col-sm-3">
                        User
                        {sortByUserName.up ? (
                          <i
                            className="fa fa-solid fa-sort-up ms-1"
                            onClick={() => handleUserNameSort("down")}
                          />
                        ) : sortByUserName.down ? (
                          <i
                            className="fa fa-solid fa-sort-down ms-1"
                            onClick={() => handleUserNameSort("up")}
                          />
                        ) : (
                          <i
                            className="fa fa-sort ms-1"
                            onClick={() => handleUserNameSort("up")}
                          ></i>
                        )}
                      </th>
                      <th scope="col" className="col-sm-1">
                        Is Admin
                        {sortByAdmin.up ? (
                          <i
                            className="fa fa-solid fa-sort-up ms-1"
                            onClick={() => handleSortByAdmin("down")}
                          />
                        ) : sortByAdmin.down ? (
                          <i
                            className="fa fa-solid fa-sort-down ms-1"
                            onClick={() => handleSortByAdmin("up")}
                          />
                        ) : (
                          <i
                            className="fa fa-sort ms-1"
                            onClick={() => handleSortByAdmin("up")}
                          ></i>
                        )}
                      </th>
                      <th scope="col" colSpan="4">
                        Project Types
                      </th>
                      <th scope="col" className="col-sm-1"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {usersFiltered &&
                      usersFiltered.map((user, index) => {
                        return (
                          <tr key={index}>
                            <td className="col-sm-1">{user.id}</td>
                            <td className="col-sm-3">{user.username}</td>
                            <td className="col-sm-1">
                              <input
                                type="checkbox"
                                disabled
                                checked={user.admin ? "checked" : ""}
                              />
                            </td>
                            <td colSpan="4">{user.prjTypesNamesList}</td>
                            <td className="">
                              <button
                                className="btn btn-link btn-sm"
                                id={"edit_" + user.id}
                                onClick={(e) => handleEditClick(user.id)}
                              >
                                Edit
                              </button>
                              <button
                                className="btn btn-link btn-sm text-danger"
                                id={"delete_" + user.id}
                                onClick={(e) => handleDeleteClick(user.id)}
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
      {showDeleteModal && (
        <DeleteModal
          token={token}
          selectedUser={selectedUser}
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          setLoading={setLoading}
          setUsers={setUsers}
          setToUpdateUsers={setToUpdateUsers}
        />
      )}
      {showEditModal && (
        <EditModal
          token={token}
          loggedUser={loggedUser}
          selectedUser={selectedUser}
          showEditModal={showEditModal}
          setShowEditModal={setShowEditModal}
          setLoading={setLoading}
          setUsers={setUsers}
          setToUpdateUsers={setToUpdateUsers}
        />
      )}
    </div>
  );
};

export default Admin;
