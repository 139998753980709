import FetchUtils from "./FetchUtils";

class Qtest {
  static async getProjects(token) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const data = await FetchUtils.fetch(
        process.env.REACT_APP_QTEST_ADAPTER_URL + "/qtest/projects",
        "GET",
        headers
      );

      if (!data || data.status !== "OK") {
        let errorMsg =
          "qTest projects cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { message: errorMsg };
      }
      return data.data;
    } catch (err) {
      return {
        message:
          "Error getting projects (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getTestExecutionInfo(token, projectId) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const data = await FetchUtils.fetch(
        process.env.REACT_APP_QTEST_SERVICE_URL +
          "/qtest-metrics/execution/" +
          projectId,
        "GET",
        headers
      );

      if (!data || data.status !== "OK") {
        let errorMsg =
          "Test execution info cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { message: errorMsg };
      }
      data.data.projectId = projectId;
      return data.data;
    } catch (err) {
      return {
        message:
          "Error getting test execution info (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getTestExecutionChurnInfo(token, projectId) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const data = await FetchUtils.fetch(
        process.env.REACT_APP_QTEST_SERVICE_URL +
          "/qtest-metrics/test-execution-churn/" +
          projectId,
        "GET",
        headers
      );

      if (!data || data.status !== "OK") {
        let errorMsg =
          "Test execution info cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { message: errorMsg };
      }
      data.data.projectId = projectId;
      return data.data;
    } catch (err) {
      return {
        message:
          "Error getting test execution info (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }
  static async getManualvsAutomated(token, projectId) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const data = await FetchUtils.fetch(
        process.env.REACT_APP_QTEST_SERVICE_URL +
          "/qtest-metrics/manual-vs-automated-test/" +
          projectId,
        "GET",
        headers
      );

      if (!data || data.status !== "OK") {
        let errorMsg =
          "Test info cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { message: errorMsg };
      }
      data.data.projectId = projectId;
      return data.data;
    } catch (err) {
      return {
        message:
          "Error getting test execution info (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getAutomatedTestRunByType(token, projectId) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const data = await FetchUtils.fetch(
        process.env.REACT_APP_QTEST_SERVICE_URL +
          "/qtest-metrics/automated-test/" +
          projectId,
        "GET",
        headers
      );
      if (!data || data.status !== "OK") {
        let errorMsg =
          "Test info cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { message: errorMsg };
      }
      data.data.projectId = projectId;
      return data.data;
    } catch (err) {
      return {
        message:
          "Error getting test execution info (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getTestExecutionFailuresByTypeTrending(token, projectId) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const data = await FetchUtils.fetch(
        process.env.REACT_APP_QTEST_SERVICE_URL +
          "/qtest-metrics/test-exe-failures-by-trending/Automation/" +
          projectId,
        "GET",
        headers
      );
      if (!data || data.status !== "OK") {
        let errorMsg =
          "Test info cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { message: errorMsg };
      }
      data.data.projectId = projectId;
      return data.data;
    } catch (err) {
      return {
        message:
          "Error getting test execution info (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getTestExecutionFailuresByType(token, projectId) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const data = await FetchUtils.fetch(
        process.env.REACT_APP_QTEST_SERVICE_URL +
          "/qtest-metrics/test-execution-failure-by-type/" +
          projectId,
        "GET",
        headers
      );
      if (!data || data.status !== "OK") {
        let errorMsg =
          "Test info cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { message: errorMsg };
      }
      data.data.projectId = projectId;
      return data.data;
    } catch (err) {
      return {
        message:
          "Error getting test execution info (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getManualTestRunROI(token, projectId) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const data = await FetchUtils.fetch(
        process.env.REACT_APP_QTEST_SERVICE_URL +
          "/qtest-metrics/manual-test-run-roi/" +
          projectId,
        "GET",
        headers
      );
      if (!data || data.status !== "OK") {
        let errorMsg =
          "Manual Test Run ROI cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { message: errorMsg };
      }
      data.data.projectId = projectId;
      return data.data;
    } catch (err) {
      return {
        message:
          "Error getting Manual Test Run ROI info (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getTestDevelopmentInfo(token, projectId) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const data = await FetchUtils.fetch(
        process.env.REACT_APP_QTEST_SERVICE_URL +
          "/qtest-metrics/testcase-trending/" +
          projectId,
        "GET",
        headers
      );

      if (!data || data.status !== "OK") {
        let errorMsg =
          "Test Development Info cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { message: errorMsg };
      }
      data.projectId = projectId;
      return data;
    } catch (err) {
      return {
        message:
          "Error getting test development info (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getReleases(token, projectId) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const data = await FetchUtils.fetch(
        process.env.REACT_APP_QTEST_ADAPTER_URL +
          "/qtest/projects/" +
          projectId +
          "/releases",
        "GET",
        headers
      );

      if (!data || data.status !== "OK") {
        let errorMsg =
          "qTest releases cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { message: errorMsg };
      }
      return data.data;
    } catch (err) {
      return {
        message:
          "Error getting releases (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getTestCycles(token, projectId, releaseInfoArr) {
    try {
      if (!releaseInfoArr || releaseInfoArr.length < 1) {
        return { message: "List of selected releases is empty!" };
      }
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      var url =
        process.env.REACT_APP_QTEST_ADAPTER_URL +
        "/qtest/projects/" +
        projectId +
        "/releases/test-iterations?";
      for (let releaseInfo of releaseInfoArr) {
        url += "releaseIds=" + releaseInfo.id + ":-:" + releaseInfo.name + "&";
      }
      const data = await FetchUtils.fetch(url, "GET", headers);

      if (!data || data.status !== "OK") {
        let errorMsg =
          "qTest releases cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { message: errorMsg };
      }
      return data.testIterations;
    } catch (err) {
      return {
        message:
          "Error getting test cycles: (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getRequirementChartsInfo(token, projectId) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      let url =
        process.env.REACT_APP_QTEST_SERVICE_URL + "/requirments/" + projectId;

      const data = await FetchUtils.fetch(url, "GET", headers);

      if (!data || data.status !== "OK") {
        let errorMsg =
          "Qtest Requirement Charts info cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { errMsg: errorMsg };
      }

      data.data.projectId = projectId;

      return data.data;
    } catch (err) {
      return {
        errMsg:
          "Error getting requirements info (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getRequirementCoverageQtestInfo(token, projectId) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      let url =
        process.env.REACT_APP_QTEST_SERVICE_URL +
        "/requirments/details/" +
        projectId;

      const data = await FetchUtils.fetch(url, "GET", headers);

      if (!data || data.status !== "OK") {
        let errorMsg =
          "Qtest Requirements Coverage info cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { errMsg: errorMsg };
      }

      data.projectId = projectId;
      return data;
    } catch (err) {
      return {
        errMsg:
          "Error getting Requirement Coverage Qtest info: (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getRequirementsCoverageByAutoTestsInfo(token, projectId) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      let url =
        process.env.REACT_APP_QTEST_SERVICE_URL +
        "requirementsCoverageByAutoTests/" +
        projectId;
      // let data = requirements_qtest_response;
      let data = {};

      if (!data || data.status !== "OK") {
        let errorMsg =
          "Qtest Requirements Coverage info cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { message: errorMsg };
      }

      data.data.projectId = projectId;

      return data.data;
    } catch (err) {
      return {
        message:
          "Error getting Requirements CoverageByAutoTests: (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getProcessComplianceRequirementsInfo(token, projectId) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      let url =
        process.env.REACT_APP_QTEST_SERVICE_URL +
        "/process-compliance/requirements/" +
        projectId;

      const data = await FetchUtils.fetch(url, "GET", headers);

      if (!data || data.status !== "OK") {
        let errorMsg =
          "ProjectID " +
          projectId +
          ": Process Compliance Requirements info cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { errMsg: errorMsg };
      }
      data.data.projectId = projectId;
      return data;
    } catch (err) {
      return {
        message:
          "Error getting Process Compliance Requirements: (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }
  static async getProcessComplianceTestRunsDataInfo(
    token,
    projectId,
    projectName
  ) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      let url =
        process.env.REACT_APP_QTEST_SERVICE_URL +
        "/process-compliance/test-runs/" +
        projectId;

      const data = await FetchUtils.fetch(url, "GET", headers);

      if (!data || data.status !== "OK") {
        let errorMsg =
          "ProjectID " +
          projectId +
          ": Process Compliance Test Runs Data info cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { errMsg: errorMsg };
      }
      data.projectId = projectId;
      data.projectName = projectName;
      return data;
    } catch (err) {
      return {
        message:
          "Error getting Process Compliance Test Runs Data: (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getProcessComplianceTestSuitesInfo(token, projectId) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      let url =
        process.env.REACT_APP_QTEST_SERVICE_URL +
        "/process-compliance/test-suites/" +
        projectId;

      const data = await FetchUtils.fetch(url, "GET", headers);

      if (!data || data.status !== "OK") {
        let errorMsg =
          "ProjectID " +
          projectId +
          ": Process Compliance Test Suites info cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { errMsg: errorMsg };
      }
      data.data.projectId = projectId;
      return data;
    } catch (err) {
      return {
        message:
          "Error getting Process Compliance Test Suites: (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getProcessComplianceTestCasesInfo(
    token,
    projectId,
    projectName
  ) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      let url =
        process.env.REACT_APP_QTEST_SERVICE_URL +
        "/process-compliance/test-cases/" +
        projectId;

      const data = await FetchUtils.fetch(url, "GET", headers);

      if (!data || data.status !== "OK") {
        let errorMsg =
          "ProjectID " +
          projectId +
          ": ProjectID" +
          projectId +
          ": Process Compliance Test Cases info cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { errMsg: errorMsg };
      }
      data.projectId = projectId;
      data.projectName = projectName;
      return data;
    } catch (err) {
      return {
        message:
          "Error getting Process Compliance Test Cases: (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getProjectTrackerLatestSprintInfo(token, projectIds) {
    try {
      if (!projectIds || projectIds.length < 1) {
        return { message: "List of selected releases is empty!" };
      }
      let index = 0;
      let url =
        process.env.REACT_APP_QTEST_SERVICE_URL +
        "/project-tracker/latest-sprint?";
      for (let projectId of projectIds) {
        url += "projectId=" + projectId;
        if (index < projectIds.length - 1) {
          url += "&";
        }
        index++;
      }
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const data = await FetchUtils.fetch(url, "GET", headers);

      if (!data || data.status !== "OK") {
        let errorMsg =
          "ProjectIDs " +
          projectIds +
          ": Project Tracker LatestSprint info cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { errMsg: errorMsg };
      }
      return data;
    } catch (err) {
      return {
        message:
          "Error getting Project Tracker LatestSprint: (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getProjectTrackerLatestSprintTestExeInfo(token, projectIds) {
    try {
      if (!projectIds || projectIds.length < 1) {
        return { message: "List of selected releases is empty!" };
      }
      let index = 0;
      let url =
        process.env.REACT_APP_QTEST_SERVICE_URL +
        "/project-tracker/latest-sprint/test-case?";
      for (let projectId of projectIds) {
        url += "projectId=" + projectId;
        if (index < projectIds.length - 1) {
          url += "&";
        }
        index++;
      }
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const data = await FetchUtils.fetch(url, "GET", headers);

      if (!data || data.status !== "OK") {
        let errorMsg =
          "ProjectIDs " +
          projectIds +
          ": Project Tracker LatestSprint info cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { errMsg: errorMsg };
      }
      return data;
    } catch (err) {
      return {
        message:
          "Error getting Project Tracker LatestSprint: (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getAutomationTrendingChartInfo(token, projectTypeName) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      let url =
        process.env.REACT_APP_QTEST_SERVICE_URL +
        "/cross-project/automation-testcase-trending?projectType=" +
        projectTypeName;

      const data = await FetchUtils.fetch(url, "GET", headers);

      if (!data || data.status !== "OK") {
        let errorMsg =
          "Jira Automation Trending info cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { errMsg: errorMsg };
      }

      return data;
    } catch (err) {
      return {
        errMsg:
          "Error getting Automation Trending info (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }
}

export default Qtest;
