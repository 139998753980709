import React, { useState } from "react";
import "font-awesome/css/font-awesome.min.css";

const InsightsCard = (props) => {
  const [isHovering, setIsHovering] = useState(false);
  let mouseStyling = isHovering ? "pointer" : "none";

  const getIndicator = () => {
    let indicator;

    if (props.indicator === "Healthly") {
      indicator = "#007AC3";
    } else if (props.indicator === "Attention") {
      indicator = "#FFC107";
    } else if (props.indicator === "Alert") {
      indicator = "#DC3545";
    } else {
      indicator = "blue";
    }

    return indicator;
  };

  const handleClick = () => {
    props.onClick();
  };

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  return (
    <div
      className="card"
      style={{ height: "100%", cursor: mouseStyling }}
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseOut}
      onClick={handleClick}
    >
      <div
        className="card-header"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <span
          className="rounded shadow-sm pb-1"
          style={{ backgroundColor: getIndicator() }}
        >
          <small className="text-light ms-2 me-2 mt-2">{props.indicator}</small>
        </span>
        <div>
          <a
            href={props.infoLink}
            target="_blank"
            style={{ color: getIndicator() }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              fill="currentColor"
              className="bi bi-info-square"
              viewBox="0 0 16 16"
              style={{ color: "#007AC3" }}
            >
              <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
            </svg>
          </a>
        </div>
      </div>
      <div className="card-body mb-3">
        <div className="form-check">
          <input
            style={{ marginTop: "5px" }}
            className="form-check-input"
            type="checkbox"
            value={props.serviceName}
            onChange={props.checked}
            onClick={(e) => e.stopPropagation()}
            id="flexCheckChecked"
          />
          <h5 className="card-title mb-4">{props.serviceName}</h5>
        </div>
        <p className="card-text ms-2 fs-7" style={{ fontSize: "16px" }}>
          Production Version: <strong>{props.serviceVersion}</strong>
        </p>
        <p className="card-text ms-2 fs-7" style={{ fontSize: "16px" }}>
          Regions:
          <strong style={{ marginLeft: "5px" }}>
            {props.serviceRegions.map((region, index) => {
              return (
                <span
                  key={index}
                  className="badge rounded-pill bg-light text-dark"
                  style={{ margin: "0 2px" }}
                >
                  {region}
                </span>
              );
            })}
          </strong>
        </p>
      </div>
    </div>
  );
};

export default InsightsCard;
