import FetchUtils from "./FetchUtils";

class Admin {

    static async getUsers(token) {
        const headers = {
            'authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
        try {
            const data = await FetchUtils.fetch(process.env.REACT_APP_ADMIN_SERVICE_URL + '/users/getAllUsers', 'GET', headers);
            if (!data || !(data.status == 'OK' || data.status == 200)) {
                let errorMsg = 'Users info cannot be taken from the server: ' + (data?.message ? data.message : (data.error ? data.error : 'Unexpected server error [' + data.status + ']'));
                return { message: errorMsg };
            }
            return data;

        } catch (err) {
            return { message: 'Error getting users info (' + err.message ? err.message : JSON.stringify(err) + ')' };
        }
    };


    static async getUserByUserName(token, userName) {
        const headers = {
            'authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
        try {
            const data = await FetchUtils.fetch(process.env.REACT_APP_ADMIN_SERVICE_URL + '/users/getUserByUsername/' + userName, 'GET', headers);
            if (!data || !(data.status == 'OK' || data.status == 200)) {
                let errorMsg = 'User info cannot be taken from the server: ' + (data?.message ? data.message : (data.error ? data.error : 'Unexpected server error'));
                return { message: errorMsg };
            }
            return data;

        } catch (err) {
            return { message: 'Error getting user info (' + err.message ? err.message : JSON.stringify(err) + ')' };
        }
    };

    static async createUser(token, userInfo) {
        const headers = {
            'authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
        let url = process.env.REACT_APP_ADMIN_SERVICE_URL + '/users';
        let body = JSON.stringify(userInfo);

        try {
            const data = await FetchUtils.fetch(url, 'POST', headers, body);
            if (!data || !(data.status == 'OK' || data.status == 200)) {
                let errorMsg = 'User cannot be created: ' + (data?.message ? data.message : (data.error ? data.error : 'Unexpected server error'));
                return { message: errorMsg };
            }
            return data;

        } catch (err) {
            return { message: 'Error during user creation (' + err.message ? err.message : JSON.stringify(err) + ')' };
        }
    };

    static async updateUser(token, userInfo) {
        const headers = {
            'authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
        let url = process.env.REACT_APP_ADMIN_SERVICE_URL + '/users';
        let body = JSON.stringify(userInfo);

        try {
            const data = await FetchUtils.fetch(url, 'PUT', headers, body);
            if (!data || !(data.status == 'OK' || data.status == 200)) {
                let errorMsg = 'User cannot be updated: ' + (data?.message ? data.message : (data.error ? data.error : 'Unexpected server error'));
                return { message: errorMsg };
            }
            return data;

        } catch (err) {
            return { message: 'Error during user updating (' + err.message ? err.message : JSON.stringify(err) + ')' };
        }
    };

    static async deleteUser(token, userId) {
        const headers = {
            'authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
        try {
            const data = await FetchUtils.fetch(process.env.REACT_APP_ADMIN_SERVICE_URL + '/users/delete/' + userId, 'DELETE', headers);
            if (!data || !(data.status == 'OK' || data.status == 204)) {
                let errorMsg = 'User cannot be deleted from the server: ' + (data?.message ? data.message : (data.error ? data.error : 'Unexpected server error'));
                return { message: errorMsg };
            }
            return data;

        } catch (err) {
            return { message: 'Error deleting user (' + err.message ? err.message : JSON.stringify(err) + ')' };
        }
    };

    static async saveFavoriteProjects(token, userId, projectId) {
        const headers = {
            'authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }
        let url = process.env.REACT_APP_ADMIN_SERVICE_URL + '/users/saveFavoriteProjects?projectId='+projectId+'&userId='+userId;

        try {
            const data = await FetchUtils.fetch(url, 'POST', headers, "{}");
            if (!data || !(data.status == 'OK' || data.status == 200)) {
                let errorMsg = 'User cannot be updated: ' + (data?.message ? data.message : (data.error ? data.error : 'Unexpected server error'));
                return { message: errorMsg };
            }
            return data;

        } catch (err) {
            return { message: 'Error during user creation (' + err.message ? err.message : JSON.stringify(err) + ')' };
        }
    };
    


}

export default Admin;