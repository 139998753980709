// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Creatable-component {
  z-index: 2;
}
.Creatable-component .css-1rhbuit-multiValue {
  background: #0196fc;
  padding: 3.5px 6px;
  border-radius: 15px;
}
.Creatable-component_off .css-1rhbuit-multiValue {
  background: #aed8ff;
  padding: 3.5px 6px;
  border-radius: 15px;
  color: #ffffff;
}

.Creatable-component .css-1rhbuit-multiValue .css-12jo7m5 {
  color: white;
  font-weight: 500;
}

.Creatable-component_off .css-1rhbuit-multiValue .css-12jo7m5 {
  color: white;
  font-weight: 500;
}
.Creatable-component .css-1rhbuit-multiValue .css-xb97g8 {
  background: white;
  margin-left: 1px;
  border-radius: 50%;
  color: #0196fc;
  width: 20px;
  height: 20px;
  margin-top: 1px;
}

.Creatable-component_off .css-1rhbuit-multiValue .css-xb97g8 {
  background: white;
  margin-left: 1px;
  border-radius: 50%;
  color: #aed8ff;
  width: 20px;
  height: 20px;
  margin-top: 1px;
}`, "",{"version":3,"sources":["webpack://./src/components/css/ADOInfo.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;AACA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,mBAAmB;AACrB;AACA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB;AACA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;EAClB,cAAc;EACd,WAAW;EACX,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;EAClB,cAAc;EACd,WAAW;EACX,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".Creatable-component {\n  z-index: 2;\n}\n.Creatable-component .css-1rhbuit-multiValue {\n  background: #0196fc;\n  padding: 3.5px 6px;\n  border-radius: 15px;\n}\n.Creatable-component_off .css-1rhbuit-multiValue {\n  background: #aed8ff;\n  padding: 3.5px 6px;\n  border-radius: 15px;\n  color: #ffffff;\n}\n\n.Creatable-component .css-1rhbuit-multiValue .css-12jo7m5 {\n  color: white;\n  font-weight: 500;\n}\n\n.Creatable-component_off .css-1rhbuit-multiValue .css-12jo7m5 {\n  color: white;\n  font-weight: 500;\n}\n.Creatable-component .css-1rhbuit-multiValue .css-xb97g8 {\n  background: white;\n  margin-left: 1px;\n  border-radius: 50%;\n  color: #0196fc;\n  width: 20px;\n  height: 20px;\n  margin-top: 1px;\n}\n\n.Creatable-component_off .css-1rhbuit-multiValue .css-xb97g8 {\n  background: white;\n  margin-left: 1px;\n  border-radius: 50%;\n  color: #aed8ff;\n  width: 20px;\n  height: 20px;\n  margin-top: 1px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
