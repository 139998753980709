import { Chart } from "react-google-charts";

export default function RequirementsAverageAgeChart(props) {
  const reqAvgData = props.data?.reqAvgData;

  if (!reqAvgData) {
    return (
      <div className="form-outline mt-4" id="error-message">
        <div className="alert alert-danger" role="alert">
          Data is empty!
        </div>
      </div>
    );
  }

  let headerData = ["Week", "Stories"];
  let tableData = [
    headerData,
    ["1 wk", reqAvgData["1 wk"] >= 0 ? reqAvgData["1 wk"] : 0],
    ["2 wk", reqAvgData["2 wk"] >= 0 ? reqAvgData["2 wk"] : 0],
    ["3 wk", reqAvgData["3 wk"] >= 0 ? reqAvgData["3 wk"] : 0],
    ["4 wk", reqAvgData["4 wk"] >= 0 ? reqAvgData["4 wk"] : 0],
    ["5 wk", reqAvgData["5 wk"] >= 0 ? reqAvgData["5 wk"] : 0],
    ["6 wk", reqAvgData["6 wk"] >= 0 ? reqAvgData["6 wk"] : 0],
    ["7 wk", reqAvgData["7 wk"] >= 0 ? reqAvgData["7 wk"] : 0],
    ["8 wk", reqAvgData["8 wk"] >= 0 ? reqAvgData["8 wk"] : 0],
    ["9 wk", reqAvgData["9 wk"] >= 0 ? reqAvgData["9 wk"] : 0],
    ["10 wk", reqAvgData["10 wk"] >= 0 ? reqAvgData["10 wk"] : 0],
  ];

  var options = {
    title: "Stories Average Age ('To verify' to 'Resolved')",
    titleTextStyle: { fontSize: "16" },
    bars: "vertical",
    isStacked: true,
    hAxis: { slantedText: true, textStyle: { fontSize: 11 } },
    vAxis: { format: "0" },
    chartArea: { left: 70, top: 50, width: "65%", height: "65%" },
    tooltip: { isHtml: true },
  };

  return (
    <div>
      <Chart
        chartType="ColumnChart"
        width="100%"
        height="400px"
        data={tableData}
        options={options}
      />
    </div>
  );
}
