import { useEffect, useRef } from "react";
import { Chart } from "react-google-charts";

const getTooltip = function (statuses, date, name, values) {
  var tooltip =
    '<div align="left" style="width: auto; float: left; table-layout: auto">' +
    '<table class="table table-hover table-condensed table-sm" >' +
    "<thead><tr><th>" +
    date +
    "</th></tr></thead>" +
    "<tbody>";

  let index = 0;
  for (let statValue of statuses) {
    if (statValue === name) {
      tooltip =
        tooltip +
        '<tr class="table-danger" key={' +
        index +
        "}><td><b>" +
        name +
        "</b>";
    } else {
      tooltip = tooltip + "<tr  key={" + index + "}><td>" + statValue;
    }
    tooltip =
      tooltip +
      ": <b>" +
      Math.abs(
        Object.values(values)[index] ? Object.values(values)[index] : 0
      ) +
      "</b></td></tr>";
    index++;
  }
  tooltip = tooltip + "</tbody></table></div>";

  return tooltip;
};

const getCorrectCurrentIteration = (props, availableIterations_data = []) => {
  let iterationToSearch = props.currentIteration;
  let availableIterations = Object.keys(availableIterations_data);
  let iteration = {};

  if (availableIterations.includes(iterationToSearch)) {
    iteration = availableIterations_data[iterationToSearch];
  }

  return iteration;
};

export default function DefectsChurnChart(props) {
  let defectChurn = [];

  if (props.isADO === "ADO") {
    defectChurn = props.jiraChartsInfo?.defectChurn?.data;
  } else {
    defectChurn = props.jiraChartsInfo?.defectChurn;
  }

  const selectedDefectChurnStateValueRef = useRef();
  const currentIterationInfoRef = useRef();

  useEffect(() => {
    selectedDefectChurnStateValueRef.current = defectChurn;
  }, [defectChurn]);

  let currentIterationInfo = [];

  if (defectChurn) {
    if (props.isADO === "ADO") {
      currentIterationInfo =
        props.currentIteration === "Overall"
          ? defectChurn[0]
          : getCorrectCurrentIteration(
              props,
              props.jiraChartsInfo.defectChurn.data
            );
    } else {
      currentIterationInfo =
        props.currentIteration === "Overall"
          ? defectChurn[0]
          : getCorrectCurrentIteration(props, props.jiraChartsInfo.defectChurn);
    }
  }
  const issueMap =
    props.isADO === "ADO"
      ? props.jiraChartsInfo?.defectAgeChartMap?.data?.issueMap
      : props.jiraChartsInfo?.issueMap;
  currentIterationInfoRef.current = currentIterationInfo;
  let statuses =
    props.isADO == "ADO"
      ? ["1 - Severe", "2 - High", "3 - Medium", "4 - Low", "Not Defined"]
      : [
          "Sev 1-Severe",
          "Sev 2-High",
          "Sev 3-Medium",
          "Sev 4-Low",
          "Not Defined",
        ];

  let headerData = [
    "Age",
    statuses[0],
    { type: "string", role: "tooltip", p: { html: true } },
    statuses[1],
    { type: "string", role: "tooltip", p: { html: true } },
    statuses[2],
    { type: "string", role: "tooltip", p: { html: true } },
    statuses[3],
    { type: "string", role: "tooltip", p: { html: true } },
    statuses[4],
    { type: "string", role: "tooltip", p: { html: true } },
  ];
  let tempDataEmpty = [
    headerData,
    ["1 Resub", 0, {}, 0, {}, 0, {}, 0, {}],
    ["2 Resub", 0, {}, 0, {}, 0, {}, 0, {}],
    ["3 Resub", 0, {}, 0, {}, 0, {}, 0, {}],
    ["4 Resub", 0, {}, 0, {}, 0, {}, 0, {}],
    ["5 Resub", 0, {}, 0, {}, 0, {}, 0, {}],
    ["6 Resub", 0, {}, 0, {}, 0, {}, 0, {}],
    ["7 Resub", 0, {}, 0, {}, 0, {}, 0, {}],
    ["8 Resub", 0, {}, 0, {}, 0, {}, 0, {}],
    ["9 Resub", 0, {}, 0, {}, 0, {}, 0, {}],
    ["10 Resub", 0, {}, 0, {}, 0, {}, 0, {}],
    ["+10 Resub", 0, {}, 0, {}, 0, {}, 0, {}],
  ];
  let tempData = [];
  let tooltipValues = {
    severeIteration: 0,
    highIteration: 0,
    mediumIteration: 0,
    lowIteration: 0,
    notDefined: 0,
  };

  for (let i = 0; i <= 11; i++) {
    let week = i === 11 ? "over10" : `x${i}`;
    let weekString = i === 11 ? "+10 Resub" : `${i} Resub`;
    let severeIteration = [];
    let highIteration = [];
    let mediumIteration = [];
    let lowIteration = [];
    let notDefined = [];

    if (i === 0) {
      tempData.push(headerData);
    }

    if (statuses[0] in currentIterationInfo) {
      severeIteration = currentIterationInfo[statuses[0]];
      tooltipValues.severeIteration =
        currentIterationInfo[statuses[0]][week].length;
    }

    if (statuses[1] in currentIterationInfo) {
      highIteration = currentIterationInfo[statuses[1]];
      tooltipValues.highIteration =
        currentIterationInfo[statuses[1]][week].length;
    }

    if (statuses[2] in currentIterationInfo) {
      mediumIteration = currentIterationInfo[statuses[2]];
      tooltipValues.mediumIteration =
        currentIterationInfo[statuses[2]][week].length;
    }

    if (statuses[3] in currentIterationInfo) {
      lowIteration = currentIterationInfo[statuses[3]];
      tooltipValues.lowIteration =
        currentIterationInfo[statuses[3]][week].length;
    }

    if (statuses[4] in currentIterationInfo) {
      notDefined = currentIterationInfo[statuses[4]];
      tooltipValues.notDefined = currentIterationInfo[statuses[4]][week].length;
    }

    tempData.push([
      i === 11 ? "+10 Resub" : `${i} Resub`,

      statuses[0] in currentIterationInfo
        ? currentIterationInfo[statuses[0]][week].length
        : 0,
      getTooltip(statuses, weekString, statuses[0], tooltipValues),
      statuses[1] in currentIterationInfo
        ? currentIterationInfo[statuses[1]][week].length
        : 0,
      getTooltip(statuses, weekString, statuses[1], tooltipValues),
      statuses[2] in currentIterationInfo
        ? currentIterationInfo[statuses[2]][week].length
        : 0,
      getTooltip(statuses, weekString, statuses[2], tooltipValues),
      statuses[3] in currentIterationInfo
        ? currentIterationInfo[statuses[3]][week].length
        : 0,
      getTooltip(statuses, weekString, statuses[3], tooltipValues),
      statuses[4] in currentIterationInfo
        ? currentIterationInfo[statuses[4]][week].length
        : 0,
      getTooltip(statuses, weekString, statuses[4], tooltipValues),
    ]);
  }

  if (tempData) {
    tempData.splice(1, 1);
  }

  const chartEvents = [
    {
      eventName: "ready",
      callback: ({ chartWrapper, google }) => {
        var handler = async function () {
          const chart = chartWrapper.getChart();
          var selection = chart.getSelection();
          if (!selection || selection.length < 1) {
            return;
          }
          var row = selection[0].row;
          var col = selection[0].column;
          var severityMap = {
            1: statuses[0],
            3: statuses[1],
            5: statuses[2],
            7: statuses[3],
            9: statuses[4],
          };
          var weekMap = {
            0: "x1",
            1: "x2",
            2: "x3",
            3: "x4",
            4: "x5",
            5: "x6",
            6: "x7",
            7: "x8",
            8: "x9",
            9: "x10",
            10: "over10",
          };
          var weekMapName = [
            "1 Resub",
            "2 Resub",
            "3 Resub",
            "4 Resub",
            "5 Resub",
            "6 Resub",
            "7 Resub",
            "8 Resub",
            "9 Resub",
            "10 Resub",
            "+10 Resub",
          ];
          var defectTitles = [
            "Issue ID",
            "Issue Summary",
            "Status",
            "Resolution",
            "Severity",
            "Assignee",
          ];

          let selectedElement =
            currentIterationInfoRef.current[severityMap[col]];
          var defectsIdsArray = selectedElement[weekMap[row]];
          defectsIdsArray = defectsIdsArray ? defectsIdsArray : [];
          var defectsInfoArray = [];

          Object.keys(issueMap).forEach((issueId) => {
            if (defectsIdsArray.indexOf(issueId) >= 0) {
              defectsInfoArray.push(issueMap[issueId]);
            }
          });

          props.setModalData({
            title: `${weekMapName[row]} : ${severityMap[col]}`,
            projectId: props.currentProjectId,
            createdDt: null,
            releaseName: null,
            status: null,
            severity: severityMap[col],
            tableTitles: defectTitles,
            defects: defectsInfoArray,
          });
          if (defectsInfoArray.length < 1) {
            props.setShowModal(false);
          } else {
            props.setShowModal(true);
          }
        };

        google.visualization.events.addListener(
          chartWrapper.getChart(),
          "select",
          () => handler()
        );
      },
    },
  ];

  var options = {
    title: "Defect Churn",
    titleTextStyle: { fontSize: "16" },
    bars: "vertical",
    isStacked: true,
    hAxis: { slantedText: true, textStyle: { fontSize: 11 } },
    vAxis: { format: "0" },
    chartArea: { left: 70, top: 50, width: "65%", height: "65%" },
    tooltip: { isHtml: true },
  };

  return (
    <div>
      <Chart
        chartType="ColumnChart"
        width="100%"
        height="400px"
        data={currentIterationInfo ? tempData : tempDataEmpty}
        chartEvents={chartEvents}
        options={options}
      />
    </div>
  );
}
