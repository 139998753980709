import { useState } from "react";

export default function useProjectDetails() {
  const getProjectDetails = () => {
    const projectDetailsStr = localStorage.getItem("currentProjectDetails");
    if (projectDetailsStr === "undefined" || projectDetailsStr === "{}") {
      return null;
    }
    const projectDetails = JSON.parse(projectDetailsStr);
    return projectDetails;
  };

  const [projectDetails, setProjectDetails] = useState(getProjectDetails());

  const saveProjectDetails = (projectDetails) => {
    localStorage.setItem(
      "currentProjectDetails",
      JSON.stringify(projectDetails)
    );
    setProjectDetails(projectDetails);
  };

  const getProjectReportInfo = () => {
    const projectReportInfoStr = localStorage.getItem(
      "currentProjectReportInfo "
    );
    if (projectReportInfoStr === "undefined" || projectReportInfoStr === "{}") {
      return null;
    }
    const projectReportInfo = JSON.parse(projectReportInfoStr);
    return projectReportInfo;
  };

  const [projectReportInfo, setProjectReportInfo] = useState(
    getProjectReportInfo()
  );

  const saveProjectReportInfo = (projectReportInfo) => {
    localStorage.setItem(
      "currentProjectReportInfo ",
      JSON.stringify(projectReportInfo)
    );
    setProjectReportInfo(projectReportInfo);
  };

  const getCurrentProjectId = () => {
    const projectIdStr = localStorage.getItem("currentProjectId");
    if (projectIdStr === "undefined" || projectIdStr === "{}") {
      return null;
    }
    const projectId = JSON.parse(projectIdStr);
    return projectId;
  };

  const [currentProjectId, setCurrentProjectId] = useState(
    getCurrentProjectId()
  );

  const saveCurrentProjectId = (projectDetails) => {
    localStorage.setItem("currentProjectId", JSON.stringify(projectDetails));
    setCurrentProjectId(projectDetails);
  };

  const getAddNewProjectFlag = () => {
    const str = localStorage.getItem("addNewProjectFlag");
    if (str === "undefined" || str === "{}") {
      return null;
    }
    const flag = JSON.parse(str);
    return flag;
  };

  const [addNewProjectFlag, setAddNewProjectFlag] = useState(
    getAddNewProjectFlag()
  );

  const saveAddNewProjectFlag = (flag) => {
    localStorage.setItem("addNewProjectFlag", JSON.stringify(flag));
    setAddNewProjectFlag(flag);
  };

  return {
    setProjectDetails: saveProjectDetails,
    projectDetails,
    setCurrentProjectId: saveCurrentProjectId,
    currentProjectId,
    setAddNewProjectFlag: saveAddNewProjectFlag,
    addNewProjectFlag,
    setProjectReportInfo: saveProjectReportInfo,
    projectReportInfo,
  };
}
