import React, { useState } from "react";
import ReactModal from "react-modal";
import ErrorMessage from "../ErrorMessage";
import useErrorMessage from "../../hooks/useErrorMessage";
import Loading from "../Loading";
import "../../css/bootstrap.min.css";
import "../../css/custom.min.css";

ReactModal.setAppElement("#root");

export default function TestCasesDevelopmentModal(props) {
  const { setErrorMessage, errorMessage } = useErrorMessage();
  const [loading, setLoading] = useState(false);

  const style = {
    content: {
      border: "4px",
      borderCcolor: "#000000",
      borderRadius: "4px",
      bottom: "auto",
      maxHeight: "80%",
      minHeight: "20rem",
      left: "50%",
      padding: "1rem",
      position: "fixed",
      right: "auto",
      top: "50%",
      transform: "translate(-50%,-50%)",
      minWidth: "20rem",
      width: "60%",
      maxWidth: "60rem",
      backgroundColor: "#e7eff8",
      boxShadow: "0 5px 15px rgba(0,0,0,.5)",
    },
  };

  const ModalWindow = () => {
    const handleCloseClick = () => {
      props.setShowModal(false);
    };

    return (
      <ReactModal
        isOpen={props.isOpen}
        shouldFocusAfterRender={false}
        dialogClassName="modal-90w"
        shouldCloseOnOverlayClick={true}
        style={style}
        scrollable={false}
        shouldCloseOnEsc={true}
        shouldReturnFocusAfterClose={true}
        onRequestClose={() => handleCloseClick()}
      >
        <div
          id="modal-header"
          className="modal-header"
          style={{ padding: "0", paddingBottom: "6px" }}
        >
          <h5 className="modal-title" id="modalTitle">
            {props?.data?.title}
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleCloseClick()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div
          id="modal-body"
          className="modal-body"
          style={{
            minHeight: "10rem",
            maxHeight: "600px",
            overflowY: "auto",
            bottom: "0",
            top: "0",
            padding: "0",
          }}
        >
          <ErrorMessage errorMessage={errorMessage} />
          {loading ? (
            <Loading />
          ) : (
            <div
              id="modal-body"
              className="modal-body"
              style={{
                minHeight: "10rem",
                maxHeight: "600px",
                overflowY: "auto",
                bottom: "0",
                top: "0",
                padding: "0",
              }}
            >
              <table
                className="table table-bordered table-hover table-sm "
                style={{
                  backgroundColor: "#ffffff",
                  height: "100%",
                  top: 0,
                  padding: 0,
                  margin: 0,
                  fontSize: "12px",
                }}
              >
                <thead
                  style={{
                    position: "sticky",
                    top: 0,
                  }}
                >
                  <tr className="table-primary">
                    {props.data?.headers ? (
                      <>
                        {props.data.headers.map((header, index) => {
                          return <td key={index}>{header}</td>;
                        })}
                      </>
                    ) : (
                      <>
                        <td>ID</td>
                        <td>Name</td>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {props.data?.testCasesData?.map((item, index) => {
                    if (
                      props.data.idModal ===
                      "testExecutionFailureByTypeTrendingChart"
                    ) {
                      return (
                        <tr key={index}>
                          <td>
                            <a
                              href={item.link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {item.id}
                            </a>
                          </td>
                          <td>{item.name}</td>
                          <td>{item.status}</td>
                          <td>{item.defect}</td>
                        </tr>
                      );
                    } else if (
                      props.data.idModal === "testExecutionFailureByTypeChart"
                    ) {
                      return (
                        <tr key={index}>
                          <td>
                            <a
                              href={item.link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {item.id}
                            </a>
                          </td>
                          <td>{item.name}</td>
                          <td>{item.status}</td>
                          <td>{item.defect}</td>
                          <td>{item.type}</td>
                        </tr>
                      );
                    } else {
                      return (
                        <tr key={index}>
                          <td>
                            <a
                              href={item.link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {item.testCaseId}
                            </a>
                          </td>
                          <td>{item.testCaseDescription}</td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
        <div className="modal-footer">
          <button
            className="btn  btn-primary  btn-block ms-4 "
            onClick={() => handleCloseClick()}
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </ReactModal>
    );
  };

  return <ModalWindow />;
}
