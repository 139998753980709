import React, { useState, useEffect } from "react";
import Jira from "../../services/Jira";
import ErrorMessage from "../../components/common/ErrorMessage";
import ProjectTypes from "../../components/projects/ProjectTypes.js";
import JiraCustomChart from "../../components/charts/jiraCustomCharts/JiraCustomChart.js";
import Projects from "../../services/Projects";
import Loading from "../../components/common/Loading";
import useProjects from "../../components/hooks/useProjects";
import { MultiSelect } from "react-multi-select-component";
import "../../components/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";

async function getJiraCustomInfo(token, projectType) {
  try {
    const resp = await Jira.getJiraCustomInfo(token, projectType);
    return resp;
  } catch (err) {
    return {
      errMsg: err.message ? err.message : JSON.stringify(err),
    };
  }
}

async function getProjectTypes(token) {
  try {
    return await Projects.getProjectTypes(token);
  } catch (err) {
    return {
      errMsg: "Project Types list was not received! (" + err.message + ")",
    };
  }
}

export default function JiraCustomPage(props) {
  const { projectTypes, setProjectTypes } = useProjects();
  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);
  const [chartsInfo, setChartsInfo] = useState({});
  const [jiraItems, setJiraItems] = useState([]);
  const [selectedProjectType, setSelectedProjectType] = useState("");
  const [releaseFoundValues, setReleaseFoundValues] = useState([]);
  const [selectedReleaseFoundValues, setSelectedReleaseFoundValues] = useState(
    []
  );
  const [issueTypesValues, setIssueTypesValues] = useState([]);
  const [selectedIssueTypesValues, setSelectedIssueTypesValues] = useState([]);
  const [statusValues, setStatusValues] = useState([]);
  const [selectedStatusValues, setSelectedStatusValues] = useState([]);
  const [severityValues, setSeverityValues] = useState([]);
  const [selectedSeverityValues, setSelectedSeverityValues] = useState([]);
  const [priorityValues, setPriorityValues] = useState([]);
  const [selectedPriorityValues, setSelectedPriorityValues] = useState([]);
  const [teamValues, setTeamValues] = useState([]);
  const [selectedTeamValues, setSelectedTeamValues] = useState([]);
  const [environmentValues, setEnvironmentValues] = useState([]);
  const [selectedEnvironmentValues, setSelectedEnvironmentValues] = useState(
    []
  );

  let localProps = {};
  Object.assign(localProps, props);
  localProps.style = "multy";
  localProps.styleValue = { height: "auto" };
  localProps.notAll = true;

  useEffect(() => {
    const handleProjectTypes = async (e) => {
      if (!projectTypes || Object.entries(projectTypes).length === 0) {
        const response = await getProjectTypes(props.token);
        if (response.message) {
          setErrorMessage(response.message);
          setProjectTypes([]);
        } else {
          if (!response || response.length < 1) {
            setErrorMessage("Projects types list is empty!");
          }
          setProjectTypes(response);
        }
      }
    };
    props.changeStyle("jiraCustom");
    handleProjectTypes();
  }, [projectTypes]);

  const handleProjectTypeChange = async (prjTypeObj) => {
    let projectTypeName = prjTypeObj[0].projectTypeName;
    setLoading(true);
    setSelectedProjectType([]);
    setEnvironmentValues([]);
    setIssueTypesValues([]);
    setPriorityValues([]);
    setReleaseFoundValues([]);
    setSeverityValues([]);
    setStatusValues([]);
    setTeamValues([]);
    setSelectedReleaseFoundValues([]);
    setSelectedEnvironmentValues([]);
    setSelectedIssueTypesValues([]);
    setSelectedPriorityValues([]);
    setSelectedSeverityValues([]);
    setSelectedStatusValues([]);
    setSelectedTeamValues([]);

    if (!chartsInfo[projectTypeName]) {
      let result = await getJiraCustomInfo(props.token, projectTypeName);
      if (false) {
        setErrorMessage(result.errMsg);
      } else {
        chartsInfo[projectTypeName] = result;
        transformChartInfo(result);
        setChartsInfo(chartsInfo);
        setJiraItems(
          chartsInfo[projectTypeName]?.issues
            ? chartsInfo[projectTypeName]?.issues
            : []
        );
        setLoading(false);
      }
    }
  };

  const transformChartInfo = (data) => {
    let environments = data.availableEnvironments.filter((env) => env !== null);
    let issueTypes = data.availableIssueTypes.filter(
      (issueType) => issueType !== null
    );
    let priorities = data.availablePriorities.filter(
      (priority) => priority !== null
    );
    let releaseFounds = data.availableReleaseFounds.filter(
      (release) => release !== null
    );
    let severities = data.availableSeverities.filter(
      (severity) => severity !== null
    );
    let statuses = data.availableStatuses.filter((status) => status !== null);
    let programs = data.availableTeams.filter((program) => program !== null);
    let environmentsOptions = environments.map((item) => {
      return {
        label: item,
        value: item,
      };
    });
    let issueTypesOptions = issueTypes.map((item) => {
      return {
        label: item,
        value: item,
      };
    });
    let prioritiesOptions = priorities.map((item) => {
      return {
        label: item,
        value: item,
      };
    });
    let releaseFoundsOptions = releaseFounds.map((item) => {
      return {
        label: item,
        value: item,
      };
    });
    let severitiesOptions = severities.map((item) => {
      return {
        label: item,
        value: item,
      };
    });
    let statusesOptions = statuses.map((item) => {
      return {
        label: item,
        value: item,
      };
    });
    let programsOptions = programs.map((item) => {
      return {
        label: item,
        value: item,
      };
    });

    setEnvironmentValues(environmentsOptions);
    setIssueTypesValues(issueTypesOptions);
    setPriorityValues(prioritiesOptions);
    setReleaseFoundValues(releaseFoundsOptions);
    setSeverityValues(severitiesOptions);
    setStatusValues(statusesOptions);
    setTeamValues(programsOptions);
  };

  return (
    <div id="jira_custom_main_container">
      <div
        id="jira_custom_main_container_1"
        className="bg-light container-fluid pt-3 pb-3"
        style={
          loading
            ? {
                pointerEvents: "none",
                opacity: "0.4",
              }
            : {}
        }
      >
        <h4 className="ms-4">Jira Custom </h4>
        <ErrorMessage errorMessage={errorMessage} />
        <div id="" className="container-fluid ">
          <div className="row ms-1 me-1 border pb-3">
            <div className="col col-sm-2 border-end mt-3">
              <label htmlFor="projectTypes" className="form-label fs-7 ">
                Program
              </label>
              <div id="new" style={{ height: "40px" }} className="me-3">
                <ProjectTypes
                  id="projectTypes"
                  props={localProps}
                  handleChange={handleProjectTypeChange}
                  placeholder="Select..."
                />
              </div>
            </div>
            <div className="col-sm-10">
              <div className="row ms-3">
                <div className="col-sm-3 mt-3">
                  <label htmlFor="releaseFound" className="form-label fs-7 ">
                    Release Found
                  </label>
                  <MultiSelect
                    id="releaseFound"
                    options={releaseFoundValues}
                    placeholder="Select Release Found"
                    onChange={setSelectedReleaseFoundValues}
                    value={selectedReleaseFoundValues}
                  />
                </div>
                <div className="col-sm-3 mt-3">
                  <label htmlFor="issueTypes" className="form-label fs-7 ">
                    Issue Types
                  </label>
                  <MultiSelect
                    id="issueTypes"
                    options={issueTypesValues}
                    placeholder="Select Issue Types"
                    onChange={setSelectedIssueTypesValues}
                    value={selectedIssueTypesValues}
                  />
                </div>
                <div className="col-sm-3 mt-3">
                  <label htmlFor="status" className="form-label fs-7 ">
                    Status
                  </label>
                  <MultiSelect
                    id="status"
                    options={statusValues}
                    placeholder="Select Status"
                    onChange={setSelectedStatusValues}
                    value={selectedStatusValues}
                  />
                </div>
                <div className="col-sm-3 mt-3">
                  <label htmlFor="severity" className="form-label fs-7 ">
                    Severity
                  </label>
                  <MultiSelect
                    id="severity"
                    options={severityValues}
                    placeholder="Select Severity"
                    onChange={setSelectedSeverityValues}
                    value={selectedSeverityValues}
                  />
                </div>
                <div className="col-sm-3 mt-3">
                  <label htmlFor="priority" className="form-label fs-7 ">
                    Priority
                  </label>
                  <MultiSelect
                    id="priority"
                    options={priorityValues}
                    placeholder="Select Priority"
                    onChange={setSelectedPriorityValues}
                    value={selectedPriorityValues}
                  />
                </div>
                <div className="col-sm-3 mt-3">
                  <label htmlFor="team" className="form-label fs-7 ">
                    Team
                  </label>
                  <MultiSelect
                    id="team"
                    options={teamValues}
                    placeholder="Select Program"
                    onChange={setSelectedTeamValues}
                    value={selectedTeamValues}
                  />
                </div>
                <div className="col-sm-3 mt-3">
                  <label htmlFor="environment" className="form-label fs-7 ">
                    Environment
                  </label>
                  <MultiSelect
                    id="environment"
                    options={environmentValues}
                    placeholder="Select Environment"
                    onChange={setSelectedEnvironmentValues}
                    value={selectedEnvironmentValues}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row ms-3 me-3 mb-4 mt-3 justify-content-around">
            {loading ? (
              <Loading />
            ) : (
              <div id="jira-custom-chart" className="col">
                <div id="jira-custom-chart-root">
                  <JiraCustomChart
                    selectedReleaseFoundValues={selectedReleaseFoundValues}
                    selectedIssueTypesValues={selectedIssueTypesValues}
                    selectedStatusValues={selectedStatusValues}
                    selectedSeverityValues={selectedSeverityValues}
                    selectedPriorityValues={selectedPriorityValues}
                    selectedTeamValues={selectedTeamValues}
                    selectedEnvironmentValues={selectedEnvironmentValues}
                    jiraItems={jiraItems}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
