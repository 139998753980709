import FetchUtils from "./FetchUtils";
class Insights {
  static async getServiceInsightsSelectorInfo(token) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const data = await FetchUtils.fetch(
        process.env.REACT_APP_SERVICE_INSIGHTS_URL +
          "/insights/service-selector",
        "GET",
        headers
      );

      if (!data || data.status !== "OK") {
        let errorMsg =
          "Service Insights Info cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { message: errorMsg };
      }
      return data.data;
    } catch (err) {
      return {
        message:
          "Error getting Service Insights Info (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getServiceInsightsCardInfo(token, payload) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const body = JSON.stringify(payload);
      const data = await FetchUtils.fetch(
        process.env.REACT_APP_SERVICE_INSIGHTS_URL + "/insights/service-card",
        "POST",
        headers,
        body
      );

      if (!data || data.status !== "OK") {
        let errorMsg =
          "Service Insights Info cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { message: errorMsg };
      }
      return data.data;
    } catch (err) {
      return {
        message:
          "Error getting Service Insights Info (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getServiceInsightsDetailsInfo(token, ids) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const data = await FetchUtils.fetch(
        process.env.REACT_APP_SERVICE_INSIGHTS_URL +
          `/insights/service-detail-card?ids=${ids}`,
        "GET",
        headers
      );

      if (!data || data.status !== "OK") {
        let errorMsg =
          "Service Insights Info cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { message: errorMsg };
      }
      return data.data;
    } catch (err) {
      return {
        message:
          "Error getting Service Insights Info (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }
}

export default Insights;
