import React, { useState, useEffect } from "react";
import Jira from "../../../services/Jira";
import Qtest from "../../../services/Qtest";
import ErrorMessage from "../../common/ErrorMessage";
import useProjectDetails from "../../hooks/useProjectDetails";
import "../../css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";

async function getAllInfoFromServer(token, projectId) {
  let promises = [];
  try {
    return Promise.all([
      getProcessComplianceRequirementsInfo(token, projectId),
      getProcessComplianceTestSuitesInfo(token, projectId),
      getProcessComplianceTestRunsDataInfo(token, projectId),
      getProcessComplianceTestCasesInfo(token, projectId),
      getProcessComplianceTestIssuesInfo(token, projectId),
    ]);
  } catch (err) {
    return { message: "ProjectComplaince Promise call error: " + err.message };
  }
}

async function getProcessComplianceRequirementsInfo(token, projectId) {
  try {
    const resp = await Qtest.getProcessComplianceRequirementsInfo(
      token,
      projectId
    );
    return resp;
  } catch (err) {
    return {
      message:
        "Error connecting to API for Process Compliance Requirements Info",
    };
  }
}

async function getProcessComplianceTestRunsDataInfo(token, projectId) {
  try {
    const resp = await Qtest.getProcessComplianceTestRunsDataInfo(
      token,
      projectId
    );
    return resp;
  } catch (err) {
    return {
      message:
        "Error connecting to API for Process Compliance TestRuns Data Info",
    };
  }
}

async function getProcessComplianceTestSuitesInfo(token, projectId) {
  try {
    const resp = await Qtest.getProcessComplianceTestSuitesInfo(
      token,
      projectId
    );
    return resp;
  } catch (err) {
    return {
      message:
        "Error connecting to API for Process Compliance Test Suites Info",
    };
  }
}

async function getProcessComplianceTestIssuesInfo(token, projectId) {
  try {
    const resp = await Jira.getProcessComplianceTestIssuesInfo(
      token,
      projectId
    );
    return resp;
  } catch (err) {
    return {
      message:
        "Error connecting to API for Process Compliance Test Issues Info",
    };
  }
}

async function getProcessComplianceTestCasesInfo(token, projectId) {
  try {
    const resp = await Qtest.getProcessComplianceTestCasesInfo(
      token,
      projectId
    );
    return resp;
  } catch (err) {
    return {
      message: "Error connecting to API for Process Compliance Test Cases Info",
    };
  }
}

var prevItemIndex = 0;

const procComplMenuMap = [
  {
    name: "Test Cycles/Suites",
    state: "",
    toShow: false,
  },
  {
    name: "Test Cases Not Run",
    state: "",
    toShow: false,
  },
  {
    name: "Test Cases in Progress",
    state: "",
    toShow: false,
  },
  {
    name: "Test Cases not in Ready State",
    state: "",
    toShow: false,
  },
  {
    name: "Test Cases with Empty Fields",
    state: "",
    toShow: false,
  },
  {
    name: "Open Issues",
    state: "",
    toShow: false,
  },
  {
    name: "Failed & Blocked Test Cases with No Defects (Or blank Actual Result for Jama)",
    state: "",
    toShow: false,
  },
  {
    name: 'Test Cases without the Word "Verify" in Name',
    state: "",
    toShow: false,
  },
  {
    name: "Test Cases without Related Requirements",
    state: "",
    toShow: false,
  },
  {
    name: "Not Targeted Issues",
    state: "",
    toShow: false,
  },
  {
    name: "Test Runs with No Build Number",
    state: "",
    toShow: false,
  },
  {
    name: "Test Plans (Releases) with no Requirements",
    state: "",
    toShow: false,
  },
];

async function setProcComplMenuItem(itemIndex) {
  procComplMenuMap[prevItemIndex].state = "";
  procComplMenuMap[prevItemIndex].toShow = false;
  procComplMenuMap[itemIndex].state = "active";
  procComplMenuMap[itemIndex].toShow = true;
  prevItemIndex = itemIndex;
}

export default function ProcessComplianceTable(props) {
  const [procComplMenu, setProcComplMenu] = useState(procComplMenuMap);
  const { projectDetails } = useProjectDetails();

  const [
    processComplianceRequirementsInfo,
    setProcessComplianceRequirementsInfo,
  ] = useState();
  const [
    processComplianceTestRunsDataInfo,
    setProcessComplianceTestRunsDataInfo,
  ] = useState();
  const [processComplianceTestSuitesInfo, setProcessComplianceTestSuitesInfo] =
    useState();
  const [processComplianceTestIssuesInfo, setProcessComplianceTestIssuesInfo] =
    useState();
  const [processComplianceTestCasesInfo, setProcessComplianceTestCasesInfo] =
    useState();

  const [errorMessage, setErrorMessage] = useState("");
  const [loadingRequirementsInfo, setLoadingRequirementsInfo] = useState(true);
  const [loadingTestSuitesInfo, setLoadingTestSuitesInfo] = useState(true);
  const [loadingTestIssuesInfo, setLoadingTestIssuesInfo] = useState(true);
  const [loadingTestCasesInfo, setLoadingTestCasesInfo] = useState(true);
  const [loadingTestRunsData, setLoadingTestRunsData] = useState(true);

  const [showreleaseReqsTable, setShowreleaseReqsTable] = useState(false);
  const [showtestCyclesTable, setShowtestCyclesTable] = useState(false);
  const [showtestCasesNotRunTable, setShowtestCasesNotRunTable] =
    useState(false);
  const [showtestRunsNoBuildNumberTable, setShowtestRunsNoBuildNumberTable] =
    useState(false);
  const [showtestCasesInProgressTable, setShowtestCasesInProgressTable] =
    useState(false);
  const [
    showtestCasesFailedWithoutActual,
    setShowtestCasesFailedWithoutActual,
  ] = useState(false);
  const [shownotReadyTestCases, setShownotReadyTestCases] = useState(false);
  const [showtestCasesNotCorrectName, setShowtestCasesNotCorrectName] =
    useState(false);
  const [showtestCasesWithoutReq, setShowtestCasesWithoutReq] = useState(false);
  const [showtestCyclesTableIssues, setShowtestCyclesTableIssues] =
    useState(false);
  const [shownotTargetIssuesTable, setShownotTargetIssuesTable] =
    useState(false);
  const [showtestmsgInfo1, setShowtestmsgInfo1] = useState(false);
  const [showtestmsgInfo2, setShowtestmsgInfo2] = useState(false);
  const [showtestmsgInfo3, setShowtestmsgInfo3] = useState(false);
  const [showtestmsgInfo4, setShowtestmsgInfo4] = useState(false);
  const [showtestmsgInfo5, setShowtestmsgInfo5] = useState(false);
  const [modalData, setModalData] = useState({
    title: "",
    projectId: null,
    createdDt: null,
    releaseName: null,
    status: null,
    severity: null,
  });

  useEffect(() => {
    const handleProcessComplianceDetails = async (e) => {
      var currentProjectId = props.currentProjectId;
      var url = window.location.href;
      let res = /project\/(\d+)/g.exec(url);

      if (!currentProjectId) {
        if (res?.length > 1) {
          currentProjectId = res[1];
          props.setCurrentProjectId(currentProjectId);
        } else {
          setErrorMessage("Project is not selected!");
          return;
        }
      }
      if (
        (processComplianceRequirementsInfo === null ||
          processComplianceRequirementsInfo?.data?.projectId !==
            currentProjectId) &&
        (processComplianceTestSuitesInfo === null ||
          processComplianceTestSuitesInfo?.data?.projectId !==
            currentProjectId) &&
        (processComplianceTestRunsDataInfo === null ||
          processComplianceTestRunsDataInfo?.data?.projectId !==
            currentProjectId) &&
        (processComplianceTestCasesInfo === null ||
          processComplianceTestCasesInfo?.data?.projectId !==
            currentProjectId) &&
        (processComplianceTestIssuesInfo === null ||
          processComplianceTestIssuesInfo?.projectId !== currentProjectId)
      ) {
        setLoadingRequirementsInfo(true);
        setLoadingTestSuitesInfo(true);
        setLoadingTestIssuesInfo(true);
        setLoadingTestCasesInfo(true);
        setLoadingTestRunsData(true);
        const responses = await getAllInfoFromServer(
          props.token,
          currentProjectId
        );
        if (responses !== null) {
          setResponses(responses);
        }
      }
    };
    setLoadingRequirementsInfo(false);
    setLoadingTestSuitesInfo(false);
    setLoadingTestIssuesInfo(false);
    setLoadingTestCasesInfo(false);
    setLoadingTestRunsData(false);
    handleProcessComplianceDetails();
  }, [props.currentProjectId]);

  function setResponses(responses) {
    if (!responses[0]) {
      setErrorMessage(
        "Process Compliance Requirements response does not contain any information!"
      );
      setProcessComplianceRequirementsInfo(null);
      setLoadingRequirementsInfo(false);
    } else {
      setErrorMessage("");
      setProcessComplianceRequirementsInfo(responses[0]);
      setLoadingRequirementsInfo(false);
    }
    if (!responses[1]) {
      setErrorMessage(
        "Process Compliance Test Suites response does not contain any information!"
      );
      setProcessComplianceTestSuitesInfo(null);
      setLoadingTestSuitesInfo(false);
    } else {
      setErrorMessage("");
      setProcessComplianceTestSuitesInfo(responses[1]);
      setLoadingTestSuitesInfo(false);
    }
    if (!responses[2]) {
      setErrorMessage(
        "Process Compliance Test Runs response does not contain any information!"
      );
      setProcessComplianceTestRunsDataInfo(null);
      setLoadingTestRunsData(false);
    } else {
      setErrorMessage("");
      setProcessComplianceTestRunsDataInfo(responses[2]);
      setLoadingTestRunsData(false);
    }
    if (!responses[3]) {
      setErrorMessage(
        "Process Compliance Test Cases response does not contain any information!"
      );
      setProcessComplianceTestCasesInfo(null);
      setLoadingTestCasesInfo(false);
    } else {
      setErrorMessage("");
      setProcessComplianceTestCasesInfo(responses[3]);
      setLoadingTestCasesInfo(false);
    }
    if (!responses[4]) {
      setErrorMessage(
        "Process Compliance Test Issues response does not contain any information!"
      );
      setProcessComplianceTestIssuesInfo(null);
      setLoadingTestIssuesInfo(false);
    } else {
      setErrorMessage("");
      setProcessComplianceTestIssuesInfo(responses[4]);
      setLoadingTestIssuesInfo(false);
    }
  }

  function showTable(index) {
    setShowtestmsgInfo1(false);
    setShowtestmsgInfo2(false);
    setShowtestmsgInfo3(false);
    setShowtestmsgInfo4(false);
    setShowtestmsgInfo5(false);

    setShowreleaseReqsTable(false);
    setShowtestCyclesTable(false);
    setShowtestCasesNotRunTable(false);
    setShowtestRunsNoBuildNumberTable(false);
    setShowtestCasesInProgressTable(false);
    setShowtestCasesFailedWithoutActual(false);
    setShownotReadyTestCases(false);
    setShowtestCasesNotCorrectName(false);
    setShowtestCasesWithoutReq(false);
    setShowtestCyclesTableIssues(false);
    setShownotTargetIssuesTable(false);

    setProcComplMenuItem(index);
    setProcComplMenu(procComplMenuMap);

    if (
      !procComplMenu[0].toShow &&
      !procComplMenu[5].toShow &&
      !procComplMenu[1].toShow &&
      !procComplMenu[9].toShow &&
      !procComplMenu[10].toShow &&
      !procComplMenu[11].toShow
    ) {
      setShowtestmsgInfo1(true);
    } else if (procComplMenu[1].toShow && !procComplMenu[9].toShow) {
      setShowtestmsgInfo2(true);
    } else if (procComplMenu[10].toShow) {
      setShowtestmsgInfo3(true);
    } else if (!procComplMenu[0].toShow && !procComplMenu[5].toShow) {
      if (!procComplMenu[11].toShow && !procComplMenu[9].toShow) {
        setShowtestmsgInfo4(true);
      }
    } else if (
      procComplMenu[5].toShow &&
      processComplianceTestIssuesInfo?.openIssuesCount <= 0
    ) {
      setShowtestmsgInfo5(true);
    }

    if (index === 11) {
      setShowreleaseReqsTable(procComplMenu[index].toShow);
    } else if (index === 0) {
      setShowtestCyclesTable(procComplMenu[index].toShow);
    } else if (index === 1) {
      setShowtestCasesNotRunTable(procComplMenu[index].toShow);
    } else if (index === 10) {
      setShowtestRunsNoBuildNumberTable(procComplMenu[index].toShow);
    } else if (index === 2) {
      setShowtestCasesInProgressTable(procComplMenu[index].toShow);
    } else if (index === 6) {
      setShowtestCasesFailedWithoutActual(procComplMenu[index].toShow);
    } else if (index === 3) {
      setShownotReadyTestCases(procComplMenu[index].toShow);
    } else if (index === 7) {
      setShowtestCasesNotCorrectName(procComplMenu[index].toShow);
    } else if (index === 8) {
      setShowtestCasesWithoutReq(procComplMenu[index].toShow);
    } else if (index === 5) {
      setShowtestCyclesTableIssues(procComplMenu[index].toShow);
    } else if (index === 9) {
      setShownotTargetIssuesTable(procComplMenu[index].toShow);
    }
  }

  function ReleaseWithoutReq() {
    try {
      if (
        processComplianceRequirementsInfo?.data?.releasesRequirements &&
        Object.keys(
          processComplianceRequirementsInfo?.data?.releasesRequirements
        ).length > 0
      ) {
        let reqInfo =
          processComplianceRequirementsInfo.data.releasesRequirements[0];
        if (reqInfo) {
          return Object.keys(reqInfo).map((key) => {
            return (
              <tr style={{ fontSize: 10 }}>
                <td>{key}</td>
                <td>
                  {reqInfo[key].map((dataItem) => {
                    return <tr>{dataItem}</tr>;
                  })}
                </td>
              </tr>
            );
          });
        } else {
          return <div></div>;
        }
      } else {
        return <div></div>;
      }
    } catch (err) {
      return null;
    }
  }

  function TestSuitesData() {
    try {
      if (
        processComplianceTestSuitesInfo?.data?.testSuitesData &&
        Object.keys(processComplianceTestSuitesInfo?.data?.testSuitesData)
          .length > 0
      ) {
        let reqInfo = Object.entries(
          processComplianceTestSuitesInfo.data.testSuitesData
        ).filter((k) => k.indexOf("testSuitesData") > -1);
        if (reqInfo && Object.keys(reqInfo).length > 0) {
          return reqInfo[0][1].map((dataItem) => {
            return (
              <tr style={{ fontSize: 10 }}>
                <td>{dataItem.testSuiteID}</td>
                <td>{dataItem.testSuite}</td>
                <td>{dataItem.comments === null ? "" : dataItem.comments}</td>
              </tr>
            );
          });
        } else {
          return <div></div>;
        }
      } else {
        return <div></div>;
      }
    } catch (err) {
      return null;
    }
  }

  function TestRunsData(testRunType) {
    try {
      if (
        processComplianceTestRunsDataInfo?.data?.testRunsWithStatus &&
        Object.keys(processComplianceTestRunsDataInfo?.data?.testRunsWithStatus)
          .length > 0
      ) {
        let reqInfo = Object.entries(
          processComplianceTestRunsDataInfo.data.testRunsWithStatus
        ).filter((k) => k.indexOf(Object.values(testRunType)[0]) > -1);
        if (reqInfo && Object.keys(reqInfo).length > 0) {
          return reqInfo[0][1].map((dataItem) => {
            return (
              <tr style={{ fontSize: 11 }}>
                <td>
                  <a
                    href={dataItem.testCaseLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {dataItem.testCaseID}
                  </a>
                </td>
                <td>{dataItem.testCaseName}</td>
                <td>
                  <a
                    href={dataItem.testRunLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {dataItem.testRunID}
                  </a>
                </td>
                <td>{dataItem.testSuite}</td>
                <td>{dataItem.assignedTo}</td>
              </tr>
            );
          });
        } else {
          return <div></div>;
        }
      } else {
        return <div></div>;
      }
    } catch (err) {
      return null;
    }
  }

  function TestCasesData(testRunType) {
    try {
      if (
        processComplianceTestCasesInfo?.data?.testCasesCommonData &&
        Object.keys(processComplianceTestCasesInfo?.data?.testCasesCommonData)
          .length > 0
      ) {
        let reqInfo = Object.entries(
          processComplianceTestCasesInfo.data.testCasesCommonData
        ).filter((k) => k.indexOf(Object.values(testRunType)[0]) > -1);

        if (reqInfo && Object.keys(reqInfo).length > 0) {
          if (reqInfo[0][0] === "testCaseWithNotReadyState") {
            return reqInfo[0][1].map((dataItem) => {
              return (
                <tr style={{ fontSize: 11 }}>
                  <td>
                    <a
                      href={dataItem.testCaseLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {dataItem.testCaseID}
                    </a>
                  </td>
                  <td>{dataItem.testCaseName}</td>
                  <td>{dataItem.testCaseState}</td>
                  <td>{dataItem.testSuite}</td>
                </tr>
              );
            });
          } else {
            return reqInfo[0][1].map((dataItem) => {
              return (
                <tr style={{ fontSize: 11 }}>
                  <td>
                    <a
                      href={dataItem.testCaseLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {dataItem.testCaseID}
                    </a>
                  </td>
                  <td>{dataItem.testCaseName}</td>
                  <td>{dataItem.testSuite}</td>
                </tr>
              );
            });
          }
        } else {
          return <div></div>;
        }
      } else {
        return <div></div>;
      }
    } catch (err) {
      return null;
    }
  }

  function TestOpenIssuesData() {
    try {
      if (
        processComplianceTestIssuesInfo?.issuesCommonData &&
        Object.keys(processComplianceTestIssuesInfo?.issuesCommonData).length >
          0
      ) {
        let reqInfo = Object.entries(
          processComplianceTestIssuesInfo.issuesCommonData
        ).filter((k) => k.indexOf("openIssuesData") > -1);
        if (reqInfo && Object.keys(reqInfo).length > 0) {
          return reqInfo[0][1].map((dataItem) => {
            return (
              <tr style={{ fontSize: 10 }}>
                <td className="col-sm-2">
                  <a
                    href={dataItem.defectLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {dataItem.defectID}
                  </a>
                </td>
                <td className="col-sm-5">{dataItem.defectName}</td>
                <td className="col-sm-5">{dataItem.defectStatus}</td>
              </tr>
            );
          });
        } else {
          return <div></div>;
        }
      } else {
        return <div></div>;
      }
    } catch (err) {
      return null;
    }
  }

  function NotTargetedIssuesData() {
    try {
      if (
        processComplianceTestIssuesInfo?.issuesCommonData &&
        Object.keys(processComplianceTestIssuesInfo?.issuesCommonData).length >
          0
      ) {
        let reqInfo = Object.entries(
          processComplianceTestIssuesInfo.issuesCommonData
        ).filter((k) => k.indexOf("notTargetedIssuesData") > -1);
        if (reqInfo && Object.keys(reqInfo).length > 0) {
          return reqInfo[0][1].map((dataItem) => {
            return (
              <tr style={{ fontSize: 10 }}>
                <td className="col-sm-2">
                  <a
                    href={dataItem.defectLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {dataItem.defectID}
                  </a>
                </td>
                <td className="col-sm-5">{dataItem.defectName}</td>
                <td className="col-sm-5">{dataItem.defectSeverity}</td>
                <td className="col-sm-5">{dataItem.defectStatus}</td>
                <td className="col-sm-5">{dataItem.defectResolution}</td>
                <td className="col-sm-5">{dataItem.targetRelease}</td>
              </tr>
            );
          });
        } else {
          return <div></div>;
        }
      } else {
        return <div></div>;
      }
    } catch (err) {
      return null;
    }
  }

  return (
    <div className="col-xs-12 col-sm-12">
      <ErrorMessage errorMessage={errorMessage} />
      <div className="row">
        <div className="col-xs-12 col-sm-12">
          <table
            id="requirementsMappingTable"
            className="table table-bordered table-condensed table-responsive table-hover table-sm"
            style={{ fontSize: 14 }}
          >
            <thead className="table-primary small">
              <tr
                style={{
                  backgroundColor: "rgb(233, 236, 239)",
                  color: "rgb(33, -37, 41)",
                }}
              >
                <th>Item</th>
                <th></th>
              </tr>
            </thead>
            <tbody id="milestones" className="table-primary small">
              <tr
                className={
                  projectDetails?.projectMilestones &&
                  Object.values(projectDetails?.projectMilestones).length > 0
                    ? "table-success"
                    : "table-danger"
                }
              >
                <td>Are Milestones defined in Launch Pad?</td>
                <td>
                  {projectDetails?.projectMilestones &&
                  Object.values(projectDetails?.projectMilestones).length > 0
                    ? "Yes"
                    : "No"}
                </td>
              </tr>
            </tbody>
            <tbody id="releases" className="table-primary small">
              <tr
                className={
                  showreleaseReqsTable
                    ? "table-active"
                    : processComplianceRequirementsInfo?.data
                        ?.releasesWithoutRequirementsCount > 0
                    ? "table-danger"
                    : "table-success"
                }
                onClick={() => showTable(11)}
              >
                <td>{procComplMenuMap[11].name}</td>
                <td
                  style={
                    loadingRequirementsInfo
                      ? { pointerEvents: "none", opacity: "0.4" }
                      : {}
                  }
                >
                  {loadingRequirementsInfo && (
                    <div className="d-flex justify-content-center bg-transparent align-items-center">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  )}
                  <a style={{ color: "blue", cursor: "pointer" }}>
                    {processComplianceRequirementsInfo?.data
                      ?.releasesWithoutRequirementsCount === undefined
                      ? "0"
                      : processComplianceRequirementsInfo?.data
                          ?.releasesWithoutRequirementsCount === null
                      ? "0"
                      : processComplianceRequirementsInfo?.data
                          ?.releasesWithoutRequirementsCount}
                  </a>
                </td>
              </tr>
            </tbody>
            <tbody id="testcycles" className="table-primary small">
              <tr
                className={
                  showtestCyclesTable
                    ? "table-active"
                    : processComplianceTestSuitesInfo?.data
                        ?.testCyclesAndSuitesCount === undefined
                    ? "table-success"
                    : processComplianceTestSuitesInfo?.data
                        ?.testCyclesAndSuitesCount === null
                    ? "table-success"
                    : processComplianceTestSuitesInfo?.data
                        ?.testCyclesAndSuitesCount === 0
                    ? "table-success"
                    : "table-danger"
                }
                onClick={() => showTable(0)}
              >
                <td>{procComplMenuMap[0].name}</td>
                <td
                  style={
                    loadingTestSuitesInfo
                      ? { pointerEvents: "none", opacity: "0.4" }
                      : {}
                  }
                >
                  {loadingTestSuitesInfo && (
                    <div className="d-flex justify-content-center bg-transparent align-items-center">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  )}
                  <a style={{ color: "blue", cursor: "pointer" }}>
                    {processComplianceTestSuitesInfo?.data
                      ?.testCyclesAndSuitesCount === undefined
                      ? "0"
                      : processComplianceTestSuitesInfo?.data
                          ?.testCyclesAndSuitesCount === null
                      ? "0"
                      : processComplianceTestSuitesInfo?.data
                          ?.testCyclesAndSuitesCount}
                  </a>
                </td>
              </tr>
            </tbody>
            <tbody id="testruns" className="table-primary small">
              <tr
                className={
                  showtestCasesNotRunTable
                    ? "table-active"
                    : processComplianceTestRunsDataInfo?.data
                        ?.testRunsUnexecutedCount === undefined
                    ? "table-success"
                    : processComplianceTestRunsDataInfo?.data
                        ?.testRunsUnexecutedCount === null
                    ? "table-success"
                    : processComplianceTestRunsDataInfo?.data
                        ?.testRunsUnexecutedCount === 0
                    ? "table-success"
                    : "table-danger"
                }
                onClick={() => showTable(1)}
              >
                <td>Unexecuted Test Runs</td>
                <td
                  style={
                    loadingTestRunsData
                      ? { pointerEvents: "none", opacity: "0.4" }
                      : {}
                  }
                >
                  {loadingTestRunsData && (
                    <div className="d-flex justify-content-center bg-transparent align-items-center">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  )}
                  <a style={{ color: "blue", cursor: "pointer" }}>
                    {processComplianceTestRunsDataInfo?.data
                      ?.testRunsUnexecutedCount === undefined
                      ? "0"
                      : processComplianceTestRunsDataInfo?.data
                          ?.testRunsUnexecutedCount === null
                      ? "0"
                      : processComplianceTestRunsDataInfo?.data
                          ?.testRunsUnexecutedCount}
                  </a>
                </td>
              </tr>
              <tr
                className={
                  showtestCasesInProgressTable
                    ? "table-active"
                    : processComplianceTestRunsDataInfo?.data
                        ?.testRunsInprogressCount === undefined
                    ? "table-success"
                    : processComplianceTestRunsDataInfo?.data
                        ?.testRunsInprogressCount === null
                    ? "table-success"
                    : processComplianceTestRunsDataInfo?.data
                        ?.testRunsInprogressCount === 0
                    ? "table-success"
                    : "table-danger"
                }
                onClick={() => showTable(2)}
              >
                <td>In Progress Test Runs</td>
                <td
                  style={
                    loadingTestRunsData
                      ? { pointerEvents: "none", opacity: "0.4" }
                      : {}
                  }
                >
                  {loadingTestRunsData && (
                    <div className="d-flex justify-content-center bg-transparent align-items-center">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  )}
                  <a style={{ color: "blue", cursor: "pointer" }}>
                    {processComplianceTestRunsDataInfo?.data
                      ?.testRunsInprogressCount === undefined
                      ? "0"
                      : processComplianceTestRunsDataInfo?.data
                          ?.testRunsInprogressCount === null
                      ? "0"
                      : processComplianceTestRunsDataInfo?.data
                          ?.testRunsInprogressCount}
                  </a>
                </td>
              </tr>
              <tr
                className={
                  showtestCasesFailedWithoutActual
                    ? "table-active"
                    : processComplianceTestRunsDataInfo?.data
                        ?.testRunsFailedBlockedCount === undefined
                    ? "table-success"
                    : processComplianceTestRunsDataInfo?.data
                        ?.testRunsFailedBlockedCount === null
                    ? "table-success"
                    : processComplianceTestRunsDataInfo?.data
                        ?.testRunsFailedBlockedCount === 0
                    ? "table-success"
                    : "table-danger"
                }
                onClick={() => showTable(6)}
              >
                <td>Failed & Blocked Test Runs with No Defects</td>
                <td
                  style={
                    loadingTestRunsData
                      ? { pointerEvents: "none", opacity: "0.4" }
                      : {}
                  }
                >
                  {loadingTestRunsData && (
                    <div className="d-flex justify-content-center bg-transparent align-items-center">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  )}
                  <a style={{ color: "blue", cursor: "pointer" }}>
                    {processComplianceTestRunsDataInfo?.data
                      ?.testRunsFailedBlockedCount === undefined
                      ? "0"
                      : processComplianceTestRunsDataInfo?.data
                          ?.testRunsFailedBlockedCount === null
                      ? "0"
                      : processComplianceTestRunsDataInfo?.data
                          ?.testRunsFailedBlockedCount}
                  </a>
                </td>
              </tr>
              <tr
                className={
                  showtestRunsNoBuildNumberTable
                    ? "table-active"
                    : processComplianceTestRunsDataInfo?.data
                        ?.testRunsWithNoBuildNoCount === undefined
                    ? "table-success"
                    : processComplianceTestRunsDataInfo?.data
                        ?.testRunsWithNoBuildNoCount === null
                    ? "table-success"
                    : processComplianceTestRunsDataInfo?.data
                        ?.testRunsWithNoBuildNoCount === 0
                    ? "table-success"
                    : "table-danger"
                }
                onClick={() => showTable(10)}
              >
                <td>Test Runs with No Build Number</td>
                <td
                  style={
                    loadingTestRunsData
                      ? { pointerEvents: "none", opacity: "0.4" }
                      : {}
                  }
                >
                  {loadingTestRunsData && (
                    <div className="d-flex justify-content-center bg-transparent align-items-center">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  )}
                  <a style={{ color: "blue", cursor: "pointer" }}>
                    {processComplianceTestRunsDataInfo?.data
                      ?.testRunsWithNoBuildNoCount === undefined
                      ? "0"
                      : processComplianceTestRunsDataInfo?.data
                          ?.testRunsWithNoBuildNoCount === null
                      ? "0"
                      : processComplianceTestRunsDataInfo?.data
                          ?.testRunsWithNoBuildNoCount}
                  </a>
                </td>
              </tr>
            </tbody>
            <tbody id="testcases" className="table-primary small">
              <tr
                className={
                  shownotReadyTestCases
                    ? "table-active"
                    : processComplianceTestCasesInfo?.data
                        ?.testCasesNotReadyStateCount === undefined
                    ? "table-success"
                    : processComplianceTestCasesInfo?.data
                        ?.testCasesNotReadyStateCount === null
                    ? "table-success"
                    : processComplianceTestCasesInfo?.data
                        ?.testCasesNotReadyStateCount === 0
                    ? "table-success"
                    : "table-danger"
                }
                onClick={() => showTable(3)}
              >
                <td>Test Cases not in Ready State</td>
                <td
                  style={
                    loadingTestCasesInfo
                      ? { pointerEvents: "none", opacity: "0.4" }
                      : {}
                  }
                >
                  {loadingTestCasesInfo && (
                    <div className="d-flex justify-content-center bg-transparent align-items-center">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  )}
                  <a style={{ color: "blue", cursor: "pointer" }}>
                    {processComplianceTestCasesInfo?.data
                      ?.testCasesNotReadyStateCount === undefined
                      ? "0"
                      : processComplianceTestCasesInfo?.data
                          ?.testCasesNotReadyStateCount === null
                      ? "0"
                      : processComplianceTestCasesInfo?.data
                          ?.testCasesNotReadyStateCount}
                  </a>
                </td>
              </tr>
              <tr
                className={
                  showtestCasesNotCorrectName
                    ? "table-active"
                    : processComplianceTestCasesInfo?.data
                        ?.testCasesWithOutVerifyWordCount === undefined
                    ? "table-success"
                    : processComplianceTestCasesInfo?.data
                        ?.testCasesWithOutVerifyWordCount === null
                    ? "table-success"
                    : processComplianceTestCasesInfo?.data
                        ?.testCasesWithOutVerifyWordCount === 0
                    ? "table-success"
                    : "table-danger"
                }
                onClick={() => showTable(7)}
              >
                <td>{procComplMenuMap[7].name}</td>
                <td
                  style={
                    loadingTestCasesInfo
                      ? { pointerEvents: "none", opacity: "0.4" }
                      : {}
                  }
                >
                  {loadingTestCasesInfo && (
                    <div className="d-flex justify-content-center bg-transparent align-items-center">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  )}
                  <a style={{ color: "blue", cursor: "pointer" }}>
                    {processComplianceTestCasesInfo?.data
                      ?.testCasesWithOutVerifyWordCount === undefined
                      ? "0"
                      : processComplianceTestCasesInfo?.data
                          ?.testCasesWithOutVerifyWordCount === null
                      ? "0"
                      : processComplianceTestCasesInfo?.data
                          ?.testCasesWithOutVerifyWordCount}
                  </a>
                </td>
              </tr>
              <tr
                className={
                  showtestCasesWithoutReq
                    ? "table-active"
                    : processComplianceTestCasesInfo?.data
                        ?.testCasesWithOutRelatedRequirementsCount === undefined
                    ? "table-success"
                    : processComplianceTestCasesInfo?.data
                        ?.testCasesWithOutRelatedRequirementsCount === null
                    ? "table-success"
                    : processComplianceTestCasesInfo?.data
                        ?.testCasesWithOutRelatedRequirementsCount === 0
                    ? "table-success"
                    : "table-danger"
                }
                onClick={() => showTable(8)}
              >
                <td>{procComplMenuMap[8].name}</td>
                <td
                  style={
                    loadingTestCasesInfo
                      ? { pointerEvents: "none", opacity: "0.4" }
                      : {}
                  }
                >
                  {loadingTestCasesInfo && (
                    <div className="d-flex justify-content-center bg-transparent align-items-center">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  )}
                  <a style={{ color: "blue", cursor: "pointer" }}>
                    {processComplianceTestCasesInfo?.data
                      ?.testCasesWithOutRelatedRequirementsCount === undefined
                      ? "0"
                      : processComplianceTestCasesInfo?.data
                          ?.testCasesWithOutRelatedRequirementsCount === null
                      ? "0"
                      : processComplianceTestCasesInfo?.data
                          ?.testCasesWithOutRelatedRequirementsCount}
                  </a>
                </td>
              </tr>
            </tbody>
            <tbody id="issues" className="table-primary small">
              <tr
                className={
                  shownotTargetIssuesTable
                    ? "table-active"
                    : processComplianceTestIssuesInfo?.notTargetedIssuesCount ===
                      undefined
                    ? "table-success"
                    : processComplianceTestIssuesInfo?.notTargetedIssuesCount ===
                      null
                    ? "table-success"
                    : processComplianceTestIssuesInfo?.notTargetedIssuesCount ===
                      0
                    ? "table-success"
                    : "table-danger"
                }
                onClick={() => showTable(9)}
              >
                <td>{procComplMenuMap[9].name}</td>
                <td
                  style={
                    loadingTestIssuesInfo
                      ? { pointerEvents: "none", opacity: "0.4" }
                      : {}
                  }
                >
                  {loadingTestIssuesInfo && (
                    <div className="d-flex justify-content-center bg-transparent align-items-center">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  )}
                  <a style={{ color: "blue", cursor: "pointer" }}>
                    {processComplianceTestIssuesInfo?.notTargetedIssuesCount ===
                    undefined
                      ? "0"
                      : processComplianceTestIssuesInfo?.notTargetedIssuesCount ===
                        null
                      ? "0"
                      : processComplianceTestIssuesInfo?.notTargetedIssuesCount}
                  </a>
                </td>
              </tr>
              <tr
                className={
                  showtestCyclesTableIssues
                    ? "table-active"
                    : processComplianceTestIssuesInfo?.openIssuesCount ===
                      undefined
                    ? "table-success"
                    : processComplianceTestIssuesInfo?.openIssuesCount === null
                    ? "table-success"
                    : processComplianceTestIssuesInfo?.openIssuesCount === 0
                    ? "table-success"
                    : "table-danger"
                }
                onClick={() => showTable(5)}
              >
                <td>Open Issues</td>
                <td
                  style={
                    loadingTestIssuesInfo
                      ? { pointerEvents: "none", opacity: "0.4" }
                      : {}
                  }
                >
                  {loadingTestIssuesInfo && (
                    <div className="d-flex justify-content-center bg-transparent align-items-center">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  )}
                  <a style={{ color: "blue", cursor: "pointer" }}>
                    {processComplianceTestIssuesInfo?.openIssuesCount ===
                    undefined
                      ? "0"
                      : processComplianceTestIssuesInfo?.openIssuesCount ===
                        null
                      ? "0"
                      : processComplianceTestIssuesInfo?.openIssuesCount}
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="row">
        {showtestmsgInfo1 && (
          <div>
            Note: only information for past and current iterations/sprints will
            be shown.
          </div>
        )}
        {showtestmsgInfo2 && (
          <div>
            Note: only information for past iterations/sprints will be shown.
          </div>
        )}
        {showtestmsgInfo3 && (
          <div>
            Note: This includes all information from all test cycles (including
            Smoke test cycles).
          </div>
        )}
        {showtestmsgInfo4 && (
          <div>
            Iteration/Sprint "iteration.name" does not have start date,
            therefore test cases included in following test cycles will not be
            included in the result statistics.
          </div>
        )}
        {showtestmsgInfo5 && (
          <div style={{ foreColor: "red" }}>
            Open issues list will be displayed only for inactive projects.
          </div>
        )}
      </div>
      <div className="row" style={{ fontSize: 14 }}>
        {showreleaseReqsTable && (
          <div className="col-xs-12 col-sm-12 ">
            <table
              id="releaseReqsTable"
              className="table table-bordered table-condensed inherit-style mb-0 table-sm"
            >
              <thead className="table-primary small">
                <tr
                  className="noTopBorder"
                  style={{
                    backgroundColor: "rgb(233, 236, 239)",
                    color: "rgb(33, -37, 41)",
                  }}
                >
                  <th className="col-sm-3">Release Name</th>
                  <th className="col-sm-8">Requirements</th>
                </tr>
              </thead>
              <ReleaseWithoutReq />
            </table>
          </div>
        )}
        {showtestCyclesTable && (
          <div className="col-xs-12 col-sm-12 ">
            <table
              id="testCyclesTable"
              className="table table-bordered table-condensed table-responsive table-hover table-sm"
            >
              <thead className="table-primary small">
                <tr className="noTopBorder">
                  <th className="col-sm-3">Test Cycle/Suite ID</th>
                  <th className="col-sm-5">Test Cycle/Suite Name</th>
                  <th className="col-sm-5">Comments</th>
                </tr>
              </thead>
              <TestSuitesData />
            </table>
          </div>
        )}
        {showtestCasesNotRunTable && (
          <div className="col-xs-12 col-sm-12 ">
            <table
              id="testCasesNotRunTable"
              className="table table-bordered table-condensed table-responsive table-hover table-sm"
            >
              <thead className="table-primary small">
                <tr className="noTopBorder">
                  <th className="col-sm-2">Test Case ID</th>
                  <th className="col-sm-5">Test Case Name</th>
                  <th className="col-sm-2">Test Run ID</th>
                  <th className="col-sm-5">Test Suite</th>
                  <th className="col-sm-5">Assigned To</th>
                </tr>
              </thead>
              <tbody>
                <TestRunsData item={"Unexecuted"} />
              </tbody>
            </table>
          </div>
        )}
        {showtestRunsNoBuildNumberTable && (
          <div className="col-xs-12 col-sm-12 ">
            <table
              id="testRunsNoBuildNumberTable"
              className="table table-bordered table-condensed table-responsive table-hover table-sm"
            >
              <thead className="table-primary small">
                <tr className="noTopBorder">
                  <th className="col-sm-2">Test Case ID</th>
                  <th className="col-sm-5">Test Case Name</th>
                  <th className="col-sm-2">Test Run ID</th>
                  <th className="col-sm-5">Test Suite Name</th>
                  <th className="col-sm-5">Assigned To</th>
                </tr>
              </thead>
              <tbody>
                <TestRunsData item={"noBuildNumber"} />
              </tbody>
            </table>
          </div>
        )}
        {showtestCasesInProgressTable && (
          <div className="col-xs-12 col-sm-12 ">
            <table
              id="testCasesInProgressTable"
              className="table table-bordered table-condensed table-responsive table-hover table-sm"
            >
              <thead className="table-primary small">
                <tr className="noTopBorder">
                  <th className="col-sm-2">Test Case ID</th>
                  <th className="col-sm-5">Test Case Name</th>
                  <th className="col-sm-2">Test Run ID</th>
                  <th className="col-sm-5">Test Suite Name</th>
                  <th className="col-sm-5">Assigned To</th>
                </tr>
              </thead>
              <tbody>
                <TestRunsData item={"InProgress"} />
              </tbody>
            </table>
          </div>
        )}
        {showtestCasesFailedWithoutActual && (
          <div className="col-xs-12 col-sm-12 ">
            <table
              id="testCasesFailedWithoutActual"
              className="table table-bordered table-condensed table-responsive table-hover table-sm"
            >
              <thead className="table-primary small">
                <tr className="noTopBorder">
                  <th className="col-sm-2">Test Case ID</th>
                  <th className="col-sm-5">Test Case Name</th>
                  <th className="col-sm-2">Test Run ID</th>
                  <th className="col-sm-5">Test Cycle/Suite Name</th>
                  <th className="col-sm-5">Assigned To</th>
                </tr>
              </thead>
              <tbody>
                <TestRunsData item={"FailedAndBlocked"} />
              </tbody>
            </table>
          </div>
        )}
        {shownotReadyTestCases && (
          <div className="col-xs-12 col-sm-12 ">
            <table
              id="not_Ready_TestCases"
              className="table table-bordered table-condensed table-responsive table-hover table-sm"
            >
              <thead className="table-primary small">
                <tr className="noTopBorder">
                  <th className="col-sm-2">ID</th>
                  <th className="col-sm-3">Name</th>
                  <th className="col-sm-2">State</th>
                  <th className="col-sm-3">Test Cycle/Suite Name</th>
                </tr>
              </thead>
              <tbody>
                <TestCasesData item={"testCaseWithNotReadyState"} />
              </tbody>
            </table>
          </div>
        )}
        {showtestCasesNotCorrectName && (
          <div className="col-xs-12 col-sm-12">
            <table
              id="testCasesNotCorrectName"
              className="table table-bordered table-condensed table-responsive table-hover table-sm"
            >
              <thead className="table-primary small">
                <tr className="noTopBorder">
                  <th className="col-sm-2">ID</th>
                  <th className="col-sm-2">Name</th>
                  <th className="col-sm-4">Test Cycle/Suite Name</th>
                </tr>
              </thead>
              <tbody>
                <TestCasesData item={"testCaseWithOutVerifyWord"} />
              </tbody>
            </table>
          </div>
        )}
        {showtestCasesWithoutReq && (
          <div className="col-xs-12 col-sm-12 ">
            <table
              id="testCasesWithoutReq"
              className="table table-bordered table-condensed table-responsive table-hover table-sm"
            >
              <thead className="table-primary small">
                <tr className="noTopBorder">
                  <th className="col-sm-2">ID</th>
                  <th className="col-sm-3 ">Name</th>
                  <th className="col-sm-3">Test Cycle/Suite Name</th>
                </tr>
              </thead>
              <tbody>
                <TestCasesData item={"testCaseWithOutRelatedRequirements"} />
              </tbody>
            </table>
          </div>
        )}
        {showtestCyclesTableIssues && (
          <div className="col-xs-12 col-sm-12 ">
            <table
              id="testCyclesTableIssues"
              className="table table-bordered table-condensed table-responsive table-hover table-sm"
            >
              <thead className="table-primary small">
                <tr className="noTopBorder">
                  <th className="col-sm-2">Defect ID</th>
                  <th className="col-sm-2">Defect Name</th>
                  <th className="col-sm-2">Defect Status</th>
                </tr>
              </thead>
              <tbody>
                <TestOpenIssuesData />
              </tbody>
            </table>
          </div>
        )}
        {shownotTargetIssuesTable && (
          <div className="col-xs-12 col-sm-12 ">
            <table
              id="notTargetIssuesTable"
              className="table table-bordered table-condensed table-responsive table-hover table-sm"
            >
              <thead className="table-primary small">
                <tr className="noTopBorder">
                  <th className="col-sm-1">Defect ID</th>
                  <th className="col-sm-5">Defect Name</th>
                  <th className="col-sm-2">Defect Severity</th>
                  <th className="col-sm-2">Defect Status</th>
                  <th className="col-sm-2">Defect Resolution</th>
                  <th className="col-sm-3">Target Release</th>
                </tr>
              </thead>
              <tbody>
                <NotTargetedIssuesData />
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}
