import PageLogo from "../common/PageLogo";
import AdminService from "../../services/Admin";
import Admin from "../../pages/Admin";
import Dashboard from "../../pages/Dashboard";
import ProjectDetails from "../../pages/ProjectDetails";
import CrossProject from "../../pages/CrossProject";
import ServiceInsights from "../../pages/serviceInsights/ServiceInsights";
import ServiceInsightsDetails from "../../pages/serviceInsights/ServiceInsightsDetails";
import useNavTabStyles from "../hooks/useNavTabStyles";
import useUserInfo from "../hooks/useUserInfo";
import useToken from "../hooks/useToken";
import useProjectDetails from "../hooks/useProjectDetails";
import useProjects from "../hooks/useProjects";
import { useState } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "../../services/authConfig";
import { InteractionStatus } from "@azure/msal-browser";
import "../css/custom.min.css";
import "../css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";

async function getUser(idTokenValue, userName) {
  try {
    return await AdminService.getUserByUserName(idTokenValue, userName);
  } catch (err) {
    return {
      message:
        "Get user with [" + "] name returns an error (" + err.message + ")",
    };
  }
}

const LoginForm = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();
  const { token, setToken } = useToken();
  const { loggedUser, setLoggedUser } = useUserInfo({});
  const { setCurrentProjectId, currentProjectId } = useProjectDetails();
  const { selectedProjectType, setSelectedProjectType } = useProjects();
  const [loading, setLoading] = useState(false);
  const { navTabStyles, setNavTabStyles } = useNavTabStyles({
    dashboard: "active",
    crossProject: "",
    details: "",
    admin: "",
  });

  const handleLogin = () => {
    instance.loginRedirect(loginRequest).catch((e) => {});
  };

  const navTabChangeStyle = (tabId) => {
    let newStatuses = {};
    for (let key in navTabStyles) {
      if (key === tabId) {
        newStatuses[key] = "active";
      } else {
        newStatuses[key] = "";
      }
    }
    setNavTabStyles(newStatuses);
  };

  const getProjectIdFromUrl = () => {
    var url = window.location.href;
    let currentId = currentProjectId;
    let res = /project\/(\d+)/g.exec(url);

    if (!currentId) {
      if (res?.length > 1 && res[1] !== "null") {
        currentId = res[1];
        setCurrentProjectId(currentId);
      }
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  };

  if (token === null) {
    instance.handleRedirectPromise().then(async (tokenResponse) => {
      if (
        localStorage.getItem(
          "msal.token.keys.5f4dd567-c22b-4649-b321-4e83e08e9668"
        ) != null
      ) {
        let idTokenKey = JSON.parse(
          localStorage.getItem(
            "msal.token.keys.5f4dd567-c22b-4649-b321-4e83e08e9668"
          )
        ).idToken;
        let idTokenValue = JSON.parse(localStorage.getItem(idTokenKey)).secret;
        let accoutKey = JSON.parse(localStorage.getItem("msal.account.keys"));
        let username = JSON.parse(
          localStorage.getItem(accoutKey)
        ).username.split("@")[0];
        setToken(idTokenValue);
        localStorage.setItem("userName", username);
        let userInfoResponse = await getUser(idTokenValue, username);
        if (userInfoResponse != null) {
          setLoggedUser({
            name: username,
            admin: userInfoResponse.admin ? userInfoResponse.admin : false,
            id: userInfoResponse.id,
            projectTypes: userInfoResponse.projectTypes
              ? userInfoResponse.projectTypes
              : [],
            favoriteProjects: userInfoResponse.favoriteProjects
              ? userInfoResponse.favoriteProjects
              : [],
          });
          setLoading(true);
        }
      }
    });
  }

  if (isAuthenticated) {
    if (loggedUser) {
      return (
        <>
          <PageLogo />
          <HashRouter basename={"/"}>
            <Switch>
              <Redirect exact from="/" to="dashboard" />
              <Route exact path="/dashboard">
                <Dashboard
                  token={token}
                  loggedUser={loggedUser}
                  setLoggedUser={(info) => setLoggedUser(info)}
                  navTabChangeStyle={navTabChangeStyle}
                  currentProjectId={currentProjectId}
                  setCurrentProjectId={setCurrentProjectId}
                  selectedProjectType={selectedProjectType}
                  setSelectedProjectType={setSelectedProjectType}
                />
              </Route>
              <Route path="/project/:projectId">
                <ProjectDetails
                  token={token}
                  loggedUser={loggedUser}
                  navTabChangeStyle={navTabChangeStyle}
                  currentProjectId={currentProjectId}
                  setCurrentProjectId={setCurrentProjectId}
                  selectedProjectType={selectedProjectType}
                  setSelectedProjectType={setSelectedProjectType}
                />
              </Route>
              <Route path="/crossProject">
                <CrossProject
                  token={token}
                  loggedUser={loggedUser}
                  navTabChangeStyle={navTabChangeStyle}
                  currentProjectId={currentProjectId}
                  setToken={setToken}
                />
              </Route>
              <Route path="/admin">
                <Admin token={token} loggedUser={loggedUser} />
              </Route>
              <Route path="/service-insights">
                <ServiceInsights
                  token={token}
                  loggedUser={loggedUser}
                  navTabChangeStyle={navTabChangeStyle}
                  currentProjectId={currentProjectId}
                />
              </Route>
              <Route path="/service-insights-details">
                <ServiceInsightsDetails
                  token={token}
                  loggedUser={loggedUser}
                  navTabChangeStyle={navTabChangeStyle}
                  currentProjectId={currentProjectId}
                />
              </Route>
              <Route path="/logout">
                <LoginForm />
              </Route>
            </Switch>
          </HashRouter>
        </>
      );
    } else if (loading) {
      return (
        <>
          <PageLogo />
          <HashRouter basename={"/"}>
            <Switch>
              <Redirect exact from="/" to="dashboard" />
              <Route exact path="/dashboard">
                <Dashboard
                  token={token}
                  loggedUser={loggedUser}
                  setLoggedUser={(info) => setLoggedUser(info)}
                  navTabChangeStyle={navTabChangeStyle}
                  currentProjectId={currentProjectId}
                  setCurrentProjectId={setCurrentProjectId}
                  selectedProjectType={selectedProjectType}
                  setSelectedProjectType={setSelectedProjectType}
                />
              </Route>
              <Route path="/project/:projectId">
                <ProjectDetails
                  token={token}
                  loggedUser={loggedUser}
                  navTabChangeStyle={navTabChangeStyle}
                  currentProjectId={currentProjectId}
                  setCurrentProjectId={setCurrentProjectId}
                  selectedProjectType={selectedProjectType}
                  setSelectedProjectType={setSelectedProjectType}
                />
              </Route>
              <Route path="/crossProject">
                <CrossProject
                  token={token}
                  loggedUser={loggedUser}
                  navTabChangeStyle={navTabChangeStyle}
                  currentProjectId={currentProjectId}
                  setToken={setToken}
                />
              </Route>
              <Route path="/admin">
                <Admin token={token} loggedUser={loggedUser} />
              </Route>
              <Route path="/service-insights">
                <ServiceInsights
                  token={token}
                  loggedUser={loggedUser}
                  navTabChangeStyle={navTabChangeStyle}
                  currentProjectId={currentProjectId}
                />
              </Route>
              <Route path="/service-insights-details">
                <ServiceInsightsDetails
                  token={token}
                  loggedUser={loggedUser}
                  navTabChangeStyle={navTabChangeStyle}
                  currentProjectId={currentProjectId}
                />
              </Route>
              <Route path="/logout">
                <LoginForm />
              </Route>
            </Switch>
          </HashRouter>
        </>
      );
    }
  } else if (
    inProgress !== InteractionStatus.Startup &&
    inProgress !== InteractionStatus.HandleRedirect
  ) {
    // inProgress check prevents sign-in button from being displayed briefly after returning from a redirect sign-in. Processing the server response takes a render cycle or two
    return (
      <div>
        <PageLogo />
        <section className="d-flex min-vh">
          <div className="container py-5 h-100">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                <div
                  className="card shadow-2-strong"
                  style={{ borderRadius: "1rem" }}
                >
                  <div className="card-body p-5 text-center">
                    <h3 className="mb-5">Logout</h3>
                    <h5 className="mb-5">
                      You are now logged out. Any automatic login has also been
                      stopped. Didn't mean to log out?
                    </h5>
                    <button
                      onClick={handleLogin}
                      className="btn btn-primary btn-lg btn-block"
                    >
                      Login
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  } else {
    return null;
  }
};

export default LoginForm;
