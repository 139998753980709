import { useState } from "react";

export default function useErrorMessage() {
  const getErrorMessage = () => {
    return localStorage.getItem("errorMessage");
  };

  const [errorMessage, setErrorMessage] = useState(getErrorMessage());

  const saveErrorMessage = (userErrorMessage) => {
    localStorage.setItem("errorMessage", userErrorMessage);
    setErrorMessage(userErrorMessage);
  };

  const clearErrorMessage = () => {
    localStorage.removeItem("errorMessage");
  };

  return {
    setErrorMessage: saveErrorMessage,
    clearErrorMessage,
    errorMessage,
  };
}
