import React, { useState, useEffect } from "react";
import ProjectTypes from "../projects/ProjectTypes";
import ErrorMessage from "../common/ErrorMessage";
import DatePicker from "react-datepicker";
import useProjects from "../hooks/useProjects";
import "react-datepicker/dist/react-datepicker.css";
import "font-awesome/css/font-awesome.min.css";
import "../css/bootstrap.min.css";
import "../css/custom.min.css";

function ProjectInfo(props) {
  const DataSource = {
    JIRA: "JIRA",
    ADO: "ADO",
    QTEST: "qTest",
  };
  const { projects } = useProjects();
  const [isProjectNamePopulated, setIsProjectNamePopulated] = useState(
    props.projectDetails?.projectName ? true : false
  );
  const [isTestPlanPopulated, setIsTestPlanPopulated] = useState(
    props.projectDetails?.testPlanLink ? true : false
  );
  const [isStartDatePopulated, setIsStartDatePopulated] = useState(
    props.projectDetails?.startDate ? true : false
  );
  const [isEndDatePopulated, setIsEndDatePopulated] = useState(
    props.projectDetails?.endDate ? true : false
  );
  const [startDate, setStartDate] = useState(
    props.projectDetails?.startDate
      ? Date.parse(props.projectDetails.startDate) +
          new Date(props.projectDetails.startDate).getTimezoneOffset() * 60000
      : 0
  );
  const [endDate, setEndDate] = useState(
    props.projectDetails?.endDate
      ? Date.parse(props.projectDetails.endDate) +
          new Date(props.projectDetails.endDate).getTimezoneOffset() * 60000
      : 0
  );
  const [errorMessage, setErrorMessage] = useState("");

  const [createdDate, setCreatedDate] = useState(
    props.projectDetails?.createdTime
      ? new Date(
          props.projectDetails.createdTime +
            new Date().getTimezoneOffset() * 60000
        ).toLocaleDateString()
      : ""
  );
  const [updatedDate, setUpdatedDate] = useState(
    props.projectDetails?.updatedTime
      ? new Date(
          props.projectDetails.updatedTime +
            new Date().getTimezoneOffset() * 60000
        ).toLocaleDateString()
      : ""
  );

  useEffect(() => {
    setCreatedDate(
      props.projectDetails?.createdTime
        ? new Date(
            props.projectDetails.createdTime +
              new Date().getTimezoneOffset() * 60000
          ).toLocaleDateString()
        : ""
    );
  }, [props.projectDetails?.createdTime]);

  useEffect(() => {
    setUpdatedDate(
      props.projectDetails?.updatedTime
        ? new Date(
            props.projectDetails.updatedTime +
              new Date().getTimezoneOffset() * 60000
          ).toLocaleDateString()
        : ""
    );
  }, [props.projectDetails?.updatedTime]);

  let localProps = {};
  Object.assign(localProps, props);
  localProps["disabled"] = props.disabled ? true : false;
  localProps["notAll"] = true;
  localProps["decreaseProjectTypes"] = true;
  const disabled = props.disabled ? true : false;
  const planLinkStateInput = props.disabled ? "d-none" : "";
  const planLinkStateLink = !props.disabled ? "d-none" : "";

  const compareDates = (start, end) => {
    let date1 = new Date(start);
    let date2 = new Date(end);
    let isValid = false;

    if (date1.getTime() > date2.getTime()) {
      isValid = false;
    } else {
      isValid = true;
    }

    return isValid;
  };

  const handleStartDateChange = (date) => {
    setErrorMessage("");
    setIsStartDatePopulated(false);

    if (endDate === 0) {
      setStartDate(date);
      props.props.handleStartDateChange(date);
      if (date) {
        setIsStartDatePopulated(true);
      }
      return;
    }

    if (endDate !== undefined) {
      let valid = compareDates(date, endDate);

      if (!valid) {
        setStartDate("");
        setErrorMessage("Start date should be lower than End date.");
        return props.props.setDateError(true);
      }
    }
    if (date) {
      setIsStartDatePopulated(true);
    }
    setStartDate(date);
    props.props.handleStartDateChange(date);
    props.props.setDateError(false);
  };

  const handleEndDateChange = (date, id) => {
    setErrorMessage("");
    setIsEndDatePopulated(false);

    if (date && startDate !== undefined) {
      let valid = compareDates(startDate, date);

      if (!valid) {
        setEndDate("");
        setErrorMessage("End date should be greater than Start date.");
        return props.props.setDateError(true);
      }
    }
    if (date) {
      setIsEndDatePopulated(true);
    }
    setEndDate(date);
    props.props.handleEndDateChange(date);
    props.props.setDateError(false);
  };

  const handleProjectNameChange = (e) => {
    setErrorMessage("");
    setIsProjectNamePopulated(false);
    //validate if the name is unique
    if (projects && Object.entries(projects).length > 0) {
      let tmpName = e.target.value;
      if (!tmpName) {
        props.props.handleProjectNameChange(e);
        return;
      }

      tmpName = tmpName.toLowerCase().trim();
      let filtered = projects.filter((project) => {
        if (props.projectDetails?.projectId !== project.projectId) {
          return project.projectName.toLowerCase().trim() === tmpName;
        }
        return false;
      });
      if (filtered.length > 0) {
        setErrorMessage("Project with provided name already exists!");
        return;
      }
    }
    setIsProjectNamePopulated(true);
    props.props.handleProjectNameChange(e);
  };

  return (
    <div id="project-info-container">
      <ErrorMessage errorMessage={errorMessage} />
      <div className="row mb-4">
        <div className="instruction">
          <span className="reqStarInline">*</span> Indicates a required field.
        </div>
        <div className="col-md-6">
          <label htmlFor="projectName" className="form-label fs-7 fw-bold">
            <span style={isProjectNamePopulated ? {} : { color: "red" }}>
              *{" "}
            </span>
            Project Name:
          </label>
          <input
            type="text"
            id="projectName"
            className="form-control form-control-sm"
            placeholder="Project Name"
            onChange={(e) => handleProjectNameChange(e)}
            defaultValue={props.projectDetails?.projectName}
            disabled={disabled}
            autoComplete="off"
          />
        </div>
        <div className="col-md-2 ms-auto">
          <div className="col-5">
            <label htmlFor="projectId" className="form-label fs-7 fw-bold">
              Project ID:
            </label>
            <input
              type="text"
              className="form-control form-control-sm"
              id="projectId"
              defaultValue={props.projectDetails?.projectId}
              disabled
              autoComplete="off"
            />
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-md-3">
          <label htmlFor="projectId" className="form-label fs-7 fw-bold">
            Created by:
          </label>
          <input
            type="text"
            className="form-control form-control-sm"
            id="projectId"
            defaultValue={props.projectDetails?.createdBy}
            disabled
            autoComplete="off"
          />
        </div>
        <div className="col-md-3">
          <label htmlFor="projectId" className="form-label fs-7 fw-bold">
            Created Date:
          </label>
          <input
            type="text"
            className="form-control form-control-sm"
            id="projectId"
            defaultValue={createdDate}
            disabled
            autoComplete="off"
          />
        </div>
        <div className="col-md-3">
          <label htmlFor="projectId" className="form-label fs-7 fw-bold">
            Updated by:
          </label>
          <input
            type="text"
            className="form-control form-control-sm"
            id="projectId"
            defaultValue={
              props.projectDetails?.updatedBy
                ? props.projectDetails?.updatedBy
                : ""
            }
            disabled
            autoComplete="off"
          />
        </div>
        <div className="col-md-3">
          <label htmlFor="projectId" className="form-label fs-7 fw-bold">
            Updated Date:
          </label>
          <input
            type="text"
            className="form-control form-control-sm"
            id="projectId"
            defaultValue={updatedDate}
            disabled
            autoComplete="off"
          />
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-md-4">
          <label htmlFor="isActive" className="form-check-label fs-7 fw-bold">
            Active:
          </label>
          <input
            type="checkbox"
            className="form-check-input ml-4 ms-2"
            id="isActive"
            defaultChecked={!!props.projectDetails?.isActive}
            onChange={(e) => props.props.handleIsActiveChange(e)}
            disabled={disabled}
          />
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-md-6">
          <label htmlFor="ProjectTypes" className="form-label fs-7 fw-bold">
            <span>* </span>
            Program:
          </label>
          <ProjectTypes
            props={localProps}
            handleChange={props.props.handleProjectTypeChange}
            selectedProjectType={props.props.selectedProjectType}
            selectedProgram={true}
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col md-6">
          <label htmlFor="testPlanLink" className="form-label fs-7 fw-bold">
            <span style={isTestPlanPopulated ? {} : { color: "red" }}>* </span>
            {props.sourceDefectDataType === DataSource.ADO
              ? "ADO"
              : DataSource.QTest}{" "}
            Release Link:
          </label>
          <input
            type="text"
            id="testPlanLink"
            className={`form-control form-control-sm ${planLinkStateInput}`}
            placeholder={
              props.isADO ? "ADO Release Link" : "qTest Release Link"
            }
            defaultValue={props.projectDetails?.testPlanLink}
            onChange={(e) => {
              props.props.handleTestPlanLinkChange(e);
              e?.target?.value
                ? setIsTestPlanPopulated(true)
                : setIsTestPlanPopulated(false);
            }}
            autoComplete="off"
          />
          <a
            className={`form-control-plaintext link-primary ${planLinkStateLink}`}
            target="_blank"
            rel="noopener noreferrer"
            href={props.projectDetails?.testPlanLink}
          >
            {props.projectDetails?.testPlanLink}
          </a>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col">
          <label htmlFor="startDate" className="form-label fs-7 fw-bold">
            <span style={isStartDatePopulated ? {} : { color: "red" }}>* </span>
            Start Date:
          </label>
          <div className="col-4">
            <DatePicker
              id="project-start-date"
              className="form-control form-control-sm"
              dateFormat={"yyyy-MM-dd"}
              selected={startDate}
              disabled={disabled}
              onChange={(date) => handleStartDateChange(date)}
            ></DatePicker>
          </div>
        </div>
        <div className="col">
          <label htmlFor="endDate" className="form-label fs-7 fw-bold">
            <span style={isEndDatePopulated ? {} : { color: "red" }}>* </span>
            End Date:
          </label>
          <div className="col-4">
            <DatePicker
              id="project-end-date"
              className="form-control form-control-sm"
              dateFormat={"yyyy-MM-dd"}
              selected={endDate}
              disabled={disabled}
              onChange={(date) => handleEndDateChange(date)}
            ></DatePicker>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectInfo;
