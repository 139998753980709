import { Chart } from "react-google-charts";

const doFiltering = (jiraIssue, filters) => {
  return Object.entries(filters).every(([property, value]) =>
    value.includes(jiraIssue[property])
  );
};

export default function JiraCustomChart(props) {
  const {
    jiraItems,
    selectedEnvironmentValues,
    selectedIssueTypesValues,
    selectedPriorityValues,
    selectedReleaseFoundValues,
    selectedSeverityValues,
    selectedStatusValues,
    selectedTeamValues,
  } = props;
  var dataTable = [];
  var emptyHeaderArray = ["Project", "Count"];
  var emptyDataArray = ["Project", 0];

  if (
    !props.selectedReleaseFoundValues ||
    props.selectedReleaseFoundValues.length < 1 ||
    !props.selectedIssueTypesValues ||
    props.selectedIssueTypesValues.length < 1 ||
    !props.selectedStatusValues ||
    props.selectedStatusValues.length < 1 ||
    !props.selectedSeverityValues ||
    props.selectedSeverityValues.length < 1 ||
    !props.selectedPriorityValues ||
    props.selectedPriorityValues.length < 1 ||
    !props.selectedTeamValues ||
    props.selectedTeamValues.length < 1 ||
    !props.selectedEnvironmentValues ||
    props.selectedEnvironmentValues.length < 1
  ) {
    dataTable.push(emptyHeaderArray);
    dataTable.push(emptyDataArray);
  }

  let keysToFilter = {
    releaseFound: selectedReleaseFoundValues.map((key) => key.label),
    issueTypes: selectedIssueTypesValues.map((key) => key.label),
    status: selectedStatusValues.map((key) => key.label),
    severity: selectedSeverityValues.map((key) => key.label),
    priority: selectedPriorityValues.map((key) => key.label),
    program: selectedTeamValues.map((key) => key.label),
    environment: selectedEnvironmentValues.map((key) => key.label),
  };
  let filteredKeys = Object.entries(keysToFilter);
  let newArrayOfFilteredKeys = filteredKeys.filter((item) => {
    return item[1].length > 0;
  });
  let tempArray = [];
  let tempFilters = {};

  jiraItems.forEach((element) => {
    return newArrayOfFilteredKeys.forEach((key) => {
      let keyFiltered = key[0];
      let keyFilteringValues = key[1];

      tempFilters[keyFiltered] = keyFilteringValues;

      return keyFilteringValues.forEach((nextKey) => {
        if (nextKey === element[key[0]]) {
          tempArray.push(element);
        }
      });
    });
  });

  let filteredIssues = tempArray.filter((issue) =>
    doFiltering(issue, tempFilters)
  );

  let releasesCounter = {};
  filteredIssues.forEach((issue) => {
    const release = issue.releaseFound;
    releasesCounter[release] = (releasesCounter[release] || 0) + 1;
  });
  let finalArrayTable = Object.entries(releasesCounter).map(
    ([release, counter]) => [release, counter]
  );
  finalArrayTable.unshift(["Project", "Count"]);

  var options = {
    title: "Defect Trend",
    titleTextStyle: { fontSize: "16" },
    hAxis: { slantedText: true, textStyle: { fontSize: 11 } },
    vAxis: { format: "#" },
    chartArea: { left: 70, top: 50, width: "80%", height: "67%" },
    interpolateNulls: true,
  };

  return (
    <div>
      <Chart
        chartType="LineChart"
        width="100%"
        height="400px"
        data={finalArrayTable.length > 1 ? finalArrayTable : dataTable}
        options={options}
      />
    </div>
  );
}
