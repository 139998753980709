import React, { useEffect, useState } from "react";
import NavigationTab from "../../components/common/NavigationTab";
import Loading from "../../components/common/Loading";
import Insights from "../../services/Insights";

const ServiceInsightsDetailsPage = (props) => {
  const [loading, setLoading] = useState(true);
  const [serviceDetailsData, setServicesDetailsData] = useState();
  const [cardFilters, setCardFilters] = useState([]);

  useEffect(() => {
    if (serviceDetailsData) {
      setCardFilters(new Array(serviceDetailsData.length).fill(""));
    }
  }, [serviceDetailsData]);

  useEffect(() => {
    let dataFromStore = JSON.parse(
      localStorage.getItem("serviceInsightsDetails")
    );
    let idsFromStore = JSON.parse(
      localStorage.getItem("serviceInsightsDetailsIds")
    );

    if (dataFromStore) {
      handleInsightsServicesData(props.token, idsFromStore);
    }
  }, []);

  const handleInsightsServicesData = async (token, idsFromStore) => {
    let servicesIds = extractServicesIds(idsFromStore);

    try {
      const response = await Insights.getServiceInsightsDetailsInfo(
        token,
        servicesIds
      );

      setServicesDetailsData(response);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      return {
        errMsg: err.message ? err.message : JSON.stringify(err),
      };
    }
  };

  const extractServicesIds = (idsFromStore) => {
    let ids;

    if (idsFromStore.length > 1) {
      let stringsIds = [];

      idsFromStore.forEach((service) => {
        stringsIds.push(service.id);
      });

      ids = stringsIds.join();
    } else {
      ids = idsFromStore[0].id;
    }

    return ids;
  };

  const extractDifferentRegions = (listOfServices) => {
    const uniqueRegions = [
      ...new Set(listOfServices.map((service) => service.region)),
    ];
    const finalUniqueRegions = uniqueRegions.map((region) => ({
      value: region,
      label: region,
    }));

    return finalUniqueRegions;
  };

  const handleCardFilterChange = (index, value) => {
    const updatedFilters = [...cardFilters];
    updatedFilters[index] = value;
    setCardFilters(updatedFilters);
  };

  const getContentCardDetails = (details, cardFilter) => {
    let content;
    let filteredContent;
    let contentToMap;

    filteredContent = details.filter(
      (service) => service.region === cardFilter
    );

    contentToMap = filteredContent.length ? filteredContent : details;

    content = contentToMap.map((detailInfo, index) => {
      return (
        <div
          className="card"
          key={index}
          style={{
            margin: "0 5px 10px 5px",
            padding: "0 0",
            maxWidth: "465px",
          }}
        >
          <div className="card-header text-center d-flex justify-content-between">
            <h6
              className="d-flex align-items-center"
              style={{ marginBottom: "0" }}
            >
              {detailInfo.region}
            </h6>
            <button className="btn btn-lg btn-primary btn-sm" disabled>
              {detailInfo.environment}
            </button>
          </div>
          <div className="card-body">
            <div style={{ display: "flex", margin: "5px 0" }}>
              <h6
                className="card-title"
                style={{ width: "100%", maxWidth: "120px" }}
              >
                Version
              </h6>
              <p className="card-text" style={{ paddingLeft: "10px" }}>
                {detailInfo.serviceVersion}
              </p>
            </div>
            <div style={{ display: "flex", margin: "5px 0" }}>
              <h6
                className="card-title"
                style={{ width: "100%", maxWidth: "120px" }}
              >
                Program
              </h6>
              <p className="card-text" style={{ paddingLeft: "10px" }}>
                {detailInfo.program}
              </p>
            </div>
            <div style={{ display: "flex", margin: "5px 0" }}>
              <h6
                className="card-title"
                style={{ width: "100%", maxWidth: "120px" }}
              >
                Components
              </h6>
              <p className="card-text" style={{ paddingLeft: "10px" }}>
                {detailInfo.components?.map((component, index) => {
                  return (
                    <span
                      key={index}
                      className="badge rounded-pill bg-light text-dark"
                    >
                      {component}
                    </span>
                  );
                })}
              </p>
            </div>
          </div>
        </div>
      );
    });

    return content;
  };

  return (
    <div
      id="service_insights_details"
      style={{ minHeight: "calc(100vh - 60px)" }}
    >
      <NavigationTab
        changeStyle={props.navTabChangeStyle}
        currentProjectId={props.currentProjectId}
      />
      {loading ? (
        <Loading />
      ) : (
        serviceDetailsData &&
        serviceDetailsData.map((service, index) => {
          let regionsToFilter = extractDifferentRegions(service.detail);

          return (
            <div key={index} style={{ margin: "0 100px 25px 100px" }}>
              <div className="container-fluid" style={{ margin: "25px 0 0 0" }}>
                <div
                  className="row ms-1 me-1 border pb-4 px-0 border-ligth border-1 rounded"
                  style={{ marginBottom: "30px" }}
                >
                  <div
                    className="col-sm-12 px-5 py-1"
                    style={{
                      marginBottom: "25px",
                      background: "rgba(0,0,0,.03)",
                      borderBottom: "1px solid #dee2e6",
                    }}
                  >
                    <h4
                      className="ms-4 text-center"
                      style={{ padding: "5px 0" }}
                    >
                      {service.serviceName}
                    </h4>
                    <div
                      className="filter-by-region"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "15px 0",
                      }}
                    >
                      <div
                        className="form-label fs-5"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "0",
                        }}
                      >
                        <label
                          className="input-group-text"
                          htmlFor="inputGroupSelect01"
                        >
                          Region
                        </label>
                      </div>
                      <select
                        id={`filteredRegion_${index}`}
                        value={cardFilters[index]}
                        className="form-select-sm ms-2"
                        aria-label="Select a region"
                        onChange={(e) =>
                          handleCardFilterChange(index, e.target.value)
                        }
                      >
                        <option value="" defaultValue>
                          All
                        </option>
                        {regionsToFilter.map((region) => {
                          return (
                            <option value={region.value} key={region.value}>
                              {region.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="row row-cols-3 mx-3 justify-content-center">
                    {getContentCardDetails(service.detail, cardFilters[index])}
                  </div>
                </div>
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};

export default ServiceInsightsDetailsPage;
