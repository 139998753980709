import React, { useState, useEffect } from "react";
import ProjectTypes from "../../components/projects/ProjectTypes";
import Jira from "../../services/Jira";
import Qtest from "../../services/Qtest";
import Project from "../../services/Projects";
import Loading from "../../components/common/Loading";
import ReactTooltip from "react-tooltip";
import ErrorMessage from "../../components/common/ErrorMessage";
import useProjects from "../../components/hooks/useProjects";
import "../../components/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";

const toolTipType = {
  project: "Project",
  defect: "Defect",
  sprint: "Sprint",
};

async function getAllInfoFromServer(token, projects) {
  let promises = [];
  try {
    const projectIds = projects
      .filter((projectActive) => projectActive.isActive === true)
      .map((p) => p.projectId);
    if (projectIds && projectIds.length > 0) {
      promises.push(getProjectTrackerDefectSummaryInfo(token, projectIds));
      promises.push(getProjectTrackerLatestSprintInfo(token, projectIds));
      promises.push(
        getProjectTrackerLatestSprintTestCaseInfo(token, projectIds)
      );
      promises.push(getProcessComplianceTestRunsDataInfo(token, projects));
      promises.push(getProcessComplianceTestCasesInfo(token, projects));
      return Promise.all(promises);
    }
  } catch (err) {
    return { message: "ProjectTracker Promise call error: " + err.message };
  }
}

async function getProjectsByProjectNameInfo(token, projectName) {
  try {
    const resp = await Project.getProjectByProjectName(token, projectName);
    return resp;
  } catch (err) {
    return {
      message:
        "Error connecting to API for ProjectTracker ProjectsByProjectName Info",
    };
  }
}

async function getProjectTrackerLatestSprintInfo(token, projectIds) {
  try {
    const resp = await Qtest.getProjectTrackerLatestSprintInfo(
      token,
      projectIds
    );
    return resp;
  } catch (err) {
    return {
      message: "Error connecting to API for ProjectTracker LatestSprint Info",
    };
  }
}

async function getProjectTrackerLatestSprintTestCaseInfo(token, projectIds) {
  try {
    const resp = await Qtest.getProjectTrackerLatestSprintTestExeInfo(
      token,
      projectIds
    );
    return resp;
  } catch (err) {
    return {
      message:
        "Error connecting to API for ProjectTracker LatestSprint Test Case info",
    };
  }
}

async function getProjectTrackerDefectSummaryInfo(token, projectIds) {
  try {
    const resp = await Jira.getProjectTrackerDefectSummaryInfo(
      token,
      projectIds
    );
    return resp;
  } catch (err) {
    return {
      message:
        "Error connecting to API for ProjectTracker Jira Defect Summary info",
    };
  }
}

async function getProcessComplianceTestRunsDataInfo(token, projects) {
  try {
    var processCompDataPromiss = [];
    const filterdProjects = projects
      .filter((p) => p.isActive === true)
      .map((project) => project);
    for (let project of filterdProjects) {
      processCompDataPromiss.push(
        Qtest.getProcessComplianceTestRunsDataInfo(
          token,
          project.projectId,
          project.projectName
        )
      );
    }
    return Promise.all(processCompDataPromiss);
  } catch (err) {
    return {
      message:
        "Error connecting to API for Process Compliance TestRuns Data Info",
    };
  }
}

async function getProcessComplianceTestCasesInfo(token, projects) {
  try {
    var processCompDataTestCasesPromiss = [];
    const filterdProjects = projects
      .filter((p) => p.isActive === true)
      .map((project) => project);
    for (let project of filterdProjects) {
      processCompDataTestCasesPromiss.push(
        Qtest.getProcessComplianceTestCasesInfo(
          token,
          project.projectId,
          project.projectName
        )
      );
    }
    return Promise.all(processCompDataTestCasesPromiss);
  } catch (err) {
    return {
      message: "Error connecting to API for Process Compliance Test Cases Info",
    };
  }
}

export default function ProjectTrackerPage(props) {
  const { projectsByProjectNameInfo, setProjectsByProjectNameInfo } =
    useProjects();
  const [selectedProjectType, setSelectedProjectType] = useState("");
  const [allResponseData, setAllResponseData] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(true);

  let localProps = {};
  Object.assign(localProps, props);
  localProps.style = "multy";

  useEffect(() => {
    const handleProjectTrackerDetails = async (e) => {
      let response = null;
      let responseAll = null;
      if (
        selectedProjectType &&
        selectedProjectType !== "All" &&
        selectedProjectType.length > 0
      ) {
        if (
          !projectsByProjectNameInfo ||
          (projectsByProjectNameInfo?.length > 0 &&
            projectsByProjectNameInfo[0].projectTypeName !==
              selectedProjectType[0].projectTypeName) ||
          allResponseData === undefined
        ) {
          setLoading(true);
          response = await getProjectsByProjectNameInfo(
            props.token,
            selectedProjectType[0].projectTypeName
          );
          const filterProj = response
            .filter((p) => p.isActive === true)
            .map((project) => project);
          if (filterProj !== null && filterProj.length > 0) {
            if (response.errMsg) {
              setErrorMessage(response.errMsg);
              setProjectsByProjectNameInfo(null);
            } else {
              if (!response) {
                setErrorMessage(
                  "Project Trackker - Projects by Project Name response does not contain any information!"
                );
                setProjectsByProjectNameInfo(null);
              } else {
                setErrorMessage("");
                setProjectsByProjectNameInfo(response);

                responseAll = await getAllInfoFromServer(props.token, response);
                if (!responseAll) {
                  setErrorMessage(
                    "Project Trackker - does not contain any information!"
                  );
                  setAllResponseData(undefined);
                  setLoading(false);
                } else {
                  setErrorMessage("");
                  setAllResponseData(responseAll);
                  setLoading(false);
                }
              }
            }
          } else {
            setErrorMessage("Project Tracker - No Active Project!");
            setAllResponseData(undefined);
            setLoading(false);
          }
        } else {
          if (allResponseData) {
            setErrorMessage("");
            setLoading(false);
          } else {
            setErrorMessage(
              "Project Trackker - does not contain any information!"
            );
            setLoading(true);
          }
        }
      }
    };
    handleProjectTrackerDetails();
    props.changeStyle("projectTracker");
  }, [selectedProjectType]);

  function LatestSprintData() {
    var projectTracker = [];
    try {
      if (allResponseData && allResponseData.length > 0) {
        const projects = projectsByProjectNameInfo
          .filter((p) => p.isActive === true)
          .map((project) => project);
        projects.forEach((item) => {
          var createdBug = {
            totalCount: 0,
            sev1: 0,
            sev2: 0,
            sev3: 0,
            sev4: 0,
          };
          var closedBug = {
            totalCount: 0,
            sev1: 0,
            sev2: 0,
            sev3: 0,
            sev4: 0,
          };
          var openBug = {
            totalCount: 0,
            sev1: 0,
            sev2: 0,
            sev3: 0,
            sev4: 0,
          };
          var defectSummary = {
            createdBug,
            closedBug,
            openBug,
          };

          var latestSprintData = {
            latestSprintName: "",
            sprintStartDate: "",
            sprintEndDate: "",
          };
          var latestSprintTestExe = {
            executedPercent: 0,
            passedPercent: 0,
            failedPercent: 0,
            blockedPercent: 0,
            executedCount: 0,
            passedCount: 0,
            failedCount: 0,
            blockedCount: 0,
          };
          var latestSprintTestCaseState = {
            new: 0,
            inProgress: 0,
            readyForBaseline: 0,
            baselined: 0,
          };
          var processComp = {
            testCasesNotRun: 0,
            reqWithoutTestCases: 0,
            failedBlockedTestCasesWithoutDefects: 0,
          };
          var projectTrackerData = {
            projectName: "",
            defectSummary,
            latestSprintData,
          };
          projectTrackerData.projectName = item.projectName;
          projectTrackerData.projectStartDate = item.startDate;
          projectTrackerData.projectEndDate = item.endDate;
          if (
            allResponseData &&
            allResponseData.length > 0 &&
            allResponseData[0]?.status === "OK"
          ) {
            let reqInfo = Object.entries(
              allResponseData[0].data.defectSummaryByProduct
            ).filter((k) => k.indexOf(item.projectName) > -1);
            if (reqInfo && reqInfo.length > 0) {
              projectTrackerData.defectSummary.createdBug.totalCount =
                reqInfo[0][1]?.Created?.totalCount;
              projectTrackerData.defectSummary.closedBug.totalCount =
                reqInfo[0][1]?.Closed?.totalCount;
              projectTrackerData.defectSummary.openBug.totalCount =
                reqInfo[0][1]?.Open?.totalCount;
              if (
                reqInfo[0][1]?.Created !== undefined &&
                reqInfo[0][1]?.Created !== null
              ) {
                Object.keys(reqInfo[0][1]?.Created).forEach((s) => {
                  if (s === "Sev 1-Severe") {
                    projectTrackerData.defectSummary.createdBug.sev1 =
                      Object.entries(reqInfo[0][1].Created).filter(
                        (k) => k.indexOf(s) > -1
                      )[0][1];
                  } else if (s === "Sev 2-High") {
                    projectTrackerData.defectSummary.createdBug.sev2 =
                      Object.entries(reqInfo[0][1].Created).filter(
                        (k) => k.indexOf(s) > -1
                      )[0][1];
                  } else if (s === "Sev 3-Medium") {
                    projectTrackerData.defectSummary.createdBug.sev3 =
                      Object.entries(reqInfo[0][1].Created).filter(
                        (k) => k.indexOf(s) > -1
                      )[0][1];
                  } else if (s === "Sev 4-Low") {
                    projectTrackerData.defectSummary.createdBug.sev4 =
                      Object.entries(reqInfo[0][1].Created).filter(
                        (k) => k.indexOf(s) > -1
                      )[0][1];
                  }
                });
              }
              if (
                reqInfo[0][1]?.Closed !== undefined &&
                reqInfo[0][1]?.Closed !== null
              ) {
                Object.keys(reqInfo[0][1]?.Closed).forEach((s) => {
                  if (s === "Sev 1-Severe") {
                    projectTrackerData.defectSummary.closedBug.sev1 =
                      Object.entries(reqInfo[0][1].Closed).filter(
                        (k) => k.indexOf(s) > -1
                      )[0][1];
                  } else if (s === "Sev 2-High") {
                    projectTrackerData.defectSummary.closedBug.sev2 =
                      Object.entries(reqInfo[0][1].Closed).filter(
                        (k) => k.indexOf(s) > -1
                      )[0][1];
                  } else if (s === "Sev 3-Medium") {
                    projectTrackerData.defectSummary.closedBug.sev3 =
                      Object.entries(reqInfo[0][1].Closed).filter(
                        (k) => k.indexOf(s) > -1
                      )[0][1];
                  } else if (s === "Sev 4-Low") {
                    projectTrackerData.defectSummary.closedBug.sev4 =
                      Object.entries(reqInfo[0][1].Closed).filter(
                        (k) => k.indexOf(s) > -1
                      )[0][1];
                  }
                });
              }
              if (
                reqInfo[0][1]?.Open !== undefined &&
                reqInfo[0][1]?.Open !== null
              ) {
                Object.keys(reqInfo[0][1]?.Open).forEach((s) => {
                  if (s === "Sev 1-Severe") {
                    projectTrackerData.defectSummary.openBug.sev1 =
                      Object.entries(reqInfo[0][1].Open).filter(
                        (k) => k.indexOf(s) > -1
                      )[0][1];
                  } else if (s === "Sev 2-High") {
                    projectTrackerData.defectSummary.openBug.sev2 =
                      Object.entries(reqInfo[0][1].Open).filter(
                        (k) => k.indexOf(s) > -1
                      )[0][1];
                  } else if (s === "Sev 3-Medium") {
                    projectTrackerData.defectSummary.openBug.sev3 =
                      Object.entries(reqInfo[0][1].Open).filter(
                        (k) => k.indexOf(s) > -1
                      )[0][1];
                  } else if (s === "Sev 4-Low") {
                    projectTrackerData.defectSummary.openBug.sev4 =
                      Object.entries(reqInfo[0][1].Open).filter(
                        (k) => k.indexOf(s) > -1
                      )[0][1];
                  }
                });
              }
            }
          } else if (
            allResponseData &&
            allResponseData.length > 0 &&
            allResponseData[0]?.data?.data.statusCode === 500
          ) {
            setErrorMessage(allResponseData[0]?.data?.data?.errMsg);
          }
          if (allResponseData[1]?.data && allResponseData[1]?.status === "OK") {
            let latestSprintTestExeData = Object.entries(
              allResponseData[1].data
            ).filter((k) => k.indexOf(item.projectName) > -1);
            if (latestSprintTestExeData && latestSprintTestExeData.length > 0) {
              projectTrackerData.latestSprintData.latestSprintName =
                Object.keys(latestSprintTestExeData[0][1])[0];

              var tempData = Object.values(
                Object.values(latestSprintTestExeData[0][1])[0]
              );
              projectTrackerData.latestSprintData.sprintStartDate = tempData[1];
              projectTrackerData.latestSprintData.sprintEndDate = tempData[2];
              Object.keys(tempData[0]).forEach((s) => {
                if (s === "Executed%") {
                  latestSprintTestExe.executedPercent = Object.entries(
                    tempData[0]
                  ).filter((k) => k.indexOf(s) > -1)[0][1];
                } else if (s === "Passed%") {
                  latestSprintTestExe.passedPercent = Object.entries(
                    tempData[0]
                  ).filter((k) => k.indexOf(s) > -1)[0][1];
                } else if (s === "Failed%") {
                  latestSprintTestExe.failedPercent = Object.entries(
                    tempData[0]
                  ).filter((k) => k.indexOf(s) > -1)[0][1];
                } else if (s === "Blocked%") {
                  latestSprintTestExe.blockedPercent = Object.entries(
                    tempData[0]
                  ).filter((k) => k.indexOf(s) > -1)[0][1];
                } else if (s === "Executed") {
                  latestSprintTestExe.executedCount = Object.entries(
                    tempData[0]
                  ).filter((k) => k.indexOf(s) > -1)[0][1];
                } else if (s === "Passed") {
                  latestSprintTestExe.passedCount = Object.entries(
                    tempData[0]
                  ).filter((k) => k.indexOf(s) > -1)[0][1];
                } else if (s === "Failed") {
                  latestSprintTestExe.failedCount = Object.entries(
                    tempData[0]
                  ).filter((k) => k.indexOf(s) > -1)[0][1];
                } else if (s === "Blocked") {
                  latestSprintTestExe.blockedCount = Object.entries(
                    tempData[0]
                  ).filter((k) => k.indexOf(s) > -1)[0][1];
                }
              });
              projectTrackerData.latestSprintData.latestSprintTestExeData =
                latestSprintTestExe;
            } else {
              projectTrackerData.latestSprintData.latestSprintTestExeData =
                latestSprintTestExe;
            }
          } else if (
            allResponseData[1]?.data &&
            allResponseData[1].data?.statusCode === 500
          ) {
            setErrorMessage(allResponseData[1]?.errMsg);
          }
          if (allResponseData[2]?.data && allResponseData[2]?.status === "OK") {
            let latestSprintTestCaseData = Object.entries(
              allResponseData[2].data
            ).filter((k) => k.indexOf(item.projectName) > -1);
            if (
              latestSprintTestCaseData &&
              latestSprintTestCaseData.length > 0
            ) {
              projectTrackerData.latestSprintData.latestSprintName =
                Object.keys(latestSprintTestCaseData[0][1])[0];
              latestSprintTestCaseState.baselined =
                Object.values(latestSprintTestCaseData[0][1])[0].Baselined ===
                undefined
                  ? 0
                  : Object.values(latestSprintTestCaseData[0][1])[0]
                      .Baselined === null
                  ? 0
                  : Object.values(latestSprintTestCaseData[0][1])[0].Baselined;
              latestSprintTestCaseState.new =
                Object.values(latestSprintTestCaseData[0][1])[0].New ===
                undefined
                  ? 0
                  : Object.values(latestSprintTestCaseData[0][1])[0].New ===
                    null
                  ? 0
                  : Object.values(latestSprintTestCaseData[0][1])[0].New;
              latestSprintTestCaseState.readyForBaseline =
                Object.values(latestSprintTestCaseData[0][1])[0]
                  .ReadyForBaseline === undefined
                  ? 0
                  : Object.values(latestSprintTestCaseData[0][1])[0]
                      .ReadyForBaseline === null
                  ? 0
                  : Object.values(latestSprintTestCaseData[0][1])[0]
                      .ReadyForBaseline;
              latestSprintTestCaseState.inProgress =
                Object.values(latestSprintTestCaseData[0][1])[0].InProgress ===
                undefined
                  ? 0
                  : Object.values(latestSprintTestCaseData[0][1])[0]
                      .InProgress === null
                  ? 0
                  : Object.values(latestSprintTestCaseData[0][1])[0].InProgress;
              projectTrackerData.latestSprintData.latestSprintTestCaseStateData =
                latestSprintTestCaseState;
            } else {
              projectTrackerData.latestSprintData.latestSprintTestCaseStateData =
                latestSprintTestCaseState;
            }
          } else if (
            allResponseData[2]?.data &&
            allResponseData[2]?.statusCode === 500
          ) {
            setErrorMessage(allResponseData[2]?.errMsg);
          }
          if (
            allResponseData &&
            allResponseData.length > 3 &&
            allResponseData[3]
          ) {
            let latestSprintProcessCompRunData = allResponseData[3].filter(
              (p) => p.projectName === item.projectName
            );
            if (
              latestSprintProcessCompRunData &&
              latestSprintProcessCompRunData.length > 0 &&
              latestSprintProcessCompRunData[0]?.status === "OK"
            ) {
              processComp.failedBlockedTestCasesWithoutDefects =
                latestSprintProcessCompRunData[0].data.testRunsFailedBlockedCount;
              processComp.testCasesNotRun =
                latestSprintProcessCompRunData[0].data.testRunsUnexecutedCount;
            } else if (
              latestSprintProcessCompRunData &&
              latestSprintProcessCompRunData[0].data?.statusCode === 500
            ) {
              setErrorMessage(latestSprintProcessCompRunData[0]?.errMsg);
            }
            if (
              allResponseData &&
              allResponseData.length > 4 &&
              allResponseData[4]
            ) {
              let latestSprintProcessCompTestCaseReqData =
                allResponseData[4].filter(
                  (p) => p.projectName === item.projectName
                );
              if (
                latestSprintProcessCompTestCaseReqData &&
                latestSprintProcessCompTestCaseReqData.length > 0 &&
                latestSprintProcessCompTestCaseReqData[0]?.status === "OK"
              ) {
                if (
                  latestSprintProcessCompTestCaseReqData[0]?.data
                    ?.testCasesWithOutRelatedRequirementsCount !== null
                ) {
                  processComp.reqWithoutTestCases =
                    latestSprintProcessCompTestCaseReqData[0].data.testCasesWithOutRelatedRequirementsCount;
                } else {
                  processComp.reqWithoutTestCases = 0;
                }
              } else if (
                latestSprintProcessCompTestCaseReqData &&
                latestSprintProcessCompTestCaseReqData[0]?.statusCode === 500
              ) {
                setErrorMessage(
                  latestSprintProcessCompTestCaseReqData[0]?.errMsg
                );
              }
            }
            projectTrackerData.processCompData = processComp;
          } else {
            projectTrackerData.processCompData = processComp;
          }
          projectTracker.push(projectTrackerData);
        });
        if (projectTracker && projectTracker.length > 0) {
          return projectTracker
            .sort((a, b) => (a.projectName > b.projectName ? 1 : -1))
            .map((dataItem) => {
              return (
                <tbody className="small" style={{ bgColor: "red" }}>
                  <ReactTooltip
                    place="left"
                    type="info"
                    effect="solid"
                    multiline="true"
                  />
                  <tr>
                    <td>
                      <span
                        data-tip={processDefectToolTip(
                          toolTipType.project,
                          dataItem
                        )}
                      >
                        {dataItem.projectName}
                      </span>
                    </td>
                    <td>
                      <span
                        data-tip={processDefectToolTip(
                          toolTipType.defect,
                          dataItem.defectSummary.createdBug
                        )}
                      >
                        {dataItem.defectSummary.createdBug.totalCount ===
                        undefined
                          ? 0
                          : dataItem.defectSummary.createdBug.totalCount}
                      </span>
                    </td>
                    <td>
                      <span
                        data-tip={processDefectToolTip(
                          toolTipType.defect,
                          dataItem.defectSummary.closedBug
                        )}
                      >
                        {dataItem.defectSummary.closedBug.totalCount ===
                        undefined
                          ? 0
                          : dataItem.defectSummary.closedBug.totalCount}
                      </span>
                    </td>
                    <td>
                      <span
                        data-tip={processDefectToolTip(
                          toolTipType.defect,
                          dataItem.defectSummary.openBug
                        )}
                      >
                        {dataItem.defectSummary.openBug.totalCount === undefined
                          ? 0
                          : dataItem.defectSummary.openBug.totalCount}
                      </span>
                    </td>

                    <td>
                      <span
                        data-tip={
                          "Start: " +
                          dataItem.latestSprintData.sprintStartDate +
                          "<br/>" +
                          "End: " +
                          dataItem.latestSprintData.sprintStartDate
                        }
                      >
                        {dataItem.latestSprintData.latestSprintName}
                      </span>
                    </td>

                    <td>
                      <span
                        data-tip={
                          dataItem?.latestSprintData?.latestSprintTestExeData
                            ?.executedCount
                        }
                      >
                        {
                          dataItem.latestSprintData.latestSprintTestExeData
                            .executedPercent
                        }
                      </span>
                      %
                    </td>
                    <td>
                      <span
                        data-tip={
                          dataItem.latestSprintData.latestSprintTestExeData
                            .passedCount
                        }
                      >
                        {
                          dataItem.latestSprintData.latestSprintTestExeData
                            .passedPercent
                        }
                      </span>
                      %
                    </td>
                    <td>
                      <span
                        data-tip={
                          dataItem.latestSprintData.latestSprintTestExeData
                            .failedCount
                        }
                      >
                        {
                          dataItem.latestSprintData.latestSprintTestExeData
                            .failedPercent
                        }
                      </span>
                      %
                    </td>
                    <td>
                      <span
                        data-tip={
                          dataItem.latestSprintData.latestSprintTestExeData
                            .blockedCount
                        }
                      >
                        {
                          dataItem.latestSprintData.latestSprintTestExeData
                            .blockedPercent
                        }
                      </span>
                      %
                    </td>

                    <td>
                      {
                        dataItem.latestSprintData.latestSprintTestCaseStateData
                          .new
                      }
                    </td>
                    <td>
                      {
                        dataItem.latestSprintData.latestSprintTestCaseStateData
                          .inProgress
                      }
                    </td>
                    <td>
                      {
                        dataItem.latestSprintData.latestSprintTestCaseStateData
                          .readyForBaseline
                      }
                    </td>
                    <td>
                      {
                        dataItem.latestSprintData.latestSprintTestCaseStateData
                          .baselined
                      }
                    </td>

                    <td>
                      <span
                        data-tip={
                          "Count of test cases from past sprints that were not run"
                        }
                      >
                        {dataItem.processCompData.testCasesNotRun}
                      </span>
                    </td>
                    <td>{dataItem.processCompData.reqWithoutTestCases}</td>
                    <td>
                      {
                        dataItem.processCompData
                          .failedBlockedTestCasesWithoutDefects
                      }
                    </td>
                  </tr>
                </tbody>
              );
            });
        }
      } else {
        return <div></div>;
      }
    } catch (err) {
      return "Failed to load project tracker data: " + err;
    }
  }

  function processDefectToolTip(type, dataItem) {
    if (dataItem && Object.keys(dataItem).length > 0) {
      let toolTip = "";
      if (type === toolTipType.defect) {
        if (dataItem.sev1 > 0) {
          toolTip = "Sev 1-Severe: " + dataItem.sev1;
        }
        if (dataItem.sev2 > 0) {
          toolTip = toolTip + "<br/>" + "Sev 2-High: " + dataItem.sev2;
        }
        if (dataItem.sev3 > 0) {
          toolTip = toolTip + "<br/>" + "Sev 3-Medium: " + dataItem.sev3;
        }
        if (dataItem.sev4 > 0) {
          toolTip = toolTip + "<br/>" + "Sev 4-Low: " + dataItem.sev4;
        }
      }
      if (type === toolTipType.project || type === toolTipType.sprint) {
        if (dataItem.projectStartDate !== "") {
          toolTip = "Start: " + dataItem.projectStartDate;
        }
        if (dataItem.projectEndDate !== "") {
          toolTip = toolTip + "<br/>" + "End: " + dataItem.projectEndDate;
        }
      }
      dataItem.toolTip = toolTip;
    }
    return dataItem.toolTip;
  }

  return (
    <div className="bg-light  container-fluid pt-3 pb-3">
      <h4 className="page-header">Project Tracker</h4>
      <ErrorMessage errorMessage={errorMessage} />
      <div className="" ng-show="true">
        <loading></loading>
        <div className="row">
          <div className="col-xs-12 col-sm-12">
            <div className="col-sm-2">
              <label
                htmlFor="projectTypes"
                className="col-md-4 col-sm-2 control-label text-right"
              >
                Program
              </label>
              <ProjectTypes
                props={localProps}
                handleChange={setSelectedProjectType}
                setErrorMessage={setErrorMessage}
              />
            </div>
            <div className="vspacer"></div>
            <div className="row ms-3 mt-2 me-3">
              <table
                id="projectTrackerTable"
                className="table table-bordered table-condensed table-responsive table-hover small"
              >
                <thead
                  className="table-primary"
                  style={{
                    backgroundColor: "rgb(233, 236, 239)",
                    color: "rgb(33, -37, 41)",
                    fontSize: 12,
                  }}
                >
                  <tr>
                    <th rowSpan="2">Projects</th>
                    <th colSpan="3">Defect Summary</th>
                    <th rowSpan="2">Latest Sprint</th>
                    <th colSpan="4">Latest Sprint Test Execution</th>
                    <th colSpan="4">Latest Sprint Test Case State</th>
                    <th colSpan="3">Process Compliance</th>
                  </tr>
                  <tr>
                    <th>Created</th>
                    <th>Closed</th>
                    <th>Open</th>
                    <th>Executed</th>
                    <th>Passed</th>
                    <th>Failed</th>
                    <th>Blocked</th>
                    <th>New</th>
                    <th>In Progress</th>
                    <th>Ready For Baseline</th>
                    <th>Baselined</th>

                    <th>Test Cases Not Run</th>
                    <th>Requirements Without Test Cases</th>
                    <th>Failed/Blocked Test Cases without Defects</th>
                  </tr>
                </thead>
                {selectedProjectType && loading ? (
                  <Loading />
                ) : (
                  <LatestSprintData />
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
