import React, { useState, useEffect } from "react";
import Loading from "../../common/Loading";
import ErrorMessage from "../../common/ErrorMessage";
import QTest from "../../../services/Qtest";
import useQtestInfo from "../../hooks/useQtestInfo";
import { Chart } from "react-google-charts";
import "../../css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";

async function getRequirementsStatInfo(token, projectId) {
  try {
    const resp = await QTest.getRequirementChartsInfo(token, projectId);
    return resp;
  } catch (err) {
    return { message: "Error connecting to API" };
  }
}

export default function ProcessComplianceRequirementChart(props) {
  const { requirementsStatInfo, setRequirementsStatInfo } = useQtestInfo();
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleRequirementsDetails = async (e) => {
      var currentProjectId = props.currentProjectId;
      var url = window.location.href;
      let res = /project\/(\d+)/g.exec(url);

      if (!currentProjectId) {
        if (res?.length > 1) {
          currentProjectId = res[1];
          props.setCurrentProjectId(currentProjectId);
        } else {
          setErrorMessage("Project is not selected!");
          setLoading(false);
          return;
        }
      }

      if (
        !requirementsStatInfo ||
        requirementsStatInfo.projectId !== currentProjectId
      ) {
        const response = await getRequirementsStatInfo(
          props.token,
          currentProjectId
        );
        if (response.errMsg) {
          setErrorMessage(response.errMsg);
          setRequirementsStatInfo(null);
        } else {
          if (!response?.Coverage) {
            setErrorMessage("Response does not contain any information!");
            setRequirementsStatInfo(null);
          } else {
            setErrorMessage("");
            setRequirementsStatInfo(response);
          }
        }
      } else {
        if (requirementsStatInfo) {
          if (!requirementsStatInfo?.Coverage) {
            setErrorMessage("Response does not contain any information!");
            setRequirementsStatInfo(null);
          }
        }
      }
      setLoading(false);
    };
    handleRequirementsDetails();
  }, [props.currentProjectId]);

  const RequirementsCoverageByTestCasesChart = () => {
    const coverageByTestCasesInfo = requirementsStatInfo;

    if (
      (!coverageByTestCasesInfo && !coverageByTestCasesInfo.Coverage) ||
      Object.keys(coverageByTestCasesInfo).length < 1
    ) {
      return (
        <div className="form-outline mt-4" id="error-message">
          <div className="alert alert-danger" role="alert">
            Data is empty!
          </div>
        </div>
      );
    }

    var data = [];
    data.push(
      ["Coverage", "Count"],
      [
        "Covered",
        Object.values(
          Object.values(coverageByTestCasesInfo.Coverage)[
            Object.values(coverageByTestCasesInfo.Coverage).length - 1
          ]
        )[0],
      ],
      [
        "Not Covered",
        Object.values(
          Object.values(coverageByTestCasesInfo.Coverage)[
            Object.values(coverageByTestCasesInfo.Coverage).length - 1
          ]
        )[1],
      ]
    );

    if (data.length < 2) {
      data.push(["Dates", 0, "", 0, ""]);
    }

    var options = {
      title: "Requirements Coverage by Test Cases",
      titleTextStyle: {
        fontSize: "16",
      },
      is3D: true,
      hAxis: { slantedText: true, textStyle: { fontSize: 11 } },
      vAxis: { format: "0" },
    };

    return (
      <Chart
        chartType="PieChart"
        width="100%"
        height="375px"
        data={data}
        options={options}
      />
    );
  };

  return (
    <div id="main_container">
      {loading ? (
        <Loading />
      ) : (
        <div>
          <ErrorMessage errorMessage={errorMessage} />
          {requirementsStatInfo && <RequirementsCoverageByTestCasesChart />}
        </div>
      )}
    </div>
  );
}
