import ReactModal from "react-modal";
import React, { useState } from "react";
import Admin from "../../../services/Admin";
import "../../css/bootstrap.min.css";
import "../../css/custom.min.css";
import "font-awesome/css/font-awesome.min.css";
import "font-awesome/css/font-awesome.css";

ReactModal.setAppElement("#root");

const styleDeleteModal = {
  content: {
    border: "4px",
    borderCcolor: "#000000",
    borderRadius: "4px",
    bottom: "auto",
    maxHeight: "20%",
    minHeight: "20rem",
    left: "50%",
    padding: "1rem",
    position: "fixed",
    right: "auto",
    top: "50%",
    transform: "translate(-50%,-50%)",
    minWidth: "10rem",
    width: "25%",
    maxWidth: "25rem",
    backgroundColor: "#e7eff8",
    boxShadow: "0 5px 15px rgba(0,0,0,.5)",
  },
};

async function deleteUser(credentials, userId) {
  try {
    return await Admin.deleteUser(credentials, userId);
  } catch (err) {
    return { message: err.message };
  }
}

export default function DeleteModal(props) {
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState("");
  const [deleteResult, setDeleteResult] = useState(true);

  const handleDeleteCloseClick = () => {
    props.setShowDeleteModal(false);
  };

  const handleDeleteConfirmClick = async () => {
    //props.setShowDeleteModal(false);
    props.setLoading(true);
    let response = await deleteUser(props.token, props.selectedUser.id);
    if (response?.message) {
      setDeleteMessage(response.message);
      setDeleteResult(false);
    } else {
      setDeleteMessage(
        "User " + props.selectedUser.username + " deleted successfully!"
      );
      setDeleteResult(true);
    }
    props.setLoading(false);

    setShowStatusModal(true);
    props.setToUpdateUsers(true);
  };

  const DeleteModalWindow = () => {
    if (!props.selectedUser?.id) {
      return null;
    }

    return (
      <ReactModal
        isOpen={props.showDeleteModal}
        shouldFocusAfterRender={true}
        dialogClassName="modal-dialog"
        shouldCloseOnOverlayClick={true}
        style={styleDeleteModal}
        scrollable={false}
        shouldCloseOnEsc={true}
        shouldReturnFocusAfterClose={true}
        onRequestClose={() => handleDeleteCloseClick()}
      >
        <div
          id="modal-header"
          className="modal-header"
          style={{ padding: "0", paddingBottom: "6px" }}
        >
          <div className="container d-flex align-items-center justify-content-center">
            <i className="fa fa-light fa-exclamation-triangle fa-3x text-danger"></i>
            <h5 className="modal-title ms-3" id="modalTitle">
              {"Are you sure?"}
            </h5>
          </div>
          <button
            type="button"
            className="btn btn-close btn-sm"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleDeleteCloseClick()}
          />
        </div>
        <div
          id="modal-body"
          className="modal-body"
          style={{
            minHeight: "10rem",
            maxHeight: "600px",
            overflowY: "auto",
            bottom: "0",
            top: "0",
            padding: "0",
          }}
        >
          <div className="mt-5 mb-0 container d-flex align-items-center justify-content-center">
            <span className="align-middle">
              {"Are you sure that you want to delete "}
              <i className="text-danger">{props.selectedUser["username"]}</i>
              {" user from Launch Pad?"}
            </span>
          </div>
        </div>
        <div className="modal-footer container d-flex align-items-center justify-content-center">
          <button
            className="btn  btn-secondary  btn-block ms-4 "
            onClick={() => handleDeleteCloseClick()}
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            className="btn  btn-danger  btn-block ms-4 "
            onClick={() => handleDeleteConfirmClick()}
            data-dismiss="modal"
          >
            Yes, delete it!
          </button>
        </div>
      </ReactModal>
    );
  };

  const StatusModalWindow = () => {
    const handleCloseClick = () => {
      setShowStatusModal(false);
      props.setShowDeleteModal(false);
    };

    const style = {
      content: {
        border: "4px",
        borderCcolor: "#000000",
        borderRadius: "4px",
        bottom: "auto",
        maxHeight: "30%",
        minHeight: "15rem",
        left: "50%",
        padding: "1rem",
        position: "fixed",
        right: "auto",
        top: "50%",
        transform: "translate(-50%,-50%)",
        minWidth: "10rem",
        //width: '20%',
        maxWidth: "30rem",
        backgroundColor: "#e7eff8",
        boxShadow: "0 5px 15px rgba(0,0,0,.5)",
      },
    };

    return (
      <ReactModal
        isOpen={showStatusModal}
        shouldFocusAfterRender={true}
        dialogClassName="modal-dialog"
        shouldCloseOnOverlayClick={true}
        style={style}
        scrollable={false}
        shouldCloseOnEsc={true}
        shouldReturnFocusAfterClose={true}
        onRequestClose={() => handleCloseClick()}
        overlayClassName="Overlay"
      >
        <div
          id="modal-header"
          className="modal-header"
          style={{ padding: "0", paddingBottom: "6px" }}
        >
          <div className="container d-flex align-items-center justify-content-center">
            <i
              className={
                "fa fa-exclamation fa-3x " +
                (deleteResult ? "text-success" : "text-danger")
              }
            ></i>
            <h5 className="modal-title ms-3" id="modalTitle">
              {deleteResult ? "User Deleted" : "Error"}
            </h5>
          </div>
          <button
            type="button"
            className="btn btn-close btn-sm"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleCloseClick()}
          />
        </div>
        <div
          id="modal-body"
          className="modal-body "
          style={{
            width: "100%",
            height: "100%",
            bottom: "0",
            top: "0",
            padding: "0",
          }}
        >
          <div className="mt-3 mb-3 container d-flex align-items-center justify-content-center overflow-auto align-middle ">
            {deleteMessage}
          </div>
        </div>
        <div className="modal-footer container d-flex align-items-center justify-content-center">
          <button
            className="btn btn-primary ms-4 mb-1"
            onClick={() => handleCloseClick()}
            data-dismiss="modal"
          >
            OK
          </button>
        </div>
      </ReactModal>
    );
  };

  return (
    <div>
      {/* <DeleteModalWindow /> */}
      {props.selectedUser?.id && (
        <ReactModal
          isOpen={props.showDeleteModal}
          shouldFocusAfterRender={true}
          dialogClassName="modal-dialog"
          shouldCloseOnOverlayClick={true}
          style={styleDeleteModal}
          scrollable={false}
          shouldCloseOnEsc={true}
          shouldReturnFocusAfterClose={true}
          onRequestClose={() => handleDeleteCloseClick()}
        >
          <div
            id="modal-header"
            className="modal-header"
            style={{ padding: "0", paddingBottom: "6px" }}
          >
            <div className="container d-flex align-items-center justify-content-center">
              <i className="fa fa-light fa-exclamation-triangle fa-3x text-danger"></i>
              <h5 className="modal-title ms-3" id="modalTitle">
                {"Are you sure?"}
              </h5>
            </div>
            <button
              type="button"
              className="btn btn-close btn-sm"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => handleDeleteCloseClick()}
            />
          </div>
          <div
            id="modal-body"
            className="modal-body"
            style={{
              minHeight: "10rem",
              maxHeight: "600px",
              overflowY: "auto",
              bottom: "0",
              top: "0",
              padding: "0",
            }}
          >
            <div className="mt-5 mb-0 container d-flex align-items-center justify-content-center">
              <span className="align-middle">
                {"Are you sure that you want to delete "}
                <i className="text-danger">{props.selectedUser["username"]}</i>
                {" user from Launch Pad?"}
              </span>
            </div>
          </div>
          <div className="modal-footer container d-flex align-items-center justify-content-center">
            <button
              className="btn  btn-secondary  btn-block ms-4 "
              onClick={() => handleDeleteCloseClick()}
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              className="btn  btn-danger  btn-block ms-4 "
              onClick={() => handleDeleteConfirmClick()}
              data-dismiss="modal"
            >
              Yes, delete it!
            </button>
          </div>
        </ReactModal>
      )}
      {showStatusModal && <StatusModalWindow />}
    </div>
  );
}
