import React, { useEffect } from "react";
import RequirementsCoverageCharts from "../../components/charts/requirementsCharts/RequirementsCoverageCharts";
import RequirementsCoverageTestCasesMapping from "../../components/charts/requirementsCharts/RequirementsCoverageTestCasesMapping";

const RequirementsCoveragePage = (props) => {
  useEffect(() => {
    return props.changeStyle("requirementsCoverage");
  }, [props.currentProjectId]);

  return (
    <div>
      <RequirementsCoverageCharts
        token={props.token}
        currentProjectId={props.currentProjectId}
        setCurrentProjectId={props.setCurrentProjectId}
      />
      <RequirementsCoverageTestCasesMapping
        token={props.token}
        currentProjectId={props.currentProjectId}
        setCurrentProjectId={props.setCurrentProjectId}
      />
    </div>
  );
};

export default RequirementsCoveragePage;
