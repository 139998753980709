import ReactModal from "react-modal";
import Admin from "../../../services/Admin";
import Projects from "../../../services/Projects";
import useProjects from "../../hooks/useProjects";
import useUserInfo from "../../hooks/useUserInfo";
import "../../css/bootstrap.min.css";
import "../../css/custom.min.css";
import "font-awesome/css/font-awesome.min.css";
import "font-awesome/css/font-awesome.css";

import React, { useState, useEffect } from "react";
ReactModal.setAppElement("#root");

const styleEditModal = {
  content: {
    border: "4px",
    borderCcolor: "#000000",
    borderRadius: "4px",
    bottom: "auto",
    maxHeight: "80%",
    //minHeight: '20rem',
    left: "50%",
    padding: "1rem",
    position: "fixed",
    right: "auto",
    top: "50%",
    transform: "translate(-50%,-50%)",
    minWidth: "10rem",
    width: "30%",
    maxWidth: "60rem",
    backgroundColor: "#e7eff8",
    boxShadow: "0 5px 15px rgba(0,0,0,.5)",
  },
};

const styleProjectTypesList = {
  "min-height": "10rem",
  "max-height": "95%",
  //"overflow-y": "auto"
};

async function getProjectTypes(token) {
  try {
    return await Projects.getProjectTypes(token);
  } catch (err) {
    return {
      message: "Project Types list was not received! (" + err.message + ")",
    };
  }
}

async function saveUser(credentials, body) {
  try {
    return await Admin.updateUser(credentials, body);
  } catch (err) {
    return {
      message:
        "User [" +
        body.username +
        "] was not saved! An error returned: (" +
        err.message +
        ")",
    };
  }
}

async function createUser(credentials, body) {
  try {
    return await Admin.createUser(credentials, body);
  } catch (err) {
    return {
      message:
        "User [" +
        body.username +
        "] was not created! An error returned: (" +
        err.message +
        ")",
    };
  }
}

export default function EditModal(props) {
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [saveMessage, setSaveMessage] = useState("");
  const [saveStatus, setSaveStatus] = useState(true);

  const [errorMessage, setErrorMessage] = useState("");

  const { loggedUser } = useUserInfo({});

  //Project Types
  const { projectTypes, setProjectTypes } = useProjects();

  //user details
  const [userName, setUserName] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [userProjectTypes, setUserProjectTypes] = useState([]);

  const [editUser, setEditUser] = useState({});
  const [isAllSelected, setIsAllSelected] = useState(false);

  useEffect(() => {
    const handleProjectTypes = async (e) => {
      if (!projectTypes || Object.entries(projectTypes).length === 0) {
        const response = await getProjectTypes(props.token);
        if (response.message) {
          setErrorMessage(response.message);
          setProjectTypes([]);
        } else {
          if (!response || response.length < 1) {
            setErrorMessage("Projects types list is empty!");
          }
          setProjectTypes(response);
        }
      }
    };

    handleProjectTypes();

    if (props.selectedUser?.id) {
      setEditUser(props.selectedUser);
      if (props.selectedUser.projectTypes) {
        setUserProjectTypes(props.selectedUser.projectTypes);
        let tmpUsersPrjTypes = props.selectedUser.projectTypes.filter(
          (item) => loggedUser.projectTypes.indexOf(item) >= 0
        );
        if (tmpUsersPrjTypes.length === loggedUser.projectTypes.length) {
          setIsAllSelected(true);
        }
      }
      setIsAdmin(props.selectedUser.admin);
      setUserName(props.selectedUser.username);
    }
  }, [props.selectedUser?.id]);

  const handleUserSave = async (e) => {
    const body = {
      id: props.selectedUser.id,
      username: userName,
      admin: isAdmin,
      projectTypes: userProjectTypes,
    };

    let response;
    let statusText = " created successfully!";

    if (!props.selectedUser.id) {
      response = await createUser(props.token, body);
    } else {
      statusText = " updated successfully!";
      response = await saveUser(props.token, body);
    }
    if (response.status === 200) {
      setSaveMessage("User " + response.username + statusText);
      setSaveStatus(true);
    } else {
      setSaveStatus(false);
      if (response.message) {
        setSaveMessage(response.message);
      } else {
        setSaveMessage("Error code: " + response.status + "");
      }
    }
    setShowStatusModal(true);
    props.setToUpdateUsers(true);
  };

  const handleCloseClick = () => {
    props.setShowEditModal(false);
  };

  const handleProjectTypeCheckBoxChange = (e, id, isAllSelectedValue) => {
    let tmp = JSON.parse(JSON.stringify(userProjectTypes));
    if (id === -1) {
      if (isAllSelectedValue) {
        loggedUser.projectTypes.forEach((item) => {
          if (tmp.indexOf(item) < 0) {
            tmp.push(item);
          }
        });
      } else {
        tmp = tmp.filter((item) => {
          return loggedUser.projectTypes.indexOf(item) < 0;
        });
      }
    } else {
      if (tmp.indexOf(id) < 0) {
        tmp.push(id);
      } else {
        tmp = tmp.filter((item) => {
          return item !== id;
        });
      }
    }
    setUserProjectTypes(tmp);
  };

  const handleIsAdminClick = (e) => {
    setIsAdmin(e.target.checked);
  };

  const StatusModalWindow = () => {
    const handleCloseClick = () => {
      setShowStatusModal(false);
      if (saveStatus) {
        props.setShowEditModal(false);
      }
    };

    const style = {
      content: {
        border: "4px",
        borderCcolor: "#000000",
        borderRadius: "4px",
        bottom: "auto",
        maxHeight: "30%",
        minHeight: "15rem",
        left: "50%",
        padding: "1rem",
        position: "fixed",
        right: "auto",
        top: "50%",
        transform: "translate(-50%,-50%)",
        minWidth: "10rem",
        //width: '20%',
        maxWidth: "30rem",
        backgroundColor: "#e7eff8",
        boxShadow: "0 5px 15px rgba(0,0,0,.5)",
        overflow: "hidden",
      },
    };

    return (
      <ReactModal
        isOpen={showStatusModal}
        shouldFocusAfterRender={true}
        //dialogClassName="modal-dialog"
        shouldCloseOnOverlayClick={true}
        style={style}
        scrollable={false}
        shouldCloseOnEsc={true}
        shouldReturnFocusAfterClose={true}
        onRequestClose={() => handleCloseClick()}
        //overlayClassName="Overlay"
      >
        <div
          id="modal-header"
          className="modal-header"
          style={{ padding: "0", paddingBottom: "6px" }}
        >
          <div className="container d-flex align-items-center justify-content-center">
            <i
              className={
                "fa fa-exclamation fa-3x " +
                (saveStatus ? "text-success" : "text-danger")
              }
            ></i>
            <h5 className="modal-title ms-3" id="modalTitle">
              {saveStatus ? "User Updated" : "Error"}
            </h5>
          </div>
          <button
            type="button"
            className="btn btn-close btn-sm"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleCloseClick()}
          />
        </div>
        <div
          id="modal-body"
          className="modal-body "
          style={{
            width: "100%",
            height: "100%",
            bottom: "0",
            top: "0",
            padding: "0",
            overflow: "hidden",
          }}
        >
          <div className="mt-3 mb-3 container d-flex align-items-center justify-content-center overflow-auto align-middle ">
            {saveMessage}
          </div>
        </div>
        <div className="modal-footer container d-flex align-items-center justify-content-center">
          <button
            className="btn btn-primary ms-4 mb-1"
            onClick={() => handleCloseClick()}
            data-dismiss="modal"
          >
            OK
          </button>
        </div>
      </ReactModal>
    );
  };

  return (
    <div>
      <ReactModal
        isOpen={props.showEditModal}
        shouldFocusAfterRender={true}
        dialogClassName="modal-dialog"
        shouldCloseOnOverlayClick={true}
        style={styleEditModal}
        scrollable={false}
        shouldCloseOnEsc={true}
        shouldReturnFocusAfterClose={true}
        onRequestClose={() => handleCloseClick()}
      >
        <div
          id="modal-header"
          className="modal-header"
          style={{ padding: "0", paddingBottom: "6px" }}
        >
          <div className="container d-flex align-items-center justify-content-center">
            <h5 className="modal-title ms-3" id="modalTitleEdit">
              User Settings
            </h5>
          </div>
          <button
            type="button"
            className="btn btn-close btn-sm"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleCloseClick()}
          />
        </div>
        <div
          id="modal-body"
          className="modal-body"
          style={{
            // minHeight: "10rem",
            // maxHeight: "600px",
            //overflowY: 'auto',
            bottom: "0",
            top: "0",
            padding: "0",
          }}
        >
          <div className="row mb-2">
            <div className="col-md-6">
              <label htmlFor="userName" className="form-label fs-7 mb-1">
                User Name:
              </label>
              <input
                type="text"
                id="userName"
                key="userName"
                className="form-control form-control-sm"
                placeholder="User Name"
                onChange={(e) => {
                  e.preventDefault();
                  setUserName(e.target.value);
                }}
                defaultValue={userName}
                autoComplete="on"
              />
              <div className="text-danger small ms-1">
                User name should be exactly as in LDAP
              </div>
            </div>
            <div className="col-sm-2">
              <label htmlFor="userId" className="form-label fs-7 mb-1">
                User Id:
              </label>
              <input
                type="text"
                id="userId"
                className="form-control form-control-sm"
                placeholder=""
                disabled
                defaultValue={editUser?.id}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-4">
              <label htmlFor="isAdmin" className="form-check-label fs-7 mb-1">
                Admin:
              </label>
              <input
                type="checkbox"
                className="form-check-input ml-4 ms-2"
                id="isAdmin"
                defaultChecked={isAdmin}
                onChange={handleIsAdminClick}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-10 w-100">
              <label
                htmlFor="project_type_select"
                className="form-check-label fs-7 mb-1"
              >
                Project Types:
              </label>
              {errorMessage && (
                <div className="form-outline mt-4">
                  <div className="alert alert-danger" role="alert">
                    {errorMessage}
                  </div>
                </div>
              )}
              <div
                className="p-3 mb-2 bg-light overflow-auto"
                style={styleProjectTypesList}
              >
                <ul className="list-unstyled">
                  <li>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        setIsAllSelected(!isAllSelected);
                        handleProjectTypeCheckBoxChange(e, -1, !isAllSelected);
                      }}
                    >
                      <input
                        type="checkbox"
                        className="me-2 mb-0"
                        checked={isAllSelected}
                        key={-1}
                      ></input>
                      Select All
                    </div>
                  </li>
                  <hr className="hr" />
                  {projectTypes &&
                    projectTypes.map((projectType, index) => {
                      if (
                        loggedUser.projectTypes?.indexOf(
                          projectType.projectTypeId
                        ) >= 0
                      ) {
                        return (
                          <li>
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={(e) =>
                                handleProjectTypeCheckBoxChange(
                                  e,
                                  projectType.projectTypeId
                                )
                              }
                            >
                              <input
                                type="checkbox"
                                className="me-2 mb-0"
                                checked={
                                  userProjectTypes.indexOf(
                                    projectType.projectTypeId
                                  ) >= 0
                                }
                                key={index}
                              ></input>
                              {projectType.projectTypeName}
                            </div>
                          </li>
                        );
                      }
                      return null;
                    })}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="modal-footer container d-flex align-items-center justify-content-center">
          <button
            className="btn  btn-secondary  btn-block ms-4 "
            onClick={() => handleCloseClick()}
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            className="btn  btn-primary  btn-block ms-4 "
            onClick={() => handleUserSave()}
            data-dismiss="modal"
          >
            Save
          </button>
        </div>
      </ReactModal>
      {showStatusModal && <StatusModalWindow />}
    </div>
  );
}
