import React, { useRef } from "react";
import { Chart } from "react-google-charts";
import "../../css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";

var getTooltipBySeverities = function (date, dataMap, name) {
  var tooltip =
    '<div align="left" style="width: auto; float: left; padding:5px 5px 5px 5px;">' +
    '<table class="table table-hover table-condensed table-sm" >' +
    "<thead><tr><th>" +
    date +
    "</th></tr></thead>" +
    "<tbody>";
  for (let sev in dataMap) {
    if (sev !== "All") {
      tooltip = tooltip + "<tr><td>" + sev + ": " + dataMap[sev] + "</td></tr>";
    } else {
      tooltip =
        tooltip +
        "<tr><td>" +
        name +
        " Total: <b>" +
        dataMap[sev] +
        "</b></td></tr>";
    }
  }
  tooltip = tooltip + "</tbody></table></div>";
  return tooltip;
};

const prepareDataForTooltipBySeverities = function (dataMap, date, status) {
  let resDataMap = {};

  for (let severity in dataMap) {
    let value;
    if (dataMap[severity] && dataMap[severity]) {
      value = dataMap[severity].length;
    }
    value = value ? value : 0;
    resDataMap[severity] = value;
  }

  resDataMap = Object.keys(resDataMap)
    .sort()
    .reduce((obj, key) => {
      obj[key] = resDataMap[key];
      return obj;
    }, {});

  return resDataMap;
};

export default function OpenClosedDefectsChart(props) {
  const closureRateRef = useRef();
  const currentIterationInfoRef = useRef();
  const currentIssueMapRef = useRef();
  const currentDataTableRef = useRef();

  var statuses = ["Created", "Closed", "Open"];
  var defectTitles = [
    "Issue ID",
    "Issue Summary",
    "Status",
    "Resolution",
    "Severity",
  ];

  const getValue = (value) => {
    return value ? value.length : 0;
  };
  currentIssueMapRef.current =
    props.isADO === "ADO"
      ? props.jiraChartsInfo?.defectAgeChartMap?.data?.issueMap
      : props.jiraChartsInfo?.issueMap;
  var notSortedOpenClosedDefects =
    props.jiraSteercoDefectsInfo?.steerCoOpenClosedDefectsBySev;
  var currentIterationDataObj = notSortedOpenClosedDefects
    ? notSortedOpenClosedDefects[props.currentIteration]
    : null;
  notSortedOpenClosedDefects = currentIterationDataObj
    ? currentIterationDataObj
    : null;
  currentIterationInfoRef.current = currentIterationDataObj;

  let col_max = 0;

  let columnArray = ["Dates", "Created", "Closed", "Open"];
  let valEmptyArray = ["Dates", 0, "", 0, "", 0, ""];

  let currentIterationForOpendClosedDefects = {};
  if (notSortedOpenClosedDefects) {
    let sorted = Object.keys(notSortedOpenClosedDefects).sort(function (a, b) {
      return new Date(a).getTime() - new Date(b).getTime();
    });
    sorted.forEach(function (key) {
      currentIterationForOpendClosedDefects[key] =
        notSortedOpenClosedDefects[key];
    });
  }

  var dataTable = [];
  var header = ["Dates"];
  for (var i = 1; i < columnArray.length; i++) {
    header.push(columnArray[i]);
    header.push({ type: "string", role: "tooltip", p: { html: true } });
  }
  dataTable.push(header);

  const datesArr = Object.keys(currentIterationForOpendClosedDefects);
  var isStatEmpty = true;

  for (let date of datesArr) {
    isStatEmpty = false;
    var currentDateStatObj = currentIterationForOpendClosedDefects[date];
    let tempStatObj = {};
    for (let status of statuses) {
      tempStatObj[status] = getValue(currentDateStatObj[status]["All"]);

      if (col_max < tempStatObj[status]) {
        col_max = tempStatObj[status];
      }
    }
    let tooltipDataCreated = prepareDataForTooltipBySeverities(
      currentDateStatObj["Created"],
      date,
      "Created"
    );
    let tooltipDataClosed = prepareDataForTooltipBySeverities(
      currentDateStatObj["Closed"],
      date,
      "Closed"
    );
    let tooltipDataOpen = prepareDataForTooltipBySeverities(
      currentDateStatObj["Open"],
      date,
      "Open"
    );

    var dataArray = [
      date,
      tempStatObj.Created,
      getTooltipBySeverities(date, tooltipDataCreated, "Created"),
      tempStatObj.Closed,
      getTooltipBySeverities(date, tooltipDataClosed, "Closed"),
      tempStatObj.Open,
      getTooltipBySeverities(date, tooltipDataOpen, "Open"),
    ];
    dataTable.push(dataArray);
  }

  //calculate closureRate for the last date
  if (datesArr && datesArr.length > 0) {
    let lastDate = datesArr[datesArr.length - 1];
    let lastDateObj = currentIterationForOpendClosedDefects[lastDate];
    let closureRateValue =
      (
        (parseInt(getValue(lastDateObj.Closed.All)) /
          parseInt(getValue(lastDateObj.Created.All))) *
        100
      ).toFixed(2) + "%";
    closureRateRef.current = closureRateValue;
  } else {
    closureRateRef.current = "0.00 %";
  }

  // set default value if no results received from server
  if (isStatEmpty) {
    dataTable.push(valEmptyArray);
  }

  var data = dataTable;
  currentDataTableRef.current = dataTable;

  var options = {
    title: "Open & Closed Defects",
    titleTextStyle: { fontSize: "16" },
    hAxis: {
      slantedText: true,
      slantedTextAngle: 45,
      textStyle: { fontSize: 11 },
    },
    vAxis: { format: "#" },
    gridlines: { count: -1 },
    chartArea: { left: 70, top: 50, width: "65%", height: "65%" },
    tooltip: { isHtml: true },
  };

  if (col_max < 5) {
    options.vAxis = { format: "#", viewWindow: { max: 4 } };
  } else {
    options.vAxis = { format: "#", viewWindow: { max: col_max + 1 } };
  }

  var chartEvents = [
    {
      eventName: "ready",
      callback: ({ chartWrapper, google }) => {
        var handler = async function (e) {
          const chart = chartWrapper.getChart();
          var selection = chart.getSelection();
          if (!selection || selection.length < 1) {
            return;
          }
          var row = selection[0].row;
          var col = selection[0].column;

          var selectedStatus = currentDataTableRef.current[0][col];
          var selectedDate = currentDataTableRef.current[row + 1][0];

          var defectsIdsArray = currentIterationInfoRef.current?.[selectedDate][
            selectedStatus
          ]?.["All"]
            ? currentIterationInfoRef.current[selectedDate][selectedStatus][
                "All"
              ]
            : [];
          var defectsInfoArray = [];
          Object.keys(currentIssueMapRef.current).forEach((issueId) => {
            if (defectsIdsArray?.indexOf(issueId) >= 0) {
              defectsInfoArray.push(currentIssueMapRef.current[issueId]);
            }
          });

          props.setModalData({
            title: selectedDate + ": " + selectedStatus,
            projectId: props.currentProjectId,
            createdDt: selectedDate,
            releaseName: null,
            status: selectedStatus,
            severity: null,
            tableTitles: defectTitles,
            defects: defectsInfoArray,
          });
          if (defectsInfoArray.length < 1) {
            props.setShowModal(false);
          } else {
            props.setShowModal(true);
          }
        };

        google.visualization.events.addListener(
          chartWrapper.getChart(),
          "select",
          handler
        );
      },
    },
  ];

  return (
    <div className="bg-white w-100 me-5 pe-5 pt-1">
      <div className="w-100 text-primary text-end me-5 mt-4">
        Closure Rate: {closureRateRef.current}
      </div>
      <Chart
        chartType="LineChart"
        width="100%"
        height="400px"
        data={data}
        options={options}
        chartEvents={chartEvents}
      />
    </div>
  );
}
