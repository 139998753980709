import { useState } from "react";

export default function useProjects() {
  const getProjectsTypes = () => {
    const projectTypesStr = localStorage.getItem("projectTypes");
    if (projectTypesStr === "undefined" || projectTypesStr === "{}") {
      return null;
    }
    const projectTypes = JSON.parse(projectTypesStr);
    return projectTypes;
  };

  const [projectTypes, setProjectTypes] = useState(getProjectsTypes());

  const saveProjectsTypes = (projectTypes) => {
    localStorage.setItem("projectTypes", JSON.stringify(projectTypes));
    setProjectTypes(projectTypes);
  };

  const getSelectedProjectType = () => {
    const projectTypeStr = localStorage.getItem("selectedProjectTypeId");
    if (projectTypeStr === "undefined" || projectTypeStr === "") {
      return "ALL";
    }
    return JSON.parse(projectTypeStr);
  };
  const [selectedProjectType, setSelectedProjectType] = useState(
    getSelectedProjectType()
  );

  const saveSelectedProjectType = (projectType) => {
    localStorage.setItem("selectedProjectTypeId", JSON.stringify(projectType));
    setSelectedProjectType(projectType);
  };

  const getProjects = () => {
    const projectsStr = localStorage.getItem("projects");
    if (projectsStr === "undefined" || projectsStr === "{}") {
      return null;
    }
    const projects = JSON.parse(projectsStr);
    return projects;
  };

  const [projects, setProjects] = useState(getProjects());

  const saveProjects = (projects) => {
    localStorage.setItem("projects", JSON.stringify(projects));
    setProjects(projects);
  };

  const getActiveProjects = () => {
    const projectsStr = localStorage.getItem("activeProjects");
    if (projectsStr === "undefined" || projectsStr === "{}") {
      return null;
    }
    const projects = JSON.parse(projectsStr);
    return projects;
  };

  const [activeProjects, setActiveProjects] = useState(getActiveProjects());

  const saveActiveProjects = (projects) => {
    localStorage.setItem("activeProjects", JSON.stringify(projects));
    setActiveProjects(projects);
  };

  const getInactiveProjects = () => {
    const projectsStr = localStorage.getItem("inactiveProjects");
    if (projectsStr === "undefined" || projectsStr === "{}") {
      return null;
    }
    const projects = JSON.parse(projectsStr);
    return projects;
  };

  const [inactiveProjects, setInactiveProjects] = useState(
    getInactiveProjects()
  );

  const saveInactiveProjects = (projects) => {
    localStorage.setItem("inactiveProjects", JSON.stringify(projects));
    setInactiveProjects(projects);
  };

  const getToRefreshProjects = () => {
    const str = localStorage.getItem("toRefreshProjects");
    if (str === "undefined" || str === "{}") {
      return null;
    }
    const obj = JSON.parse(str);
    return obj;
  };

  const [toRefreshProjects, setToRefreshProjects] = useState(
    getToRefreshProjects()
  );

  const saveToRefreshProjects = (value) => {
    localStorage.setItem("toRefreshProjects", JSON.stringify(value));
    setToRefreshProjects(value);
  };

  const getToRefreshActiveProjects = () => {
    const str = localStorage.getItem("toRefreshActiveProjects");
    if (str === "undefined" || str === "{}") {
      return null;
    }
    const obj = JSON.parse(str);
    return obj;
  };

  const [toRefreshActiveProjects, setToRefreshActiveProjects] = useState(
    getToRefreshActiveProjects()
  );

  const saveToRefreshActiveProjects = (value) => {
    localStorage.setItem("toRefreshActiveProjects", JSON.stringify(value));
    setToRefreshActiveProjects(value);
  };

  const getToRefreshInactiveProjects = () => {
    const str = localStorage.getItem("toRefreshInactiveProjects");
    if (str === "undefined" || str === "{}") {
      return null;
    }
    const obj = JSON.parse(str);
    return obj;
  };

  const [toRefreshInactiveProjects, setToRefreshInactiveProjects] = useState(
    getToRefreshInactiveProjects()
  );

  const saveToRefreshInactiveProjects = (value) => {
    localStorage.setItem("toRefreshInactiveProjects", JSON.stringify(value));
    setToRefreshInactiveProjects(value);
  };

  const getProjectsByProjectNameInfo = () => {
    const str = localStorage.getItem("ProjectsByProjectNameInfo");
    if (str === "undefined" || str === "{}") {
      return null;
    }
    const obj = JSON.parse(str);
    return obj;
  };

  const [projectsByProjectNameInfo, setProjectsByProjectNameInfo] = useState(
    getProjectsByProjectNameInfo()
  );

  const saveProjectsByProjectNameInfo = (value) => {
    localStorage.setItem("ProjectsByProjectNameInfo", JSON.stringify(value));
    setProjectsByProjectNameInfo(value);
  };

  return {
    setProjectTypes: saveProjectsTypes,
    setSelectedProjectType: saveSelectedProjectType,
    setProjects: saveProjects,
    setProjectsByProjectNameInfo: saveProjectsByProjectNameInfo,
    getProjects,
    getActiveProjects,
    setActiveProjects: saveActiveProjects,
    getInactiveProjects,
    setInactiveProjects: saveInactiveProjects,
    setToRefreshProjects: saveToRefreshProjects,
    setToRefreshActiveProjects: saveToRefreshActiveProjects,
    setToRefreshInactiveProjects: saveToRefreshInactiveProjects,
    toRefreshProjects,
    toRefreshActiveProjects,
    toRefreshInactiveProjects,
    projectTypes,
    selectedProjectType,
    projects,
    activeProjects,
    inactiveProjects,
    projectsByProjectNameInfo,
  };
}
