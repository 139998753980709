import React from "react";
import "../css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";

function ErrorMessage(props) {
  if (props.errorMessage) {
    return (
      <div className="form-outline mt-4" id="error-message">
        <div className="alert alert-danger" role="alert">
          {props.errorMessage}
        </div>
      </div>
    );
  } else {
    return <div className="form-outline mt-0" id="error-message" height="0" />;
  }
}

export default ErrorMessage;
