import React, { useEffect, useState } from "react";
import Jira from "../../../services/Jira";
import Loading from "../../common/Loading";
import MultiRangeSlider from "multi-range-slider-react";
import { Chart } from "react-google-charts";

const getRequirementStatus = async (token, id) => {
  try {
    const finalData = await Jira.getRequirementsStatusTrendingIfo(
      token,
      id
    ).then((res) => res);
    return finalData.reqStatusTrendingData;
  } catch (err) {
    return {
      errMsg: err.message ? err.message : JSON.stringify(err),
    };
  }
};

const RequirementsStatusTrendingChart = ({ token, currentProjectId }) => {
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState("");
  const [data, setData] = useState([]);
  const [listOfDates, setListOfDates] = useState([]);
  const [selectedMinDate, setSelectedMinDate] = useState(0);
  const [selectedMaxDate, setSelectedMaxDate] = useState(1);
  const [selectedMinRange, setSelectedMinRange] = useState("");
  const [selectedMaxRange, setSelectedMaxRange] = useState("");

  useEffect(() => {
    const handleRequirementStatusInfo = async () => {
      setLoading(true);
      const res = await getRequirementStatus(token, currentProjectId);
      setResponse(res);
      setLoading(false);
    };
    handleRequirementStatusInfo();
  }, []);

  useEffect(() => {
    let dates = Object.entries(response);
    let keysToMap = [];
    let headerToMap = [];
    let tooltipToMap = { type: "string", role: "tooltip", p: { html: true } };

    dates.forEach((date) => {
      keysToMap = Object.keys(date[1]);
    });

    keysToMap.map((key, index) => {
      if (index === 0) {
        headerToMap.push("Date");
      }
      headerToMap.push(key, tooltipToMap);
    });

    let tempList = [];
    if (response) {
      tempList = Object.values(response);
    }
    if (selectedMaxDate <= 1) {
      setSelectedMaxDate(tempList.length);
    }

    const listOfDates = Object.keys(response);
    setListOfDates(listOfDates);

    let dataToMap = [];
    Object.entries(response).forEach((element, index) => {
      const listOfValues = Object.values(element[1]);
      const listOfKeys = Object.keys(element[1]);

      dataToMap.push([element[0]]);

      listOfValues.forEach((value, valueIndex) => {
        dataToMap[index].push(
          value,
          getTooltip(element[0], listOfKeys[valueIndex], value, listOfKeys)
        );
      });
    });

    const filteredlist = dataToMap.slice(selectedMinDate, selectedMaxDate);

    const date = (s) => {
      const [month, day, year] = s.split("/");
      return new Date(year, month - 1, day);
    };
    const sortedData = filteredlist.sort((a, b) => {
      return date(a[0]) > date(b[0]) ? 1 : -1;
    });

    filteredlist.unshift(headerToMap);
    setData(sortedData);

    if (sortedData[sortedData.length - 1] && sortedData[selectedMinDate + 1]) {
      setSelectedMaxRange(sortedData[sortedData.length - 1][0]);
      setSelectedMinRange(sortedData[selectedMinDate + 1][0]);
    }
  }, [response, selectedMaxDate, selectedMinDate]);

  const getTooltip = (date, name, value, statusesKeys) => {
    let statuses = { ...statusesKeys };

    statusesKeys.forEach((status, index) => {
      statuses[status] = status;
      delete statuses[index];
    });

    var tooltip =
      '<div align="left" style="width: auto; float: left; table-layout: auto">' +
      '<table class="table table-hover table-condensed table-sm" >' +
      "<thead><tr><th>" +
      date +
      "</th></tr></thead>" +
      "<tbody>";

    for (let statValue in statuses) {
      if (statuses[statValue] === name) {
        tooltip = tooltip + '<tr class="table-danger"><td><b>' + name + "</b>";
      } else {
        tooltip = tooltip + "<tr><td>" + statuses[statValue];
      }
      tooltip =
        tooltip +
        ": <b>" +
        Math.abs(statuses[statValue] === name ? value : 0) +
        "</b></td></tr>";
    }
    tooltip = tooltip + "</tbody></table></div>";

    return tooltip;
  };

  const options = {
    title: "Requirement Status Trending",
    hAxis: {
      titleTextStyle: { color: "#333" },
    },
    vAxis: { minValue: 0 },
    tooltip: { isHtml: true },
    chartArea: { width: "80%", height: "70%" },
  };

  const Slider = () => {
    const handleInput = (e) => {
      setSelectedMinDate(e.minValue);
      setSelectedMaxDate(e.maxValue);
    };

    return (
      <MultiRangeSlider
        min={0}
        max={listOfDates.length}
        step={1}
        ruler={false}
        label={false}
        preventWheel={false}
        minValue={selectedMinDate}
        maxValue={selectedMaxDate}
        onInput={(e) => {
          handleInput(e);
        }}
      />
    );
  };

  return (
    <div className="mb-5">
      <h4>Requirements Status Trending</h4>
      {loading ? (
        <Loading />
      ) : data.length >= 1 ? (
        <div>
          <Slider className="mt-4" />
          <p>Selected dates: {selectedMinRange + " - " + selectedMaxRange}</p>
          <Chart
            chartType="AreaChart"
            width="100%"
            height="400px"
            data={data}
            options={options}
          />
        </div>
      ) : (
        <div className="form-outline mt-4 w-25" id="error-message">
          <div className="alert alert-danger" role="alert">
            Data is empty!
          </div>
        </div>
      )}
    </div>
  );
};

export default RequirementsStatusTrendingChart;
