import { Chart } from "react-google-charts";

const getDefAnalyticsTooltip = (environment, id, values, names) => {
  let tooltip =
    '<div align="left" style="width: auto; float: left; table-layout: auto">' +
    '<table class="table table-hover table-condensed table-sm" >' +
    "<thead><tr><th>" +
    environment +
    "</th></tr></thead>" +
    "<tbody>";

  for (let value in values) {
    let name = names[value] ? names[value] : value;
    if (value === id) {
      tooltip = tooltip + '<tr class="table-danger"><td><b>' + name + "</b>";
    } else {
      tooltip = tooltip + "<tr><td>" + name;
    }
    tooltip = tooltip + ": <b>" + Math.abs(values[value]) + "</b></td></tr>";
  }
  tooltip = tooltip + "</tbody></table></div>";

  return tooltip;
};

const getxAxisStyle = (tableLength) => {
  let vAxisLabelStyle = {};
  let hasNumberOfXLabelsLarge = tableLength > 5;

  if (hasNumberOfXLabelsLarge) {
    vAxisLabelStyle = {
      slantedTextAngle: 60,
      textStyle: { fontSize: "10" },
    };
  } else {
    vAxisLabelStyle = {
      textStyle: { fontSize: "11" },
    };
  }

  return vAxisLabelStyle;
};

export default function EnvironmentsChart(props) {
  const projectsData = props.chartsInfo ? props.chartsInfo : [];
  let prNames = { 0: "Total" };
  let environmentMap = {};
  let dataTable = [];
  let headersArr = ["Discovered By"];

  if (!projectsData || Object.keys(projectsData).length === 0) {
    dataTable.push([
      "Project",
      "Environments",
      { type: "string", role: "tooltip" },
    ]);
    dataTable.push(["Environments", 0, ""]);
  } else {
    for (let projectId in projectsData) {
      if (projectId == 0) {
        continue;
      }

      let projectName = props.projects?.filter((item) => {
        return item.projectId + "" === projectId;
      })[0]?.projectName;
      projectName = projectName ? projectName : "undefined";

      headersArr.push(projectName);
      headersArr.push({ type: "string", role: "tooltip", p: { html: true } });
    }

    dataTable.push(headersArr);

    for (let projectId in projectsData) {
      if (projectId == 0) {
        continue;
      }

      let projectName = props.projects?.filter((item) => {
        return item.projectId + "" === projectId;
      })[0]?.projectName;
      projectName = projectName ? projectName : "undefined";

      let environmentStatMap = projectsData[projectId].environmentMap;

      if (!(projectId in prNames)) {
        prNames[projectId] = projectName;
      }

      for (let environment in environmentStatMap) {
        if (!environmentMap[environment]) {
          environmentMap[environment] = [];
          environmentMap[environment][0] = 0;
        }
        environmentMap[environment][projectId] =
          environmentStatMap[environment];
        environmentMap[environment][0] =
          environmentMap[environment][0] + environmentStatMap[environment];
      }
    }

    for (let environment in environmentMap) {
      var dataArray = [];
      dataArray.push(environment);
      let tempMap = environmentMap[environment];

      for (let prID in prNames) {
        if (prID != 0) {
          let count = tempMap[prID] ? tempMap[prID] : 0;
          dataArray.push(count);
          var tooltip = getDefAnalyticsTooltip(
            environment,
            prID,
            tempMap,
            prNames
          );
          dataArray.push(tooltip);
        }
      }
      dataTable.push(dataArray);
    }
  }

  const options = {
    title: "Environments",
    titleTextStyle: { fontSize: "16" },
    bars: "vertical",
    isStacked: true,
    vAxis: { format: "0" },
    hAxis: getxAxisStyle(dataTable.length),
    chartArea: { left: 70, top: 50, width: "65%", height: "65%" },
    tooltip: { isHtml: true },
  };

  return (
    <div>
      <Chart
        chartType="ColumnChart"
        width="100%"
        height="400px"
        data={dataTable}
        options={options}
      />
    </div>
  );
}
