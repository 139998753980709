import { parse, valid } from "node-html-parser";

class FetchUtils {
  static fetch = (url, method, headers, body) => {
    return fetch(url, {
      method: method,
      headers: headers,
      body: body,
    })
      .then((response) => {
        let parsed = validateResponse(response);
        return parsed;
      })
      .catch((err) => {
        return {
          message: "Error appeared: " + err.message,
        };
      });
  };
}

let validateResponse = (response) => {
  var responseCopy = response.clone();
  let commonRespObj = {
    status: response.status,
    statusCode: response.statusCode ? response.statusCode : response.status,
    statusText: response.statusText,
    message:
      "Response cannot be parsed. Try to refresh a page or contact Administrator.",
  };

  if (commonRespObj.statusCode == "403") {
    commonRespObj.message =
      "Your session has expired, please log out and then log in again.";
    return commonRespObj;
  }

  return response
    .json()
    .then((json) => {
      json.status = json.status ? json.status : response.status;
      return json;
    })
    .catch((err) => {
      if (err instanceof SyntaxError) {
        return responseCopy
          .text()
          .then((text) => {
            commonRespObj.message = text;

            //try to parse text as HTML
            try {
              if (valid(text)) {
                const htmlElm = parse(text);
                let title = htmlElm.querySelector("title").innerText;
                if (title) {
                  commonRespObj.message = title;
                }
              }
            } catch (err) {
              return null;
            }
            return commonRespObj;
          })
          .catch((err) => {
            return commonRespObj;
          });
      }
      return commonRespObj;
    });
};

export default FetchUtils;
