// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `thead button {
    border: 0;
    border-radius: none;
    font-family: inherit;
    font-weight: 700;
    font-size: inherit;
    padding: 0.5em;
    margin-bottom: 1px;
  }
  
  thead button.ascending::after {
    content: '↓';
    display: inline-block;
    margin-left: 1em;
  }
  
  thead button.descending::after {
    content: '↑';
    display: inline-block;
    margin-left: 1em;
  }`, "",{"version":3,"sources":["webpack://./src/components/css/styles.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,mBAAmB;IACnB,oBAAoB;IACpB,gBAAgB;IAChB,kBAAkB;IAClB,cAAc;IACd,kBAAkB;EACpB;;EAEA;IACE,YAAY;IACZ,qBAAqB;IACrB,gBAAgB;EAClB;;EAEA;IACE,YAAY;IACZ,qBAAqB;IACrB,gBAAgB;EAClB","sourcesContent":["thead button {\n    border: 0;\n    border-radius: none;\n    font-family: inherit;\n    font-weight: 700;\n    font-size: inherit;\n    padding: 0.5em;\n    margin-bottom: 1px;\n  }\n  \n  thead button.ascending::after {\n    content: '↓';\n    display: inline-block;\n    margin-left: 1em;\n  }\n  \n  thead button.descending::after {\n    content: '↑';\n    display: inline-block;\n    margin-left: 1em;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
