import React, { useState, useRef } from "react";
import { Chart } from "react-google-charts";

const ItemsWithResolvedStatusChart = (props) => {
  const isAdoProject = props.isADO === "ADO";
  const projectSourceData = isAdoProject
    ? props.jiraChartsInfo?.itemsWithResolvedStatus.data
        .resolvedIssueCountByType
    : props.jiraChartsInfo?.itemsWithResolvedStatus;
  const [itemsWithResolvedStatusData, setItemsWithResolvedStatusData] =
    useState(projectSourceData || {});
  const issueMap = isAdoProject
    ? props.jiraChartsInfo?.itemsWithResolvedStatus.data.issueMap || {}
    : props.jiraChartsInfo.issueMap || {};
  const currentIterationInfoRef = useRef();
  const currentDataTableRef = useRef();
  const currentIterationRef =
    props.currentIteration === "Overall"
      ? itemsWithResolvedStatusData[0]
      : itemsWithResolvedStatusData[props.currentIteration];

  const dataTable = [["Items", "Quantity"]];
  let itemsWithResolvedStatusHeaders = Object.keys(currentIterationRef || {});
  let itemsWithResolvedStatusQuantity = Object.values(
    currentIterationRef || {}
  );
  currentIterationInfoRef.current = currentIterationRef;

  itemsWithResolvedStatusHeaders.forEach((severity, index) => {
    dataTable.push([severity, itemsWithResolvedStatusQuantity[index].length]);
  });

  currentDataTableRef.current = dataTable;

  const chartEvents = [
    {
      eventName: "ready",
      callback: ({ chartWrapper, google }) => {
        var handler = async function () {
          const chart = chartWrapper.getChart();
          var selection = chart.getSelection();
          if (!selection || selection.length < 1) {
            return;
          }
          var row = selection[0].row + 1;
          let selectedSeverity = currentDataTableRef.current[row][0];

          var defectTitles = [
            "Issue ID",
            "Issue Summary",
            "Status",
            "Resolution",
            "Severity",
            "Assignee",
          ];
          var defectsIdsArray =
            currentIterationInfoRef.current?.[selectedSeverity];
          defectsIdsArray = defectsIdsArray ? defectsIdsArray : [];
          var defectsInfoArray = [];

          Object.keys(issueMap).forEach((issueId) => {
            if (defectsIdsArray.indexOf(issueId) >= 0) {
              defectsInfoArray.push(issueMap[issueId]);
            }
          });

          props.setModalData({
            title: `Resolved Items : ${currentDataTableRef.current[row][0]}`,
            projectId: props.currentProjectId,
            createdDt: null,
            releaseName: null,
            status: null,
            severity: currentDataTableRef.current[row][0],
            tableTitles: defectTitles,
            defects: defectsInfoArray,
          });
          if (defectsInfoArray.length < 1) {
            props.setShowModal(false);
          } else {
            props.setShowModal(true);
          }
        };

        google.visualization.events.addListener(
          chartWrapper.getChart(),
          "select",
          () => handler()
        );
      },
    },
  ];

  const options = {
    title: "Items with Resolved Status",
    titleTextStyle: { fontSize: "16" },
    chartArea: { left: 70, top: 50, width: "65%", height: "65%" },
    is3D: true,
  };

  return (
    <Chart
      chartType="PieChart"
      width="100%"
      height="400px"
      data={dataTable}
      options={options}
      chartEvents={chartEvents}
    />
  );
};

export default ItemsWithResolvedStatusChart;
