import React, { useRef } from "react";
import { Chart } from "react-google-charts";
import "font-awesome/css/font-awesome.min.css";
import "../../css/bootstrap.min.css";

const getTooltip = function (statuses, date, name, values, isADOProject) {
  var tooltip =
    '<div align="left" style="width: auto; float: left; table-layout: auto">' +
    '<table class="table table-hover table-condensed table-sm" >' +
    "<thead><tr><th>" +
    date +
    "</th></tr></thead>" +
    "<tbody>";

  let index = 0;
  for (let statValue of statuses) {
    let convertedName = isADOProject
      ? statValue !== "All"
        ? `Priority ${statValue}`
        : statValue
      : name;

    if (statValue === name) {
      isADOProject
        ? (tooltip =
            tooltip +
            '<tr class="table-danger" key={' +
            index +
            "}><td><b>" +
            convertedName +
            "</b>")
        : (tooltip =
            tooltip +
            '<tr class="table-danger" key={' +
            index +
            "}><td><b>" +
            name +
            "</b>");
    } else {
      isADOProject
        ? (tooltip = tooltip + "<tr  key={" + index + "}><td>" + convertedName)
        : (tooltip = tooltip + "<tr  key={" + index + "}><td>" + statValue);
    }
    tooltip =
      tooltip +
      ": <b>" +
      Math.abs(getValue(values[statValue]) ? getValue(values[statValue]) : 0) +
      "</b></td></tr>";
    index++;
  }
  tooltip = tooltip + "</tbody></table></div>";

  return tooltip;
};

const getValue = (value) => {
  return value ? value.length : 0;
};

export default function OpenDefectsByPriorityChart(props) {
  let isADOProject = props.isADO === "ADO" ? true : false;
  var priorities = isADOProject
    ? ["All", "1", "2", "3", "4"]
    : ["All", "High", "Medium", "Low", "Unknown", "Not Defined"];
  var defectTitles = ["Issue ID", "Issue Summary"];
  const currentIterationInfoRef = useRef();
  const currentDataTableRef = useRef();
  const currentIssueMapRef = useRef();
  let col_max = 0;

  currentIssueMapRef.current = props.jiraSteercoDefectsInfo?.issueMap
    ? props.jiraSteercoDefectsInfo.issueMap
    : {};
  const notSortedInfo = props.jiraSteercoDefectsInfo
    ?.steerCoOpenDefectsPriorityMap
    ? props.jiraSteercoDefectsInfo?.steerCoOpenDefectsPriorityMap[
        props.currentIteration
      ]
    : null;

  currentIterationInfoRef.current = notSortedInfo;

  let columnArray = ["Dates"];
  for (let priority of priorities) {
    columnArray.push(priority);
  }

  let currentIterationInfo = {};

  if (notSortedInfo) {
    Object.keys(notSortedInfo)
      .sort(function (a, b) {
        return new Date(a).getTime() - new Date(b).getTime();
      })
      .forEach(function (key) {
        currentIterationInfo[key] = notSortedInfo[key];
      });
  }

  var dataTable = [];
  var header = ["Dates"];
  let adoHeaderMap = {
    All: "All",
    1: "Priority 1",
    2: "Priority 2",
    3: "Priority 3",
    4: "Priority 4",
  };

  for (var i = 1; i < columnArray.length; i++) {
    let convertedAdoHeaderTitle = adoHeaderMap[columnArray[i]];
    let headerTitle = isADOProject ? convertedAdoHeaderTitle : columnArray[i];
    header.push(headerTitle);
    header.push({ type: "string", role: "tooltip", p: { html: true } });
  }
  dataTable.push(header);

  const datesArr = Object.keys(currentIterationInfo);
  var isStatEmpty = true;
  for (let date of datesArr) {
    isStatEmpty = false;
    var tempStatObj = currentIterationInfo[date];

    let currentDateObjArray = [];
    currentDateObjArray.push(date);

    for (let priority of priorities) {
      if (!tempStatObj[priority]) {
        tempStatObj[priority] = 0;
      }

      if (col_max < getValue(tempStatObj[priority])) {
        col_max = getValue(tempStatObj[priority]);
      }

      currentDateObjArray.push(getValue(tempStatObj[priority]));
      currentDateObjArray.push(
        getTooltip(priorities, date, priority, tempStatObj, isADOProject)
      );
    }

    dataTable.push(currentDateObjArray);
  }

  if (isStatEmpty) {
    let valEmptyArray = isADOProject
      ? ["Dates", 0, "", 0, "", 0, "", 0, "", 0, ""]
      : ["Dates", 0, "", 0, "", 0, "", 0, "", 0, "", 0, ""];
    dataTable.push(valEmptyArray);
  }

  var data = dataTable;
  currentDataTableRef.current = dataTable;

  var options = {
    title: "Open Defects By Safe-Business Priority",
    titleTextStyle: { fontSize: "16" },
    hAxis: {
      slantedText: true,
      slantedTextAngle: 45,
      textStyle: { fontSize: 11 },
    },
    chartArea: { left: 70, top: 50, width: "65%", height: "65%" },
    tooltip: { isHtml: true },
    gridlines: { count: -1 },
    vAxis: { format: "#" },
  };

  if (col_max < 5) {
    options.vAxis = { format: "#", viewWindow: { max: 4 } };
  } else {
    options.vAxis = { format: "#", viewWindow: { max: col_max + 1 } };
  }

  var chartEvents = [
    {
      eventName: "ready",
      callback: ({ chartWrapper, google }) => {
        var handler = async function (e) {
          const chart = chartWrapper.getChart();
          var selection = chart.getSelection();
          if (!selection || selection.length < 1) {
            return;
          }
          var row = selection[0].row;
          var col = selection[0].column;
          let mapPriorities = {
            "Priority 1": 1,
            "Priority 2": 2,
            "Priority 3": 3,
            "Priority 4": 4,
            All: "All",
          };

          var selectedPriority = currentDataTableRef.current[0][col];
          var selectedDate = currentDataTableRef.current[row + 1][0];
          var priority = isADOProject
            ? mapPriorities[selectedPriority]
            : selectedPriority;
          var defectsIdsArray = currentIterationInfoRef.current?.[
            selectedDate
          ]?.[priority]
            ? currentIterationInfoRef.current[selectedDate][priority]
            : [];
          var defectsInfoArray = [];
          Object.keys(currentIssueMapRef.current).forEach((issueId) => {
            if (defectsIdsArray?.indexOf(issueId) >= 0) {
              defectsInfoArray.push(currentIssueMapRef.current[issueId]);
            }
          });

          props.setModalData({
            title: selectedDate + ": " + selectedPriority,
            projectId: props.currentProjectId,
            createdDt: selectedDate,
            releaseName: null,
            status: "Open",
            severity: null,
            tableTitles: defectTitles,
            defects: defectsInfoArray,
          });
          if (defectsInfoArray.length < 1) {
            props.setShowModal(false);
          } else {
            props.setShowModal(true);
          }
        };

        google.visualization.events.addListener(
          chartWrapper.getChart(),
          "select",
          handler
        );
      },
    },
  ];

  return (
    <Chart
      chartType="LineChart"
      width="100%"
      height="400px"
      data={data}
      options={options}
      chartEvents={chartEvents}
    />
  );
}
