import { useState } from "react";
import { Chart } from "react-google-charts";
import Loading from "../../common/Loading";

export default function DefectsByCategoryChart({
  selectedFiltersList,
  backLogItems,
}) {
  const [loading, setLoading] = useState(false);
  const dates = [];
  var dataTable = [
    ["Date", "Issues"],
    [0, 0],
  ];

  var emptyHeaderArray = ["Project", "Count"];
  var emptyDataArray = ["Project", 0];
  let dataIntoArray = Object.entries(backLogItems);
  const dummyData = [["Date", "Issues"]];

  if (
    !selectedFiltersList.selectedCategoryValues ||
    selectedFiltersList.selectedCategoryValues.length < 1 ||
    !selectedFiltersList.selectedDiscoverByValues ||
    selectedFiltersList.selectedDiscoverByValues.length < 1 ||
    !selectedFiltersList.selectedEscapedValues ||
    selectedFiltersList.selectedEscapedValues.length < 1 ||
    !selectedFiltersList.selectedPriorityValues ||
    selectedFiltersList.selectedPriorityValues.length < 1 ||
    !selectedFiltersList.selectedProductValues ||
    selectedFiltersList.selectedProductValues.length < 1 ||
    !selectedFiltersList.selectedReleaseFoundValues ||
    selectedFiltersList.selectedReleaseFoundValues.length < 1 ||
    !selectedFiltersList.selectedResolutionValues ||
    selectedFiltersList.selectedResolutionValues.length < 1 ||
    !selectedFiltersList.selectedSeverityValues ||
    selectedFiltersList.selectedSeverityValues.length < 1 ||
    !selectedFiltersList.selectedStatusValues ||
    selectedFiltersList.selectedStatusValues.length < 1
  ) {
    dataTable.push(emptyHeaderArray);
    dataTable.push(emptyDataArray);
  }

  let keysToFilter = {
    selectedDates: selectedFiltersList.selectedDates.map((key) => key.label),
    releaseFound: selectedFiltersList.selectedReleaseFoundValues.map(
      (key) => key.label
    ),
    status: selectedFiltersList.selectedStatusValues.map((key) => key.label),
    severity: selectedFiltersList.selectedSeverityValues.map(
      (key) => key.label
    ),
    priority: selectedFiltersList.selectedPriorityValues.map(
      (key) => key.label
    ),
    category: selectedFiltersList.selectedCategoryValues.map(
      (key) => key.label
    ),
    discoveredBy: selectedFiltersList.selectedDiscoverByValues.map(
      (key) => key.label
    ),
    escaped: selectedFiltersList.selectedEscapedValues.map((key) => key.label),
    product: selectedFiltersList.selectedProductValues.map((key) => key.label),
    resolution: selectedFiltersList.selectedResolutionValues.map(
      (key) => key.label
    ),
  };
  let filteredKeys = Object.entries(keysToFilter);
  let newArrayOfFilteredKeys = filteredKeys.filter((item) => {
    return item[1].length > 0;
  });
  let tempArray = [];
  let tempFilters = {};

  dataIntoArray.forEach((element) => {
    return newArrayOfFilteredKeys.forEach((key) => {
      let keyFiltered = key[0];
      let keyFilteringValues = key[1];

      tempFilters[keyFiltered] = keyFilteringValues;

      return keyFilteringValues.forEach((nextKey) => {
        if (nextKey === element[key[0]]) {
          tempArray.push(element, "test");
        }
      });
    });
  });

  tempFilters.selectedDates = selectedFiltersList.selectedDates;
  const issuesWithDate = [];
  if (dataIntoArray && dataIntoArray.length > 2) {
    Object.entries(dataIntoArray[0][1]).map((element) => {
      const dateToEvaluate = element[0].split(" ")[0];
      tempFilters.selectedDates.forEach((el) => {
        if (dateToEvaluate === el.label) {
          issuesWithDate.push(element);
        }
      });
    });
  }

  issuesWithDate.forEach((element) => {
    const splitdate = element[0].split(" ");
    const date = splitdate[0];
    let filtersFit = 0;
    let issueCount = 0;
    if (Object.keys(tempFilters).length >= 1) {
      element[1].forEach((issue) => {
        const elementToEvaluate = dataIntoArray[20][1].find(
          (item) => item.issueID === issue
        );

        tempFilters.releaseFound &&
          tempFilters.releaseFound.forEach((filter) => {
            if (elementToEvaluate.releaseFound === filter) {
              filtersFit += 1;
            }
          });
        tempFilters.status &&
          tempFilters.status.forEach((filter) => {
            if (elementToEvaluate.status === filter) {
              filtersFit += 1;
            }
          });
        tempFilters.severity &&
          tempFilters.severity.forEach((filter) => {
            if (elementToEvaluate.severity === filter) {
              filtersFit += 1;
            }
          });
        tempFilters.priority &&
          tempFilters.priority.forEach((filter) => {
            if (elementToEvaluate.priority === filter) {
              filtersFit += 1;
            }
          });
        tempFilters.resolution &&
          tempFilters.resolution.forEach((filter) => {
            if (elementToEvaluate.resolution === filter) {
              filtersFit += 1;
            }
          });
        tempFilters.product &&
          tempFilters.product.forEach((filter) => {
            if (elementToEvaluate.product === filter) {
              filtersFit += 1;
            }
          });
        tempFilters.escaped &&
          tempFilters.escaped.forEach((filter) => {
            if (elementToEvaluate.escapedDefectOption === filter) {
              filtersFit += 1;
            }
          });
        tempFilters.discoveredBy &&
          tempFilters.discoveredBy.forEach((filter) => {
            if (elementToEvaluate.discoveredBy === filter) {
              filtersFit += 1;
            }
          });
        tempFilters.category &&
          tempFilters.category.forEach((filter) => {
            if (elementToEvaluate.category === filter) {
              filtersFit += 1;
            }
          });

        if (filtersFit === Object.keys(tempFilters).length - 1) {
          issueCount += 1;
        }
        filtersFit = 0;
      });
    }
    const tempArray = [date, issueCount];
    dates.push(date);
    dummyData.push(tempArray);
    dummyData.sort((a, b) => Date.parse(a[0]) - Date.parse(b[0]));
  });

  var options = {
    title: "Issues by Category",
    titleTextStyle: { fontSize: "16" },
    hAxis: { slantedText: true, textStyle: { fontSize: 11 } },
    vAxis: { format: "#" },
    chartArea: { left: 70, top: 50, width: "80%", height: "67%" },
    interpolateNulls: true,
  };

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div>
          <Chart
            chartType="LineChart"
            width="100%"
            height="400px"
            data={dummyData.length > 1 ? dummyData : dataTable}
            options={options}
          />
        </div>
      )}
    </div>
  );
}
