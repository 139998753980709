import { useState } from "react";

export default function useTestExecutionInfo() {
  const getTestExecutionInfo = () => {
    const infoStr = sessionStorage.getItem("testExecutionInfo");
    if (infoStr === "undefined") {
      return null;
    }
    const info = JSON.parse(infoStr);
    return info;
  };

  const [testExecutionInfo, setTestExecutionInfo] = useState(
    getTestExecutionInfo()
  );

  const saveTestExecutionInfo = (info) => {
    sessionStorage.setItem("testExecutionInfo", JSON.stringify(info));
    setTestExecutionInfo(info);
  };

  const getTestDevelopmentInfo = () => {
    const infoStr = sessionStorage.getItem("testDevelopmentInfo");
    if (infoStr === "undefined") {
      return null;
    }
    const info = JSON.parse(infoStr);
    return info;
  };

  const [testDevelopmentInfo, setTestDevelopmentInfo] = useState(
    getTestDevelopmentInfo()
  );

  const saveTestDevelopmentInfo = (info) => {
    sessionStorage.setItem("testDevelopmentInfo", JSON.stringify(info));
    setTestDevelopmentInfo(info);
  };

  const getTestToolProjects = () => {
    const infoStr = sessionStorage.getItem("testToolProjects");
    if (infoStr === "undefined") {
      return null;
    }
    const info = JSON.parse(infoStr);
    return info;
  };

  const [testToolProjects, setTestToolProjects] = useState(
    getTestToolProjects()
  );

  const saveTestToolProjects = (info) => {
    sessionStorage.setItem("testToolProjects", JSON.stringify(info));
    setTestToolProjects(info);
  };

  const getTestProjectReleasesInfo = () => {
    const infoStr = sessionStorage.getItem("testProjectReleasesInfo");
    if (infoStr === "undefined") {
      return null;
    }
    const info = JSON.parse(infoStr);
    return info;
  };

  const [testProjectReleasesInfo, setTestProjectReleasesInfo] = useState(
    getTestProjectReleasesInfo()
  );

  const saveTestProjectReleasesInfo = (info) => {
    sessionStorage.setItem("testProjectReleasesInfo", JSON.stringify(info));
    setTestProjectReleasesInfo(info);
  };

  const getTestCycles = () => {
    const infoStr = sessionStorage.getItem("testCycles");
    if (infoStr === "undefined") {
      return null;
    }
    const info = JSON.parse(infoStr);
    return info;
  };

  const [testCycles, setTestCycles] = useState(getTestCycles());

  const saveTestCycles = (info) => {
    sessionStorage.setItem("testCycles", JSON.stringify(info));
    setTestCycles(info);
  };

  const getRequirementCoverageQtestInfo = () => {
    const infoStr = sessionStorage.getItem("requirementCoverageQtestInfo");
    if (infoStr === "undefined") {
      return null;
    }
    const info = JSON.parse(infoStr);
    return info;
  };

  const [requirementCoverageQtestInfo, setRequirementCoverageQtestInfo] =
    useState(getRequirementCoverageQtestInfo());

  const saveRequirementCoverageQtestInfo = (info) => {
    sessionStorage.setItem(
      "requirementCoverageQtestInfo",
      JSON.stringify(info)
    );
    setRequirementCoverageQtestInfo(info);
  };

  const getRequirementsStatInfo = () => {
    const infoStr = sessionStorage.getItem("requirementsStatInfo");
    if (infoStr === "undefined") {
      return null;
    }
    const info = JSON.parse(infoStr);
    return info;
  };

  const [requirementsStatInfo, setRequirementsStatInfo] = useState(
    getRequirementsStatInfo()
  );

  const saveRequirementsStatInfo = (info) => {
    sessionStorage.setItem("requirementsStatInfo", JSON.stringify(info));
    setRequirementsStatInfo(info);
  };

  const getProcessComplianceRequirementsInfo = () => {
    const infoStr = sessionStorage.getItem("processComplianceRequirementsInfo");
    if (infoStr === "undefined") {
      return null;
    }
    const info = JSON.parse(infoStr);
    return info;
  };

  const [
    processComplianceRequirementsInfo,
    setProcessComplianceRequirementsInfo,
  ] = useState(getProcessComplianceRequirementsInfo());

  const saveProcessComplianceRequirementsInfo = (info) => {
    sessionStorage.setItem(
      "processComplianceRequirementsInfo",
      JSON.stringify(info)
    );
    setProcessComplianceRequirementsInfo(info);
  };

  const getProcessComplianceTestRunsDataInfo = () => {
    const infoStr = sessionStorage.getItem("processComplianceTestRunsDataInfo");
    if (infoStr === "undefined") {
      return null;
    }
    const info = JSON.parse(infoStr);
    return info;
  };

  const [
    processComplianceTestRunsDataInfo,
    setProcessComplianceTestRunsDataInfo,
  ] = useState(getProcessComplianceTestRunsDataInfo());

  const saveProcessComplianceTestRunsDataInfo = (info) => {
    sessionStorage.setItem(
      "processComplianceTestRunsDataInfo",
      JSON.stringify(info)
    );
    setProcessComplianceTestRunsDataInfo(info);
  };

  const getProcessComplianceTestSuitesInfo = () => {
    const infoStr = sessionStorage.getItem("processComplianceTestSuitesInfo");
    if (infoStr === "undefined") {
      return null;
    }
    const info = JSON.parse(infoStr);
    return info;
  };

  const [processComplianceTestSuitesInfo, setProcessComplianceTestSuitesInfo] =
    useState(getProcessComplianceTestSuitesInfo());

  const saveProcessComplianceTestSuitesInfo = (info) => {
    sessionStorage.setItem(
      "processComplianceTestSuitesInfo",
      JSON.stringify(info)
    );
    setProcessComplianceTestSuitesInfo(info);
  };

  const getProcessComplianceTestCasesInfo = () => {
    const infoStr = sessionStorage.getItem("processComplianceTestCasesInfo");
    if (infoStr === "undefined") {
      return null;
    }
    const info = JSON.parse(infoStr);
    return info;
  };

  const [processComplianceTestCasesInfo, setProcessComplianceTestCasesInfo] =
    useState(getProcessComplianceTestCasesInfo());

  const saveProcessComplianceTestCasesInfo = (info) => {
    sessionStorage.setItem(
      "processComplianceTestCasesInfo",
      JSON.stringify(info)
    );
    setProcessComplianceTestCasesInfo(info);
  };

  const getProjectTrackerLatestSprintInfo = () => {
    const infoStr = sessionStorage.getItem("projectTrackerLatestSprintInfo");
    if (infoStr === "undefined") {
      return null;
    }
    const info = JSON.parse(infoStr);
    return info;
  };

  const [projectTrackerLatestSprintInfo, setProjectTrackerLatestSprintInfo] =
    useState(getProjectTrackerLatestSprintInfo());

  const saveProjectTrackerLatestSprintInfo = (info) => {
    sessionStorage.setItem(
      "projectTrackerLatestSprintInfo",
      JSON.stringify(info)
    );
    setProjectTrackerLatestSprintInfo(info);
  };

  const getProjectTrackerLatestSprintTestCaseInfo = () => {
    const infoStr = sessionStorage.getItem(
      "projectTrackerLatestSprintTestCaseInfo"
    );
    if (infoStr === "undefined") {
      return null;
    }
    const info = JSON.parse(infoStr);
    return info;
  };

  const [
    projectTrackerLatestSprintTestCaseInfo,
    setProjectTrackerLatestSprintTestCaseInfo,
  ] = useState(getProjectTrackerLatestSprintTestCaseInfo());

  const saveProjectTrackerLatestSprintTestCaseInfo = (info) => {
    sessionStorage.setItem(
      "projectTrackerLatestSprintTestCaseInfo",
      JSON.stringify(info)
    );
    setProjectTrackerLatestSprintTestCaseInfo(info);
  };

  return {
    setTestExecutionInfo: saveTestExecutionInfo,
    testExecutionInfo,
    setTestDevelopmentInfo: saveTestDevelopmentInfo,
    testDevelopmentInfo,
    testToolProjects,
    setTestToolProjects: saveTestToolProjects,
    testProjectReleasesInfo,
    setTestProjectReleasesInfo: saveTestProjectReleasesInfo,
    testCycles,
    setTestCycles: saveTestCycles,
    requirementCoverageQtestInfo,
    setRequirementCoverageQtestInfo: saveRequirementCoverageQtestInfo,
    requirementsStatInfo,
    setRequirementsStatInfo: saveRequirementsStatInfo,
    processComplianceRequirementsInfo,
    setProcessComplianceRequirementsInfo: saveProcessComplianceRequirementsInfo,
    processComplianceTestRunsDataInfo,
    setProcessComplianceTestRunsDataInfo: saveProcessComplianceTestRunsDataInfo,
    processComplianceTestSuitesInfo,
    setProcessComplianceTestSuitesInfo: saveProcessComplianceTestSuitesInfo,
    processComplianceTestCasesInfo,
    setProcessComplianceTestCasesInfo: saveProcessComplianceTestCasesInfo,
    projectTrackerLatestSprintInfo,
    setProjectTrackerLatestSprintInfo: saveProjectTrackerLatestSprintInfo,
    projectTrackerLatestSprintTestCaseInfo,
    setProjectTrackerLatestSprintTestCaseInfo:
      saveProjectTrackerLatestSprintTestCaseInfo,
  };
}
