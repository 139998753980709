import React from "react";
import Admin from "../../services/Admin";
import EmptyStar from "../../assets/images/star-regular.svg";
import SolidStar from "../../assets/images/star-solid.svg";
import useProjectDetails from "../hooks/useProjectDetails";
import useEditProjectDetails from "../hooks/useEditProjectDetails";
import { useHistory } from "react-router-dom";
import "../css/bootstrap.min.css";
import "../css/custom.min.css";

async function saveFavorite(token, userId, projectId) {
  try {
    return await Admin.saveFavoriteProjects(token, userId, projectId);
  } catch (err) {
    return { message: "Favorite was not saved: (" + err.message + ")" };
  }
}

function ProjectCard(props) {
  const { setAddNewProjectFlag } = useProjectDetails();
  const { clearEditProjectDetails } = useEditProjectDetails();
  const history = useHistory();

  const handleDetailsClick = () => {
    clearEditProjectDetails();
    props.setCurrentProjectId(props.project.projectId);
    setAddNewProjectFlag(false);
    props.navTabChangeStyle("details");
    history.push(`/project/${props.project.projectId}/settings`);
  };

  const handleAddFavouriteClick = async (e) => {
    e.stopPropagation();
    let updatedUser = await saveFavorite(
      props.token,
      props.loggedUser.id,
      props.project.projectId
    );
    if (updatedUser.message) {
    } else {
      let tmpUser = JSON.parse(JSON.stringify(props.loggedUser));
      tmpUser.favoriteProjects = updatedUser.favoriteProjects;
      props.setLoggedUser(tmpUser);
    }
  };

  const handleFavouriteIcon = () => {
    let icon;

    if (
      props.loggedUser?.favoriteProjects?.indexOf(
        props.project.projectId + ""
      ) >= 0
    ) {
      icon = <img src={SolidStar} alt="Favourite Icon" />;
    } else {
      icon = <img src={EmptyStar} alt="Favourite Icon" />;
    }

    return icon;
  };
  const handleCloseModal = (e) => {
    e.stopPropagation();
    props.handleOpenModal(props.project.projectName, props.project.endDate);
  };

  let date = new Date(props.project.endDate);
  let today = new Date();

  return (
    <div className="col mb-4">
      <div
        className={
          "card hover-zoom " +
          (!props.project.isActive ? "projectCardInActive" : "")
        }
        style={{ width: 400, cursor: "pointer" }}
        onClick={() => handleDetailsClick(props.project)}
      >
        <div className={"card-body"}>
          <div
            className="card-header ms-0 ps-2 mb-3"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <span
              className="rounded shadow-sm pb-1"
              style={{ backgroundColor: "rgb(0, 122, 195)" }}
            >
              <small className="text-light ms-2 me-2 mt-2">
                {props.project.projectTypeName}
              </small>
            </span>
            <div style={{ display: "flex", gap: "15px" }}>
              <div
                style={{
                  // color: "red",
                  // border: "solid red 1.5px",
                  // width: "20px",
                  // height: "20px",
                  // fontSize: "16px",
                  // fontStyle: "italic",
                  // textAlign: "center",
                  display:
                    date <= today && props.project.isActive ? "flex" : "none",
                  // justifyContent: "center",
                  // alignItems: "center",
                }}
                onClick={(e) => handleCloseModal(e)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  fill="currentColor"
                  className="bi bi-info-square"
                  viewBox="0 0 16 16"
                  style={{ color: "red" }}
                >
                  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                  <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                </svg>
              </div>

              <div
                style={{ width: "24px" }}
                onClick={(e) => handleAddFavouriteClick(e)}
              >
                {handleFavouriteIcon()}
              </div>
            </div>
          </div>
          <div className="card-title mb-4 mt-8">
            <h5>{props.project.projectName}</h5>
          </div>
          <div></div>
          <div className="card-footer">
            <div className="row">
              <small className="text-muted text-end">
                Project ID: {props.project.projectId}
              </small>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <p className="float-start">
                  <small className="text-muted">
                    Start Date: {props.project.startDate}
                  </small>
                </p>
              </div>
              <div className="col-sm-6">
                <p className="float-end">
                  <small className="text-muted">
                    End Date: {props.project.endDate}
                  </small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectCard;
