import { useState } from "react";

export default function useUserInfo() {
  const getLoggedUser = () => {
    const str = localStorage.getItem("loggedUser");
    if (str === "undefined" || str === "{}") {
      return null;
    }
    const info = JSON.parse(str);
    return info;
  };

  const [loggedUser, setLoggedUser] = useState(getLoggedUser());

  const saveLoggedUser = (info) => {
    localStorage.setItem("loggedUser", JSON.stringify(info));
    setLoggedUser(info);
  };

  return {
    setLoggedUser: saveLoggedUser,
    loggedUser,
  };
}
