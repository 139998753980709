import { Chart } from "react-google-charts";

const getTicksArrayForTrendingChart = (minValue, maxValue) => {
  // Figure out the largest number (positive or negative)
  var biggestNumber = Math.max(Math.abs(maxValue), Math.abs(minValue));

  // Round to an exponent of 10 appropriate for the biggest number
  var roundingExp = Math.floor(Math.log(biggestNumber) / Math.LN10);
  var roundingDec = Math.pow(10, roundingExp);

  // Round your max and min to the nearest exponent of 10
  var newMax = Math.ceil(maxValue / roundingDec) * roundingDec;
  var newMin = Math.floor(minValue / roundingDec) * roundingDec;

  // Determine the range of your values
  var range = Math.max(Math.abs(newMax), Math.abs(newMin));
  //(newMin<0)?newMax - newMin : newMax;
  var gridlines = 2;

  // Calculate the best factor for number of gridlines (2-5 gridlines)
  // If the range of numbers divided by 2 or 5 is a whole number, use it
  for (var i = 2; i <= 4; ++i) {
    if (Math.round(range / i) === range / i) {
      gridlines = i;
    }
  }
  var step = Math.round(range / gridlines);
  var ticksArr = [];
  if (newMin < 0) {
    for (i = 0; i >= newMin - step; i -= step) {
      ticksArr.push({ v: i, f: String(Math.abs(i)) });
      if (minValue >= i) {
        break;
      }
    }
    ticksArr.reverse();
  }
  if (newMax > 0) {
    for (i = 0; i <= newMax + step; i += step) {
      ticksArr.push(i);
      if (i > maxValue) {
        break;
      }
    }
  }
  return ticksArr;
};

const getTooltip = function (statuses, date, name, values, ADO) {
  var tooltip =
    '<div align="left" style="width: auto; float: left; table-layout: auto">' +
    '<table class="table table-hover table-condensed table-sm" >' +
    "<thead><tr><th>" +
    date +
    "</th></tr></thead>" +
    "<tbody>";

  let index = 0;
  for (let statValue of statuses) {
    if (statValue === name) {
      tooltip =
        tooltip +
        '<tr class="table-danger" key={' +
        index +
        "}><td><b>" +
        name +
        "</b>";
    } else {
      tooltip = tooltip + "<tr  key={" + index + "}><td>" + statValue;
    }
    tooltip =
      tooltip +
      ": <b>" +
      Math.abs(values[statValue] ? values[statValue] : 0) +
      "</b></td></tr>";
    index++;
  }
  tooltip = tooltip + "</tbody></table></div>";

  return tooltip;
};

export default function DefectTrendingChart(props) {
  let initialDates = [];
  initialDates =
    props.isADO === "ADO"
      ? props?.jiraChartsInfo?.defectAgeChartMap?.data?.dates || []
      : props?.jiraChartsInfo?.dates || [];

  let preStatuses = [];
  preStatuses =
    props.isADO === "ADO"
      ? Object.entries(
          props?.jiraChartsInfo?.defectAgeChartMap?.data?.defectsTrending || []
        )
      : Object.entries(props?.jiraChartsInfo?.defectsTrending || []);

  let overallIterationData = preStatuses.slice(-1)[0];
  let preStatusesData = preStatuses.length > 0 ? overallIterationData[1] : [];
  let preStatusesValues = Object.values(preStatusesData);
  let index = 0;
  let count = 0;

  preStatusesValues.forEach((item) => {
    if (Object.entries(item).length > count) {
      index = preStatusesValues.indexOf(item);
      count = Object.entries(item).length;
    }
  });

  let statuses = [];
  let bigger_obj = preStatusesValues[index];

  if (bigger_obj) {
    statuses = Object.keys(bigger_obj);
  }

  let notSortedInfo = [];

  if (props.isADO === "ADO") {
    if (props.jiraChartsInfo?.defectAgeChartMap?.data?.defectsTrending) {
      notSortedInfo =
        props.jiraChartsInfo?.defectAgeChartMap?.data?.defectsTrending[
          props.currentIteration
        ] || [];
    } else {
      notSortedInfo = [];
    }
  } else {
    notSortedInfo =
      props.jiraChartsInfo?.defectsTrending[props.currentIteration] || [];
  }

  var maxValue = 0;
  var minValue = 0;

  let columnArray = ["Dates"];

  for (let status of statuses) {
    columnArray.push(status);
  }

  let currentIterationInfo = {};
  if (notSortedInfo) {
    Object.keys(notSortedInfo)
      .sort(function (a, b) {
        return new Date(a).getTime() - new Date(b).getTime();
      })
      .forEach(function (key) {
        currentIterationInfo[key] = notSortedInfo[key];
      });
  }

  let dataTable_ = [];
  var header = ["Dates"];

  dataTable_.push(header);

  const datesArr = Object.keys(currentIterationInfo);

  var isStatEmpty = true;

  if (datesArr.length > 0 && Object.keys(preStatusesData).length > 0) {
    for (var i = 1; i < columnArray.length; i++) {
      header.push(columnArray[i]);
      header.push({ type: "string", role: "tooltip", p: { html: true } });
    }
    for (let date of datesArr) {
      let tempMaxValue = 0;
      let tempMinValue = 0;

      isStatEmpty = false;
      var tempStatObj = currentIterationInfo[date];

      let currentDateObjArray = [];
      currentDateObjArray.push(date);

      for (let status of statuses) {
        if (!tempStatObj[status]) {
          tempStatObj[status] = 0;
        }

        if (status === "Closed" || status === "Resolved" || status === "Done") {
          tempMaxValue += tempStatObj[status];
        } else {
          if (tempStatObj[status] >= 0) {
            tempStatObj[status] = -1 * tempStatObj[status];
          }
          tempMinValue += tempStatObj[status];
        }

        if (props.isADO === "ADO") {
          if (status != "Closed") {
            currentDateObjArray.push(tempStatObj[status]);
            currentDateObjArray.push(
              getTooltip(
                statuses,
                date,
                status,
                tempStatObj,
                props.isADO === "ADO"
              )
            );
          } else {
            currentDateObjArray.push(tempStatObj[status]);
            currentDateObjArray.push(
              getTooltip(
                statuses,
                date,
                status,
                tempStatObj,
                props.isADO === "ADO"
              )
            );
          }
        } else {
          currentDateObjArray.push(tempStatObj[status]);
          currentDateObjArray.push(
            getTooltip(
              statuses,
              date,
              status,
              tempStatObj,
              props.isADO === "ADO"
            )
          );
        }
      }

      if (maxValue < tempMaxValue) {
        maxValue = tempMaxValue;
      }
      if (minValue > tempMinValue) {
        minValue = tempMinValue;
      }

      dataTable_.push(currentDateObjArray);
    }
  }

  const data = dataTable_;

  // set up ticks for vertical axis

  var ticksArr = getTicksArrayForTrendingChart(minValue, maxValue);

  var options = {
    title: "Defect Trending",
    titleTextStyle: { fontSize: "16" },
    bars: "vertical",
    isStacked: true,
    hAxis: {
      slantedText: true,
      slantedTextAngle: 45,
      textStyle: { fontSize: 11 },
    },
    chartArea: { left: 70, top: 50, width: "65%", height: "65%" },
    tooltip: { isHtml: true },
    vAxis: {
      ticks: ticksArr, // [{ v: -25, f: '25' }, 0, 25, 50, 75]
      format: "#",
    },
  };

  if (isStatEmpty) {
    if (data[0] && data[0].length === 1) {
      options.vAxis.ticks = [
        { v: -10, f: "10" },
        { v: 0, f: "0" },
        0,
        10,
        20,
        30,
      ];
      data[0] = ["Dates", "Resolved", "Done"];

      if (initialDates && initialDates.lenght >= 1) {
        initialDates.forEach((item) => {
          data.push([item, 0, 0]);
        });
      } else {
        data.push(["No date", 0, 0]);
      }
    }
  }

  return (
    <Chart
      chartType="ColumnChart"
      width="100%"
      height="400px"
      data={data}
      options={options}
    />
  );
}
