import React, { useEffect } from "react";
import ProcessComplianceRequirementsChart from "../../components/charts/processComplianceCharts/ProcessComplianceRequirementsChart";
import ProcessComplianceTable from "../../components/charts/processComplianceCharts/ProcessComplianceTable";

const ProcessCompliancePage = (props) => {
  useEffect(() => {
    return props.changeStyle("processCompliance");
  }, [props.currentProjectId]);

  return (
    <div
      id="main_container"
      className="bg-light container-fluid pt-3 pb-3 row ms-3 me-3"
    >
      <div id="requirementsCoverageByTestCasesChart" className="col-5">
        <h4>Requirements</h4>
        <div id="req-coverage-tc-root">
          <ProcessComplianceRequirementsChart
            token={props.token}
            currentProjectId={props.currentProjectId}
            setCurrentProjectId={props.setCurrentProjectId}
          />
        </div>
      </div>
      <div id="requirementsStatusChart" className="col-7">
        <h4>Process Compliance</h4>
        <div id="req-status-root">
          <ProcessComplianceTable
            token={props.token}
            currentProjectId={props.currentProjectId}
            setCurrentProjectId={props.setCurrentProjectId}
          />
        </div>
      </div>
    </div>
  );
};

export default ProcessCompliancePage;
