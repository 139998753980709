import React, { useState, useEffect } from "react";
import ProjectTypes from "../../components/projects/ProjectTypes";
import Jira from "../../services/Jira";
import DefectsModal from "../../components/common/modals/DefectsModal";
import ErrorMessage from "../../components/common/ErrorMessage";
import MultiRangeSlider from "multi-range-slider-react";
import { Chart } from "react-google-charts";
import { MultiSelect } from "react-multi-select-component";
import "../../components/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";

async function getEDAChartsInfo(token, projectTypeName, queryStatusesString) {
  try {
    const resp = await Jira.getJiraEDAInfo(
      token,
      projectTypeName,
      queryStatusesString
    );
    return resp;
  } catch (err) {
    return {
      message:
        "Error connecting to API for EDA info (" + err.message
          ? err.message
          : JSON.stringify(err) + ")",
    };
  }
}

export default function EscapedDefectAnalyticsPage(props) {
  const [eDAChartsInfo, setEDAChartsInfo] = useState();
  const [selectedEDAChartsInfo, setSelectedEDAChartsInfo] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedProjectType, setSelectedProjectType] = useState("");
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [dates, setDates] = useState([]);
  const [selectedMinDate, setSelectedMinDate] = useState(0);
  const [selectedMaxDate, setSelectedMaxDate] = useState(0);
  const [minDate, setMinDate] = useState(0);
  const [maxDate, setMaxDate] = useState(-1);
  const [datesWithTitles, setDatesWithTitles] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({
    title: "",
    projectId: null,
    createdDt: null,
    releaseName: null,
    status: null,
    severity: null,
  });

  let localProps = {};
  Object.assign(localProps, props);
  localProps.style = "multy";
  localProps.styleValue = { height: "auto" };

  useEffect(() => {
    function handleScrollYChange() {
      const lastScrollY = window.scrollY;
      if (lastScrollY) {
        sessionStorage.setItem("scrollY", lastScrollY);
      }
    }

    window.addEventListener("scroll", handleScrollYChange, true);
    return () => {
      window.removeEventListener("scroll", handleScrollYChange);
    };
  }, []);

  useEffect(() => {
    const handleEDADetails = async (e) => {
      if (
        selectedProjectType[0]?.projectTypeName &&
        selectedProjectType[0].projectTypeName !== "All"
      ) {
        setLoading(true);
        setErrorMessage(null);
        let queryStatusesString;

        if (selectedStatuses.length) {
          queryStatusesString = selectedStatuses
            .map((status) => {
              return status.value;
            })
            .join();
        }
        const response = await getEDAChartsInfo(
          props.token,
          selectedProjectType,
          queryStatusesString
        );
        setSelectedProducts([]);
        setSelectedCategories([]);

        if (response.errMsg) {
          setErrorMessage(response.errMsg);
          setEDAChartsInfo(null);
        } else {
          if (!response?.dates) {
            setErrorMessage("Response does not contain any information!");
            setEDAChartsInfo(null);
          } else {
            setErrorMessage("");
            setEDAChartsInfo(response);
            setSelectedEDAChartsInfo(response);

            //prepare options for Products drop-down
            if (response?.products) {
              let tmpProducts = response.products.map((item) => {
                return { label: item, value: item };
              });
              setProducts(tmpProducts);
            }
            //prepare options for Categories drop-down
            if (response?.categories) {
              let tmpCategories = response.categories.map((item) => {
                return { label: item, value: item };
              });
              setCategories(tmpCategories);
              //set all categories to be selected by default
              setSelectedCategories(tmpCategories);
            }
            if (response?.statuses) {
              let tmpStatuses = response.statuses.map((item) => {
                return { label: item, value: item };
              });
              setStatuses(tmpStatuses);
            }

            let min = 0,
              max = 0,
              datesArr = [],
              datesMap = {},
              tempDatesMap = {};
            if (response?.dates) {
              response.dates.map((dateStrValue) => {
                let currDateValue = Date.parse(dateStrValue);
                if (min === 0 || min > currDateValue) {
                  min = currDateValue;
                }
                if (max < currDateValue) {
                  max = currDateValue;
                }
                datesArr.push(currDateValue);
                tempDatesMap[currDateValue] = dateStrValue;
                return dateStrValue;
              });
            }
            datesArr.sort();
            datesArr.map((item, index) => {
              if (min === item) {
                min = index;
              }
              if (max === item) {
                max = index;
              }
              datesMap[index] = tempDatesMap[item];
              return index;
            });
            let dates = response.dates.sort((a, b) => {
              return Date.parse(a) - Date.parse(b);
            });
            setDates(dates);
            setSelectedMinDate(min);
            setSelectedMaxDate(max);
            setMinDate(min);
            setMaxDate(max);
            setDatesWithTitles(datesMap);
          }
        }
      }

      setLoading(false);
      sessionStorage.setItem("scrollY", "");
    };
    props.changeStyle("eda");
    handleEDADetails();
  }, [selectedProjectType, selectedStatuses]);

  const SliderObj = () => {
    const handleInput = (e) => {
      setSelectedMinDate(e.minValue);
      setSelectedMaxDate(e.maxValue);
    };

    return (
      <MultiRangeSlider
        min={minDate}
        max={maxDate}
        step={1}
        ruler={false}
        label={false}
        preventWheel={false}
        minValue={selectedMinDate}
        maxValue={selectedMaxDate}
        onInput={(e) => {
          handleInput(e);
        }}
      />
    );
  };

  function test(statArray, componentsArray, componentsMap, selectedProduct) {
    Object.keys(statArray).forEach((date1) => {
      if (
        Date.parse(date1) >= Date.parse(datesWithTitles[selectedMinDate]) &&
        Date.parse(date1) <= Date.parse(datesWithTitles[selectedMaxDate])
      ) {
        componentsArray = statArray[date1];
        //iterate components for the current date
        for (let component in componentsArray) {
          if (!componentsMap[component]) {
            componentsMap[component] = {};
            componentsMap[component]["Total"] = 0;
            componentsMap[component][selectedProduct] = 0;
          }
          if (!componentsMap[component][selectedProduct]) {
            componentsMap[component][selectedProduct] = 0;
          }
          componentsMap[component]["Total"] += componentsArray[component];
          componentsMap[component][selectedProduct] +=
            componentsArray[component];
        }
      }
    });
    return componentsArray;
  }

  const getDefAnalyticsTooltip = function (
    title,
    selectedTitle,
    values,
    names
  ) {
    var tooltip =
      '<div align="left" style="width: auto; float: left; ">' +
      '<table class="table table-hover table-condensed">' +
      "<thead><tr><th>" +
      title +
      "</th></tr></thead>" +
      "<tbody>";

    for (let value in values) {
      var name = names[value] ? names[value] : value;
      if (value === selectedTitle) {
        tooltip = tooltip + '<tr class="table-danger"><td><b>' + name + "</b>";
      } else {
        tooltip = tooltip + "<tr><td>" + name;
      }
      tooltip = tooltip + ": <b>" + Math.abs(values[value]) + "</b></td></tr>";
    }
    tooltip = tooltip + "</tbody></table></div>";

    return tooltip;
  };

  const FaultContainmentChart = () => {
    var dataObject = null;
    try {
      dataObject = selectedEDAChartsInfo.faultContainmentReport;
    } catch (Exception) {}

    var dataTable = [];
    var header = [
      "Dates",
      "Fault Containment",
      { type: "string", role: "tooltip", p: { html: true } },
    ];
    dataTable.push(header);

    if (
      !dataObject ||
      !selectedProducts ||
      selectedProducts.length <= 0 ||
      !selectedCategories ||
      selectedCategories.length <= 0
    ) {
      dataTable.push(["Date", 0, ""]);
    } else {
      var tempDataArr = [];
      var totalCountArr = [];
      var sortedDate = [];

      for (let selectedProduct of selectedProducts) {
        var statObj = dataObject[selectedProduct.value];

        //if data does not have info for the current product, then go to next selected product
        if (!statObj) {
          continue;
        }

        //iterate categories in the current product
        for (let selectedCategory of selectedCategories) {
          const statArray = statObj[selectedCategory.value];
          //if data does not have info for the category, then go to next selected category
          if (!statArray) {
            continue;
          }

          var isMinFound = false;
          var isMaxFound = false;

          let dates1 = [];
          Object.keys(statArray).forEach((k) => {
            dates1.push(k);
          });
          sortedDate = dates1.sort((a, b) => Date.parse(a) - Date.parse(b));
          //iterate dates for the current product-category

          var totalObj = statArray; // scope.jiraAllProjectsStatMap[0][0].trendingEDATotalStatMap[product];
          for (const [i, date] of sortedDate.entries()) {
            if (date === datesWithTitles[selectedMinDate]) {
              isMinFound = true;
            }
            if (isMinFound && !isMaxFound) {
              tempDataArr[date] = tempDataArr[date] ? tempDataArr[date] : {};
              totalCountArr[date] = totalCountArr[date]
                ? totalCountArr[date]
                : {};

              var value = tempDataArr[date].escapedIssues
                ? tempDataArr[date].escapedIssues
                : 0;
              tempDataArr[date].escapedIssues =
                value + statArray[date].escapedIssues;
              value = totalCountArr[date].totalIssues
                ? totalCountArr[date].totalIssues
                : 0;
              totalCountArr[date].totalIssues =
                value + totalObj[date].totalIssues;
            }
            if (date === datesWithTitles[selectedMaxDate]) {
              isMaxFound = true;
            }
          }
        }
      }
      let newDates = [];
      Object.keys(tempDataArr).forEach((k) => {
        newDates.push(k);
      });
      sortedDate = newDates.sort((a, b) => Date.parse(a) - Date.parse(b));
      var firstDate = datesWithTitles[selectedMinDate];
      for (const [i, date] of newDates.entries()) {
        let totalCountArrValue = 0;
        let totalCountArrFirstDateValue = 0;
        let tempDataArrValue = 0;
        let tempDataArrFirstDateValue = 0;
        totalCountArr[date].totalIssues = totalCountArr[date].totalIssues || 0;
        totalCountArrValue =
          totalCountArrValue + totalCountArr[date].totalIssues;
        totalCountArr[firstDate].totalIssues =
          totalCountArr[firstDate].totalIssues || 0;
        totalCountArrFirstDateValue =
          totalCountArrFirstDateValue + totalCountArr[firstDate].totalIssues;
        tempDataArr[date].escapedIssues = tempDataArr[date].escapedIssues || 0;
        tempDataArrValue = tempDataArrValue + tempDataArr[date].escapedIssues;
        tempDataArr[firstDate].escapedIssues =
          tempDataArr[firstDate].escapedIssues || 0;
        tempDataArrFirstDateValue =
          tempDataArrFirstDateValue + tempDataArr[firstDate].escapedIssues;
        var finalValue = Math.round(
          ((totalCountArrValue -
            totalCountArrFirstDateValue -
            (tempDataArrValue - tempDataArrFirstDateValue)) /
            (totalCountArrValue - totalCountArrFirstDateValue)) *
            100
        );
        var tooltip =
          date +
          "\nFault Containment: " +
          finalValue +
          "%\nEscaped Issues since " +
          firstDate +
          " : " +
          Math.round(tempDataArrValue - tempDataArrFirstDateValue) +
          "\nTotal Issues since " +
          firstDate +
          " : " +
          Math.round(totalCountArrValue - totalCountArrFirstDateValue);
        dataTable.push([date, finalValue, tooltip]);
      }
    }
    if (dataTable.length < 2) {
      dataTable.push(["Date", 0, ""]);
    }
    var data = dataTable;
    var options = {
      title: "Fault Containment (Escaped Defects)",
      titleTextStyle: { fontSize: "16" },
      vAxis: { minValue: 0, maxValue: 100, format: "#'%'" },
      chartArea: { left: 70, top: 50, width: "80%", height: "65%" },
      interpolateNulls: true,
    };

    return (
      <Chart
        chartType="LineChart"
        width="100%"
        height="400px"
        data={data}
        options={options}
      />
    );
  };

  const EscapedDefectsByComponentChart = () => {
    var dataObject = null;
    try {
      dataObject = selectedEDAChartsInfo.edByComponent;
    } catch (Exception) {}

    var dataTable = [];
    var header = ["Component"];

    if (
      !dataObject ||
      !selectedProducts ||
      selectedProducts.length <= 0 ||
      !selectedCategories ||
      selectedCategories.length <= 0
    ) {
      //empty chart
      header.push("Product");
      dataTable.push(header);
      dataTable.push(["Component", 0]);
    } else {
      var componentsMap = {};

      for (let selectedProduct of selectedProducts) {
        var statObj = dataObject[selectedProduct.value];

        //add selected products to the headers list
        header.push(selectedProduct.value);
        header.push({ type: "string", role: "tooltip", p: { html: true } });

        //if data does not have info for the current product, then go to next selected product
        if (!statObj) {
          continue;
        }

        //iterate categories in the current product
        for (let selectedCategory of selectedCategories) {
          var statArray = statObj[selectedCategory.value];
          //if data does not have info for the category, then go to next selected category
          if (!statArray) {
            continue;
          }

          let componentsArray = [];
          //iterate dates for the current product-category
          componentsArray = test(
            statArray,
            componentsArray,
            componentsMap,
            selectedProduct.value
          );
        }
      }
    }

    if (!componentsMap || Object.entries(componentsMap).length === 0) {
      header = ["Component", "Product"];
      dataTable.push(header);
      dataTable.push(["Component", 0]);
    } else {
      //add headers to the data table
      dataTable.push(header);

      for (let component in componentsMap) {
        var dataArray = [];
        dataArray.push(component);
        let tempMap = componentsMap[component];
        for (let prName of selectedProducts) {
          var count = tempMap[prName.value] ? tempMap[prName.value] : 0;
          dataArray.push(count);
          var tooltip = getDefAnalyticsTooltip(
            component,
            prName.value,
            tempMap,
            selectedProducts
          );
          dataArray.push(tooltip);
        }
        dataTable.push(dataArray);
      }
    }
    var data = dataTable;
    var options = {
      title: "Escaped Defects by Component",
      titleTextStyle: { fontSize: "16" },
      bars: "vertical",
      isStacked: true,
      tooltip: { isHtml: true },
      chartArea: { left: 70, top: 50, width: "65%", height: "65%" },
      vAxis: { format: "0" },
    };

    var chartEvents = [
      {
        eventName: "ready",
        callback: ({ chartWrapper, google }) => {
          var handler = async function () {
            const chart = chartWrapper.getChart();
            var selection = chart.getSelection();
            if (!selection || selection.length < 1) {
              return;
            }
            var row = selection[0].row;
            var col = selection[0].column;

            var selectedProduct = dataTable[0][col];
            var selectedComponent = dataTable[row + 1][0];

            if (!selectedEDAChartsInfo) {
              return;
            }

            let productKey = Object.keys(
              selectedEDAChartsInfo.productMap
            ).filter((key) => {
              return (
                selectedEDAChartsInfo.productMap[key].indexOf(
                  selectedProduct
                ) >= 0
              );
            });
            let productFieldId =
              selectedEDAChartsInfo.productFieldIdMap[productKey[0]];

            setModalData({
              title: selectedComponent + ": " + selectedProduct,
              component: selectedComponent,
              endDate: datesWithTitles[selectedMaxDate],
              productFieldId: productFieldId,
              productName: selectedProduct,
              qaReason: "",
              startDate: datesWithTitles[selectedMinDate],
              tableTitles: ["Issue ID", "Issue Summary"],
              eda: true,
              bComponent: true,
            });
            setShowModal(true);
          };

          google.visualization.events.addListener(
            chartWrapper.getChart(),
            "select",
            () => handler()
          );
        },
      },
    ];

    return (
      <Chart
        chartType="ColumnChart"
        width="100%"
        height="400px"
        data={data}
        options={options}
        chartEvents={chartEvents}
      />
    );
  };

  const EscapedDefectsByQAReasonChart = () => {
    var dataObject = null;
    try {
      dataObject = selectedEDAChartsInfo.edByQAReason;
    } catch (Exception) {}

    var dataTable = [];
    var header = ["Reason"];

    if (
      !dataObject ||
      !selectedProducts ||
      selectedProducts.length <= 0 ||
      !selectedCategories ||
      selectedCategories.length <= 0
    ) {
      //empty chart
      header.push("Product");
      dataTable.push(header);
      dataTable.push(["Reason", 0]);
    } else {
      var reasonsMap = {};

      for (let selectedProduct of selectedProducts) {
        var statObj = dataObject[selectedProduct.value];

        //add selected products to the headers list
        header.push(selectedProduct.value);
        header.push({ type: "string", role: "tooltip", p: { html: true } });

        //if data does not have info for the current product, then go to next selected product
        if (!statObj) {
          continue;
        }

        //iterate categories in the current product
        for (let selectedCategory of selectedCategories) {
          var statArray = statObj[selectedCategory.value];
          //if data does not have info for the category, then go to next selected category
          if (!statArray) {
            continue;
          }

          let reasonsArray = [];
          //iterate dates for the current product-category
          reasonsArray = test(
            statArray,
            reasonsArray,
            reasonsMap,
            selectedProduct.value
          );
        }
      }
    }

    if (!reasonsMap || Object.entries(reasonsMap).length === 0) {
      header = ["Reason", "Product"];
      dataTable.push(header);
      dataTable.push(["Reason", 0]);
    } else {
      //add headers to the data table
      dataTable.push(header);

      for (let reason in reasonsMap) {
        var dataArray = [];
        dataArray.push(reason);
        let tempMap = reasonsMap[reason];
        for (let prName of selectedProducts) {
          var count = tempMap[prName.value] ? tempMap[prName.value] : 0;
          dataArray.push(count);
          var tooltip = getDefAnalyticsTooltip(
            reason,
            prName.value,
            tempMap,
            selectedProducts
          );
          dataArray.push(tooltip);
        }
        dataTable.push(dataArray);
      }
    }
    var data = dataTable;
    var options = {
      title: "Escaped Defects by QA Reason",
      titleTextStyle: {
        fontSize: "16",
      },
      bars: "vertical",
      isStacked: true,
      tooltip: { isHtml: true },
      chartArea: { left: 70, top: 50, width: "65%", height: "65%" },
      vAxis: { format: "0" },
    };

    var chartEvents = [
      {
        eventName: "ready",
        callback: ({ chartWrapper, google }) => {
          var handler = async function () {
            const chart = chartWrapper.getChart();
            var selection = chart.getSelection();
            if (!selection || selection.length < 1) {
              return;
            }
            var row = selection[0].row;
            var col = selection[0].column;

            var selectedProduct = dataTable[0][col];
            var selectedQaReason = dataTable[row + 1][0];

            if (!selectedEDAChartsInfo) {
              return;
            }

            let productKey = Object.keys(
              selectedEDAChartsInfo.productMap
            ).filter((key) => {
              return (
                selectedEDAChartsInfo.productMap[key].indexOf(
                  selectedProduct
                ) >= 0
              );
            });
            let productFieldId =
              selectedEDAChartsInfo.productFieldIdMap[productKey[0]];

            setModalData({
              title: selectedQaReason + ": " + selectedProduct,
              component: "",
              endDate: datesWithTitles[selectedMaxDate],
              productFieldId: productFieldId,
              productName: selectedProduct,
              qaReason: selectedQaReason,
              startDate: datesWithTitles[selectedMinDate],
              tableTitles: [
                "Issue ID",
                "Issue Summary",
                "EDA Issue",
                "Process Area Category",
              ],
              eda: true,
              bQAreason: true,
            });
            setShowModal(true);
          };

          google.visualization.events.addListener(
            chartWrapper.getChart(),
            "select",
            () => handler()
          );
        },
      },
    ];

    return (
      <Chart
        chartType="ColumnChart"
        width="100%"
        height="400px"
        data={data}
        options={options}
        chartEvents={chartEvents}
      />
    );
  };

  return (
    <div id="main_container">
      <DefectsModal
        isOpen={showModal}
        setShowModal={setShowModal}
        data={modalData}
        token={props.token}
      />
      {loading && (
        <div className="d-flex justify-content-center bg-transparent align-items-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}

      <div
        id="main_container_1"
        className="bg-light container-fluid pt-3 pb-3"
        style={
          loading
            ? {
                pointerEvents: "none",
                opacity: "0.4",
              }
            : {}
        }
      >
        <h4 className="ms-4">Escaped Defect Analysis</h4>
        <ErrorMessage errorMessage={errorMessage} />
        <div id="" className="container-fluid pb-3 border">
          <div className="row mb-3 ms-3 me-3 mt-3">
            <div className="col">
              <label htmlFor="projectTypes" className="form-label fs-7 ">
                Program
              </label>
              <div id="new" style={{ height: "40px" }}>
                <ProjectTypes
                  id="projectTypes"
                  props={localProps}
                  handleChange={setSelectedProjectType}
                />
              </div>
            </div>
            <div className="col">
              <label htmlFor="products" className="form-label fs-7 ">
                Products
              </label>
              <MultiSelect
                id="products"
                options={products}
                placeholder="Select Product(s)"
                onChange={setSelectedProducts}
                value={selectedProducts}
              />
            </div>
            <div className="col">
              <label htmlFor="categories" className="form-label fs-7 ">
                Categories
              </label>
              <MultiSelect
                id="categories"
                options={categories}
                onChange={setSelectedCategories}
                placeholder="Select Category"
                value={selectedCategories}
              />
            </div>
            <div className="col">
              <label htmlFor="categories" className="form-label fs-7 ">
                Status
              </label>
              <MultiSelect
                id="statuses"
                value={selectedStatuses}
                options={statuses}
                onChange={setSelectedStatuses}
                placeholder="Select Status"
              />
            </div>
          </div>
          <div
            className="row ms-3 me-3 mb-4"
            style={
              maxDate < 0
                ? {
                    pointerEvents: "none",
                    opacity: "0.4",
                  }
                : {}
            }
          >
            <div>
              <label className="form-label fs-7 ">
                Selected Dates: {datesWithTitles[selectedMinDate]} -{" "}
                {datesWithTitles[selectedMaxDate]}
              </label>
            </div>
            <SliderObj />
          </div>
          <div className="row ms-3 me-3 mb-4 justify-content-around">
            <div id="faultContainmentChart" className="col">
              <div id="fault-containment-root">
                <FaultContainmentChart />
              </div>
            </div>
          </div>
          <div className="row ms-3 me-3 mb-4 justify-content-around">
            <div
              id="escapedDefectsByComponent"
              className="col col-6"
              style={{ marginBottom: "25px" }}
            >
              <div id="escaped--by-component-root">
                <EscapedDefectsByComponentChart />
              </div>
            </div>
            <div id="escapedDefectsByQAReason" className="col col-6">
              <div id="escaped--by-qa-root">
                <EscapedDefectsByQAReasonChart />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
