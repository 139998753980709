import React, { useState, useEffect } from "react";
import ErrorMessage from "../../components/common/ErrorMessage";
import ProjectTypes from "../../components/projects/ProjectTypes";
import IssueBacklogRegressionNewFeatureChart from "../../components/charts/issueBacklogRegressionNewFeaturesCharts/IssueBacklogRegressionNewFeatureChart";
import Projects from "../../services/Projects";
import Loading from "../../components/common/Loading";
import useProjects from "../../components/hooks/useProjects";
import { MultiSelect } from "react-multi-select-component";
import Button from "@mui/material/Button";
import "../../components/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import Jira from "../../services/Jira";

async function getJiraCustomInfo(token, projectType) {
  try {
    const resp = await Jira.getIssueBacklogRegression(token, projectType);
    return resp;
  } catch (err) {
    return {
      errMsg: err.message ? err.message : JSON.stringify(err),
    };
  }
}

async function getProjectTypes(token) {
  try {
    return await Projects.getProjectTypes(token);
  } catch (err) {
    return {
      errMsg: "Project Types list was not received! (" + err.message + ")",
    };
  }
}

export default function IssueBacklogRegressionNewFeaturesPage(props) {
  const { projectTypes, setProjectTypes } = useProjects();
  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);
  const [chartsInfo, setChartsInfo] = useState({});
  const [jiraItems, setJiraItems] = useState([]);
  const [selectedProjectType, setSelectedProjectType] = useState("");
  const [releaseFoundValues, setReleaseFoundValues] = useState([]);
  const [selectedReleaseFoundValues, setSelectedReleaseFoundValues] = useState(
    []
  );
  const [issueTypesValues, setIssueTypesValues] = useState([]);
  const [selectedIssueTypesValues, setSelectedIssueTypesValues] = useState([]);
  const [statusValues, setStatusValues] = useState([]);
  const [selectedStatusValues, setSelectedStatusValues] = useState([]);
  const [severityValues, setSeverityValues] = useState([]);
  const [selectedSeverityValues, setSelectedSeverityValues] = useState([]);
  const [priorityValues, setPriorityValues] = useState([]);
  const [selectedPriorityValues, setSelectedPriorityValues] = useState([]);
  const [dates, setDates] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedFiltersList, setSelectedFiltersList] = useState({
    selectedDates,
    selectedReleaseFoundValues,
    selectedIssueTypesValues,
    selectedStatusValues,
    selectedSeverityValues,
    selectedPriorityValues,
  });
  const [ableToEdit, setAbleToEdit] = useState(false);
  const [ableToCalculate, setAbleToCalculate] = useState(false);

  let localProps = {};
  Object.assign(localProps, props);
  localProps.style = "multy";
  localProps.styleValue = { height: "auto" };
  localProps.notAll = true;

  useEffect(() => {
    const handleProjectTypes = async (e) => {
      if (!projectTypes || Object.entries(projectTypes).length === 0) {
        const response = await getProjectTypes(props.token);
        if (response.message) {
          setErrorMessage(response.message);
          setProjectTypes([]);
        } else {
          if (!response || response.length < 1) {
            setErrorMessage("Projects types list is empty!");
          }
          setProjectTypes(response);
        }
      }
    };

    props.changeStyle("issueBacklogRegressionNewFeature");

    handleProjectTypes();
  }, [projectTypes]);

  const handleProjectTypeChange = async (prjTypeObj) => {
    let projectTypeName = prjTypeObj[0].projectTypeName;
    setLoading(true);
    setAbleToCalculate(false);
    setAbleToEdit(false);
    setSelectedProjectType([]);
    setIssueTypesValues([]);
    setPriorityValues([]);
    setReleaseFoundValues([]);
    setSeverityValues([]);
    setStatusValues([]);
    setSelectedReleaseFoundValues([]);
    setSelectedIssueTypesValues([]);
    setSelectedPriorityValues([]);
    setSelectedSeverityValues([]);
    setSelectedStatusValues([]);

    if (!chartsInfo[projectTypeName]) {
      let result = await getJiraCustomInfo(props.token, projectTypeName);
      if (false) {
        setErrorMessage(result.errMsg);
      } else {
        chartsInfo[projectTypeName] = result;
        transformChartInfo(result.data);
        setChartsInfo(chartsInfo);
        setJiraItems(result.data);
        setLoading(false);
      }
    }
  };

  const transformChartInfo = (data) => {
    const datesWithHours = Object.keys(data.trendingIssueMap);
    let availableDates = [];
    if (datesWithHours.length > 1) {
      availableDates = datesWithHours.map((element) => {
        let splitElement = element.split(" ");
        return splitElement[0];
      });
      availableDates = availableDates.sort();
    }

    let issueTypes = data.availableIssueTypes.filter(
      (issueType) => issueType !== null
    );
    let priorities = data.availablePriorities.filter(
      (priority) => priority !== null
    );
    let releaseFounds = data.availableTargetReleases.filter(
      (release) => release !== null && release !== ""
    );
    let severities = data.availableSeverities.filter(
      (severity) => severity !== null
    );
    let statuses = data.availableStatuses.filter((status) => status !== null);

    let datesOptions = availableDates.map((item) => {
      return {
        label: item,
        value: item,
      };
    });
    let issueTypesOptions = issueTypes.map((item) => {
      return {
        label: item,
        value: item,
      };
    });
    let prioritiesOptions = priorities.map((item) => {
      return {
        label: item,
        value: item,
      };
    });
    let releaseFoundsOptions = releaseFounds.map((item) => {
      return {
        label: item,
        value: item,
      };
    });
    let severitiesOptions = severities.map((item) => {
      return {
        label: item,
        value: item,
      };
    });
    let statusesOptions = statuses.map((item) => {
      return {
        label: item,
        value: item,
      };
    });
    setDates(datesOptions);
    setIssueTypesValues(issueTypesOptions);
    setPriorityValues(prioritiesOptions);
    setReleaseFoundValues(releaseFoundsOptions);
    setSeverityValues(severitiesOptions);
    setStatusValues(statusesOptions);
  };

  const editFilters = () => {
    setAbleToCalculate(false);
    setAbleToEdit(false);
    setSelectedFiltersList({
      selectedDates: [],
      selectedReleaseFoundValues: [],
      selectedIssueTypesValues: [],
      selectedStatusValues: [],
      selectedSeverityValues: [],
      selectedPriorityValues: [],
    });
  };

  const sendInformationTochart = () => {
    setAbleToCalculate(true);
    setAbleToEdit(true);
    setSelectedFiltersList({
      selectedDates,
      selectedReleaseFoundValues,
      selectedIssueTypesValues,
      selectedStatusValues,
      selectedSeverityValues,
      selectedPriorityValues,
    });
  };

  return (
    <div id="jira_custom_main_container">
      <div
        id="jira_custom_main_container_1"
        className="bg-light container-fluid pt-3 pb-3"
        style={
          loading
            ? {
                pointerEvents: "none",
                opacity: "0.4",
              }
            : {}
        }
      >
        <h4 className="ms-4">
          Cross-Project - Issue Backlog (Regession - NewFeature)
        </h4>
        <ErrorMessage errorMessage={errorMessage} />
        <div id="" className="container-fluid ">
          <div className="row ms-1 me-1 border pb-3 border-ligth border-1 rounded p-3">
            <div className="col col-sm-2 border-end border-ligth  mt-3">
              <label htmlFor="projectTypes" className="form-label fs-7 ">
                Program
              </label>
              <div id="new" style={{ height: "40px" }} className="me-3">
                <ProjectTypes
                  id="projectTypes"
                  props={localProps}
                  handleChange={handleProjectTypeChange}
                  placeholder="Select..."
                />
              </div>
            </div>
            <div className="col-sm-10">
              <div className="row ms-3">
                <div className="col-sm-4">
                  <label htmlFor="dates" className="form-label fs-7 mt-3">
                    Dates
                  </label>

                  <MultiSelect
                    id="dates"
                    options={dates}
                    placeholder="Select date"
                    onChange={setSelectedDates}
                    value={selectedDates}
                    disabled={ableToEdit}
                  />
                </div>
                <div className="col-sm-4">
                  <label
                    htmlFor="releaseFound"
                    className="form-label fs-7 mt-3"
                  >
                    Target Releases
                  </label>
                  <MultiSelect
                    id="releaseFound"
                    options={releaseFoundValues}
                    placeholder="Select Release Found"
                    onChange={setSelectedReleaseFoundValues}
                    value={selectedReleaseFoundValues}
                    disabled={ableToEdit}
                  />
                </div>
                <div className="col-sm-4 mt-3">
                  <label htmlFor="issueTypes" className="form-label fs-7 ">
                    Issue Types
                  </label>
                  <MultiSelect
                    id="issueTypes"
                    options={issueTypesValues}
                    placeholder="Select Issue Types"
                    onChange={setSelectedIssueTypesValues}
                    value={selectedIssueTypesValues}
                    disabled={ableToEdit}
                  />
                </div>
              </div>
              <div className="row ms-3">
                <div className="col-sm-4 mt-3">
                  <label htmlFor="status" className="form-label fs-7 ">
                    Status
                  </label>
                  <MultiSelect
                    id="status"
                    options={statusValues}
                    placeholder="Select Status"
                    onChange={setSelectedStatusValues}
                    value={selectedStatusValues}
                    disabled={ableToEdit}
                  />
                </div>
                <div className="col-sm-4 mt-3">
                  <label htmlFor="severity" className="form-label fs-7 ">
                    Severity
                  </label>
                  <MultiSelect
                    id="severity"
                    options={severityValues}
                    placeholder="Select Severity"
                    onChange={setSelectedSeverityValues}
                    value={selectedSeverityValues}
                    disabled={ableToEdit}
                  />
                </div>
                <div className="col-sm-4 mt-3">
                  <label htmlFor="priority" className="form-label fs-7 ">
                    Priority
                  </label>
                  <MultiSelect
                    id="priority"
                    options={priorityValues}
                    placeholder="Select Priority"
                    onChange={setSelectedPriorityValues}
                    value={selectedPriorityValues}
                    disabled={ableToEdit}
                  />
                </div>
              </div>
              <div className="row ms-3 ">
                <div className="col mt-5 d-flex justify-content-end gap-3">
                  <Button
                    variant="outlined"
                    disabled={!ableToCalculate}
                    onClick={() => {
                      editFilters();
                    }}
                  >
                    Edit filters
                  </Button>
                  <Button
                    variant="contained"
                    disabled={ableToCalculate}
                    onClick={() => {
                      sendInformationTochart();
                    }}
                  >
                    Calculate
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div className="row ms-3 me-3 mb-4 mt-3 justify-content-around">
            {loading ? (
              <Loading />
            ) : (
              <div id="jira-custom-chart" className="col">
                <div id="jira-custom-chart-root">
                  <IssueBacklogRegressionNewFeatureChart
                    selectedFiltersList={selectedFiltersList}
                    backLogItems={jiraItems}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
