import { useState } from "react";

export default function useNavTabStyles() {
  const getNavTabStyles = () => {
    const navTabStylesString = sessionStorage.getItem("navTabStyles");
    if (!navTabStylesString || navTabStylesString === "undefined") {
      return {
        dashboard: "active",
        crossProject: "",
        details: "",
        serviceInsights: "",
      };
    }
    return JSON.parse(navTabStylesString);
  };

  const [navTabStyles, setNavTabStyles] = useState(getNavTabStyles());

  const saveNavTabStyles = (currNavTabStyles) => {
    sessionStorage.setItem("navTabStyles", JSON.stringify(currNavTabStyles));
    setNavTabStyles(currNavTabStyles);
  };

  return {
    setNavTabStyles: saveNavTabStyles,
    navTabStyles,
  };
}
