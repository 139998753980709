import FetchUtils from "./FetchUtils";

class ADO {
  static async submitSync(token, dataObject) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Origin: process.env.REACT_APP_ADO_PROJECTS_ITERATIONS_CYCLES_URL,
      };

      let url =
        process.env.REACT_APP_ADO_PROJECTS_ITERATIONS_CYCLES_URL +
        "/ado/ado-sync";
      let body = JSON.stringify(dataObject);
      const data = await FetchUtils.fetch(url, "POST", headers, body);

      if (!data || data.status !== "CREATED") {
        let errorMsg =
          "Error submitting the ADO sync job: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { message: errorMsg };
      }
      return data.data;
    } catch (err) {
      return {
        message:
          "Error submitting the ADO sync job (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getADOProjectsList(token, program) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const data = await FetchUtils.fetch(
        process.env.REACT_APP_ADO_PROJECTS_URL +
          `/adolistener/${program}/adoProjects`,
        "GET",
        headers
      );

      if (!data || data.status !== "OK") {
        let errorMsg =
          "Project list cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { message: errorMsg };
      }
      return data.data;
    } catch (err) {
      return {
        message:
          "Error getting project details (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getADOProjectFields(token, program, queryParams) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const data = await FetchUtils.fetch(
        process.env.REACT_APP_ADO_PROJECTS_URL +
          `/adolistener/${program}/adoProjectFields?${queryParams}`,
        "GET",
        headers
      );

      if (!data || data.status !== "OK") {
        let errorMsg =
          "Project fields cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { message: errorMsg };
      }
      return data.data;
    } catch (err) {
      return {
        message:
          "Error getting project details (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getADOAreaValuesAndReleases(token, program, payload) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const data = await FetchUtils.fetch(
        process.env.REACT_APP_ADO_PROJECTS_URL +
          `/adolistener/${program}/adoProjectFieldsValues`,
        "POST",
        headers,
        payload
      );

      if (!data || data.status !== "OK") {
        let errorMsg =
          "Project Area Values and Releases field values cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { message: errorMsg };
      }
      return data.data;
    } catch (err) {
      return {
        message:
          "Error getting project details (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getADOProjectWorkItemTypes(token, program, queryParams) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const data = await FetchUtils.fetch(
        process.env.REACT_APP_ADO_PROJECTS_URL +
          `/adolistener/${program}/adoProjectWorkItemTypes?${queryParams}`,
        "GET",
        headers
      );

      if (!data || data.status !== "OK") {
        let errorMsg =
          "Project Work Item Types fields cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { message: errorMsg };
      }
      return data.data;
    } catch (err) {
      return {
        message:
          "Error getting project details (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getADOIterations(token, projectName) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const data = await FetchUtils.fetch(
        process.env.REACT_APP_ADO_PROJECTS_ITERATIONS_CYCLES_URL +
          `/ado/iteration?projectName=${projectName}`,
        "GET",
        headers
      );

      return data.data;
    } catch (err) {
      return {
        message:
          "Error getting project details (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getADOIterationDates(token, projectName, path) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const data = await FetchUtils.fetch(
        process.env.REACT_APP_ADO_PROJECTS_ITERATIONS_CYCLES_URL +
          `/ado/iterationDates?iterationPath=${path}&projectName=${projectName}`,
        "GET",
        headers
      );

      if (!data || data.status !== "OK") {
        let errorMsg =
          "Project details cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { message: errorMsg };
      }
      return data.data;
    } catch (err) {
      return {
        message:
          "Error getting project details (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getADOTestPlanReleases(token, program, projects) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const data = await FetchUtils.fetch(
        process.env.REACT_APP_ADO_PROJECTS_URL +
          `/adolistener/${program}/adoProjectTestPlan?adoProject=${projects}`,
        "GET",
        headers
      );

      if (!data || data.status !== "OK") {
        let errorMsg =
          "Project Test Plan Releases list cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { message: errorMsg };
      }
      return data.data;
    } catch (err) {
      return {
        message:
          "Error getting project details (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getADOTestSuites(token, program, project, testPlansIds) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const data = await FetchUtils.fetch(
        process.env.REACT_APP_ADO_PROJECTS_URL +
          `/adolistener/${program}/${project}/suitesName?planIds=${testPlansIds}`,
        "GET",
        headers
      );

      if (!data || data.status !== "OK") {
        let errorMsg =
          "Project Test Suites list cannot be taken from the server: " +
          (data?.message
            ? data.message
            : data.error
            ? data.error
            : "Unexpected server error");
        return { message: errorMsg, statusCode: "ERROR" };
      }
      return data.data;
    } catch (err) {
      return {
        message:
          "Error getting project details (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getADOTestExecutionChartsInfo(token, projectId, body) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      let promises = [];

      promises.push(
        FetchUtils.fetch(
          process.env.REACT_APP_ADO_PROJECTS_ITERATIONS_CYCLES_URL +
            `/ado/manual-vs-automated-test`,
          "POST",
          headers,
          JSON.stringify(body)
        )
      );

      let responses = await Promise.allSettled(promises);
      let objectDataResponse = {
        manualVsAutomatedTests: {},
      };
      let objectDataResponseStrings = ["manualVsAutomatedTests"];

      responses.forEach((chart, index) => {
        objectDataResponse[objectDataResponseStrings[index]] = chart.value;
      });

      return objectDataResponse;
    } catch (err) {
      return {
        errMsg:
          "Error getting ADO info (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getADOChartsInfo(token, projectId, body) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      let promises = [];
      promises.push(
        FetchUtils.fetch(
          process.env.REACT_APP_ADO_PROJECTS_ITERATIONS_CYCLES_URL +
            `/ado/defect-current-release`,
          "POST",
          headers,
          JSON.stringify(body)
        )
      );
      promises.push(
        FetchUtils.fetch(
          process.env.REACT_APP_ADO_PROJECTS_ITERATIONS_CYCLES_URL +
            `/ado/targeted-open-defects`,
          "POST",
          headers,
          JSON.stringify(body)
        )
      );
      promises.push(
        FetchUtils.fetch(
          process.env.REACT_APP_ADO_PROJECTS_ITERATIONS_CYCLES_URL +
            `/ado/items-resolved-status`,
          "POST",
          headers,
          JSON.stringify(body)
        )
      );
      promises.push(
        FetchUtils.fetch(
          process.env.REACT_APP_ADO_PROJECTS_ITERATIONS_CYCLES_URL +
            `/ado/resolved-assigned-items`,
          "POST",
          headers,
          JSON.stringify(body)
        )
      );
      promises.push(
        FetchUtils.fetch(
          process.env.REACT_APP_ADO_PROJECTS_ITERATIONS_CYCLES_URL +
            `/ado/OpenCloseDefectTrendAge`,
          "POST",
          headers,
          JSON.stringify(body)
        )
      );
      promises.push(
        FetchUtils.fetch(
          process.env.REACT_APP_ADO_PROJECTS_ITERATIONS_CYCLES_URL +
            `/ado/defect-churn`,
          "POST",
          headers,
          JSON.stringify(body)
        )
      );

      promises.push(
        FetchUtils.fetch(
          process.env.REACT_APP_ADO_PROJECTS_ITERATIONS_CYCLES_URL +
            `/ado/open-issues-backlog`,
          "POST",
          headers,
          JSON.stringify(body)
        )
      );

      let responses = await Promise.allSettled(promises);
      let objectDataResponse = {
        defectCurrentReleaseInfo: {},
        targetedOpenDefectsInfo: {},
        itemsWithResolvedStatus: {},
        resolvedAndAssignedItems: {},
        defectAgeChartMap: {},
        defectChurn: {},
        openBacklogInfo: {},
      };
      let objectDataResponseStrings = [
        "defectCurrentReleaseInfo",
        "targetedOpenDefectsInfo",
        "itemsWithResolvedStatus",
        "resolvedAndAssignedItems",
        "defectAgeChartMap",
        "defectChurn",
        "openBacklogInfo",
      ];

      responses.forEach((chart, index) => {
        objectDataResponse[objectDataResponseStrings[index]] = chart.value;
      });

      return objectDataResponse;
    } catch (err) {
      return {
        errMsg:
          "Error getting ADO info (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }

  static async getADOSteercoChartsInfo(token, projectId, body) {
    try {
      const headers = {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      let promises = [];

      promises.push(
        FetchUtils.fetch(
          process.env.REACT_APP_ADO_PROJECTS_ITERATIONS_CYCLES_URL +
            `/ado/sterco-defects
          `,
          "POST",
          headers,
          JSON.stringify(body)
        )
      );

      let responses = await Promise.allSettled(promises);

      let key = responses.length - 1;

      const data = responses[key].value?.data;

      return data;
    } catch (err) {
      return {
        errMsg:
          "Error getting ADO info (" + err.message
            ? err.message
            : JSON.stringify(err) + ")",
      };
    }
  }
}

export default ADO;
