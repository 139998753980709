import { Chart } from "react-google-charts";

//====================================================================================
// Cross-Project - Defect Analytics - Escaped Defects
//====================================================================================

const getxAxisStyle = (tableLength) => {
  let vAxisLabelStyle = {};
  let hasNumberOfXLabelsLarge = tableLength > 5;

  if (hasNumberOfXLabelsLarge) {
    vAxisLabelStyle = {
      slantedTextAngle: 60,
      textStyle: { fontSize: "10" },
    };
  } else {
    vAxisLabelStyle = {
      textStyle: { fontSize: "11" },
    };
  }

  return vAxisLabelStyle;
};

export default function EscapedDefectsChart(props) {
  //chart will receive array of selected projects with needed data
  var projectsData = props.chartsInfo ? props.chartsInfo : [];

  var dataTable = [];
  dataTable.push(["Project", "Escaped Defects count"]);

  //prepare array of empty values
  var emptyDataArray = ["Escaped Issues", 0];

  // set default empty values if no results received from server
  if (!projectsData || Object.keys(projectsData).length === 0) {
    dataTable.push(emptyDataArray);
  } else {
    for (let projectId in projectsData) {
      if (projectId == 0) {
        continue;
      }
      let data = projectsData[projectId];
      var dataArray = [];
      //find project name by id
      let projectName = props.projects?.filter((item) => {
        return item.projectId + "" === projectId;
      })[0]?.projectName;

      dataArray.push(projectName);
      let value = data.escapeDefectCount ? data.escapeDefectCount : 0;
      dataArray.push(value);
      dataTable.push(dataArray);
    }
  }

  var options = {
    title: "Escaped Defects",
    titleTextStyle: { fontSize: "16" },
    bars: "vertical",
    hAxis: getxAxisStyle(dataTable.length),
    vAxis: { format: "0" },
    chartArea: { left: 70, top: 50, width: "65%", height: "65%" },
  };

  return (
    <div>
      <Chart
        chartType="ColumnChart"
        width="100%"
        height="400px"
        data={dataTable}
        options={options}
      />
    </div>
  );
}
