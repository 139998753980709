import React, { useEffect, useState } from "react";
import Qtest from "../../services/Qtest";
import MultiRangeSlider from "multi-range-slider-react";
import ErrorMessage from "../../components/common/ErrorMessage";
import ProjectTypes from "../../components/projects/ProjectTypes";
import TestCasesDevelopmentModal from "../../components/common/modals/TestCasesDevelopmentModal";
import Projects from "../../services/Projects";
import { Chart } from "react-google-charts";
import { MultiSelect } from "react-multi-select-component";
import "../../components/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";

async function getProjects(token) {
  try {
    return await Projects.getAllProjects(token);
  } catch (err) {
    return {
      message: "Projects cannot be retrieved! (" + JSON.stringify(err) + ")",
    };
  }
}

async function getAutomationTrendingInfo(token, projectTypeName) {
  try {
    const resp = await Qtest.getAutomationTrendingChartInfo(
      token,
      projectTypeName
    );
    return resp;
  } catch (err) {
    return {
      message:
        "Error connecting to API for Automation Trending info (" + err.message
          ? err.message
          : JSON.stringify(err) + ")",
    };
  }
}

const AutomationTrendingPage = (props) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [projects, setProjects] = useState([]);
  const [selectedProjectType, setSelectedProjectType] = useState("");
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectedAutomationTrendingInfo, setSelectedAutomationTrendingInfo] =
    useState();
  const [minDate, setMinDate] = useState(0);
  const [maxDate, setMaxDate] = useState(-1);
  const [selectedMinDate, setSelectedMinDate] = useState(0);
  const [selectedMaxDate, setSelectedMaxDate] = useState(0);
  const [datesWithTitles, setDatesWithTitles] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState();
  const [dates, setDates] = useState([]);

  let localProps = {};
  Object.assign(localProps, props);
  localProps.style = "multy";
  localProps.styleValue = { height: "auto" };

  useEffect(() => {
    props.changeStyle("automationTrending");
    setErrorMessage(false);
    getAutomationTrendingData();
  }, [selectedProjectType]);

  useEffect(() => {
    const handleProjectsInfo = async (e) => {
      if (!projects || Object.entries(projects).length === 0) {
        setLoading(true);
        setErrorMessage("");
        const response = await getProjects(props.token);
        setLoading(false);
        if (response.errMsg) {
          setErrorMessage(response.errMsg);
          setProjects([]);
        } else {
          if (!(response?.length > 0)) {
            setErrorMessage("Projects list is empty!");
            setProjects([]);
          } else {
            setErrorMessage(null);
            setProjects(response);
          }
        }
      }
    };
    handleProjectsInfo();
  }, [projects]);

  useEffect(() => {
    setErrorMessage(null);
    if (!projects) {
      return;
    }

    let filteredByPrjTypeProjects = projects;

    if (
      selectedProjectType?.[0] &&
      selectedProjectType[0].projectTypeId !== 0
    ) {
      filteredByPrjTypeProjects = projects.filter((item) => {
        return item.projectTypeName === selectedProjectType;
      });
    }
    let tmpPrjs = filteredByPrjTypeProjects.map((item) => {
      return { label: item.projectName, value: item.projectId };
    });

    setSelectedProjects([]);
    setFilteredProjects(tmpPrjs);
  }, [selectedProjectType]);

  const getAutomationTrendingData = async () => {
    if (selectedProjectType && selectedProjectType !== "All") {
      setLoading(true);
      const response = await getAutomationTrendingInfo(
        props.token,
        selectedProjectType
      );
      if (response.errMsg) {
        setErrorMessage(response.errMsg);
      } else {
        setErrorMessage("");
        setSelectedAutomationTrendingInfo(
          response.testCaseAutomationTrendingMapDto
        );

        let min = 0,
          max = 0,
          datesArr = [],
          datesMap = {},
          tempDatesMap = {},
          tempDatesArray = Object.keys(
            response.testCaseAutomationTrendingMapDto.automationTrendingMap
          );

        tempDatesArray.map((item) => {
          let transformDateFormat = item.split(" ")[0];
          let currDateValue = Date.parse(transformDateFormat);

          if (min === 0 || min > currDateValue) {
            min = currDateValue;
          }
          if (max < currDateValue) {
            max = currDateValue;
          }
          datesArr.push(currDateValue);
          tempDatesMap[currDateValue] = transformDateFormat;
        });

        datesArr.sort();
        datesArr.map((item, index) => {
          if (min === item) {
            min = index;
          }
          if (max === item) {
            max = index;
          }
          datesMap[index] = tempDatesMap[item];
          return index;
        });
        let dates = datesArr.sort((a, b) => {
          return Date.parse(a) - Date.parse(b);
        });

        setDates(dates);
        setSelectedMinDate(min);
        setSelectedMaxDate(max);
        setMinDate(min);
        setMaxDate(max);
        setDatesWithTitles(datesMap);
      }
    }
    setLoading(false);
  };

  const Slider = () => {
    const handleInput = (e) => {
      setSelectedMinDate(e.minValue);
      setSelectedMaxDate(e.maxValue);
    };

    return (
      <MultiRangeSlider
        min={minDate}
        max={maxDate}
        step={1}
        ruler={false}
        label={false}
        preventWheel={false}
        minValue={selectedMinDate}
        maxValue={selectedMaxDate}
        onInput={(e) => {
          handleInput(e);
        }}
      />
    );
  };

  const handleChangeProject = (e) => {
    setSelectedProjectType(e[0].projectTypeName);
    setSelectedMinDate(0);
    setSelectedMaxDate(0);
    setMinDate(0);
    setMaxDate(0);
    setDatesWithTitles([]);
  };

  const getTooltip = (date, name, values) => {
    let statuses = {
      Automated: "Automated",
      "Automation Candidate": "Automation Candidate",
      "Automation Not Possible": "Automation Not Possible",
      "In Progress": "In Progress",
      "Manual Only": "Manual Only",
      "Not Evaluated": "Not Evaluated",
    };

    var tooltip =
      '<div align="left" style="width: auto; float: left; table-layout: auto">' +
      '<table class="table table-hover table-condensed table-sm" >' +
      "<thead><tr><th>" +
      date +
      "</th></tr></thead>" +
      "<tbody>";

    for (let statValue in statuses) {
      if (statuses[statValue] === name) {
        tooltip = tooltip + '<tr class="table-danger"><td><b>' + name + "</b>";
      } else {
        tooltip = tooltip + "<tr><td>" + statuses[statValue];
      }
      tooltip =
        tooltip +
        ": <b>" +
        Math.abs(values[statValue] ? values[statValue] : 0) +
        "</b></td></tr>";
    }
    tooltip = tooltip + "</tbody></table></div>";

    return tooltip;
  };

  const AutomationTrendingChartTable = ({ automationTrendingData }) => {
    let isDataAvailable = automationTrendingData
      ? Object.keys(automationTrendingData.automationTrendingMap)
      : [];
    let dataTable = [];
    let emptyDataTableHeader = ["Date", "Status"];
    let dataTableHeader = [
      "Date",
      "Automated",
      { type: "string", role: "tooltip", p: { html: true } },
      "Automation Candidate",
      { type: "string", role: "tooltip", p: { html: true } },
      "Automation Not Possible",
      { type: "string", role: "tooltip", p: { html: true } },
      "In Progress",
      { type: "string", role: "tooltip", p: { html: true } },
      "Manual Only",
      { type: "string", role: "tooltip", p: { html: true } },
      "Not Evaluated",
      { type: "string", role: "tooltip", p: { html: true } },
    ];

    if (!automationTrendingData || isDataAvailable.length <= 0) {
      dataTable.push(emptyDataTableHeader);
      return dataTable.push([0, 0]);
    } else {
      dataTable.push(dataTableHeader);
    }

    let automationTrendingIssues = Object.entries(
      automationTrendingData?.automationTrendingMap
    );

    automationTrendingIssues.forEach((date) => {
      let transformDateFormat = date[0].split(" ")[0];
      let statusCounter = {
        Automated: 0,
        "Automation Candidate": 0,
        "Automation Not Possible": 0,
        "In Progress": 0,
        "Manual Only": 0,
        "Not Evaluated": 0,
      };

      date[1].forEach((issue) => {
        statusCounter[issue.automationStatus]++;
      });

      dataTable.push([
        transformDateFormat,
        statusCounter["Automated"],
        getTooltip(transformDateFormat, "Automated", statusCounter),
        statusCounter["Automation Candidate"],
        getTooltip(transformDateFormat, "Automation Candidate", statusCounter),
        statusCounter["Automation Not Possible"],
        getTooltip(
          transformDateFormat,
          "Automation Not Possible",
          statusCounter
        ),
        statusCounter["In Progress"],
        getTooltip(transformDateFormat, "In Progress", statusCounter),
        statusCounter["Manual Only"],
        getTooltip(transformDateFormat, "Manual Only", statusCounter),
        statusCounter["Not Evaluated"],
        getTooltip(transformDateFormat, "Not Evaluated", statusCounter),
      ]);
    });

    dataTable.sort((a, b) => Date.parse(a[0]) - Date.parse(b[0]));

    let filteredDataTable = dataTable.filter((date) => {
      if (date[0] === "Date") {
        return date;
      }

      if (
        Date.parse(date[0]) >= Date.parse(datesWithTitles[selectedMinDate]) &&
        Date.parse(date[0]) <= Date.parse(datesWithTitles[selectedMaxDate])
      ) {
        return date;
      }
    });

    var chartEvents = [
      {
        eventName: "ready",
        callback: ({ chartWrapper, google }) => {
          var handler = async function (e) {
            const chart = chartWrapper.getChart();
            var selection = chart.getSelection();
            if (!selection || selection.length < 1) {
              return;
            }
            var row = selection[0].row;
            var col = selection[0].column;
            var selectedStatus = dataTable[0][col];
            var selectedDate = dataTable[row + 1][0];

            let automationTrendingIssuesArray = automationTrendingIssues.map(
              (date) => {
                let transformDateFormat = date[0].split(" ")[0];
                return [transformDateFormat, date[1]];
              }
            );
            let testCaseInfoData = automationTrendingIssuesArray.filter(
              (date) => {
                if (date[0] === selectedDate) {
                  return date;
                }
              }
            );
            let testCasesArray = [];

            testCaseInfoData[0][1]?.forEach((item) => {
              if (item.automationStatus === selectedStatus) {
                testCasesArray.push({
                  testCaseId: item.documentKey,
                  testCaseDescription: item.name,
                  link: item.selfLink,
                });
              }
            });

            setModalData({
              title: `${selectedDate} : ${selectedStatus}`,
              testCasesData: testCasesArray,
            });
            setShowModal(true);
          };

          google.visualization.events.addListener(
            chartWrapper.getChart(),
            "select",
            handler
          );
        },
      },
    ];

    var options = {
      title: "Automation Trending",
      titleTextStyle: { fontSize: "16" },
      bars: "vertical",
      isStacked: true,
      tooltip: { isHtml: true },
      chartArea: { left: 70, top: 50, width: "65%", height: "65%" },
      vAxis: { format: "0" },
    };

    return (
      <Chart
        chartType="ColumnChart"
        width="100%"
        height="400px"
        data={filteredDataTable}
        options={options}
        chartEvents={chartEvents}
      />
    );
  };

  return (
    <div id="main_container">
      {loading && (
        <div className="d-flex justify-content-center bg-transparent align-items-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      <TestCasesDevelopmentModal
        isOpen={showModal}
        setShowModal={setShowModal}
        setModalData={setModalData}
        data={modalData}
        token={props.token}
      />
      <div
        id="main_container_1"
        className="bg-light  container-fluid pt-3 pb-3"
        style={
          loading
            ? {
                pointerEvents: "none",
                opacity: "0.4",
              }
            : {}
        }
      >
        <h4 className="ms-4">Automation Trending</h4>
        <ErrorMessage errorMessage={errorMessage} />
        <div id="" className="container-fluid pb-3 border">
          <div>
            <h1 />
          </div>
          <div className="row mb-3 ms-3 me-3">
            <div className="col">
              <label
                htmlFor="projectTypes"
                className="form-label fs-7"
                style={{ marginBottom: "13px" }}
              >
                Program
              </label>
              <div id="new" style={{ height: "40px" }}>
                <ProjectTypes
                  id="projectTypes"
                  props={localProps}
                  handleChange={handleChangeProject}
                />
              </div>
            </div>
            <div className="col">
              <label
                htmlFor="projects"
                className="form-label fs-7"
                style={{ marginBottom: "13px" }}
              >
                Projects
              </label>
              <MultiSelect
                id="projects"
                options={filteredProjects}
                placeholder="Select Project(s)"
                onChange={setSelectedProjects}
                value={selectedProjects}
              />
            </div>
          </div>
          <div
            className="row ms-3 me-3 mb-4"
            style={
              maxDate < 0
                ? {
                    pointerEvents: "none",
                    opacity: "0.4",
                  }
                : {}
            }
          >
            <div>
              <label className="form-label fs-7">
                Selected Dates: {datesWithTitles[selectedMinDate]} -{" "}
                {datesWithTitles[selectedMaxDate]}
              </label>
            </div>
            <Slider />
            <div
              className="row ms-3 me-3 mb-4 justify-content-around"
              style={{ marginTop: "25px" }}
            >
              <div id="automationTrendingChart" className="col">
                <div id="automation-trending-root">
                  <AutomationTrendingChartTable
                    automationTrendingData={selectedAutomationTrendingInfo}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AutomationTrendingPage;
