import React, { useState, useEffect } from "react";
import TrashButton from "./TrashButton";
import PlusButton from "./PlusButton";
import DatePicker from "react-datepicker";
import useProjectDetails from "../hooks/useProjectDetails";
import "../css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "../css/custom.min.css";
import "react-datepicker/dist/react-datepicker.css";

function Milestones(props) {
  const { projectDetails } = useProjectDetails();
  const [milestones, setMilestones] = useState([]);
  const [isPopulated, setIsPopulated] = useState(false);

  useEffect(() => {
    if (projectDetails?.projectMilestones) {
      projectDetails.projectMilestones.forEach((item) => {
        if (item.startDate) {
          item.startDateTimeStamp =
            Date.parse(item.startDate) +
            new Date(item.startDate).getTimezoneOffset() * 60000;
        }
        if (item.endDate) {
          item.endDateTimeStamp =
            Date.parse(item.endDate) +
            new Date(item.endDate).getTimezoneOffset() * 60000;
        }
      });
      setMilestones(projectDetails.projectMilestones);
    }
  }, [projectDetails]);

  useEffect(() => {
    //check if list of milestones is not empty
    //and that at least one milestone has not empty name
    let result = milestones.some((item) => {
      if (item.milestone) return true;
      return false;
    });
    setIsPopulated(result);
  }, [milestones]);

  const handlePlusForMilestones = () => {
    let tmp = JSON.parse(JSON.stringify(milestones));
    let ids = tmp.map((item) => {
      return item.id;
    });
    ids.sort(function (a, b) {
      return a - b;
    });
    let id = ids.length > 0 ? ids[ids.length - 1] + 1 : 1;
    let obj = {
      id: id,
      milestone: "",
      startDate: null,
      endDate: null,
    };
    if (projectDetails?.projectId) {
      obj["projectId"] = projectDetails.projectI;
    }
    tmp.push(obj);
    setMilestones(tmp);
  };

  const handleDeleteForMilestones = (id) => {
    let tmpMilestones = JSON.parse(JSON.stringify(milestones));
    let indexToRemove;
    tmpMilestones.map((item, index) => {
      if (item.id === id) {
        indexToRemove = index;
      }
      return item;
    });
    if (indexToRemove > -1) {
      tmpMilestones.splice(indexToRemove, 1);
    }

    setMilestones(tmpMilestones);
    props.props.handleMilestonesChange(tmpMilestones);
  };

  const handleNameChange = (event, id) => {
    let tmp = JSON.parse(JSON.stringify(milestones));
    for (let key in tmp) {
      if (tmp[key].id === id) {
        tmp[key].milestone = event.target.value;
      }
    }
    setMilestones(tmp);
    props.props.handleMilestonesChange(tmp);
  };

  const handleStartDateChange = (date, id) => {
    let tmpMilestones = JSON.parse(JSON.stringify(milestones));
    for (let key in tmpMilestones) {
      if (tmpMilestones[key].id === id) {
        tmpMilestones[key].startDate = date
          ? date.getFullYear() +
            "-" +
            (date.getMonth() + 1) +
            "-" +
            date.getDate()
          : null;
        tmpMilestones[key].startDateTimeStamp = date ? date.getTime() : 0;
        break;
      }
    }
    setMilestones(tmpMilestones);
    props.props.handleMilestonesChange(tmpMilestones);
  };
  const handleEndDateChange = (date, id) => {
    let tmpMilestones = JSON.parse(JSON.stringify(milestones));
    for (let key in tmpMilestones) {
      if (tmpMilestones[key].id === id) {
        tmpMilestones[key].endDate = date
          ? date.getFullYear() +
            "-" +
            (date.getMonth() + 1) +
            "-" +
            date.getDate()
          : null;
        tmpMilestones[key].endDateTimeStamp = date ? date.getTime() : 0;
        break;
      }
    }
    setMilestones(tmpMilestones);
    props.props.handleMilestonesChange(tmpMilestones);
  };

  return (
    <div className="row mb-4">
      <div className="col md-6">
        <label htmlFor="testReleases" className="form-label fs-7 fw-bold">
          {props.isActive ? (
            <span style={isPopulated ? {} : { color: "red" }}>* </span>
          ) : null}
          Milestones:
        </label>
        <table className="table table-bordered table-sm ">
          <thead>
            <tr
              style={{ backgroundColor: "#e9ecef", color: "#212529" }}
              className="border-bottom-0 "
            >
              <th scope="col" className="fw-normal ">
                Milestone
              </th>
              <th scope="col" className="fw-normal" style={{ width: "20%" }}>
                Start Date
              </th>
              <th scope="col" className="fw-normal" style={{ width: "20%" }}>
                End Date
              </th>
              <th scope="col" className="fw-normal" style={{ width: "5%" }}>
                <PlusButton
                  disabled={!!props.disabled}
                  onClick={handlePlusForMilestones}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {milestones &&
              milestones.map((milestone, index) => {
                return (
                  <tr className="bg-body" key={index}>
                    <td>
                      <input
                        className="form-control "
                        value={milestone.milestone}
                        disabled={!!props.disabled}
                        onChange={(e) => handleNameChange(e, milestone.id)}
                        autoComplete="off"
                      />
                    </td>
                    <td>
                      <DatePicker
                        id={"milestone-start-date-" + index}
                        className="form-control "
                        dateFormat={"yyyy-MM-dd"}
                        selected={milestone.startDateTimeStamp}
                        disabled={!!props.disabled}
                        onChange={(date) =>
                          handleStartDateChange(date, milestone.id)
                        }
                      ></DatePicker>
                    </td>
                    <td>
                      <DatePicker
                        id={"milestone-end-date-" + index}
                        className="form-control "
                        dateFormat={"yyyy-MM-dd"}
                        selected={milestone.endDateTimeStamp}
                        disabled={!!props.disabled}
                        onChange={(date) =>
                          handleEndDateChange(date, milestone.id)
                        }
                      ></DatePicker>
                    </td>
                    <td>
                      <TrashButton
                        disabled={!!props.disabled}
                        id={index}
                        onClick={() => handleDeleteForMilestones(milestone.id)}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Milestones;
