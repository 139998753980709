import { useState } from "react";

export default function useEditProjectDetails() {
  const getEditProjectDetails = () => {
    const projectDetailsStr = localStorage.getItem("currentEditProjectDetails");
    if (
      projectDetailsStr === "null" ||
      projectDetailsStr === "undefined" ||
      projectDetailsStr === "{}"
    ) {
      return {
        projectId: -1,
        lpJiraRelations: [{}],
        lpIterations: [{}],
        lpQtestRelations: [{}],
        projectMilestones: [{}],
      };
    }
    const projectDetails = JSON.parse(projectDetailsStr);
    return projectDetails;
  };

  const [projectEditDetails, setEditProjectDetails] = useState(
    getEditProjectDetails()
  );

  const saveEditProjectDetails = (projectDetails) => {
    localStorage.setItem(
      "currentEditProjectDetails",
      JSON.stringify(projectDetails)
    );
    setEditProjectDetails(projectDetails);
  };

  const clearEditProjectDetails = () => {
    let empty = {
      projectId: -1,
      lpJiraRelations: [{}],
      lpIterations: [{}],
      lpQtestRelations: [{}],
      projectMilestones: [
        {
          id: 1,
          milestone: "",
          startDate: "",
          endDate: "",
          projectId: "",
        },
      ],
      projectTestSource: "qtest",
      projectName: "",
      isActive: false,
      startDate: "",
      endDate: "",
      testPlanLink: "",
    };
    saveEditProjectDetails(empty);
  };

  return {
    setEditProjectDetails: saveEditProjectDetails,
    projectEditDetails: projectEditDetails,
    clearEditProjectDetails,
  };
}
